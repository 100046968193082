import http from "../http-common";

class CargoService {

    create(data) {
        return http.post("/cargo",data);
    }

    getAll() {
        return http.get("/cargo");
    }

    updateCargo(id, data) {
        return http.put(`/cargo/${id}`,data);
    }

    eliminarCargo(id) {
        return http.delete(`/cargo/${id}`);
    }
}

export default new CargoService();