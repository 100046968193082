import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import NavBarComponent from "./navbar.component";

const Inicio = () => {
    const navigate = useNavigate();
    //const { getSession, logout, usuario } = useContext(AccountContext);
    const [loading, setLoading] = useState(false);//usuario == null ? true : false
    const auth = useAuth();

    useEffect(() => {
        /* getSession().then((session)=>{
            console.log("SESSION INICIO", session);
        }); */
    }, []);

    const ingresarTrabajador = () => {
        navigate("/ingtrabajador");
    }

    const listaTrabajadores = () => {
        navigate("/trabajadores");
    }

    return (
        <div>
            <NavBarComponent usuario={auth.user} auth={auth} />
            <div className="mt-3">
            {/* <LoadingOverlay active={loading} spinner={<PacmanLoader color="#8BC7FA" />} > */}
                <Container>
                    <div className="bg-dark text-secondary px-4 py-5 text-center">
                        <div style={{backgroundImage: 'url(img/image.png)'}}>
                        <div className="py-5">
                            {/* <Image className="d-block mx-auto mb-4" src="img/logo_csi.jpg" width="160" height="100" /> */}
                            <div className="p-5"></div>
                            <h1 className="display-5 fw-bold text-white">Sistema Reclutamiento</h1>
                            <div className="col-lg-6 mx-auto">
                                {/* <p className="fs-5 mb-4">
                                    Bienvenidos al sistema de reclutamiento de CSI Ltda.
                                </p> */}
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="mt-3"></div>
                    <Row>
                        {console.log("USUARIO INICIO", auth.user)}
                        {auth.user != null ? auth.user.perfiles.find(v => v.nombre === "Reclutador") && (
                            <Col className="text-center">
                                <Image src="img/agregar.png" roundedCircle width="140" height="140" />
                                <h2>Ingresar Trabajador</h2>
                                <p>Presione en el siguiente boton para ingresar un trabajador nuevo</p>
                                <Button variant="secondary" onClick={ingresarTrabajador}>Ingresar</Button>
                            </Col>
                        ) : ""}
                        <Col className="text-center">
                            <Image src="img/lista.png" roundedCircle width="140" height="140" />
                            <h2>Ver Trabajadores</h2>
                            <p>Presiones en el siguiente boton para ver el listado de los trabajadores y sus avances</p>
                            <Button variant="secondary" onClick={listaTrabajadores}>Ver lista</Button>
                        </Col>
                    </Row>
                </Container>
            {/* </LoadingOverlay> */}
            </div>
        </div>
    )
}

export default Inicio;