import http from "../http-common";

class HijoService {

    create(data) {
        return http.post("/hijo",data);
    }

    get(id) {
        return http.get(`/hijo/${id}`);
    }

    update(id, data) {
        return http.put(`/hijo/${id}`, data);
    }
}

export default new HijoService();