import React, { useState } from "react";

/** MUI */
import {
    Box,
    Card,
    CardContent,
    Container
} from "@mui/material";

import { useAuth } from "./AuthProvider";

import NavBarComponent from "./navbar.component";

const Documentos = () => {
    const [documentos, setDocumentos] = useState([]);
    const auth = useAuth();

    return (
        <div>
            <NavBarComponent usuario={auth.user} auth={auth} />
        <Container>
            <Box sx={{
                margin: 3
            }}>
                <Card sx={{
                    boxShadow: 2
                }}>
                    <CardContent>
                        <p>Contenido Tarjeta</p>
                    </CardContent>
                </Card>
            </Box>
        </Container>
        </div>
    )
}

export default Documentos;