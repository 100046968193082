import http from "../http-common";

class RechazoService {

    create(data) {
        return http.post("/rechazoexamen",data);
    }

    getAll() {
        return http.get("/rechazoexamen");
    }

    updateRechazo(id, data) {
        return http.put(`/rechazoexamen/${id}`,data);
    }

    eliminarRechazo(id) {
        return http.delete(`/rechazoexamen/${id}`);
    }
}

export default new RechazoService();