import http from "../http-common";

class SucursalService {

    create(data) {
        return http.post("/sucursal",data);
    }

    getAll() {
        return http.get("/sucursal");
    }

    getByCiudad(ciudad) {
        return http.get(`/sucursal/ciudad/${ciudad}`);
    }

    getByNombre(nombre) {
        return http.get(`/sucursal/nombre/${nombre}`);
    }

    updateSucursal(id, data) {
        return http.put(`/sucursal/${id}`,data);
    }

    eliminarSucursal(id) {
        return http.delete(`/sucursal/${id}`);
    }
}

export default new SucursalService();