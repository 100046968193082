import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Button, Container, Row, Col, Form, Modal, Table, Stack, TabPane, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { ThreeDots } from 'react-loader-spinner';

import perfilService from "../../services/perfil.service";
import trabajadorService from "../../services/trabajador.service";
import { toast } from "react-toastify";

const TraspasoReclutador = () => {
    const [reclutadorOrigen, setReclutadorOrigen] = useState([]);
    const [reclutadorDestino, setReclutadorDestine] = useState([]);
    const [origen, setOrigen] = useState('');
    const [destino, setDestino] = useState('');
    const [trabajadoresOrigen, setTrabajadoresOrigen] = useState([]);
    const [trabajadoresDestino, setTrabajadoresDestino] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        perfilService.getAll()
            .then(data => {
                console.log(data);
                data.data.map(p => {
                    if (p.nombre === "Reclutador") {
                        setReclutadorOrigen(p.usuarios);
                        setReclutadorDestine(p.usuarios);
                    }
                });
            })
            .catch(err => {
                console.error(err);
            });
    }, []);

    const handleOrigen = (e) => {
        setOrigen(e.target.value);
        cargarTrabajadoresOrigen(e.target.value);
    }

    const handleDestino = (e) => {
        setDestino(e.target.value);
        cargarTrabajadoresDestino(e.target.value);
    }

    function cargarTrabajadoresOrigen(id_usuario) {
        setLoading(true);
        trabajadorService.getByUser(id_usuario)
            .then(t => {
                console.log(t);
                setTrabajadoresOrigen(t.data);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
            });
    }

    function cargarTrabajadoresDestino(id_usuario) {
        setLoading(true);
        trabajadorService.getByUser(id_usuario)
            .then(t => {
                console.log(t);
                setTrabajadoresDestino(t.data);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
            });
    }

    const traspasar = () => {
        if(destino === "" || origen === "") {
            alert("Debe seleccionar un destino y uno origen");
            return;
        }
        setLoading(true);
        //Cambiamos todos los trabajadores de reclutador origen a destino
        trabajadoresOrigen.map(tro=>{
            trabajadorService.updateTrabajador(tro.id,{usuarioId: destino})
            .then(response=>{
                console.log(response);
            })
            .catch(err=>{
                console.error(err);
                setLoading(false);
            });
        });
        cargarTrabajadoresDestino(destino);
        cargarTrabajadoresOrigen(origen);
        toast.info("Traspaso realizado con exito!",{
            position: "top-center",
            autoClose: 1000
        });
        setLoading(false);
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label>Reclutador Origen</Form.Label>
                        <Form.Select onChange={(e) => handleOrigen(e)}>
                            <option value="">Seleccione Usuario</option>
                            {reclutadorOrigen.map(r => {
                                return (
                                    <option value={r.id}>{r.nombre}</option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>
                    <div className="mt-2"></div>

                    {loading ? (
                        <div className="spinner">
                            <ThreeDots color="#241654" height="100" width="100" ariaLabel="Loading..." />
                        </div>
                    ) : (
                        <BootstrapTable data={trabajadoresOrigen}>
                            <TableHeaderColumn dataField="id" isKey>ID</TableHeaderColumn>
                            <TableHeaderColumn dataField="nombre">Nombre</TableHeaderColumn>
                        </BootstrapTable>
                    )}
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Reclutador Destino</Form.Label>
                        <Form.Select onChange={(e) => handleDestino(e)}>
                            <option value="">Seleccione un Usuario</option>
                            {reclutadorDestino.map(r => {
                                return (
                                    <option value={r.id}>{r.nombre}</option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>
                    <div className="mt-2"></div>

                    {loading ? (
                        <div className="spinner">
                            <ThreeDots color="#241654" height="100" width="100" ariaLabel="Loading..." />
                        </div>
                    ) : (
                        <BootstrapTable data={trabajadoresDestino}>
                            <TableHeaderColumn dataField="id" isKey>ID</TableHeaderColumn>
                            <TableHeaderColumn dataField="nombre">Nombre</TableHeaderColumn>
                        </BootstrapTable>
                    )}
                </Col>
            </Row>
            <div className="mt-3"></div>
            <Button variant="success" onClick={traspasar}>Traspasar Trabajadores</Button>
        </Container>
    )
}

export default TraspasoReclutador;