import http from "../http-common";

class CursoEspecial {

    create(data) {
        return http.post("/cursoespecial",data);
    }

    getAll() {
        return http.get("/cursoespecial");
    }

    updateCurso(id, data) {
        return http.put(`/cursoespecial/${id}`,data);
    }

    eliminarCurso(id) {
        return http.delete(`/cursoespecial/${id}`);
    }
}

export default new CursoEspecial();