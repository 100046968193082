import http from "../http-common";

class EstadoService {

    create(data) {
        return http.post("/estado",data);
    }

    getAll() {
        return http.get("/estado");
    }

    getbyID(id) {
        return http.get(`/estado/${id}`);
    }

    updateEstado(id, data) {
        return http.put(`/estado/${id}`,data);
    }

    eliminarEstado(id) {
        return http.delete(`/estado/${id}`);
    }
}

export default new EstadoService();