import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useConfirm } from "material-ui-confirm";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { DotLoaderOverlay } from 'react-spinner-overlay';
import { toast } from 'react-toastify';
import sucursalService from "../../services/sucursal.service";
import { useAuth } from '../AuthProvider';
import NavBarComponent from '../navbar.component';

/** MUI MATERIAL */
import {
    ListItemIcon,
    MenuItem
} from '@mui/material';

/** MATERIAL REACT TABLE */
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';

/** MUI ICONOS */
/** MUI ICONOS */
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const initialSucursal = {
    nombre: "",
    direccion: "",
    ciudad: ""
}

const Sucursal = () => {
    const [sucursal, setSucursal] = useState(initialSucursal);
    const [sucursales, setSucursales] = useState([]);
    const [formerrors, setFormErrors] = useState({});
    const auth = useAuth();
    const confirm = useConfirm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        retreiveSucursales();
    }, []);

    const nombre_sucursales = ["LABORATORIO MEDINORT", "LABORATORIO CMT", "LABORATORIO CEM",
        "CEIM", "MUTUAL", "PREVENIR SALUD", "EXPRESSA MÉDICA", "ACHS"
    ]

    const handleChangeSucursal = (valor, nombre) => {
        setSucursal({ ...sucursal, [nombre]: valor });
    }

    const validarForm = () => {
        console.log("Validando el formulario....");
        let errors = {};
        //name field
        if (sucursal.nombre === "") {
            errors.nombre = "Debe seleccionar una sucursal";
        }

        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const agregarSucursal = () => {
        if (!validarForm(sucursal)) {
            return;
        }
        const data = { nombre: sucursal.nombre.toUpperCase(), direccion: sucursal.direccion.toUpperCase(), ciudad: sucursal.ciudad.toUpperCase() };
        //Ingresarmos sucursal
        setLoading(true);
        sucursalService.create(data)
            .then(data => {
                console.log(data.data);
                toast.info("Sucursal agregada exitosamente!", {
                    position: "top-center",
                    autoClose: 2000
                });
                retreiveSucursales();
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                toast.error("Ocurrio un error al ingresar!", {
                    position: "top-center",
                    autoClose: 2000
                });
                setLoading(false);
            });
    }

    function retreiveSucursales() {
        setLoading(true);
        sucursalService.getAll()
            .then(data => {
                setSucursales(data.data);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    }

    function despuesDeInsertar(row, cellName, cellValue) {
        let data = {};
        if (cellName === "ciudad") {
            //editamos la ciudad
            data.ciudad = cellValue;
        }
        if (cellName === "direccion") {
            data.direccion = cellValue;
        }
        sucursalService.updateSucursal(row.id, data)
            .then(d => {
                toast.info(d.data.message, {
                    position: "top-center",
                    autoClose: 2000
                });
            })
            .catch(err => {
                console.error(err);
            });
    }

    const cellEditProp = {
        mode: 'click', // a hook for before saving cell
        afterSaveCell: despuesDeInsertar  // a hook for after saving cell
    };

    function buttonFormat(cell, row) {
        return <Button variant="danger" onClick={() => eliminarSucursal(row.id)}><FontAwesomeIcon icon="fa-solid fa-trash-can" /></Button>;
    }

    const eliminarSucursal = (id) => {
        confirm({ title: "¿Estas Seguro?", description: "Esta seguro de eliminar la sucursal?", confirmationText: "ACEPTAR", cancellationText: "CANCELAR" })
            .then(() => {
                setLoading(true);
                sucursalService.eliminarSucursal(id)
                    .then(data => {
                        toast.info(data.data.message, {
                            position: "top-center",
                            autoClose: 2000
                        });
                        retreiveSucursales();
                        setLoading(false);
                    })
                    .catch(err => {
                        console.error(err);
                        toast.error("Ha ocurrido un error !!");
                        setLoading(false);
                    });
            })
        /* if (window.confirm("Esta seguro de eliminar la Sucursal?")) {
            sucursalService.eliminarSucursal(id)
                .then(data => {
                    toast.info(data.data.message, {
                        position: "top-center",
                        autoClose: 2000
                    });
                    retreiveSucursales();
                })
                .catch(err => {
                    console.error(err);
                });
        } */
    };

    const columns_sucursal = useMemo(
        () => [{
            id: 'sucursal',
            header: "Sucursales",
            columns: [
                {
                    header: 'Nombre',
                    accessorKey: 'nombre',
                    muiTableBodyCellEditTextFieldProps: {
                        select: true, //change to select for a dropdown
                        children: nombre_sucursales.map((s) => (
                            <MenuItem key={s} value={s}>
                                {s}
                            </MenuItem>
                        ))
                    },
                },
                {
                    header: 'Ciudad',
                    accessorKey: 'ciudad',
                },
                {
                    header: 'Dirección',
                    accessorKey: 'direccion',
                },
            ],
        }
        ],
    );

    const handleSaveRow = async ({ exitEditingMode, row, values }) => {
        //Actualizamos en la BD
        setLoading(true);
        sucursalService.updateSucursal(row.original.id, values)
            .then(resp => {
                console.log(resp);
                toast.info("Se actualizo correctamente!!", {
                    position: "top-center",
                    autoClose: 2000,
                });
                setLoading(false);
            }).catch(err => {
                console.error(err);
                toast.error("Ha ocurrido un error!!");
                setLoading(false);
            })
        //TODO 
        //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here.
        sucursales[row.index] = values;
        //send/receive api updates here
        setSucursales([...sucursales]);
        exitEditingMode(); //required to exit editing mode
    };

    return (
        <div>
            <NavBarComponent usuario={auth.user} auth={auth} />
            <div className='mt-3'>
                <Container>
                    {loading ? (
                        <DotLoaderOverlay size={28} message="Cargando..." color="#4F8BFA" />
                    ) : ""}
                    <Row>
                        <Col sm={4}>
                            <h4>Registrar Sucursal</h4>
                            <Form.Group>
                                <Form.Label>Nombre:</Form.Label>
                                <Form.Select value={sucursal.nombre != "" ? sucursal.nombre : ""} onChange={(e) => handleChangeSucursal(e.target.value, "nombre")}>
                                    <option value="">Seleccione una sucursal ...</option>
                                    <option value="LABORATORIO MEDINORT">LABORATORIO MEDINORT</option>
                                    <option value="LABORATORIO CMT">LABORATORIO CMT</option>
                                    <option value="LABORATORIO CEM">LABORATORIO CEM</option>
                                    <option value="CEIM">CEIM</option>
                                    <option value="MUTUAL">MUTUAL</option>
                                    <option value="PREVENIR SALUD">PREVENIR SALUD</option>
                                    <option value="EXPRESSA MÉDICA">EXPRESSA MÉDICA</option>
                                    <option value="ACHS">ACHS</option>
                                </Form.Select>
                                {/* <Form.Control type="text" value={sucursal.nombre} onChange={(e)=>handleChangeSucursal(e.target.value, "nombre")} /> */}
                                {formerrors.nombre && (
                                    <p className="text-warning">{formerrors.nombre}</p>
                                )}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Ciudad:</Form.Label>
                                <Form.Control type="text" value={sucursal.ciudad} onChange={(e) => handleChangeSucursal(e.target.value, "ciudad")} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Dirección</Form.Label>
                                <Form.Control type="text" value={sucursal.direccion} onChange={(e) => handleChangeSucursal(e.target.value, "direccion")} />
                            </Form.Group>
                            <div className="mt-3"></div>
                            <Button variant="primary" onClick={agregarSucursal}>Agregar</Button>
                        </Col>
                        <Col>
                            {/* <h4>Listado de Sucursales actuales</h4>
                            <BootstrapTable data={sucursales} cellEdit={cellEditProp}>
                                <TableHeaderColumn dataField="id" hidden isKey>ID</TableHeaderColumn>
                                <TableHeaderColumn dataField="nombre" editable={false}>Nombre</TableHeaderColumn>
                                <TableHeaderColumn dataField="ciudad">Ciudad</TableHeaderColumn>
                                <TableHeaderColumn dataField="direccion">Dirección</TableHeaderColumn>
                                <TableHeaderColumn dataFormat={buttonFormat} editable={false} width="10%" tdStyle={{ textAlign: "center" }}>Eliminar</TableHeaderColumn>
                            </BootstrapTable>
                            <div className='mt-3'></div> */}
                            <MaterialReactTable
                                columns={columns_sucursal}
                                data={sucursales}
                                muiTableHeadCellProps={{
                                    sx: {
                                        backgroundColor: '#102337',
                                        color: 'white'
                                    }
                                }}
                                muiTableHeadCellColumnActionsButtonProps={{
                                    sx: {
                                        color: 'white'
                                    }
                                }}
                                muiExpandAllButtonProps={{
                                    sx: {
                                        color: 'white'
                                    }
                                }}
                                onEditingRowSave={handleSaveRow}
                                localization={MRT_Localization_ES}
                                enableRowActions
                                renderRowActionMenuItems={({ row, table, closeMenu }) => [
                                    <MenuItem
                                        key={0}
                                        onClick={() => table.setEditingRow(row)}
                                        sx={{ m: 0 }}
                                    >
                                        <ListItemIcon>
                                            <EditIcon />
                                        </ListItemIcon>
                                        Editar
                                    </MenuItem>,
                                    <MenuItem
                                        key={1}
                                        sx={{ m: 0 }}
                                        onClick={()=>eliminarSucursal(row.original.id)}
                                    >
                                        <ListItemIcon>
                                            <DeleteIcon />
                                        </ListItemIcon>
                                        Eliminar
                                    </MenuItem>,
                                ]}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Sucursal;