
import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import { Container, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { useNavigate } from 'react-router-dom';
import "../estilos.css";

const NavBarComponent = (props) => {
    const { usuario, auth } = props;
    const navigate = useNavigate();

    return (

        <Navbar collapseOnSelect expand="lg" /* bg="#FG5643" */ variant="dark" style={{backgroundColor: '#DBDBDB'}}>
            <Container>
                <Navbar.Brand href="#">
                    <img src="/img/icon512.png" alt="MetaPrime" width="55" height="55" className="d-inline-block align-top" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                    <Nav className="me-auto">
                        <Nav.Link className="nav-link text-black" onClick={()=>navigate("/inicio")}>Inicio</Nav.Link>
                        {usuario != null ? usuario.perfiles.find(v => v.nombre === "Reclutador") && (<Nav.Link href="/ingtrabajador" className="nav-link text-black">Ingresar Trabajador</Nav.Link>) : ""}
                        <Nav.Link className="nav-link text-black" onClick={()=>navigate("/trabajadores")}>Trabajadores</Nav.Link>
                        {usuario != null ? usuario.perfiles.find(v => v.nombre === "Administrador") && (
                            <>
                                <NavDropdown title="Bases" id="collasible-nav-dropdown" style={{color: 'black'}}>
                                    {usuario != null ? usuario.perfiles.find(v => v.nombre === "Administrador") && (<NavDropdown.Item href="/sucursal">Sucursal</NavDropdown.Item>) : ""}
                                    {usuario != null ? usuario.perfiles.find(v => v.nombre === "Administrador") && (<NavDropdown.Item href="/cc">Unidadades de Negocio</NavDropdown.Item>) : ""}
                                    {usuario != null ? usuario.perfiles.find(v => v.nombre === "Administrador") && (<NavDropdown.Item href="/cargos">Cargos</NavDropdown.Item>) : ""}
                                    {usuario != null ? usuario.perfiles.find(v => v.nombre === "Administrador") && (<NavDropdown.Item href="/cursosespeciales">Cursos Especiales</NavDropdown.Item>) : ""}
                                    {/* {usuario != null ? usuario.perfiles.find(v => v.nombre === "Administrador") && (<NavDropdown.Item href="/maestroexamen">Exámenes</NavDropdown.Item>) : ""} */}
                                </NavDropdown>
                                {/* <NavDropdown title="Estadísticas" id="collasible-nav-dropdown">
                                    {usuario != null ? usuario.perfiles.find(v => v.nombre === "Administrador") && (<NavDropdown.Item href="/graficos">Graficos</NavDropdown.Item>) : ""} */}
                                    {/* {usuario != null ? usuario.perfiles.find(v => v.nombre === "Administrador") && (<NavDropdown.Item href="/estadistica">Tabla de Estados</NavDropdown.Item>) : ""}
                    {usuario != null ? usuario.perfiles.find(v => v.nombre === "Administrador") && (<NavDropdown.Item href="/powerbi">Tabla de Estados PBI</NavDropdown.Item>) : ""} */}
                                    {/* {usuario != null ? usuario.perfiles.find(v => v.nombre === "Administrador") && (<NavDropdown.Item href="/reclutadorbi">Reclutadores</NavDropdown.Item>) : ""}
                                    <DropdownSubmenu title="3D">
                                        {/* <NavDropdown.Item href="/tresdva">3D Verdes Amarillos</NavDropdown.Item>
                    <NavDropdown.Item href="/tresdenpro">3D En Procesos</NavDropdown.Item>
                    <NavDropdown.Item href="/tresdsini">3D Sin Iniciar</NavDropdown.Item> */}
                                        {/* <NavDropdown.Item href="/tresdtotal">3D General</NavDropdown.Item>
                                    </DropdownSubmenu>
                                    <NavDropdown.Item href="/resumentotal">Resumen Estados</NavDropdown.Item>
                                    <NavDropdown.Item href="/especialidad">Resumen Especialidad</NavDropdown.Item>
                                    <NavDropdown.Item href="/resumengnal">Resumen</NavDropdown.Item>
                                    <DropdownSubmenu title="Acreditadores">
                                        <NavDropdown.Item href="/acreditados">Estado Acreditadores</NavDropdown.Item>
                                        <NavDropdown.Item href="/contratonofaena">Contratos que no estan en faena</NavDropdown.Item>
                                        <NavDropdown.Item href="/reportgestionacre">Gestión por Acreditador</NavDropdown.Item>
                                    </DropdownSubmenu>
                                    <NavDropdown.Item href="/reporttiempo">Tiempo entre Estados</NavDropdown.Item>
                                </NavDropdown> */}
                            </>
                        ) : ""}

                       {/*  {usuario != null ? usuario.perfiles.find(v => v.nombre === "Administrador") && (<Nav.Link href="/traspaso" className="nav-link">Traspasar un Reclutador</Nav.Link>) : ""}
                        {usuario != null ? usuario.perfiles.find(v => v.nombre === "Administrador") && (<Nav.Link href="/dnc" className="nav-link">DNC</Nav.Link>) : ""} */}
                        <Nav.Link className="nav-link text-black" onClick={()=>navigate("/documentos")}>Documentos</Nav.Link>
                    </Nav>
                    {usuario && (
                        <Nav className="navbar-nav ml-auto">
                            <Nav.Link href={"#"} className="nav-item text-black">{usuario != null ? usuario.nombre : "Error de conexión"}</Nav.Link>
                            <Nav.Link href="/" className="nav-link text-black" onClick={()=>auth.logOut()}>Salir</Nav.Link>
                        </Nav>
                    )}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavBarComponent;