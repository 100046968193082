import http from "../http-common";

class CursoService {

    getAll() {
        return http.get("/curso");
    }

    create(data) {
        return http.post("/curso",data);
    }

    get(id) {
        return http.get(`/curso/${id}`);
    }

    update(id, data) {
        return http.put(`/curso/${id}`, data);
    }

    eliminarCurso(id) {
        return http.delete(`/curso/${id}`);
    }
}

export default new CursoService();