import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useConfirm } from "material-ui-confirm";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { DotLoaderOverlay } from 'react-spinner-overlay';
import { toast } from 'react-toastify';
import cargoService from "../../services/cargo.service";
import { useAuth } from "../AuthProvider";
import NavBarComponent from "../navbar.component";

/** MUI MATERIAL */
import {
    ListItemIcon,
    MenuItem
} from '@mui/material';

/** MATERIAL REACT TABLE */
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';

/** MUI ICONOS */
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const initialCargo = {
    nombre: "",
    tipo: ""
}

const Cargo = () => {
    const [cargo, setCargo] = useState(initialCargo);
    const [cargos, setCargos] = useState([]);
    const auth = useAuth();
    const confirm = useConfirm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        retreiveCargos();
    }, []);

    function retreiveCargos() {
        setLoading(true);
        cargoService.getAll()
            .then(cc => {
                console.log(cc);
                setCargos(cc.data);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    }

    const handleChangeCC = (valor, nombre) => {
        setCargo({ ...cargo, [nombre]: valor });
    }

    const ingresarCC = () => {
        if (cargo.tipo === "") {
            alert("Debe seleccionar tipo");
            return;
        }
        if (cargo.nombre === "") {
            alert("Campo nombre es requerido");
            return;
        }
        const data = { nombre: cargo.nombre.toUpperCase(), tipo: cargo.tipo };
        setLoading(true);
        cargoService.create(data)
            .then(cn => {
                console.log(cn);
                toast.info("Cargo ingresado correctamente!", {
                    position: "top-center",
                    autoClose: 2000
                });
                retreiveCargos();
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    }

    function buttonFormat(cell, row) {
        return <Button variant="danger" onClick={() => eliminarCC(row.id)}><FontAwesomeIcon icon="fa-solid fa-trash-can" /></Button>;
    }

    const eliminarCC = (id) => {
        confirm({ title: "¿Estas Seguro?", description: "Esta seguro de eliminar el Cargo?", confirmationText: "ACEPTAR", cancellationText: "CANCELAR" })
            .then(() => {
                setLoading(true);
                cargoService.eliminarCargo(id)
                    .then(data => {
                        toast.info("Eliminado Correctamente!", {
                            position: "top-center",
                            autoClose: 2000
                        });
                        retreiveCargos();
                        setLoading(false);
                    })
                    .catch(err => {
                        console.error(err);
                        setLoading(false);
                    });
            }).catch(err => {
                console.error(err);
            });
        /* if (window.confirm("Esta seguro de eliminar el Cargo?")) {
            cargoService.eliminarCargo(id)
                .then(data => {
                    toast.info(data.data.message, {
                        position: "top-center",
                        autoClose: 2000
                    });
                    retreiveCargos();
                })
                .catch(err => {
                    console.error(err);
                });
        } */
    };

    const columns_cargo = useMemo(
        () => [{
            id: 'cargoss',
            header: "Cargos",
            columns: [
                {
                    header: 'Nombre',
                    accessorKey: 'nombre',
                },
                {
                    header: 'Tipo',
                    accessorKey: 'tipo',
                },
            ],
        }
        ],
    );

    const handleSaveRow = async ({ exitEditingMode, row, values }) => {
        //Actualizamos en la BS
        setLoading(true);
        var id = row.original.id;
        cargoService.updateCargo(id, values)
            .then(resp => {
                console.log(resp);
                toast.info("Actualizado correctamente!!", {
                    position: "top-center",
                    autoClose: 2000,
                })
                setLoading(false);
            }).catch(error => {
                console.error(error);
                setLoading(false);
            });
        //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here.
        cargos[row.index] = values;
        //send/receive api updates here
        setCargos([...cargos]);
        exitEditingMode(); //required to exit editing mode
    };

    return (
        <div>
            <NavBarComponent usuario={auth.user} auth={auth} />
            <div className="mt-3">
                <Container>
                    {loading ? (
                        <DotLoaderOverlay size={28} message="Cargando..." color="#4F8BFA" />
                    ) : ""}
                    <Row>
                        <Col sm={4}>
                            <h4>Ingresar Cargos</h4>
                            <Form.Group>
                                <Form.Label>Nombre:</Form.Label>
                                <Form.Control type="text" onChange={(e) => handleChangeCC(e.target.value, "nombre")} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Tipo:</Form.Label>
                                <Form.Select onChange={(e) => handleChangeCC(e.target.value, "tipo")}>
                                    <option value="">Seleccione Tipo ...</option>
                                    <option value="DIRECTO">DIRECTO</option>
                                    <option value="INDIRECTO">INDIRECTO</option>
                                    <option value="DIRECTO-SUPERVISIÓN">DIRECTO-SUPERVISIÓN</option>
                                    <option value="DIRECTO-OPERADOR">DIRECTO-OPERADOR</option>
                                </Form.Select>
                            </Form.Group>
                            <div className="mt-3"></div>
                            <Button variant="primary" onClick={ingresarCC}>Agregar</Button>
                        </Col>
                        <Col>
                            {/* <h4>Lista de Cargos</h4>
                            <BootstrapTable data={cargos}>
                                <TableHeaderColumn dataField="id" isKey hidden>ID</TableHeaderColumn>
                                <TableHeaderColumn dataField="nombre">Nombre</TableHeaderColumn>
                                <TableHeaderColumn dataField="tipo">Tipo</TableHeaderColumn>
                                <TableHeaderColumn dataFormat={buttonFormat} width="15%" tdStyle={{ textAlign: "center", whiteSpace: 'normal', wordWrap: 'break-word' }}>Eliminar</TableHeaderColumn>
                            </BootstrapTable>
                            <div className='mt-3'></div> */}
                            <MaterialReactTable
                                muiTableHeadCellProps={{
                                    sx: {
                                        backgroundColor: '#102337',
                                        color: 'white'
                                    }
                                }}
                                muiTableHeadCellColumnActionsButtonProps={{
                                    sx: {
                                        color: 'white'
                                    }
                                }}
                                muiExpandAllButtonProps={{
                                    sx: {
                                        color: 'white'
                                    }
                                }}
                                data={cargos}
                                columns={columns_cargo}
                                localization={MRT_Localization_ES}
                                editingMode="modal"
                                onEditingRowSave={handleSaveRow}
                                enableRowActions
                                renderRowActionMenuItems={({ row, table, closeMenu }) => [
                                    <MenuItem
                                        key={0}
                                        onClick={() => table.setEditingRow(row)}
                                        sx={{ m: 0 }}
                                    >
                                        <ListItemIcon>
                                            <EditIcon />
                                        </ListItemIcon>
                                        Editar
                                    </MenuItem>,
                                    <MenuItem
                                        key={1}
                                        sx={{ m: 0 }}
                                        onClick={() => eliminarCC(row.original.id)}
                                    >
                                        <ListItemIcon>
                                            <DeleteIcon />
                                        </ListItemIcon>
                                        Eliminar
                                    </MenuItem>,
                                ]}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Cargo;