import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { toast } from 'react-toastify';
import { useAuth } from "../AuthProvider";
import NavBarComponent from "../navbar.component";

/**Servicios */
import maestroexamenService from "../../services/maestroexamen.service";


const MaestroExamen = () => {
    const [examenes, setExamenes] = useState([]);
    const [nombre, setNombre] = useState('');
    const [numero, setNumero] = useState('');
    const auth = useAuth();

    useEffect(() => {
        cargarExamenes();
    }, []);

    function cargarExamenes() {
        maestroexamenService.getAll()
            .then(resp => {
                setExamenes(resp.data);
            })
            .catch(err => {
                console.error(err);
            });
    }

    const ingresarExamen = () => {
        if (nombre === "" || numero === "") {
            alert("Debe ingresar un nombre ó número!");
            return;
        }
        maestroexamenService.create({ nombre: nombre.toUpperCase(), numero: numero })
            .then(res => {
                console.log(res);
                toast.info("Exámen Ingresado Correctamente!", {
                    position: "top-center",
                    autoClose: 1000
                });
                cargarExamenes();
            }).catch(err => {
                console.error(err);
            });
    }

    const eliminarExamen = (id) => {
        if (window.confirm("Esta seguro de eliminar el Exámen?")) {
            maestroexamenService.eliminarExamenMaestro(id)
                .then(data => {
                    toast.info("Exámen Eliminado Correctamente!", {
                        position: "top-center",
                        autoClose: 1000
                    });
                    cargarExamenes();
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }

    function buttonFormat(cell, row) {
        return <Button variant="danger" onClick={() => eliminarExamen(row.id)}><FontAwesomeIcon icon="fa-solid fa-trash-can" /></Button>;
    }

    function onAfterSaveCell(row, cellName, cellValue) {
        if (cellName === "nombre") {
            maestroexamenService.updateExamenMaestro(row.id, { nombre: cellValue })
                .then(resp => {
                    console.log(resp);
                    toast.info("Exámen editado correctamente!!", {
                        position: "top-right",
                        autoClose: 1000
                    });
                })
                .catch(err => {
                    console.error(err);
                });
        }
        if (cellName === "numero") {
            maestroexamenService.updateExamenMaestro(row.id, { numero: cellValue })
                .then(resp => {
                    console.log(resp);
                    toast.info("Exámen editado correctamente!!", {
                        position: "top-right",
                        autoClose: 1000
                    });
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }

    const cellEditProp = {
        mode: 'click',
        blurToSave: true,
        //beforeSaveCell: onBeforeSaveCell, // a hook for before saving cell
        afterSaveCell: onAfterSaveCell  // a hook for after saving cell
    };

    return (
        <div>
            <NavBarComponent usuario={auth.user} auth={auth} />
            <div className="mt-3">
                <Container>
                    <Row>
                        <Col sm={4}>
                            <h3>Ingresar Exámen</h3>
                            <Form.Group>
                                <Form.Label>Número:</Form.Label>
                                <Form.Control type="text" onChange={(e) => setNumero(e.target.value)} />
                                <Form.Label>Nombre:</Form.Label>
                                <Form.Control type="text" onChange={(e) => setNombre(e.target.value)} />
                            </Form.Group>
                            <div className="mt-3"></div>
                            <Button variant="primary" onClick={ingresarExamen}>Ingresar</Button>
                        </Col>
                        <Col>
                            <h3 className="text-center">Lista de Exámenes</h3>
                            <BootstrapTable data={examenes} cellEdit={cellEditProp}>
                                <TableHeaderColumn dataField="id" isKey>ID</TableHeaderColumn>
                                <TableHeaderColumn dataField="numero">Número</TableHeaderColumn>
                                <TableHeaderColumn dataField="nombre">Nombre</TableHeaderColumn>
                                <TableHeaderColumn dataFormat={buttonFormat} width="15%" tdStyle={{ textAlign: "center", whiteSpace: 'normal', wordWrap: 'break-word' }} editable={false}>Eliminar</TableHeaderColumn>
                            </BootstrapTable>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )

}

export default MaestroExamen;