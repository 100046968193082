import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useConfirm } from "material-ui-confirm";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { DotLoaderOverlay } from 'react-spinner-overlay';
import { toast } from 'react-toastify';
import ccService from "../../services/cc.service";
import { useAuth } from "../AuthProvider";
import NavBarComponent from "../navbar.component";

/** MUI MATERIAL */
import {
    ListItemIcon,
    MenuItem
} from '@mui/material';

/** MATERIAL REACT TABLE */
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';

/** MUI ICONOS */
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const initialCC = {
    nombre: ""
}

const CC = () => {
    const [cc, setCC] = useState(initialCC);
    const [ccs, setCcs] = useState([]);
    const auth = useAuth();
    const confirm = useConfirm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        retreiveCCs();
    }, []);

    function retreiveCCs() {
        setLoading(true);
        ccService.getAll()
            .then(cc => {
                console.log(cc);
                setCcs(cc.data);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    }

    const handleChangeCC = (valor, nombre) => {
        setCC({ ...cc, [nombre]: valor });
    }
    const data = { nombre: cc.nombre.toUpperCase() };
    const ingresarCC = () => {
        setLoading(true);
        ccService.create(data)
            .then(cn => {
                console.log(cn);
                toast.info("Centro de costo ingresado correctamente!", {
                    position: "top-center",
                    autoClose: 2000
                });
                retreiveCCs();
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    }

    function buttonFormat(cell, row) {
        return <Button variant="danger" onClick={() => eliminarCC(row.id)}><FontAwesomeIcon icon="fa-solid fa-trash-can" /></Button>;
    }

    const eliminarCC = (id) => {
        confirm({ title: "¿Estas Seguro?", description: "Esta seguro de eliminar el Centro de Costo?", confirmationText: "ACEPTAR", cancellationText: "CANCELAR" })
            .then(() => {
                setLoading(true);
                ccService.eliminarCC(id)
                    .then(data => {
                        toast.info(data.data.message, {
                            position: "top-center",
                            autoClose: 2000
                        });
                        retreiveCCs();
                        setLoading(false);
                    })
                    .catch(err => {
                        console.error(err);
                        setLoading(false);
                    });
            }).catch(err => {
                console.error(err);
            });
        /* if (window.confirm("Esta seguro de eliminar el Centro de Costo?")) {
            ccService.eliminarCC(id)
                .then(data => {
                    toast.info(data.data.message, {
                        position: "top-center",
                        autoClose: 2000
                    });
                    retreiveCCs();
                })
                .catch(err => {
                    console.error(err);
                });
        } */
    };

    const columns_cc = useMemo(
        () => [{
            id: 'centro_costo',
            header: "Centros de Costos",
            columns: [
                {
                    header: 'Nombre',
                    accessorKey: 'nombre',
                },
            ],
        }
        ],
    );

    const handleSaveRow = async ({ exitEditingMode, row, values }) => {
        //Actualizamos en la BS
        setLoading(true);
        var id = row.original.id;
        ccService.updateCC(id, values)
            .then(resp => {
                console.log(resp);
                toast.info("Actualizado correctamente!!", {
                    position: "top-center",
                    autoClose: 2000,
                })
                setLoading(false);
            }).catch(error => {
                console.error(error);
                setLoading(false);
            });
        //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here.
        ccs[row.index] = values;
        //send/receive api updates here
        setCcs([...ccs]);
        exitEditingMode(); //required to exit editing mode
    };

    return (
        <div>
            <NavBarComponent usuario={auth.user} auth={auth} />
            <div className="mt-3">
                <Container>
                    {loading ? (
                        <DotLoaderOverlay size={28} message="Cargando..." color="#4F8BFA" />
                    ) : ""}
                    <Row>
                        <Col sm={4}>
                            <h4>Ingresar Centro Costo</h4>
                            <Form.Group>
                                <Form.Label>Nombre:</Form.Label>
                                <Form.Control type="text" onChange={(e) => handleChangeCC(e.target.value, "nombre")} />
                            </Form.Group>
                            <div className="mt-3"></div>
                            <Button variant="primary" onClick={ingresarCC}>Agregar</Button>
                        </Col>
                        <Col>
                            {/* <h4>Lista de Centros de Costo</h4>
                            <BootstrapTable data={ccs}>
                                <TableHeaderColumn dataField="id" isKey hidden>ID</TableHeaderColumn>
                                <TableHeaderColumn dataField="nombre">Nombre</TableHeaderColumn>
                                <TableHeaderColumn dataFormat={buttonFormat} width="15%" tdStyle={{ textAlign: "center", whiteSpace: 'normal', wordWrap: 'break-word' }}>Eliminar</TableHeaderColumn>
                            </BootstrapTable>
                            <div className='mt-3'></div> */}
                            <MaterialReactTable
                                muiTableHeadCellProps={{
                                    sx: {
                                        backgroundColor: '#102337',
                                        color: 'white'
                                    }
                                }}
                                muiTableHeadCellColumnActionsButtonProps={{
                                    sx: {
                                        color: 'white'
                                    }
                                }}
                                muiExpandAllButtonProps={{
                                    sx: {
                                        color: 'white'
                                    }
                                }}
                                data={ccs}
                                columns={columns_cc}
                                localization={MRT_Localization_ES}
                                editingMode="modal"
                                onEditingRowSave={handleSaveRow}
                                enableRowActions
                                renderRowActionMenuItems={({ row, table, closeMenu }) => [
                                    <MenuItem
                                        key={0}
                                        onClick={() => table.setEditingRow(row)}
                                        sx={{ m: 0 }}
                                    >
                                        <ListItemIcon>
                                            <EditIcon />
                                        </ListItemIcon>
                                        Editar
                                    </MenuItem>,
                                    <MenuItem
                                        key={1}
                                        sx={{ m: 0 }}
                                        onClick={() => eliminarCC(row.original.id)}
                                    >
                                        <ListItemIcon>
                                            <DeleteIcon />
                                        </ListItemIcon>
                                        Eliminar
                                    </MenuItem>,
                                ]}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default CC;