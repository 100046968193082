import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Compressor from 'compressorjs';
import { getYear } from "date-fns";
import es from 'date-fns/locale/es';
import { useFormik } from 'formik';
import range from "lodash/range";
import React, { useEffect, useRef, useState } from "react";
import S3 from 'react-aws-s3';
import { Button, Col, Container, Form, Modal, OverlayTrigger, Row, Stack, Table, Tooltip } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoadingOverlay from 'react-loading-overlay';
import { useParams } from "react-router-dom";
import { RingSpinnerOverlay } from 'react-spinner-overlay';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import moment from "moment";
import convertfileService from "../services/convertfile.service";
import documentoService from "../services/documento.service";
import estadoService from "../services/estado.service";
import externosService from "../services/externos.service";
import hijoService from "../services/hijo.service";
import trabajadorService from "../services/trabajador.service";

/** MUI */
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import esLocale from 'date-fns/locale/es';


registerLocale('es', es);

const S3_BUCKET = 'docrecluta';
const REGION = 'sa-east-1';

function base64ToArrayBuffer(data) {
    var bString = window.atob(data);
    var bLength = bString.length;
    var bytes = new Uint8Array(bLength);
    for (var i = 0; i < bLength; i++) {
        var ascii = bString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
};

const validationSchema = [
    Yup.object().shape({
        nacionalidad: Yup.string().required("La nacionaildad es requerida!").nullable(),
        direccion: Yup.string().required("La dirección es requerida!").nullable(),
        ciudad: Yup.string().required("La ciudad es requerida!").nullable(),
        fono: Yup.string().required("El celular es requerido!").nullable(),
        direccion: Yup.string().required("La dirección es requerida!").nullable(),
        contacto_emergencia: Yup.string().required("El contacto de emergencia es requerido!").nullable(),
        fono_contacto_emergencia: Yup.string().required("El celular del contacto de emergencia es requerido!").nullable(),
        talla_pantalon: Yup.string().required("La talla de pantalon es requerida!").nullable(),
        talla_camisa: Yup.string().required("La talla de camisa es requerida!").nullable(),
        talla_buzo: Yup.string().required("La talla de buzo es requerida!").nullable(),
        nro_calzado: Yup.string().required("El numero de calzado es requerido!").nullable(),
        banco: Yup.string().required("El banco es requerido!").nullable().nullable(),
        tipo_cuenta: Yup.string().required("El tipo de cuenta del banco es requerido!").nullable(),
        nro_cuenta: Yup.string().required("El numero de cuenta del banco es requerido!").nullable(),
        afp: Yup.string().required("La AFP es requerida!").nullable(),
        prevision: Yup.string().required("La previsión es requerida!").nullable(),
        etnia: Yup.string().required("La Etnia es requerida!").nullable(),
    }),
];

const initialTrabajador = {
    rut: "",
    nombre: "",
    ap_paterno: "",
    ap_materno: "",
    cargo: "",
    sexo: "",
    fono: "",
    fecha_nacimiento: "",
    nacionalidad: [],
    estado_civil: "",
    discapacidad: "",
    pueblo_originario: "",
    direccion: "",
    ciudad: "",
    correo: "",
    contacto_emergencia: "",
    fono_contacto_emergencia: "",
    alergico: "",
    nombre_conyugue: "",
    rut_conyugue: "",
    fecha_nacimiento_conyugue: "",
    conductor: "",
    licencia: "",
    talla_camisa: "",
    talla_buzo: "",
    talla_pantalon: "",
    nro_calzado: "",
    cargo: "",
    centro_costo: "",
    nivel_estudio: "",
    sueldo_base_mensual: "",
    liquido_pactado: "",
    fecha_ingreso: "",
    duracion_contrato: "",
    jornada_trabajo: "",
    banco: "",
    tipo_cuenta: "",
    nro_cuenta: "",
    anticipo_sueldo: "",
    afp: "",
    prevision: "",
    isapre: "",
    observacion: "",
    conduccion: false,
    trabajo_escondida: false,
    etnia: "",
}

const initialHijo = {
    nombres: "",
    rut: "",
    fecha_nacimiento: "",
    trabajadorId: "",
}

const initialDocumento = {
    nombre: "",
    ruta: "",
    file: null,
    trabajadorId: "",
    fecha_inicio: moment(),
    fecha_termino: moment().add(10, 'years')
}

const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
];

function tallaPantalon() {
    var tallas = [];
    for (var i = 38; i < 55; i += 2) {
        tallas.push(i);
    }
    return tallas;
}

function calzado() {
    var calzado = [];
    for (var i = 34; i < 47; i++) {
        calzado.push(i);
    }
    return calzado;
}

function ModalHijos({ show, onHide, hijo, addHijo, date, setDate, years, registrarHijo, editHijo }) {

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Ingreso de Hijos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>Rut:</Form.Label>
                    <Form.Control type="text" value={hijo.rut} onChange={e => addHijo(e.target.value, "rut")} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Nombres:</Form.Label>
                    <Form.Control type="text" value={hijo.nombres} onChange={e => addHijo(e.target.value, "nombres")} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                        <DesktopDatePicker
                            label="Fecha Nacimiento"
                            inputFormat="dd/MM/yyyy"
                            value={hijo.fecha_nacimiento}
                            onChange={(e) => setDate(e)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Form.Group>
                {hijo.id === undefined ?
                    <Button variant="primary" onClick={registrarHijo}>Agregar</Button>
                    :
                    <Button variant="secondary" onClick={() => editHijo(hijo.id)}>Actualizar</Button>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    )
}

function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};

const FmTrabajador = () => {
    const { id_trabajador, ejecutiva } = useParams();
    const [trabajador, setTrabajador] = useState(initialTrabajador);
    const [dateNacimiento, setDateNacimiento] = useState(new Date());
    const [rutTrabajador, setRutTrabajador] = useState('');
    const years = range(1970, getYear(new Date()) + 1, 1);
    const [hijos, setHijos] = useState([]);
    const [hijo, setHijo] = useState(initialHijo);
    const [modalShow, setModalShow] = useState(false);
    const [dateHijo, setDateHijo] = useState(new Date());
    const [isLoadingButton, setLoadingButton] = useState(false);
    const [documento, setDocumento] = useState(initialDocumento);
    const [documentos, setDocumentos] = useState([]);
    const [loading, setLoading] = useState(false);
    const FileInput = useRef();
    const [progress, setProgress] = useState(0);
    const [ejecu, setEjecu] = useState('');
    const [ciudades, setCiudadades] = useState([]);
    const [paises, setPaises] = useState([]);
    const [nacionalidad, setNacionalidad] = useState([]);
    const [ciudad, setCiudad] = useState([]);
    const [dateContrato, setDateContrato] = useState('');
    const [dateAntecedente, setDateAntecedente] = useState('');
    const [dateFinContrato, setDateFinContrato] = useState('');
    const [loadingPerfil, setLoadingPerfil] = useState(false);
    LoadingOverlay.propTypes = undefined;
    const paisessuda = [{ nombre: "Argentina" }, { nombre: "Bolivia" }, { nombre: "Brazil" }, { nombre: "Chile" }, { nombre: "Colombia" }, { nombre: "Ecuador" }, { nombre: "Guyana" },
    { nombre: "Perú" }, { nombre: "Surinam" }, { nombre: "Uruguay" }, { nombre: "Venezuela" }];

    const formik = useFormik({
        initialValues: {
            rut: "",
            nombre: "",
            ap_paterno: "",
            ap_materno: "",
            cargo: "",
            sexo: "",
            fono: "",
            fecha_nacimiento: "",
            nacionalidad: "",
            estado_civil: "",
            discapacidad: "",
            pueblo_originario: "",
            direccion: "",
            ciudad: "",
            correo: "",
            contacto_emergencia: "",
            fono_contacto_emergencia: "",
            alergico: "",
            nombre_conyugue: "",
            rut_conyugue: "",
            fecha_nacimiento_conyugue: "",
            conductor: "",
            licencia: "",
            talla_camisa: "",
            talla_buzo: "",
            talla_pantalon: "",
            nro_calzado: "",
            cargo: "",
            centro_costo: "",
            nivel_estudio: "",
            sueldo_base_mensual: "",
            liquido_pactado: "",
            fecha_ingreso: "",
            duracion_contrato: "",
            jornada_trabajo: "",
            banco: "",
            tipo_cuenta: "",
            nro_cuenta: "",
            anticipo_sueldo: "",
            afp: "",
            prevision: "",
            isapre: "",
            observacion: "",
            conduccion: false,
            trabajo_escondida: false,
            etnia: ""
        },
        validationSchema: validationSchema[0],
        onSubmit: () => {
            console.log("VALORES", formik.values);
            setLoadingButton(true);
            const id = trabajador.id;
            /* trabajador.nacionalidad = nacionalidad[0] !== null ? nacionalidad[0]['nombre'] : "";
            trabajador.ciudad = ciudad[0] !== null ? ciudad[0]['nombre'] : "";
            trabajador.fecha_nacimiento = dateNacimiento; */
            trabajadorService.updateTrabajador(id, formik.values)
                .then(t => {
                    console.log(t);
                    toast.info("Actualizado Correctamente!", {
                        position: "top-right",
                        autoClose: 3000
                    });
                    setLoadingButton(false);
                })
                .catch(err => {
                    console.error(err);
                    toast.error("Problemas al actualizar", {
                        position: "top-right",
                        autoClose: 3000
                    });
                    setLoadingButton(false);
                });
        },
    })

    useEffect(async () => {
        var rut = "";//atob(id_trabajador);
        console.log("EJECU", ejecutiva);
        if (id_trabajador) {
            rut = atob(id_trabajador);
        }
        if (ejecutiva) {
            var eja = atob(ejecutiva);
            var ej = JSON.parse(eja);
            setEjecu(ej);
        }

        retreiveTrabajador(rut);
        let c = await externosService.getCiudades();
        setCiudadades(c);
        let p = await externosService.getPaises();
        /* for (var k in p) {
            console.log(p[k].altSpellings);
        } */
        setPaises(p);
    }, []);

    function retreiveTrabajador(rut) {
        setLoading(true);
        trabajadorService.buscarPorRut(rut)
            .then(t => {
                console.log(t.data);
                if (t.data[0]) {
                    formik.setFieldValue('nacionalidad', t.data[0].nacionalidad, true);
                    formik.setFieldValue('rut', t.data[0].rut, true);
                    formik.setFieldValue('nombre', t.data[0].nombre, true);
                    formik.setFieldValue('ap_paterno', t.data[0].ap_paterno, true);
                    formik.setFieldValue('ap_materno', t.data[0].ap_materno, true);
                    formik.setFieldValue('ciudad', t.data[0].ciudad, true);
                    formik.setFieldValue('direccion', t.data[0].direccion, true);
                    formik.setFieldValue('correo', t.data[0].correo, true);
                    formik.setFieldValue('fono', t.data[0].fono, true);
                    formik.setFieldValue('contacto_emergencia', t.data[0].contacto_emergencia, true);
                    formik.setFieldValue('alergico', t.data[0].alergico, true);
                    formik.setFieldValue('nombre_conyugue', t.data[0].nombre_conyugue, true);
                    formik.setFieldValue('rut_conyugue', t.data[0].rut_conyugue, true);
                    formik.setFieldValue('direccion', t.data[0].direccion, true);
                    formik.setFieldValue('fono_contacto_emergencia', t.data[0].fono_contacto_emergencia, true);
                    formik.setFieldValue('sexo', t.data[0].sexo, true);
                    formik.setFieldValue('fecha_nacimiento', t.data[0].fecha_nacimiento, true);
                    formik.setFieldValue('estado_civil', t.data[0].estado_civil, true);
                    formik.setFieldValue('discapacidad', t.data[0].discapacidad, true);
                    formik.setFieldValue('fecha_nacimiento_conyugue', t.data[0].fecha_nacimiento_conyugue, true);
                    formik.setFieldValue('conductor', t.data[0].conductor, true);
                    formik.setFieldValue('licencia', t.data[0].licencia, true);
                    formik.setFieldValue('talla_camisa', t.data[0].talla_camisa, true);
                    formik.setFieldValue('talla_buzo', t.data[0].talla_buzo, true);
                    formik.setFieldValue('talla_pantalon', t.data[0].talla_pantalon, true);
                    formik.setFieldValue('nro_calzado', t.data[0].nro_calzado, true);
                    formik.setFieldValue('cargo', t.data[0].cargo, true);
                    formik.setFieldValue('nivel_estudio', t.data[0].nivel_estudio, true);
                    formik.setFieldValue('sueldo_base_mensual', t.data[0].sueldo_base_mensual, true);
                    formik.setFieldValue('liquido_pactado', t.data[0].liquido_pactado, true);
                    formik.setFieldValue('fecha_ingreso', t.data[0].fecha_ingreso, true);
                    formik.setFieldValue('duracion_contrato', t.data[0].duracion_contrato, true);
                    formik.setFieldValue('jornada_trabajo', t.data[0].jornada_trabajo, true);
                    formik.setFieldValue('banco', t.data[0].banco, true);
                    formik.setFieldValue('tipo_cuenta', t.data[0].tipo_cuenta, true);
                    formik.setFieldValue('nro_cuenta', t.data[0].nro_cuenta, true);
                    formik.setFieldValue('anticipo_sueldo', t.data[0].anticipo_sueldo, true);
                    formik.setFieldValue('afp', t.data[0].afp, true);
                    formik.setFieldValue('prevision', t.data[0].prevision, true);
                    formik.setFieldValue('isapre', t.data[0].isapre, true);
                    formik.setFieldValue('observacion', t.data[0].observacion, true);
                    formik.setFieldValue('conduccion', t.data[0].conduccion, true);
                    formik.setFieldValue('trabajo_escondida', t.data[0].trabajo_escondida, true);
                    formik.setFieldValue('centro_costo', t.data[0].centro_costo, true);
                    formik.setFieldValue('etnia', t.data[0].etnia, true);

                    var docs = t.data[0].documentos;
                    setTrabajador(t.data[0]);
                    setHijos(t.data[0].hijos);
                    setDocumentos(t.data[0].documentos);
                    setNacionalidad([t.data[0].nacionalidad]);
                    setCiudad([t.data[0].ciudad]);
                    setDateNacimiento(Date.parse(t.data[0].fecha_nacimiento))
                    if (docs.find(v => v.nombre === "antecedente")) {
                        var ant = docs.find(v => v.nombre === "antecedente");
                        setDateAntecedente(Date.parse(ant.fecha_termino));
                    }
                    if (docs.find(v => v.nombre === "contrato")) {
                        var cont = docs.find(v => v.nombre === "contrato");
                        setDateContrato(Date.parse(cont.fecha_inicio));
                        setDateFinContrato(Date.parse(cont.fecha_termino));
                    }
                    setLoading(false);
                } else {
                    setLoading(false);
                    alert("Ocurrio un problema");
                    return;
                }
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
                toast.error("Error de conexión", {
                    position: "top-center",
                    autoClose: 5000
                });
            });
    }

    const handleChangeTrabajador = (valor, nombre) => {
        setTrabajador({ ...trabajador, [nombre]: valor });
    }

    const btnAgregarHijo = () => {
        setHijo(initialHijo);
        setModalShow(true);
    }

    const handleChangeHijo = (valor, nombre) => {
        setHijo({ ...hijo, [nombre]: valor });
    }

    const agregar_hijo = async () => {
        setLoading(true);
        hijo.trabajadorId = trabajador.id;
        hijo.fecha_nacimiento = dateHijo;
        let h = await hijoService.create(hijo);
        hijos.push(h.data);
        setHijos(hijos);
        setModalShow(false);
        setLoading(false);
    }

    const showEditHijo = (id) => {
        //Obtenemos al hijo a editar
        hijoService.get(id)
            .then(data => {
                setHijo(data.data);
                setModalShow(true);
            })
            .catch(err => {
                console.error(err);
            });
    }

    const editar_hijo = (id) => {
        //Actualizamos al hijo
        hijo.fecha_nacimiento = dateHijo;
        hijoService.update(id, hijo)
            .then(h => {
                toast.info(h.data.message, {
                    position: "top-right",
                    autoClose: 3000
                });
                setModalShow(false);
            })
            .catch(err => {
                console.error(err);
            });
        var indx = hijos.findIndex(v => v.id === id);
        hijos.splice(indx, indx >= 0 ? 1 : 0);
        hijos.push(hijo);
        setHijos(hijos);
    }

    const actualizar_trabajador = () => {
        setLoadingButton(true);
        const id = trabajador.id;
        trabajador.nacionalidad = nacionalidad[0] !== null ? nacionalidad[0]['nombre'] : "";
        trabajador.ciudad = ciudad[0] !== null ? ciudad[0]['nombre'] : "";
        trabajador.fecha_nacimiento = dateNacimiento;
        trabajadorService.updateTrabajador(id, trabajador)
            .then(t => {
                console.log(t);
                toast.info("Actualizado Correctamente!", {
                    position: "top-right",
                    autoClose: 3000
                });
                setLoadingButton(false);
            })
            .catch(err => {
                console.error(err);
                toast.error("Problemas al actualizar", {
                    position: "top-right",
                    autoClose: 3000
                });
                setLoadingButton(false);
            });
    }

    const handleArchivo = (archivo, nombre) => {
        if (archivo.type === "image/png" || archivo.type === "image/jpeg") {
            new Compressor(archivo, {
                quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
                success: (compressedResult) => {
                    // compressedResult has the compressed file.
                    // Use the compressed file to upload the images to your server.        
                    //setCompressedFile(res)
                    setDocumento({ ...documento, ['nombre']: nombre, ['file']: compressedResult });
                },
            });
        } else {
            setDocumento({ ...documento, ['nombre']: nombre, ['file']: archivo });
        }
    }

    const subir_archivo = async (file) => {
        if (!documento) {
            alert("Adjunte el archivo primero");
            return;
        }
        if (!documento.file) {
            alert("Seleccione el archivo primero");
            return;
        }
        var valor = documento.nombre;
        if (ejecu.perfiles) {
            if (ejecu.perfiles.find(v => v.nombre === "Contratador") && documentos.find(v => v.nombre === "contrato")) {
                if (dateContrato != null) {
                    var ffinal = "";
                    if (dateFinContrato === "") {
                        ffinal = moment().add(1, 'months');
                    } else {
                        ffinal = dateFinContrato;
                    }
                    documento.fecha_inicio = dateContrato;
                    documento.fecha_termino = ffinal;
                } else {
                    alert("Tiene que ingresar la fecha del contrato");
                    return;
                }
            }
        }

        setLoadingButton(true);
        setLoading(true);
        //var valor = documento.nombre;
        //Subimos el archivo a la plataforma
        //S3FileUpload.uploadFile()
        var dir = trabajador.rut.replace(/\./g, '');
        const config = {
            bucketName: 'csibucket',
            dirName: dir,
            acl: 'public-read',
            //dirName: 'photos', /* optional */
            region: 'sa-east-1',
            accessKeyId: 'AKIA47CRWEBTLXZ6R5JJ',
            secretAccessKey: '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',
        }
        const ReactS3Client = new S3(config);
        var nombre_documento = trabajador.nombre;
        let docu = null;
        var name_doc = "";

        switch (valor) {
            case "carnet_atras":
                nombre_documento = nombre_documento + "_CIATRAS";
                break;
            case "carnet_frontal":
                nombre_documento = nombre_documento + "_CIFRONTAL";
                break;
            case "antecedente":
                nombre_documento = nombre_documento + "_CA";
                break;
            case "afp":
                nombre_documento = nombre_documento + "_AFP";
                break;
            case "salud":
                nombre_documento = nombre_documento + "_SALUD";
                break;
            case "afc":
                nombre_documento = nombre_documento + "_AFC";
                break;
            case "estudio":
                nombre_documento = nombre_documento + "_ESTUDIOS";
                break;
            case "finiquito":
                nombre_documento = nombre_documento + "_FINIQUITO";
                break;
            case "cv":
                nombre_documento = nombre_documento + "_CV";
                break;
            case "foto":
                nombre_documento = nombre_documento + "_FOTO";
                break;
            case "pase":
                nombre_documento = nombre_documento + "_PASE_MOVILIDAD";
                break;
            case "contrato":
                nombre_documento = nombre_documento + "_CONTRATO";
                break;
            case "ficha":
                nombre_documento = nombre_documento + "_FICHAMEL";
            case "transporte_mel":
                nombre_documento = nombre_documento + "_TRANSMEL";
            case "extravio_credencial":
                nombre_documento = nombre_documento + "_EXTCREDEN";
            case "visto_bueno_carpeta":
                nombre_documento = nombre_documento + "_VBCARPETA";
            case "primera_asistencia":
                nombre_documento = nombre_documento + "_PRIMERAASISTENCIA";
            case "etnia":
                nombre_documento = nombre_documento + "_ETNIA";
        }

        window.Buffer = window.Buffer || require("buffer").Buffer;
        var extension = documento.file.name.split('.').pop();

        if (documento.nombre === valor) {
            //Si exite carnet en el documentos - actualizamos
            var dc = documentos.find(v => v.nombre === valor);
            if (dc) {
                var fileup = null;
                docu = await ReactS3Client.uploadFile(documento.file, nombre_documento + "." + extension);
                documento.ruta = docu.location;
                const data = {
                    nombre: documento.nombre,
                    ruta: documento.ruta,
                    fecha_inicio: documento.fecha_inicio,
                    fecha_termino: documento.fecha_termino
                }
                if (extension === "doc" || extension === "docx") {
                    let cs = await convertfileService.convertFile({ ruta: documento.ruta, nombre_archivo: nombre_documento + ".pdf" });
                    var bufferArray = base64ToArrayBuffer(cs.data);
                    var blobStore = new Blob([bufferArray], { type: "application/pdf" });
                    fileup = cs.data;
                    let docu_pdf = await ReactS3Client.uploadFile(blobStore, nombre_documento + ".pdf");
                    data.ruta = docu_pdf.location;
                }

                let update_doc = await documentoService.update(dc.id, data);
                if (update_doc) {
                    if (documento.nombre === "contrato") {
                        //Actualizamos el estado del trabajador.
                        toast.info("Se actualizo el contrato del Trabajador", {
                            position: "top-center",
                            autoClose: 1000
                        });
                        if (trabajador.estado === "Listo para contratar") {
                            trabajadorService.updateTrabajador(trabajador.id, { estado: "Contratado" })
                                .then(t => {
                                    //registroEstado("Contratado",new Date(),trabajador.id,ejecu.id);
                                    toast.info("Trabajador paso a estado Contratado", {
                                        position: "top-center",
                                        autoClose: 1000
                                    });
                                })
                                .catch(err => {
                                    console.error(err);
                                });
                        }
                    }
                    listarParaAcreditar(trabajador.id);
                    alert(update_doc.data.message);
                    setDocumento(null);
                    setLoading(false);
                    setLoadingButton(false);
                    return;
                }
            } else {
                //Ingresamos un archivo nuevo
                docu = await ReactS3Client.uploadFile(documento.file, nombre_documento + "." + extension);
                var fin = new Date(moment());
                var ffn = new Date(moment().add(10, 'years'));
                updateDocs(docu, documento.file, documento.fecha_inicio, documento.fecha_termino);
                if (documento.nombre === "contrato") {
                    //Actualizamos el estado del trabajador.
                    trabajadorService.updateTrabajador(trabajador.id, { estado: "Contratado" })
                        .then(t => {
                            registroEstado("Contratado", new Date(), trabajador.id, ejecu.id);
                            toast.info("Trabajador paso a estado Contratado", {
                                position: "top-center",
                                autoClose: 1000
                            });
                        })
                        .catch(err => {
                            console.error(err);
                        });
                }
                listarParaAcreditar(trabajador.id);
                alert("Documento subido correctamente!");
            }
        }
        setDocumento(null);
        setLoadingButton(false);
        setLoading(false);
    }

    const updateDocs = async (docu, file, fi, ff) => {
        var docs = [...documentos];
        if (docu && file) {
            documento.ruta = docu.location;
            documento.trabajadorId = trabajador.id;
            if (fi != "") {
                documento.fecha_inicio = fi;
                documento.fecha_termino = ff;
            }
            let d = await documentoService.crear(documento);
            if (d) {
                docs.push(d.data);
                setDocumentos(docs);
            } else {
                alert("Problemas al ingresar");
                return;
            }
        }
    }

    const renderTooltip = (props) => {
        <Tooltip id="button-tooltip" {...props}>Es menos del 40 %</Tooltip>
    }

    const ingresarFirma = () => {
        var ant = documentos.find(v => v.nombre === "antecedente");
        if (!dateAntecedente) {
            alert("Debe ingresar fecha");
            return;
        }
        if (!ant) {
            alert("Falta ingresar documentos de antecedentes");
            return;
        } else {
            //ingresamos la fecha del documento
            documentoService.update(ant.id, { fecha_termino: dateAntecedente })
                .then(response => {
                    console.log(response);
                    toast.info("Fecha ingresada con exito", {
                        position: "top-center",
                        autoClose: 1000
                    });
                }).catch(err => {
                    console.error(err);
                });
        }
    }

    /** Función para revisar si la persona pasa a acreditar */
    async function listarParaAcreditar(id_trabajador) {
        //Obtenemos el trabajador
        var total = 0;
        if (trabajador.conduccion && trabajador.trabajo_escondida) {
            total = 11;
        }
        if ((trabajador.conduccion === false || trabajador.conduccion === null) && trabajador.trabajo_escondida) {
            total = 8;
        }
        if ((trabajador.conduccion === false || trabajador.conduccion === null) && (trabajador.trabajo_escondida === false || trabajador.trabajo_escondida === null)) {
            total = 7;
        }
        if ((trabajador.trabajo_escondida === false || trabajador.trabajo_escondida === null) && (trabajador.conduccion)) {
            total = 10;
        }
        var contador = 0;
        try {
            let t = await trabajadorService.getByID(id_trabajador);
            let documentos = t.data.documentos;
            let examenes = t.data.examenes;
            for (let d in documentos) {
                var n = documentos[d].nombre;
                if (n === "carnet") {
                    contador += 1;
                }
                if (n === "antecedente") {
                    contador += 1;
                }
                if (n === "contrato") {
                    contador += 1;
                }
                if (n === "transporte_mel") {
                    contador += 1;
                }
                if (n === "ficha") {
                    contador += 1;
                }
                /* if (n === "extravio_credencial") {
                    contador += 1;
                } */
                /* if (n === "foto") {
                    contador += 1;
                } */
                if (trabajador.trabajo_escondida) {
                    if (n === "finiquito") {
                        contador += 1;
                    }
                }
                if (trabajador.conduccion) {
                    if (n === "licencia_conducir") {
                        contador += 1;
                    }
                    if (n === "hoja_vida_conductor") {
                        contador += 1;
                    }
                }
            }
            for (let e in examenes) {
                let ex = examenes[e].nombre;
                if (ex === "CERO DAÑO" || ex === "INDUCCION HOMBRE NUEVO") {
                    contador += 1;
                }
                if (ex === "EXÁMEN PREOCUPACIONAL" || (ex === "ALTURA GEOGRÁFICA" && ex === "ALTURA FÍSICA")) {
                    contador += 1;
                }
                if (trabajador.conduccion) {
                    if (ex === "PSICOSENSOMÉTRICO") {
                        contador += 1;
                    }
                }
            }

            if (trabajador.conduccion && trabajador.trabajo_escondida) {
                if (contador === 11) {
                    //Cambiamos el estado a listo para acreditar.
                    let tu = await trabajadorService.updateTrabajador(id_trabajador, { estado: "Listo para acreditar" });
                    registroEstado("Listo para acreditar", new Date(), id_trabajador, 0);
                    console.log(tu);
                }
            }
            if ((trabajador.conduccion === false || trabajador.conduccion === null) && trabajador.trabajo_escondida) {
                if (contador === 8) {
                    //Cambiamos el estado a listo para acreditar.
                    let tu = await trabajadorService.updateTrabajador(id_trabajador, { estado: "Listo para acreditar" });
                    registroEstado("Listo para acreditar", new Date(), id_trabajador, 0);
                    console.log(tu);
                }
            }
            if ((trabajador.conduccion === false || trabajador.conduccion === null) && (trabajador.trabajo_escondida === false || trabajador.trabajo_escondida === null)) {
                if (contador === 7) {
                    //Cambiamos el estado a listo para acreditar.
                    let tu = await trabajadorService.updateTrabajador(id_trabajador, { estado: "Listo para acreditar" });
                    registroEstado("Listo para acreditar", new Date(), id_trabajador, 0);
                    console.log(tu);
                }
            }
            if ((trabajador.trabajo_escondida === false || trabajador.trabajo_escondida === null) && (trabajador.conduccion)) {
                if (contador === 10) {
                    //Cambiamos el estado a listo para acreditar.
                    let tu = await trabajadorService.updateTrabajador(id_trabajador, { estado: "Listo para acreditar" });
                    registroEstado("Listo para acreditar", new Date(), id_trabajador, 0);
                    console.log(tu);
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    function registroEstado(estado, fecha_hoy, id_trabajador, id_usuario) {
        estadoService.create({ nombre_estado: estado, fecha: fecha_hoy, trabajadorId: id_trabajador, usuarioId: id_usuario })
            .then(resp => {
                console.log(resp);
            }).
            catch(err => {
                console.error(err);
            });
    };

    const cambiarFechaFinContrato = (fechaContrato) => {
        var fc = fechaContrato;
        var cambio = addDays(fc, 30);
        setDateFinContrato(cambio);
        setDateContrato(fc)
    }

    return (
        <Container>
            <section class="py-8 mb-3">
                <div class="container">
                    <div class="px-8 ps-md-12 bg-info overflow-hidden rounded">
                        <div class="row">
                            <div class="col-12 col-md-7 py-12 mb-2 mb-md-0">
                                <h3 class="h1 mb-2 text-white px-3">
                                    <span class="text-success">MetaPrime</span>
                                    <span className='m-2'>Demo Gratis</span>
                                </h3>
                                <p class="h5 text-white px-3">Carga de Información Personal</p>
                            </div>
                            <div class="col-12 col-md-5 py-5 h-100 position-relative">
                                <img class="d-none d-md-block position-absolute top-50 end-0 w-100 translate-middle-y mt-14" src="https://shuffle.dev/artemis-assets/images/chart-folder.png" alt="" />
                                <img class="img-fluid d-md-none" src="artemis-assets/images/chart-folder.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {loading ? (
                <RingSpinnerOverlay color="#004f9e" message="Cargando ..." />
            ) : ""}
            <Row>
                <Col>
                    <h2>Antecedentes Generales</h2>
                    {/* <Col className="mt-3 mb-3">
                        <Button
                            variant="primary"
                            disabled={isLoadingButton}
                            onClick={!isLoadingButton ? actualizar_trabajador : null}
                        >{isLoadingButton ? "Cargando ..." : "Actualizar Información"}</Button>
                    </Col> */}
                    {trabajador.centro_costo === "SERVICIO DE MANTENCIÓN Y CONSTRUCCIÓN DRT" || trabajador.centro_costo === "SERVICIO DE APOYO SHIFTING GABY" ? (
                        <Col className="mb-3">
                            <a href="https://docrecluta.s3.sa-east-1.amazonaws.com/DRT/INDUCCION_GABY.pdf" alt="Inducción Gaby" target="_blank">INDUCCIÓN CODELCO</a>
                        </Col>
                    ) : ""}
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Rut:</Form.Label>
                        <Form.Control type="text" value={trabajador.rut} placeholder="12345678-9" disabled /> */}
                        <TextField
                            label='Rut'
                            name='rut'
                            fullWidth
                            value={formik.values.rut}
                            disabled
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Nombre:</Form.Label>
                        <Form.Control type="text" value={trabajador.nombre} placeholder="NOMBRE1 NOMBRE2 APELLIDOP APELLIDOM" required onChange={(e) => handleChangeTrabajador(e.target.value, "nombre")} /> */}
                        <TextField
                            name='nombre'
                            label='Nombre'
                            id='nombre'
                            fullWidth
                            variant='outlined'
                            error={Boolean(formik.touched.nombre && formik.errors.nombre)}
                            onChange={formik.handleChange}
                            value={formik.values.nombre}
                            helperText={formik.touched.nombre && formik.errors.nombre}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Apellido Paterno:</Form.Label>
                        <Form.Control type="text" value={trabajador.ap_paterno} placeholder="APELLIDO PATERNO" required onChange={(e) => handleChangeTrabajador(e.target.value, "ap_paterno")} /> */}
                        <TextField
                            name='ap_paterno'
                            label='Apellido Paterno'
                            id='ap_paterno'
                            fullWidth
                            variant='outlined'
                            error={Boolean(formik.touched.ap_paterno && formik.errors.ap_paterno)}
                            onChange={formik.handleChange}
                            value={formik.values.ap_paterno}
                            helperText={formik.touched.ap_paterno && formik.errors.ap_paterno}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Apellido Materno:</Form.Label>
                        <Form.Control type="text" value={trabajador.ap_materno} placeholder="APELLIDO MATERNO" required onChange={(e) => handleChangeTrabajador(e.target.value, "ap_materno")} /> */}
                        <TextField
                            name='ap_materno'
                            label='Apellido Materno'
                            id='ap_materno'
                            fullWidth
                            variant='outlined'
                            error={Boolean(formik.touched.ap_materno && formik.errors.ap_materno)}
                            onChange={formik.handleChange}
                            value={formik.values.ap_materno}
                            helperText={formik.touched.ap_materno && formik.errors.ap_materno}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Sexo:</Form.Label>
                        <Form.Select aria-label="Default select example" value={trabajador ? trabajador.sexo : ""} onChange={(e) => handleChangeTrabajador(e.target.value, "sexo")}>
                            <option value="Femenino">Femenino</option>
                            <option value="Masculino">Masculino</option>
                        </Form.Select> */}
                        <FormControl fullWidth>
                            <InputLabel id='sexo'>Sexo</InputLabel>
                            <Select
                                labelId="sexo"
                                id="sexo"
                                label="Sexo"
                                name="sexo"
                                onChange={formik.handleChange}
                                value={formik.values.sexo !== null ? formik.values.sexo : ""}
                                error={Boolean(formik.touched.sexo && formik.errors.sexo)}
                            >
                                <MenuItem key="Femenino" value="Femenino">Femenino</MenuItem>
                                <MenuItem key="Masculino" value="Masculino">Masculino</MenuItem>
                            </Select>
                            <FormHelperText>{formik.touched.sexo && formik.errors.sexo}</FormHelperText>
                        </FormControl>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                            <DesktopDatePicker
                                label="Fecha Nacimiento"
                                inputFormat="dd/MM/yyyy"
                                value={formik.values.fecha_nacimiento}
                                onChange={(e) => formik.setFieldValue('fecha_nacimiento', e, true)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Nacionalidad:</Form.Label> */}
                        {/* <Form.Control type="text" value={trabajador.nacionalidad ? trabajador.nacionalidad : ""} onChange={(e) => handleChangeTrabajador(e.target.value, "nacionalidad")} /> */}
                        {/* <Typeahead
                                id="basic-example"
                                labelKey="nombre"
                                onChange={(e) => setNacionalidad(e)}
                                options={paisessuda}
                                placeholder="Elija un país..."
                                selected={trabajador.nacionalidad != null ? nacionalidad : ""}
                            /> */}
                        <TextField
                            name='nacionalidad'
                            label='Nacionalidad'
                            id='nacionalidad'
                            fullWidth
                            variant='outlined'
                            error={Boolean(formik.touched.nacionalidad && formik.errors.nacionalidad)}
                            onChange={formik.handleChange}
                            value={formik.values.nacionalidad}
                            helperText={formik.touched.nacionalidad && formik.errors.nacionalidad}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Estado Civil:</Form.Label>
                        
                        <Form.Select data-live-search="true" value={trabajador ? trabajador.estado_civil : ""} onChange={(e) => handleChangeTrabajador(e.target.value, "estado_civil")}>
                            <option value="CASADA/O">CASADA/O</option>
                            <option value="SOLTERA/O">SOLTERA/O</option>
                            <option value="DIVOCRIADA/O">DIVORCIADA/O</option>
                            <option value="VIUDA/O">VIUDA/O</option>
                        </Form.Select> */}
                        <FormControl fullWidth>
                            <InputLabel id='estado_civil'>Estado Civil</InputLabel>
                            <Select
                                labelId="estado_civil"
                                id="estado_civil"
                                name="estado_civil"
                                label="Estado Civil"
                                onChange={formik.handleChange}
                                value={formik.values.estado_civil}
                                error={Boolean(formik.touched.estado_civil && formik.errors.estado_civil)}
                            >
                                <MenuItem key="CASADA/O" value="CASADA/O">CASADA/O</MenuItem>
                                <MenuItem key="SOLTERA/O" value="SOLTERA/O">SOLTERA/O</MenuItem>
                                <MenuItem key="DIVOCRIADA/O" value="DIVOCRIADA/O">DIVOCRIADA/O</MenuItem>
                                <MenuItem key="VIUDA/O" value="VIUDA/O">VIUDA/O</MenuItem>
                            </Select>
                            <FormHelperText>{formik.touched.estado_civil && formik.errors.estado_civil}</FormHelperText>
                        </FormControl>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Discapacidad:</Form.Label>
                        <Form.Select value={trabajador ? trabajador.discapacidad : ""} onChange={(e) => handleChangeTrabajador(e.target.value, "discapacidad")}>
                            <option value="SI">SI</option>
                            <option value="NO">NO</option>
                        </Form.Select> */}
                        <FormControl fullWidth>
                            <InputLabel id='discapacidad'>Discapacidad</InputLabel>
                            <Select
                                labelId="discapacidad"
                                id="discapacidad"
                                label="Discapacidad"
                                name="discapacidad"
                                onChange={formik.handleChange}
                                value={formik.values.discapacidad !== null ? formik.values.discapacidad : ""}
                            >
                                <MenuItem key="SI" value="SI">SI</MenuItem>
                                <MenuItem key="NO" value="NO">NO</MenuItem>
                            </Select>
                        </FormControl>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <FormControl fullWidth>
                            <InputLabel id='etnia'>¿Pertenece a alguna Etnia?</InputLabel>
                            <Select
                                labelId="etnia"
                                id="etnia"
                                label="Etnia"
                                name="etnia"
                                onChange={formik.handleChange}
                                value={formik.values.etnia !== null ? formik.values.etnia : ""}
                                error={Boolean(formik.touched.etnia && formik.errors.etnia)}
                            >
                                <MenuItem key="SI" value="SI">SI</MenuItem>
                                <MenuItem key="NO" value="NO">NO</MenuItem>
                            </Select>
                            <FormHelperText>{formik.touched.etnia && formik.errors.etnia}</FormHelperText>
                        </FormControl>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Direccion de Residencia:</Form.Label>
                        <Form.Control type="text" value={trabajador.direccion} onChange={(e) => handleChangeTrabajador(e.target.value, "direccion")} /> */}
                        <TextField
                            name='direccion'
                            label='Dirección'
                            id='direccion'
                            fullWidth
                            variant='outlined'
                            error={Boolean(formik.touched.direccion && formik.errors.direccion)}
                            onChange={formik.handleChange}
                            value={formik.values.direccion}
                            helperText={formik.touched.direccion && formik.errors.direccion}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Ciudad:</Form.Label> */}
                        {/* <Form.Control type="text" value={trabajador.ciudad} onChange={(e) => handleChangeTrabajador(e.target.value, "ciudad")} /> */}
                        {/* <Typeahead
                            id="basic-example"
                            labelKey="nombre"
                            onChange={(e) => setCiudad(e)}
                            options={ciudades}
                            placeholder="Elija una ciudad..."
                            selected={trabajador.ciudad != null ? ciudad : ""}
                        /> */}
                        <TextField
                            name='ciudad'
                            label='Ciudad'
                            id='ciudad'
                            fullWidth
                            variant='outlined'
                            error={Boolean(formik.touched.ciudad && formik.errors.ciudad)}
                            onChange={formik.handleChange}
                            value={formik.values.ciudad}
                            helperText={formik.touched.ciudad && formik.errors.ciudad}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Correo Electronico:</Form.Label>
                        <Form.Control type="text" value={trabajador.correo} onChange={(e) => handleChangeTrabajador(e.target.value, "correo")} /> */}
                        <TextField
                            name='correo'
                            label='Correo Electronico'
                            id='correo'
                            fullWidth
                            variant='outlined'
                            error={Boolean(formik.touched.correo && formik.errors.correo)}
                            onChange={formik.handleChange}
                            value={formik.values.correo}
                            helperText={formik.touched.correo && formik.errors.correo}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Celular:</Form.Label>
                        <Form.Control type="text" value={trabajador.fono} onChange={(e) => handleChangeTrabajador(e.target.value, "fono")} /> */}
                        <TextField
                            name='fono'
                            label='Celular'
                            id='fono'
                            fullWidth
                            variant='outlined'
                            error={Boolean(formik.touched.fono && formik.errors.fono)}
                            onChange={formik.handleChange}
                            value={formik.values.fono}
                            helperText={formik.touched.fono && formik.errors.fono}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Nombre de contacto en caso de emergencia:</Form.Label>
                        <Form.Control type="text" value={trabajador.contacto_emergencia} onChange={(e) => handleChangeTrabajador(e.target.value, "contacto_emergencia")} /> */}
                        <TextField
                            name='contacto_emergencia'
                            label='Nombre de contacto en caso de emergencia'
                            id='contacto_emergencia'
                            fullWidth
                            variant='outlined'
                            error={Boolean(formik.touched.contacto_emergencia && formik.errors.contacto_emergencia)}
                            onChange={formik.handleChange}
                            value={formik.values.contacto_emergencia}
                            helperText={formik.touched.contacto_emergencia && formik.errors.contacto_emergencia}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Fono Contacto Emergencia:</Form.Label>
                        <Form.Control type="text" placeholder="+56912345678" value={trabajador.fono_contacto_emergencia} onChange={(e) => handleChangeTrabajador(e.target.value, "fono_contacto_emergencia")} /> */}
                        <TextField
                            name='fono_contacto_emergencia'
                            label='Fono Contacto de Emergencia'
                            id='fono_contacto_emergencia'
                            fullWidth
                            variant='outlined'
                            error={Boolean(formik.touched.fono_contacto_emergencia && formik.errors.fono_contacto_emergencia)}
                            onChange={formik.handleChange}
                            value={formik.values.fono_contacto_emergencia}
                            helperText={formik.touched.fono_contacto_emergencia && formik.errors.fono_contacto_emergencia}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Alergico A:</Form.Label>
                        <Form.Control type="text" value={trabajador.alergico} onChange={(e) => handleChangeTrabajador(e.target.value, "alergico")} /> */}
                        <TextField
                            name='alergico'
                            label='Alergico A'
                            id='alergico'
                            fullWidth
                            variant='outlined'
                            onChange={formik.handleChange}
                            value={formik.values.alergico}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Nombre Conyugue:</Form.Label>
                        <Form.Control type="text" value={trabajador.nombre_conyugue} onChange={(e) => handleChangeTrabajador(e.target.value, "nombre_conyugue")} /> */}
                        <TextField
                            name='nombre_conyugue'
                            label='Nombre Conyugue'
                            id='nombre_conyugue'
                            fullWidth
                            variant='outlined'
                            onChange={formik.handleChange}
                            value={formik.values.nombre_conyugue}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Rut Conyugue:</Form.Label>
                        <Form.Control type="text" placeholder="12345678-9" value={trabajador.rut_conyugue} onChange={(e) => handleChangeTrabajador(e.target.value, "rut_conyugue")} /> */}
                        <TextField
                            name='rut_conyugue'
                            label='Rut Conyugue'
                            id='rut_conyugue'
                            fullWidth
                            variant='outlined'
                            onChange={formik.handleChange}
                            value={formik.values.rut_conyugue}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Conductor Vehiculo:</Form.Label>
                        <Form.Select aria-label="Default select example" value={trabajador ? trabajador.conductor : ""} onChange={(e) => handleChangeTrabajador(e.target.value, "conductor")}>
                            <option>Seleccione...</option>
                            <option value="SI">SI</option>
                            <option value="NO">NO</option>
                        </Form.Select> */}
                        <FormControl fullWidth>
                            <InputLabel id='conductor'>Conductor</InputLabel>
                            <Select
                                labelId="conductor"
                                id="conductor"
                                label="Conductor"
                                name="conductor"
                                onChange={formik.handleChange}
                                value={formik.values.conductor !== null ? formik.values.conductor : ""}
                            >
                                <MenuItem key="SI" value="SI">SI</MenuItem>
                                <MenuItem key="NO" value="NO">NO</MenuItem>
                            </Select>
                        </FormControl>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Tipo de Licencia:</Form.Label>
                        <Form.Select value={trabajador ? trabajador.licencia : ""} onChange={(e) => handleChangeTrabajador(e.target.value, "licencia")}>
                            <option value="Ninguna">Ninguna</option>
                            <option value="A1">A1</option>
                            <option value="A2">A2</option>
                            <option value="A3">A3</option>
                            <option value="A4">A4</option>
                            <option value="A5">A5</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="D">D</option>
                            <option value="E">E</option>
                        </Form.Select> */}
                        <FormControl fullWidth>
                            <InputLabel id='licencia'>Licencia</InputLabel>
                            <Select
                                labelId="licencia"
                                id="licencia"
                                label="Licencia"
                                name="licencia"
                                onChange={formik.handleChange}
                                value={formik.values.licencia !== null ? formik.values.licencia : ""}
                            >
                                <MenuItem key="Ninguna" value="Ninguna">Ninguna</MenuItem>
                                <MenuItem key="A1" value="A1">A1</MenuItem>
                                <MenuItem key="A2" value="A2">A2</MenuItem>
                                <MenuItem key="A3" value="A3">A3</MenuItem>
                                <MenuItem key="A4" value="A4">A4</MenuItem>
                                <MenuItem key="A5" value="A5">A5</MenuItem>
                                <MenuItem key="B" value="B">B</MenuItem>
                                <MenuItem key="C" value="C">C</MenuItem>
                                <MenuItem key="D" value="D">D</MenuItem>
                                <MenuItem key="E" value="E">E</MenuItem>
                            </Select>
                        </FormControl>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Talla Pantalón:</Form.Label>
                        <Form.Select value={trabajador ? trabajador.talla_pantalon : ""} onChange={(e) => handleChangeTrabajador(e.target.value, "talla_pantalon")}>
                            {tallaPantalon().map((t, i) => {
                                return (
                                    <option key={i} value={t}>{t}</option>
                                )
                            })}
                        </Form.Select> */}
                        <FormControl fullWidth>
                            <InputLabel id='talla_pantalon'>Talla de Pantalón</InputLabel>
                            <Select
                                labelId="talla_pantalon"
                                id="talla_pantalon"
                                name="talla_pantalon"
                                label="Talla de Pantalón"
                                onChange={formik.handleChange}
                                value={formik.values.talla_pantalon !== null ? formik.values.talla_pantalon : ""}
                                error={Boolean(formik.touched.talla_pantalon && formik.errors.talla_pantalon)}
                            >
                                {tallaPantalon().map((t, i) => {
                                    return (
                                        <MenuItem key={i} value={t}>{t}</MenuItem>
                                    )
                                })}
                            </Select>
                            <FormHelperText>{formik.touched.talla_pantalon && formik.errors.talla_pantalon}</FormHelperText>
                        </FormControl>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Talla Camisa:</Form.Label>
                        <Form.Select value={trabajador ? trabajador.talla_camisa : ""} onChange={(e) => handleChangeTrabajador(e.target.value, "talla_camisa")}>
                            <option value="S">S</option>
                            <option value="M">M</option>
                            <option value="L">L</option>
                            <option value="XL">XL</option>
                            <option value="XXL">XXL</option>
                        </Form.Select> */}
                        <FormControl fullWidth>
                            <InputLabel id='talla_camisa'>Talla de Camisa</InputLabel>
                            <Select
                                labelId="talla_camisa"
                                id="talla_camisa"
                                name="talla_camisa"
                                label="Talla de Camisa"
                                onChange={formik.handleChange}
                                value={formik.values.talla_camisa !== null ? formik.values.talla_camisa : ""}
                                error={Boolean(formik.touched.talla_camisa && formik.errors.talla_camisa)}
                            >
                                <MenuItem key="S" value="S">S</MenuItem>
                                <MenuItem key="M" value="M">M</MenuItem>
                                <MenuItem key="L" value="L">L</MenuItem>
                                <MenuItem key="XL" value="XL">XL</MenuItem>
                                <MenuItem key="XXL" value="XXL">XXL</MenuItem>
                            </Select>
                            <FormHelperText>{formik.touched.talla_camisa && formik.errors.talla_camisa}</FormHelperText>
                        </FormControl>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Talla Buzo:</Form.Label>
                        <Form.Select value={trabajador ? trabajador.talla_buzo : ""} onChange={(e) => handleChangeTrabajador(e.target.value, "talla_buzo")}>
                            {tallaPantalon().map((b, i) => {
                                return (
                                    <option key={i} value={b}>{b}</option>
                                )
                            })}
                        </Form.Select> */}
                        <FormControl fullWidth>
                            <InputLabel id='talla_buzo'>Talla de Buzo</InputLabel>
                            <Select
                                labelId="talla_buzo"
                                id="talla_buzo"
                                name="talla_buzo"
                                label="Talla de Buzo"
                                onChange={formik.handleChange}
                                value={formik.values.talla_buzo !== null ? formik.values.talla_buzo : ""}
                                error={Boolean(formik.touched.talla_buzo && formik.errors.talla_buzo)}
                            >
                                {tallaPantalon().map((b, i) => {
                                    return (
                                        <MenuItem key={i} value={b}>{b}</MenuItem>
                                    )
                                })}
                            </Select>
                            <FormHelperText>{formik.touched.talla_buzo && formik.errors.talla_buzo}</FormHelperText>
                        </FormControl>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>N° Calzado:</Form.Label>
                        <Form.Select value={trabajador ? trabajador.nro_calzado : ""} onChange={(e) => handleChangeTrabajador(e.target.value, "nro_calzado")}>
                            {calzado().map((c, i) => {
                                return (
                                    <option key={i} value={c}>{c}</option>
                                )
                            })}
                        </Form.Select> */}
                        <FormControl fullWidth>
                            <InputLabel id='nro_calzado'>N° Calzado</InputLabel>
                            <Select
                                labelId="nro_calzado"
                                id="nro_calzado"
                                name="nro_calzado"
                                label="N° Calzado"
                                onChange={formik.handleChange}
                                value={formik.values.nro_calzado !== null ? formik.values.nro_calzado : ""}
                                error={Boolean(formik.touched.nro_calzado && formik.errors.nro_calzado)}
                            >
                                {calzado().map((c, i) => {
                                    return (
                                        <MenuItem key={i} value={c}>{c}</MenuItem>
                                    )
                                })}
                            </Select>
                            <FormHelperText>{formik.touched.nro_calzado && formik.errors.nro_calzado}</FormHelperText>
                        </FormControl>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Anticipo de Sueldo:</Form.Label>
                        <OverlayTrigger
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip id="button-tooltip">El anticipo corresponde al 40% de su liquido pactado</Tooltip>}>
                            <Button variant="success-outline"><FontAwesomeIcon icon="fa-solid fa-circle-info" /></Button>
                        </OverlayTrigger>
                        {/* <Form.Select aria-label="Default select example" value={trabajador ? trabajador.anticipo_sueldo : ""} onChange={(e) => handleChangeTrabajador(e.target.value, "anticipo_sueldo")}>
                            <option>Seleccione anticipo...</option>
                            <option value="SI">SI</option>
                            <option value="NO">NO</option>
                        </Form.Select> */}
                        <FormControl fullWidth>
                            <InputLabel id='anticipo_sueldo'>Anticipo de Sueldo</InputLabel>
                            <Select
                                labelId="anticipo_sueldo"
                                id="anticipo_sueldo"
                                name="anticipo_sueldo"
                                label="Anticipo de Sueldo"
                                onChange={formik.handleChange}
                                value={formik.values.anticipo_sueldo !== null ? formik.values.anticipo_sueldo : ""}
                            >
                                <MenuItem key="SI" value="SI">SI</MenuItem>
                                <MenuItem key="NO" value="NO">NO</MenuItem>
                            </Select>
                        </FormControl>
                    </Form.Group>

                </Col>
                <Col>
                    <h3 className="text-center">Hijos Menores de Edad</h3>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Rut</th>
                                <th>Nombres</th>
                                <th>Fecha de Nacimiento</th>
                                <th>Editar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {hijos.map((h, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{h.rut}</td>
                                        <td>{h.nombres}</td>
                                        <td>{moment(h.fecha_nacimiento).format("DD-MM-YYYY")}</td>
                                        <td><Button variant="outline-primary" onClick={() => showEditHijo(h.id)}><FontAwesomeIcon icon="fa-solid fa-pen-to-square" /></Button></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                    <Button variant="primary" onClick={btnAgregarHijo}>Agregar Hijo menor de edad</Button>
                    <ModalHijos
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        hijo={hijo}
                        addHijo={handleChangeHijo}
                        date={dateHijo}
                        setDate={setDateHijo}
                        years={years}
                        registrarHijo={agregar_hijo}
                        editHijo={editar_hijo}
                    />
                    <br />
                    <h3 className="text-center">Antecedentes Bancarios</h3>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Nombre Banco:</Form.Label>
                        <Form.Select value={trabajador ? trabajador.banco : ""} onChange={(e) => handleChangeTrabajador(e.target.value, "banco")}>
                            <option value="BANCO DEL ESTADO DE CHILE">BANCO DEL ESTADO DE CHILE</option>
                            <option value="BANCO DE CHILE">BANCO DE CHILE</option>
                            <option value="BANCO INTERNACIONAL">BANCO INTERNACIONAL</option>
                            <option value="SCOTIABANK CHILE">SCOTIABANK CHILE</option>
                            <option value="BANCO DE CREDITO E INVERSIONES">BANCO DE CREDITO E INVERSIONES</option>
                            <option value="BANCO BICE">BANCO BICE</option>
                            <option value="HSBC BANK (CHILE)">HSBC BANK (CHILE)</option>
                            <option value="BANCO SANTANDER-CHILE">BANCO SANTANDER-CHILE</option>
                            <option value="ITAÚ CORPBANCA">ITAÚ CORPBANCA</option>
                            <option value="BANCO SECURITY">BANCO SECURITY</option>
                            <option value="BANCO FALABELLA">BANCO FALABELLA</option>
                            <option value="BANCO RIPLEY">BANCO RIPLEY</option>
                            <option value="BANCO CONSORCIO">BANCO CONSORCIO</option>
                            <option value="SCOTIABANK AZUL">SCOTIABANK AZUL</option>
                            <option value="BANCO BTG PACTUAL CHILE">BANCO BTG PACTUAL CHILE</option>
                            <option value="COOPEUCH">COOPEUCH</option>
                        </Form.Select> */}
                        <FormControl fullWidth>
                            <InputLabel id='banco'>Banco</InputLabel>
                            <Select
                                labelId="banco"
                                id="banco"
                                label="Banco"
                                name="banco"
                                onChange={formik.handleChange}
                                value={formik.values.banco !== null ? formik.values.banco : ""}
                                error={Boolean(formik.touched.banco && formik.errors.banco)}
                            >
                                <MenuItem key="BANCO DEL ESTADO DE CHILE" value="BANCO DEL ESTADO DE CHILE">BANCO DEL ESTADO DE CHILE</MenuItem>
                                <MenuItem key="BANCO DE CHILE" value="BANCO DE CHILE">BANCO DE CHILE</MenuItem>
                                <MenuItem key="BANCO INTERNACIONAL" value="BANCO INTERNACIONAL">BANCO INTERNACIONAL</MenuItem>
                                <MenuItem key="SCOTIABANK CHILE" value="SCOTIABANK CHILE">SCOTIABANK CHILE</MenuItem>
                                <MenuItem key="BANCO DE CREDITO E INVERSIONES" value="BANCO DE CREDITO E INVERSIONES">BANCO DE CREDITO E INVERSIONES</MenuItem>
                                <MenuItem key="BANCO BICE" value="BANCO BICE">BANCO BICE</MenuItem>
                                <MenuItem key="HSBC BANK (CHILE)" value="HSBC BANK (CHILE)">HSBC BANK (CHILE)</MenuItem>
                                <MenuItem key="BANCO SANTANDER-CHILE" value="BANCO SANTANDER-CHILE">BANCO SANTANDER-CHILE</MenuItem>
                                <MenuItem key="ITAÚ CORPBANCA" value="ITAÚ CORPBANCA">ITAÚ CORPBANCA</MenuItem>
                                <MenuItem key="BANCO SECURITY" value="BANCO SECURITY">BANCO SECURITY</MenuItem>
                                <MenuItem key="BANCO FALABELLA" value="BANCO FALABELLA">BANCO FALABELLA</MenuItem>
                                <MenuItem key="BANCO RIPLEY" value="BANCO RIPLEY">BANCO RIPLEY</MenuItem>
                                <MenuItem key="BANCO CONSORCIO" value="BANCO CONSORCIO">BANCO CONSORCIO</MenuItem>
                                <MenuItem key="SCOTIABANK AZUL" value="SCOTIABANK AZUL">SCOTIABANK AZUL</MenuItem>
                                <MenuItem key="BANCO BTG PACTUAL CHILE" value="BANCO BTG PACTUAL CHILE">BANCO BTG PACTUAL CHILE</MenuItem>
                                <MenuItem key="COOPEUCH" value="COOPEUCH">COOPEUCH</MenuItem>
                            </Select>
                            <FormHelperText>{formik.touched.banco && formik.errors.banco}</FormHelperText>
                        </FormControl>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {/* <Form.Label >Tipo de Cuenta:</Form.Label>
                        <Form.Select aria-label="Default select example" value={trabajador ? trabajador.tipo_cuenta : ""} onChange={(e) => handleChangeTrabajador(e.target.value, "tipo_cuenta")}>
                            <option>Seleccione una cuenta...</option>
                            <option value="CORRIENTE">Corriente</option>
                            <option value="RUT">Rut</option>
                            <option value="Vista">Vista</option>
                        </Form.Select> */}
                        <FormControl fullWidth>
                            <InputLabel id='tipo_cuenta'>Tipo de Cuenta</InputLabel>
                            <Select
                                labelId="tipo_cuenta"
                                id="tipo_cuenta"
                                label="Tipo de Cuenta"
                                name="tipo_cuenta"
                                onChange={formik.handleChange}
                                value={formik.values.tipo_cuenta !== null ? formik.values.tipo_cuenta : ""}
                                error={Boolean(formik.touched.tipo_cuenta && formik.errors.tipo_cuenta)}
                            >
                                <MenuItem value="CORRIENTE">Corriente</MenuItem>
                                <MenuItem value="RUT">Rut</MenuItem>
                                <MenuItem value="Vista">Vista</MenuItem>
                            </Select>
                            <FormHelperText>{formik.touched.tipo_cuenta && formik.errors.tipo_cuenta}</FormHelperText>
                        </FormControl>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Numero de cuenta:</Form.Label>
                        <Form.Control type="text" value={trabajador.nro_cuenta} onChange={(e) => handleChangeTrabajador(e.target.value, "nro_cuenta")} /> */}
                        <TextField
                            name='nro_cuenta'
                            label='Numero de Cuenta'
                            id='nro_cuenta'
                            fullWidth
                            variant='outlined'
                            error={Boolean(formik.touched.nro_cuenta && formik.errors.nro_cuenta)}
                            onChange={formik.handleChange}
                            value={formik.values.nro_cuenta !== null ? formik.values.nro_cuenta : ""}
                            helperText={formik.touched.nro_cuenta && formik.errors.nro_cuenta}
                        />
                    </Form.Group>
                    <div className="mt-3"></div>
                    <h3 className="text-center">AFP Y SALUD</h3>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>AFP:</Form.Label>
                        <Form.Select value={trabajador ? trabajador.afp : ""} onChange={(e) => handleChangeTrabajador(e.target.value, "afp")}>
                            <option value="AFP CAPITAL">AFP CAPITAL</option>
                            <option value="AFP CUPRUM">AFP CUPRUM</option>
                            <option value="AFP HABITAT">AFP HABITAT</option>
                            <option value="AFP MODELO">AFP MODELO</option>
                            <option value="AFP PLANVITAL">AFP PLANVITAL</option>
                            <option value="AFP PROVIDA">AFP PROVIDA</option>
                            <option value="AFP UNO">AFP UNO</option>
                        </Form.Select> */}
                        <FormControl fullWidth>
                            <InputLabel id='afp'>AFP</InputLabel>
                            <Select
                                labelId="afp"
                                id="afp"
                                label="AFP"
                                name="afp"
                                onChange={formik.handleChange}
                                value={formik.values.afp !== null ? formik.values.afp : ""}
                                error={Boolean(formik.touched.afp && formik.errors.afp)}
                            >
                                <MenuItem value="AFP CAPITAL">AFP CAPITAL</MenuItem>
                                <MenuItem value="AFP CUPRUM">AFP CUPRUM</MenuItem>
                                <MenuItem value="AFP HABITAT">AFP HABITAT</MenuItem>
                                <MenuItem value="AFP MODELO">AFP MODELO</MenuItem>
                                <MenuItem value="AFP PLANVITAL">AFP PLANVITAL</MenuItem>
                                <MenuItem value="AFP PROVIDA">AFP PROVIDA</MenuItem>
                                <MenuItem value="AFP UNO">AFP UNO</MenuItem>
                            </Select>
                            <FormHelperText>{formik.touched.afp && formik.errors.afp}</FormHelperText>
                        </FormControl>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Previsión:</Form.Label>
                        <Form.Select value={trabajador ? trabajador.prevision : ""} onChange={(e) => handleChangeTrabajador(e.target.value, "prevision")}>
                            <option value="FONASA">FONASA</option>
                            <option value="ISAPRE">ISAPRE</option>
                        </Form.Select> */}
                        <FormControl fullWidth>
                            <InputLabel id='prevision'>Previsión</InputLabel>
                            <Select
                                labelId="prevision"
                                id="prevision"
                                label="Previsión"
                                name="prevision"
                                onChange={formik.handleChange}
                                value={formik.values.prevision !== null ? formik.values.prevision : ""}
                                error={Boolean(formik.touched.prevision && formik.errors.prevision)}
                            >
                                <MenuItem value="FONASA">FONASA</MenuItem>
                                {/* <MenuItem value="ISAPRE">ISAPRE</MenuItem> */}
                                <MenuItem value="BANMÉDICA">ISAPRE - BANMÉDICA</MenuItem>
                                <MenuItem value="CRUZ BLANCA">ISAPRE - CRUZ BLANCA</MenuItem>
                                <MenuItem value="COLMENA">ISAPRE - COLMENA</MenuItem>
                                <MenuItem value="CONSALUD">ISAPRE - CONSALUD</MenuItem>
                                <MenuItem value="CRUZ DEL NORTE">ISAPRE - CRUZ DEL NORTE</MenuItem>
                                <MenuItem value="ISALUD">ISAPRE - ISALUD</MenuItem>
                                <MenuItem value="NUEVA MAS VIDA">ISAPRE - NUEVA MAS VIDA</MenuItem>
                                <MenuItem value="FUNDACIÓN">ISAPRE - FUNDACIÓN</MenuItem>
                                <MenuItem value="VIDA TRES">ISAPRE - VIDA TRES</MenuItem>
                            </Select>
                            <FormHelperText>{formik.touched.prevision && formik.errors.prevision}</FormHelperText>
                        </FormControl>
                    </Form.Group>
                    {/* {formik.values.prevision === "ISAPRE" && (
                        <Form.Group className="mb-3">
                            <FormControl fullWidth>
                                <InputLabel id='isapre'>Isapre</InputLabel>
                                <Select
                                    labelId="isapre"
                                    id="isapre"
                                    label="Isapre"
                                    name="isapre"
                                    onChange={formik.handleChange}
                                    value={formik.values.isapre !== null ? formik.values.isapre : ""}
                                >
                                    <MenuItem value="BANMÉDICA">BANMÉDICA</MenuItem>
                                    <MenuItem value="CRUZ BLANCA">CRUZ BLANCA</MenuItem>
                                    <MenuItem value="COLMENA">COLMENA</MenuItem>
                                    <MenuItem value="CONSALUD">CONSALUD</MenuItem>
                                    <MenuItem value="CRUZ DEL NORTE">CRUZ DEL NORTE</MenuItem>
                                    <MenuItem value="ISALUD">ISALUD</MenuItem>
                                    <MenuItem value="NUEVA MAS VIDA">NUEVA MAS VIDA</MenuItem>
                                    <MenuItem value="FUNDACIÓN">FUNDACIÓN</MenuItem>
                                    <MenuItem value="VIDA TRES">VIDA TRES</MenuItem>
                                </Select>
                            </FormControl>
                        </Form.Group>
                    )} */}
                    <h3 className="text-center">Documentación</h3>
                    <h5>Leyenda:</h5>
                    <Stack direction="horizontal" gap={4} className="border border-dark p-2">
                        <Form.Label className="ms-auto">Documiento Subido:</Form.Label>
                        <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} className="ms-auto" />
                        <Form.Label className="ms-auto">Documiento por Subir:</Form.Label>
                        <FontAwesomeIcon icon="a-solid fa-xmark" className="ms-auto" style={{ color: "red" }} />
                    </Stack>
                    <Stack direction="horizontal" gap={4} className="border border-dark p-2 mt-2">
                        <Form.Label>Fotocopia Carnet lado Frontal:</Form.Label>
                        <Form.Control type="file" accept="image/*" className="me-auto" onChange={(e) => handleArchivo(e.target.files[0], "carnet_frontal")} />
                        <Button variant="outline-primary" disabled={isLoadingButton} onClick={!isLoadingButton ? subir_archivo : null}>{isLoadingButton ? "Subiendo ..." : "Subir"}</Button>
                        {documentos.find(v => v.nombre === "carnet_frontal") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}
                    </Stack>
                    <Stack direction="horizontal" gap={4} className="border border-dark p-2 mt-2">
                        <Form.Label>Fotocopia Carnet lado de Atras:</Form.Label>
                        <Form.Control type="file" accept="image/*" className="me-auto" onChange={(e) => handleArchivo(e.target.files[0], "carnet_atras")} />
                        <Button variant="outline-primary" disabled={isLoadingButton} onClick={!isLoadingButton ? subir_archivo : null}>{isLoadingButton ? "Subiendo ..." : "Subir"}</Button>
                        {documentos.find(v => v.nombre === "carnet_atras") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}
                    </Stack>
                    {formik.values.etnia == "SI" ? (
                        <Stack direction="horizontal" gap={4} className="border border-dark p-2 mt-2">
                            <Form.Label>Credencial Etnia:</Form.Label>
                            <Form.Control type="file" accept="image/*,.pdf,.doc,.docx" className="me-auto" onChange={(e) => handleArchivo(e.target.files[0], "etnia")} />
                            <Button variant="outline-primary" disabled={isLoadingButton} onClick={!isLoadingButton ? subir_archivo : null}>{isLoadingButton ? "Subiendo ..." : "Subir"}</Button>
                            {documentos.find(v => v.nombre === "etnia") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}
                        </Stack>
                    ) : ""}
                    <Stack direction="horizontal" gap={4} className="border border-dark p-2 mt-2">
                        <Form.Label>Certificados de Antecedentes para fines especiales:</Form.Label>
                        <Form.Control type="file" accept="image/*,.pdf,.doc,.docx" className="me-auto" onChange={(e) => handleArchivo(e.target.files[0], "antecedente")} />
                        <Button variant="outline-primary" disabled={isLoadingButton} onClick={!isLoadingButton ? subir_archivo : null}>{isLoadingButton ? "Subiendo ..." : "Subir"}</Button>
                        {documentos.find(v => v.nombre === "antecedente") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}
                    </Stack>
                    {ejecu != "" ? ejecu.perfiles.find(v => v.nombre === "Contratador") && (
                        <Stack direction="horizontal" gap={3} className="border border-dark p-2 mt-2">
                            <Form.Group>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                    <DesktopDatePicker
                                        label="Fecha Obtención de Antecedentes"
                                        inputFormat="dd/MM/yyyy"
                                        value={dateAntecedente}
                                        onChange={(e) => setDateAntecedente(e)}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Form.Group>
                            <Button variant="secondary" onClick={ingresarFirma}>Ingresar Fecha</Button>
                        </Stack>
                    ) : ""}
                    <div className="ms-auto fw-bold" style={{ color: "red" }}>*El certificado de antecedentes no debe ser mayor a 30 días.</div>
                    <Stack direction="horizontal" gap={4} className="border border-dark p-2 mt-2">
                        <Form.Label>Certificado AFP:</Form.Label>
                        <Form.Control type="file" accept="image/*,.pdf,.doc,.docx" className="me-auto" onChange={(e) => handleArchivo(e.target.files[0], "afp")} />
                        <Button variant="outline-primary" disabled={isLoadingButton} onClick={!isLoadingButton ? subir_archivo : null}>{isLoadingButton ? "Subiendo ..." : "Subir"}</Button>
                        {documentos.find(v => v.nombre === "afp") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}
                    </Stack>
                    <Stack direction="horizontal" gap={4} className="border border-dark p-2 mt-2">
                        <Form.Label>Certificado Salud:</Form.Label>
                        <Form.Control type="file" accept="image/*,.pdf,.doc,.docx" className="me-auto" onChange={(e) => handleArchivo(e.target.files[0], "salud")} />
                        <Button variant="outline-primary" disabled={isLoadingButton} onClick={!isLoadingButton ? subir_archivo : null}>{isLoadingButton ? "Subiendo ..." : "Subir"}</Button>
                        {documentos.find(v => v.nombre === "salud") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}
                    </Stack>
                    <Stack direction="horizontal" gap={4} className="border border-dark p-2 mt-2">
                        <Form.Label>Certificado AFC:</Form.Label>
                        <Form.Control type="file" accept="image/*,.pdf,.doc,.docx" className="me-auto" onChange={(e) => handleArchivo(e.target.files[0], "afc")} />
                        <Button variant="outline-primary" disabled={isLoadingButton} onClick={!isLoadingButton ? subir_archivo : null}>{isLoadingButton ? "Subiendo ..." : "Subir"}</Button>
                        {documentos.find(v => v.nombre === "afc") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}
                    </Stack>
                    <Stack direction="horizontal" gap={4} className="border border-dark p-2 mt-2">
                        <Form.Label>Certificado de Estudios:</Form.Label>
                        <Form.Control type="file" accept="image/*,.pdf,.doc,.docx" className="me-auto" onChange={(e) => handleArchivo(e.target.files[0], "estudio")} />
                        <Button variant="outline-primary" disabled={isLoadingButton} onClick={!isLoadingButton ? subir_archivo : null}>{isLoadingButton ? "Subiendo ..." : "Subir"}</Button>
                        {documentos.find(v => v.nombre === "estudio") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}
                    </Stack>
                    {trabajador.trabajo_escondida && (
                        <div>
                            <Stack direction="horizontal" gap={4} className="border border-dark p-2 mt-2">
                                <Form.Label>Ultimo finiquito:</Form.Label>
                                <Form.Control type="file" accept="image/*,.pdf,.doc,.docx" className="me-auto" onChange={(e) => handleArchivo(e.target.files[0], "finiquito")} />
                                <Button variant="outline-primary" disabled={isLoadingButton} onClick={!isLoadingButton ? subir_archivo : null}>{isLoadingButton ? "Subiendo ..." : "Subir"}</Button>
                                {documentos.find(v => v.nombre === "finiquito") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}
                            </Stack>

                            <div className="ms-auto fw-bold" style={{ color: "red" }}>*Subir ultimo finiquito solo si trabajo antes en BHP (cualquiera de sus divisiones).</div>
                        </div>
                    )}
                    <Stack direction="horizontal" gap={4} className="border border-dark p-2 mt-2">
                        <Form.Label>CV Actualizado:</Form.Label>
                        <Form.Control type="file" accept="image/*,.pdf,.doc,.docx" className="me-auto" onChange={(e) => handleArchivo(e.target.files[0], "cv")} />
                        <Button variant="outline-primary" disabled={isLoadingButton} onClick={!isLoadingButton ? subir_archivo : null}>{isLoadingButton ? "Subiendo ..." : "Subir"}</Button>
                        {documentos.find(v => v.nombre === "cv") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}
                    </Stack>
                    <Stack direction="horizontal" gap={4} className="border border-dark p-2 mt-2">
                        <Form.Label>Foto tamaño carnet o selfie con fondo blanco:</Form.Label>
                        <Form.Control type="file" accept="image/*,.pdf,.doc,.docx" className="me-auto" onChange={(e) => handleArchivo(e.target.files[0], "foto")} />
                        <Button variant="outline-primary" disabled={isLoadingButton} onClick={!isLoadingButton ? subir_archivo : null}>{isLoadingButton ? "Subiendo ..." : "Subir"}</Button>
                        {documentos.find(v => v.nombre === "foto") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}
                    </Stack>
                    <Stack direction="horizontal" gap={4} className="border border-dark p-2 mt-2">
                        <Form.Label>Pase movilidad:</Form.Label>
                        <Form.Control type="file" accept="image/*,.pdf,.doc,.docx" className="me-auto" onChange={(e) => handleArchivo(e.target.files[0], "pase")} />
                        <Button variant="outline-primary" disabled={isLoadingButton} onClick={!isLoadingButton ? subir_archivo : null}>{isLoadingButton ? "Subiendo ..." : "Subir"}</Button>
                        {documentos.find(v => v.nombre === "pase") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}
                    </Stack>
                    <Stack direction="horizontal" gap={4} className="border border-dark p-2 mt-2">
                        <Form.Label>Licencia de Conducir:</Form.Label>
                        <Form.Control type="file" accept="image/*,.pdf,.doc,.docx" className="me-auto" onChange={(e) => handleArchivo(e.target.files[0], "licencia_conducir")} />
                        <Button variant="outline-primary" disabled={isLoadingButton} onClick={!isLoadingButton ? subir_archivo : null}>{isLoadingButton ? "Subiendo ..." : "Subir"}</Button>
                        {documentos.find(v => v.nombre === "licencia_conducir") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}
                    </Stack>
                    <Stack direction="horizontal" gap={4} className="border border-dark p-2 mt-2">
                        <Form.Label>Hoja de Vida Conductor:</Form.Label>
                        <Form.Control type="file" accept="image/*,.pdf,.doc,.docx" className="me-auto" onChange={(e) => handleArchivo(e.target.files[0], "hoja_vida_conductor")} />
                        <Button variant="outline-primary" disabled={isLoadingButton} onClick={!isLoadingButton ? subir_archivo : null}>{isLoadingButton ? "Subiendo ..." : "Subir"}</Button>
                        {documentos.find(v => v.nombre === "hoja_vida_conductor") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}
                    </Stack>
                    {/* {trabajador.conduccion && (
                        <div>
                            <Stack direction="horizontal" gap={4} className="border border-dark p-2 mt-2">
                                <Form.Label>Licencia de Conducir:</Form.Label>
                                <Form.Control type="file" accept="image/*,.pdf,.doc,.docx" className="me-auto" onChange={(e) => handleArchivo(e.target.files[0], "licencia_conducir")} />
                                <Button variant="outline-primary" disabled={isLoadingButton} onClick={!isLoadingButton ? subir_archivo : null}>{isLoadingButton ? "Subiendo ..." : "Subir"}</Button>
                                {documentos.find(v => v.nombre === "licencia_conducir") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}
                            </Stack>
                            <Stack direction="horizontal" gap={4} className="border border-dark p-2 mt-2">
                                <Form.Label>Hoja de Vida Conductor:</Form.Label>
                                <Form.Control type="file" accept="image/*,.pdf,.doc,.docx" className="me-auto" onChange={(e) => handleArchivo(e.target.files[0], "hoja_vida_conductor")} />
                                <Button variant="outline-primary" disabled={isLoadingButton} onClick={!isLoadingButton ? subir_archivo : null}>{isLoadingButton ? "Subiendo ..." : "Subir"}</Button>
                                {documentos.find(v => v.nombre === "hoja_vida_conductor") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}
                            </Stack>
                        </div>
                    )} */}
                    <Row>
                        <Col>
                            {ejecu ? (
                                <>
                                    <h3 className="text-center">Exclusivo Ejecutiva</h3>
                                    <Form.Group className="mb-3">
                                        {/* <Form.Label>Sueldo Base Mensual:</Form.Label>
                                        <Form.Control type="text" value={trabajador.sueldo_base_mensual} onChange={(e) => handleChangeTrabajador(e.target.value, "sueldo_base_mensual")} /> */}
                                        <TextField
                                            name='sueldo_base_mensual'
                                            label='Sueldo base Mensual'
                                            id='sueldo_base_mensual'
                                            fullWidth
                                            variant='outlined'
                                            onChange={formik.handleChange}
                                            value={formik.values.sueldo_base_mensual}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        {/* <Form.Label>Liquido Pactado:</Form.Label>
                                        <Form.Control type="text" value={trabajador.liquido_pactado} onChange={(e) => handleChangeTrabajador(e.target.value, "liquido_pactado")} /> */}
                                        <TextField
                                            name='liquido_pactado'
                                            label='Liquido Pactado'
                                            id='liquido_pactado'
                                            fullWidth
                                            variant='outlined'
                                            onChange={formik.handleChange}
                                            value={formik.values.liquido_pactado}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        {/* <Form.Label>Duración del Contrato:</Form.Label>
                                        <Form.Select aria-label="Default select example" value={trabajador ? trabajador.duracion_contrato : ""} onChange={(e) => handleChangeTrabajador(e.target.value, "duracion_contrato")}>
                                            <option value="">Seleccione Duración del Contrato...</option>
                                            <option value="P.FIJO">Plazo Fijo</option>
                                            <option value="INDEFINIDO">Indefinido</option>
                                        </Form.Select> */}
                                        <FormControl fullWidth>
                                            <InputLabel id='duracion_contrato'>Duración del Contrato</InputLabel>
                                            <Select
                                                labelId="duracion_contrato"
                                                id="duracion_contrato"
                                                label="Duración del Contrato"
                                                name="duracion_contrato"
                                                onChange={formik.handleChange}
                                                value={formik.values.duracion_contrato !== null ? formik.values.duracion_contrato : ""}
                                            >
                                                <MenuItem value="P.FIJO">P.FIJO</MenuItem>
                                                <MenuItem value="INDEFINIDO">INDEFINIDO</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        {/* <Form.Label>Jornada de Trabajo:</Form.Label>
                                        <Form.Select aria-label="Default select example" value={trabajador ? trabajador.jornada_trabajo : ""} onChange={(e) => handleChangeTrabajador(e.target.value, "jornada_trabajo")}>
                                            <option value="4x3">4x3</option>
                                            <option value="5x2">5x2</option>
                                            <option value="7x7">7x7</option>
                                            <option value="8x6">8x6</option>
                                            <option value="14x14">14x14</option>
                                            <option value="10x10">10x10</option>
                                        </Form.Select> */}
                                        <FormControl fullWidth>
                                            <InputLabel id='jornada_trabajo'>Jornada de Trabajo</InputLabel>
                                            <Select
                                                labelId="jornada_trabajo"
                                                id="jornada_trabajo"
                                                label="Jornada de Trabajo"
                                                name="jornada_trabajo"
                                                onChange={formik.handleChange}
                                                value={formik.values.jornada_trabajo !== null ? formik.values.jornada_trabajo : ""}
                                            >
                                                <MenuItem value="4x3">4x3</MenuItem>
                                                <MenuItem value="5x2">5x2</MenuItem>
                                                <MenuItem value="7x7">7x7</MenuItem>
                                                <MenuItem value="8x6">8x6</MenuItem>
                                                <MenuItem value="14x14">14x14</MenuItem>
                                                <MenuItem value="10x10">10x10</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Form.Group>
                                    <div className="mt-3"></div>
                                    {ejecu.perfiles.find(v => v.nombre === "Contratador") && (
                                        <div>
                                            <Stack direction="horizontal" gap={4} className="border border-dark p-2 mt-2">
                                                <Form.Label>Contrato:</Form.Label>
                                                <Form.Control type="file" accept="image/*,.pdf,.doc,.docx" className="me-auto" onChange={(e) => handleArchivo(e.target.files[0], "contrato")} />
                                                <Button variant="outline-primary" disabled={dateContrato != "" ? false : true} onClick={!isLoadingButton ? subir_archivo : null}>{isLoadingButton ? "Subiendo ..." : "Subir"}</Button>
                                                {documentos.find(v => v.nombre === "contrato") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}
                                            </Stack>
                                            <Form.Group className="mt-3">
                                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                                    <DesktopDatePicker
                                                        label="Fecha Inicio Estipulación de Contrato"
                                                        inputFormat="dd-MM-yyyy"
                                                        value={dateContrato}
                                                        onChange={(e) => cambiarFechaFinContrato(e)}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </LocalizationProvider>

                                            </Form.Group>
                                            {formik.values.duracion_contrato === "P.FIJO" ? (
                                                <Form.Group className="mt-3">
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                                        <DesktopDatePicker
                                                            label="Fecha Termno de Contrato"
                                                            inputFormat="dd-MM-yyyy"
                                                            value={dateFinContrato} //dateFinContrato
                                                            onChange={(e) => setDateFinContrato(e)}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </LocalizationProvider>
                                                </Form.Group>
                                            ) : ""}
                                        </div>
                                    )}
                                    {ejecu.perfiles.find(v => v.nombre === "Contratador" || v.nombre === "Acreditador") && (
                                        <div>
                                            <Stack direction="horizontal" gap={4} className="border border-dark p-2 mt-2">
                                                <Form.Label>Ficha Deloitte:</Form.Label>
                                                <Form.Control type="file" accept="image/*,.pdf,.doc,.docx" className="me-auto" onChange={(e) => handleArchivo(e.target.files[0], "ficha")} />
                                                <Button variant="outline-primary" disabled={isLoadingButton} onClick={!isLoadingButton ? subir_archivo : null}>{isLoadingButton ? "Subiendo ..." : "Subir"}</Button>
                                                {documentos.find(v => v.nombre === "ficha") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}
                                            </Stack>
                                            <Stack direction="horizontal" gap={4} className="border border-dark p-2 mt-2">
                                                <Form.Label>Transporte MEL:</Form.Label>
                                                <Form.Control type="file" accept="image/*,.pdf,.doc,.docx" className="me-auto" onChange={(e) => handleArchivo(e.target.files[0], "transporte_mel")} />
                                                <Button variant="outline-primary" disabled={isLoadingButton} onClick={!isLoadingButton ? subir_archivo : null}>{isLoadingButton ? "Subiendo ..." : "Subir"}</Button>
                                                {documentos.find(v => v.nombre === "transporte_mel") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}
                                            </Stack>
                                            {/* <Stack direction="horizontal" gap={4} className="border border-dark p-2 mt-2">
                                                <Form.Label>Extravio Credencial:</Form.Label>
                                                <Form.Control type="file" accept="image/*,.pdf,.doc,.docx" className="me-auto" onChange={(e) => handleArchivo(e.target.files[0], "extravio_credencial")} />
                                                <Button variant="outline-primary" disabled={isLoadingButton} onClick={!isLoadingButton ? subir_archivo : null}>{isLoadingButton ? "Subiendo ..." : "Subir"}</Button>
                                                {documentos.find(v => v.nombre === "extravio_credencial") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}
                                            </Stack> */}
                                            <Stack direction="horizontal" gap={4} className="border border-dark p-2 mt-2">
                                                <Form.Label>Credencial:</Form.Label>
                                                <Form.Control type="file" accept="image/*,.pdf,.doc,.docx" className="me-auto" onChange={(e) => handleArchivo(e.target.files[0], "credencial")} />
                                                <Button variant="outline-primary" disabled={isLoadingButton} onClick={!isLoadingButton ? subir_archivo : null}>{isLoadingButton ? "Subiendo ..." : "Subir"}</Button>
                                                {documentos.find(v => v.nombre === "credencial") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}
                                            </Stack>
                                            {/* <Stack direction="horizontal" gap={4} className="border border-dark p-2 mt-2">
                                                <Form.Label>Visto Bueno Carpeta:</Form.Label>
                                                <Form.Control type="file" accept="image/*,.pdf,.doc,.docx" className="me-auto" onChange={(e) => handleArchivo(e.target.files[0], "visto_bueno_carpeta")} />
                                                <Button variant="outline-primary" disabled={isLoadingButton} onClick={!isLoadingButton ? subir_archivo : null}>{isLoadingButton ? "Subiendo ..." : "Subir"}</Button>
                                                {documentos.find(v => v.nombre === "visto_bueno_carpeta") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}
                                            </Stack>
                                            <Stack direction="horizontal" gap={4} className="border border-dark p-2 mt-2">
                                                <Form.Label>Registro Primera Asistencia:</Form.Label>
                                                <Form.Control type="file" accept="image/*,.pdf,.doc,.docx" className="me-auto" onChange={(e) => handleArchivo(e.target.files[0], "primera_asistencia")} />
                                                <Button variant="outline-primary" disabled={isLoadingButton} onClick={!isLoadingButton ? subir_archivo : null}>{isLoadingButton ? "Subiendo ..." : "Subir"}</Button>
                                                {documentos.find(v => v.nombre === "primera_asistencia") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}
                                            </Stack> */}
                                        </div>
                                    )}
                                    <Form.Group className="mt-3 mb-3">
                                        {/* <Form.Label>Observación:</Form.Label>
                                        <Form.Control as="textarea" value={trabajador.observacion} onChange={(e) => handleChangeTrabajador(e.target.value, 'observacion')} /> */}
                                        <TextField
                                            name='observacion'
                                            label='Observación'
                                            id='observacion'
                                            fullWidth
                                            variant='outlined'
                                            rows={4}
                                            multiline
                                            onChange={formik.handleChange}
                                            value={formik.values.observacion}
                                        />
                                    </Form.Group>
                                </>
                            ) : ""}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col className="mt-3">
                    <Button
                        variant="primary"
                        disabled={isLoadingButton}
                        onClick={!isLoadingButton ? formik.handleSubmit : null} //actualizar_trabajador
                    >{isLoadingButton ? "Cargando ..." : "Actualizar Información"}</Button>
                    {/* {JSON.stringify(formik.errors)} */}
                    {/* {console.log(Object.values(formik.errors).length)} */}
                    {(!formik.isSubmitting) || (Object.values(formik.errors).length !== 0) ? (
                        <div className="alert alert-danger mt-3" role="alert">
                            {Object.values(formik.errors).map(msg => (
                                <>
                                    {msg}
                                    <br />
                                </>
                            ))}
                        </div>
                    ) : ""}

                </Col>
            </Row>
        </Container >
    )
};

export default FmTrabajador;