import http from "../http-common";

class WSService {

    enviarmje(data) {
        return http.post("/ws",data);
    }

    enviar_myapi(data) {
        return http.post("/ws/enviar", data);
    }

    evniar_primerto(data) {
        return http.post("/ws/enviar1", data);
    }

    enviar_propio(data) {
        return http.post("/sendmessage",data);
    }

    enviar_template(data) {
        return http.post("/ws/enviar_template", data);
    }

    enviar_get(data) {
        return http.get(`/ws/enviar_get/${data.numero}/${data.mensaje}`);
    }
}

export default new WSService();