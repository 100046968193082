import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Button, Container, Row, Col, Form, Modal, Toast, TabPane, Stack } from 'react-bootstrap';
import { ThreeDots } from 'react-loader-spinner';

/**Material UI */
import { DataGrid, esES } from '@mui/x-data-grid';

/** React Grid */
import Paper from '@mui/material/Paper';
import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';

/** Importamos servicios */
import dncService from "../services/dnc.service";
import ccService from "../services/cc.service";
import trabajadorService from "../services/trabajador.service";
import cursoService from "../services/curso.service";
import tokenService from "../services/token.service";
import { cardClasses } from "@mui/material";

const Estadistica = () => {
    const [clm, setClm] = useState([]);
    const [ddnc, setDdnc] = useState({});
    const [tdva, setTdva] = useState('');
    const [rw, setRw] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        unirRows();
        //rw.push(calculoDNC());//calculoDNC();
        //rw.push(calculo3D());
        columnas();
        console.log(tokenService.getTokenB());
    }, []);

    async function unirRows() {
        setLoading(true);
        let dnc = await calculoDNC();
        let tresd = await calculo3D();
        let vafaena = await calculo3DVA("En faena");
        let vaasignado = await calculo3DVA("Asignado");
        let vacarpeta = await calculo3DVA("En carpeta");
        let tresdProceso = await calculo3DProceso();
        let tresdSinIniciar = await calculo3DSinIniciar();
        let enproceso = await calculoEnProceso();
        const rowse = [...rw];
        rowse.push(dnc);
        rowse.push(enproceso);
        rowse.push(tresd);
        rowse.push(vafaena);
        rowse.push(vaasignado);
        rowse.push(vacarpeta);
        rowse.push(tresdProceso);
        rowse.push(tresdSinIniciar);
        setRw(rowse);
        setLoading(false);
    }

    const columns = [
        { name: 'id', title: "ID" },
        { name: 'nombre', title: "Estado del Proceso" },
        { name: 'unidad1', title: "TALLERES" },
        { name: 'unidad3', title: "TALLERES" },
        { name: 'unidad4', title: "TALLERES" },
        { name: 'unidad5', title: "TALLERES" },
        { name: 'unidad6', title: "TALLERES" },
        { name: 'unidad7', title: "TALLERES" },
        { name: 'unidad8', title: "TALLERES" },
        { name: 'unidad9', title: "TALLERES" },
        { name: 'unidad10', title: "TALLERES" }
    ];

    const rows = [
        { id: 1, nombre: "DNC", "unidad1": 0, "unidad3": 3 },
        { id: 2, nombre: "En Faena", "unidad2": 5, "unidad4": 10 },
    ];

    const rows2 = [
        ddnc
    ];

    async function columnas() {
        let ccs = await ccService.getAll();
        var arr_cc = ccs.data;
        var cc = {}
        var cc_arr_public = [];
        const seenKeys = new Set();
        /* for(var i=0;i<arr_cc.length;i++) {
            cc_arr_public.push({field: arr_cc[i].nombre, headerName: arr_cc[i].nombre});
        } */
        cc_arr_public.push({ field: 'id', headerName: "ID" });
        cc_arr_public.push({ field: 'nombre', headerName: "Estado del Proceso", width: 180 });
        /* for (const row of arr_cc) {
            if (seenKeys.has(row.nombre)) continue;
            cc_arr_public.push({field: row.nombre, headerName: row.nombre});
            seenKeys.add(row.name);
        } */
        arr_cc.map((c, index) => {
            var cc_index = clm.findIndex(v => v.nombre === c.nombre);
            clm.splice(cc_index, cc_index >= 0 ? 1 : 0);
            cc_arr_public.push({ field: c.nombre, headerName: c.nombre, key: c.id });
        });
        setClm(cc_arr_public);
    }

    async function calculoDNC() {
        let dncs = await dncService.getAll();
        let ccs = await ccService.getAll();
        var arr_dnc = dncs.data;
        var arr_cc = ccs.data;
        var contador_cargo = 0;
        var dnc = {};
        var rows = [...rw];

        for (var i = 0; i < arr_cc.length; i++) {
            for (var j = 0; j < arr_dnc.length; j++) {
                if (arr_cc[i].id.toString() === arr_dnc[j].unidad) {
                    if (arr_dnc[j].cantidad > 0) {
                        contador_cargo = contador_cargo + arr_dnc[j].cantidad;
                    }
                }
            }
            dnc["id"] = 1;
            dnc["nombre"] = "DNC";
            dnc[arr_cc[i].nombre] = contador_cargo;
            contador_cargo = 0;
        }
        return dnc;
    }

    async function calculo3D() {
        let ccs = await ccService.getAll();
        var arr_cc = ccs.data;
        let trabajadores = await trabajadorService.getAll();
        var arr_trabajadores = trabajadores.data;
        var contador_tresd = 0;
        var td = {};
        var rows = [...rw];

        for (var i = 0; i < arr_cc.length; i++) {
            for (var j = 0; j < arr_trabajadores.length; j++) {
                if (arr_cc[i].nombre === arr_trabajadores[j].centro_costo) {
                    var cursos = arr_trabajadores[j].cursos;
                    var tresd = cursos.find(v => v.nombre === "3D");
                    if (tresd) {
                        if (tresd.aprobado === "Verde" || tresd.aprobado === "Amarillo") {
                            contador_tresd += 1;
                        }
                    }
                }
            }
            td["id"] = 2;
            td["nombre"] = "3D Verde + Amarillo";
            td[arr_cc[i].nombre] = contador_tresd;
            contador_tresd = 0;
        }
        return td;
    }

    /** Calculamos los que esten en 3D verde amarillo y en faena, asignado, proceso, etc */
    async function calculo3DVA(estado) {
        let ccs = await ccService.getAll();
        var arr_cc = ccs.data;
        let trabajadores = await trabajadorService.getAll();
        var arr_trabajadores = trabajadores.data;
        var contador_tresd = 0;
        var td = {};
        var rows = [...rw];

        for (var i = 0; i < arr_cc.length; i++) {
            for (var j = 0; j < arr_trabajadores.length; j++) {
                if (arr_cc[i].nombre === arr_trabajadores[j].centro_costo) {
                    if (arr_trabajadores[j].estado === estado) {
                        var cursos = arr_trabajadores[j].cursos;
                        var tresd = cursos.find(v => v.nombre === "3D");
                        if (tresd) {
                            if (tresd.aprobado === "Verde" || tresd.aprobado === "Amarillo") {
                                contador_tresd += 1;
                            }
                        }
                    }
                }
            }
            td["id"] = "3dva" + estado;
            td["nombre"] = "3D VA " + estado;
            td[arr_cc[i].nombre] = contador_tresd;
            contador_tresd = 0;
        }
        return td;
    }

    /** Calulo 3D en proceso */
    async function calculo3DProceso() {
        let ccs = await ccService.getAll();
        var arr_cc = ccs.data;
        let trabajadores = await trabajadorService.getAll();
        var arr_trabajadores = trabajadores.data;
        var contador_tresd = 0;
        var td = {};
        var rows = [...rw];

        for (var i = 0; i < arr_cc.length; i++) {
            for (var j = 0; j < arr_trabajadores.length; j++) {
                if (arr_cc[i].nombre === arr_trabajadores[j].centro_costo) {
                    var cursos = arr_trabajadores[j].cursos;
                    var tresd = cursos.find(v => v.nombre === "3D");
                    if (tresd) {
                        if (tresd.aprobado === "En Proceso") {
                            contador_tresd += 1;
                        }
                    }
                }
            }
            td["id"] = 4;
            td["nombre"] = "3D Proceso";
            td[arr_cc[i].nombre] = contador_tresd;
            contador_tresd = 0;
        }
        return td;
    }

    /** Calulo 3D Sin Iniciar - Por agendar */
    async function calculo3DSinIniciar() {
        let ccs = await ccService.getAll();
        var arr_cc = ccs.data;
        let trabajadores = await trabajadorService.getAll();
        var arr_trabajadores = trabajadores.data;
        var contador_tresd = 0;
        var td = {};
        var rows = [...rw];

        for (var i = 0; i < arr_cc.length; i++) {
            for (var j = 0; j < arr_trabajadores.length; j++) {
                if (arr_cc[i].nombre === arr_trabajadores[j].centro_costo) {
                    var cursos = arr_trabajadores[j].cursos;
                    var tresd = cursos.find(v => v.nombre === "3D");
                    if (tresd) {
                        if (tresd.aprobado === "Por agendar") {
                            contador_tresd += 1;
                        }
                    }
                }
            }
            td["id"] = 5;
            td["nombre"] = "3D Sin Iniciar";
            td[arr_cc[i].nombre] = contador_tresd;
            contador_tresd = 0;
        }
        return td;
    }

    /** Calula los estados en Proceso */
    async function calculoEnProceso() {
        let ccs = await ccService.getAll();
        var arr_cc = ccs.data;
        let trabajadores = await trabajadorService.getAll();
        var arr_trabajadores = trabajadores.data;
        var contador_tresd = 0;
        var td = {};
        var rows = [...rw];

        for (var i = 0; i < arr_cc.length; i++) {
            for (var j = 0; j < arr_trabajadores.length; j++) {
                if (arr_cc[i].nombre === arr_trabajadores[j].centro_costo) {
                    if (arr_trabajadores[j].estado === "En Proceso") {
                        contador_tresd += 1;
                    }
                }
            }
            td["id"] = 6;
            td["nombre"] = "En Proceso";
            td[arr_cc[i].nombre] = contador_tresd;
            contador_tresd = 0;
        }
        return td;
    }

    return (
        <Container>
            {loading ? (
                <div className="spinner">
                    <ThreeDots color="#241654" height="100" width="100" ariaLabel="Loading..." />
                </div>
            ) : (
                <Row>
                    <div style={{ height: 600 }}>
                        <DataGrid
                            columnVisibilityModel={{
                                // Hide columns status and traderName, the other columns will remain visible
                                id: false,
                            }}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            rows={rw}
                            columns={clm}
                        />
                    </div>
                </Row>
            )}
        </Container>
    )
}

export default Estadistica;