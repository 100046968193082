import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Button, Container, Row, Col, Form, Modal, Table, Toast, TabPane, Stack } from 'react-bootstrap';
import { ThreeDots } from 'react-loader-spinner';
import { toast } from "react-toastify";

/**Material UI */
import { DataGrid, esES } from '@mui/x-data-grid';
/* import {
    randomCreatedDate,
    randomTraderName,
    randomUpdatedDate,
} from '@mui/x-data-grid-generator'; */

/** Importe de servicios */
import ccService from "../../services/cc.service";
import cargoService from "../../services/cargo.service";
import dncService from "../../services/dnc.service";

const DNC = () => {
    const [ccs, setCcs] = useState([]);
    const [cargos, setCargos] = useState([]);
    const [rwCargos, setRwCargos] = useState([]);
    const [unidad, setUnidad] = useState('');
    const [rwDnc, setRwDnc] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        retreiveCC();
        //retreiveCargos();
        rows();
        cargarDNC();
    }, []);

    const columns = [{
        field: "id", headerName: "ID"
    }, {
        field: "nombre", headerName: "Cargo", width: 360
    }, {
        field: "cantidad", headerName: "Cantidad", width: 180, editable: true
    }];

    const columnsDNC = [{
        field: "id", headerName: "ID"
    }, {
        field: "unidad", headerName: "Unidad", width: 250
    }, {
        field: "cargo", headerName: "Cargo", width: 250
    }, {
        field: "cantidad", headerName: "Cantidad"
    }];

    function retreiveCC() {
        ccService.getAll()
            .then(resp => {
                setCcs(resp.data);
            })
            .catch(err => {
                console.error(err);
            });
    }

    function retreiveCargos() {
        cargoService.getAll()
            .then(resp => {
                setCargos(resp.data);
            })
            .catch(err => {
                console.error(err);
            });
    }

    async function rows() {
        let carg = await cargoService.getAll();
        if (carg) {
            var arr = [];
            carg.data.map(cr => {
                arr.push({ id: cr.id, nombre: cr.nombre, cantidad: 0 });
            });
            setRwCargos(arr);
        }

    }

    function ingresarRow(newRow) {
        Object.freeze(rwCargos);
        const arrCopy = [...rwCargos]; // 👈️ create copy
        const index = rwCargos.findIndex(v => v.id === newRow.id);
        arrCopy[index] = newRow;
        console.log(arrCopy); // 👉️ ['z', 'b', 'c']
        setRwCargos(arrCopy);
    }

    const proccessRowUpdate = async (newRow) => {
        console.log(newRow);
        ingresarRow(newRow);
        return newRow;
    }

    const processError = (error) => {
        if (error) {
            console.error(error);
        } else {
            return;
        }
    }

    const ingresarDNC = async () => {
        if (unidad === "") {
            alert("Debe ingresar la Unidad de Negocio");
            return;
        }
        //let dnc_bd = await dncService.getAll();
        //var array_dnc = dnc_bd.data;
        //console.log("ARRAY", array_dnc);
        rwCargos.map(r => {
            //Creamos
            if (r.cantidad > 0 || r.cantidad < 0) {
                dncService.create({ unidad: unidad, cargo: r.nombre, cantidad: r.cantidad })
                    .then(resp => {
                        console.log(resp);
                        toast.info("DNC´S Ingresadas exitosamente", {
                            position: "top-center",
                            autoClose: 1000
                        });
                        cargarDNC();
                    })
                    .catch(err => {
                        console.error(err);
                        toast.error("Ocurrio un error al ingresar la DNC");
                    });
            }
            /* if (array_dnc) {
                var dnc = array_dnc.find(v => v.unidad === unidad && v.cargo === r.nombre);
                if (dnc) {
                    console.log("DNC", dnc);
                    //Si existe, actualizamos
                    dncService.updateDNC(dnc.id, { cantidad: r.cantidad })
                        .then(resp => {
                            console.log(resp);
                        })
                        .catch(err => {
                            console.error(err);
                        })
                } else {
                    //Creamos
                    dncService.create({ unidad: unidad, cargo: r.nombre, cantidad: r.cantidad })
                        .then(resp => {
                            console.log(resp);
                        })
                        .catch(err => {
                            console.error(err);
                        })
                }
            } */
        });
    }

    async function cargarDNC() {
        setLoading(true);
        var suma_cantidad = 0;
        let dnc = await dncService.getAll();
        if (dnc.data) {
            var arr_dncs = [];
            var dncs_arr = dnc.data;
            for (var i = 0; i < dncs_arr.length; i++) {
                let cc = await ccService.getbyID(dncs_arr[i].unidad);
                if (dncs_arr[i].cantidad > 0 || dncs_arr[i].cantidad < 0) {
                    var r = arr_dncs.findIndex(v => v.unidad === cc.data.nombre && v.cargo === dncs_arr[i].cargo);
                    if (r >= 0) {
                        suma_cantidad = arr_dncs[r].cantidad + dncs_arr[i].cantidad;
                        arr_dncs.splice(r, 1);
                        arr_dncs.push({ id: dncs_arr[i].id, unidad: cc.data.nombre, cargo: dncs_arr[i].cargo, cantidad: suma_cantidad });
                    } else {
                        arr_dncs.push({ id: dncs_arr[i].id, unidad: cc.data.nombre, cargo: dncs_arr[i].cargo, cantidad: dncs_arr[i].cantidad });
                    }
                }
            }
            setRwDnc(arr_dncs.sort(compare));
        }
        setLoading(false);
    }

    function compare(a, b) {
        if (a.unidad < b.unidad) {
            return -1;
        }
        if (a.unidad > b.unidad) {
            return 1;
        }
        return 0;
    }

    return (
        <Container>
            <Row>
                {loading ? (
                    <div className="spinner">
                        <ThreeDots color="#241654" height="100" width="100" ariaLabel="Loading..." />
                    </div>
                ) : (
                    <div style={{ height: 350, width: '100%' }}>
                        <DataGrid
                            rows={rwDnc}
                            columns={columnsDNC}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </div>
                )}
            </Row>
            <Row>
                <Col sm={4}>
                    <Form.Group>
                        <Form.Select onChange={(e) => setUnidad(e.target.value)}>
                            <option value="">Seleccione Unidad de Negocio</option>
                            {ccs.map(cr => {
                                return (
                                    <option value={cr.id}>{cr.nombre}</option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>
                    <div className="mt-3"></div>
                    <Button variant="primary" onClick={ingresarDNC}>Ingresar</Button>
                </Col>
                <Col>
                    <div style={{ height: 600, width: '100%' }}>
                        <DataGrid
                            rows={rwCargos}
                            columns={columns}
                            experimentalFeatures={{ newEditingApi: true }}
                            processRowUpdate={proccessRowUpdate}
                            onProcessRowUpdateError={processError}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default DNC;