import http from "../http-common";

class RegistroService {

    create(data) {
        return http.post("/registro",data);
    }

    getAll() {
        return http.get("/registro");
    }

    updateRegistro(id, data) {
        return http.put(`/registro/${id}`,data);
    }

    eliminarRegistro(id) {
        return http.delete(`/registro/${id}`);
    }
}

export default new RegistroService();