import http from "../http-common";

class DNCService {

    create(data) {
        return http.post("/dnc",data);
    }

    getAll() {
        return http.get("/dnc");
    }

    getbyID(id) {
        return http.get(`/dnc/${id}`);
    }

    updateDNC(id, data) {
        return http.put(`/dnc/${id}`,data);
    }

    eliminarDNC(id) {
        return http.delete(`/dnc/${id}`);
    }
}

export default new DNCService();