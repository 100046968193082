import http from "../http-common";

class DocumentoService {

    crear(data) {
        return http.post("/documento", data);
    }

    update(id, data) {
        return http.put(`/documento/${id}`,data);
    }
}

export default new DocumentoService();