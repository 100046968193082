import http from "../http-common";
import { trackPromise } from 'react-promise-tracker';

class TrabajadorService {

    create(data) {
        return http.post("/trabajador",data);
    }

    buscarPorRut(rut) {
        return http.get(`/trabajador/${rut}`);
    }

    updateTrabajador(id, data) {
        return http.put(`/trabajador/${id}`, data);
    }
    
    getAll() {
        return trackPromise(
            http.get("/trabajador"),"tgetall"
        );
        
    }

    getByID(id) {
        return http.get(`/trabajador/id/${id}`);
    }

    getByUser(id_usuario) {
        return trackPromise(
            http.get(`/trabajador/user/${id_usuario}`),"tgetuser"
        );
    }

    getByEstado(estado) {
        return http.get(`/trabajador/estado/${estado}`);
    }

    async getAll2() {
        const {data} = await http.get("/trabajador");
        console.log("Data", data);
        return data;
    }

    getAcreditador() {
        return http.get('/trabajador/acreditador/all');
    }

    getTodos() {
        return http.get("/trabajador/todos/todos");
    }
}

export default new TrabajadorService();