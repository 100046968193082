import http from "../http-common";

class MaestroExamen {

    create(data) {
        return http.post("/maestroexamen",data);
    }

    getAll() {
        return http.get("/maestroexamen");
    }

    updateExamenMaestro(id, data) {
        return http.put(`/maestroexamen/${id}`,data);
    }

    eliminarExamenMaestro(id) {
        return http.delete(`/maestroexamen/${id}`);
    }
}

export default new MaestroExamen();