import es from 'date-fns/locale/es';
import { useConfirm } from "material-ui-confirm";
import moment from 'moment';
import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoadingOverlay from 'react-loading-overlay';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PacmanLoader from 'react-spinners/PacmanLoader';
import { toast } from 'react-toastify';
import { format, validate } from 'rut.js';
import Emoji from "./Emoji";

//Material UI Design
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import esLocale from 'date-fns/locale/es';

import bitacoraService from "../services/bitacora.service";
import cargoService from "../services/cargo.service";
import ccService from "../services/cc.service";
import cursoService from "../services/curso.service";
import cursoespecialService from "../services/cursoespecial.service";
import emailService from "../services/email.service";
import examenService from "../services/examen.service";
import registroService from "../services/registro.service";
import sucursalService from "../services/sucursal.service";
import trabajadorService from "../services/trabajador.service";
import usuarioService from "../services/usuario.service";
import wsService from "../services/ws.service";
import { useAuth } from './AuthProvider';
import NavBarComponent from './navbar.component';

/** MUI MATERIAL */
import {
    ListItemIcon,
    MenuItem
} from '@mui/material';

/** MATERIAL REACT TABLE */
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';

/** MUI ICONOS */
/** MUI ICONOS */
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

registerLocale('es', es);

function ModalAviso({ show, cerrar }) {
    return (
        <Modal show={show}>
            <Modal.Header closeButton>Aviso!</Modal.Header>
            <Modal.Body>
                <p>Este trabajador se encuentra bloqueado.</p>
                <p>Si requiere desbloquear, por favor comuniquese con su jefatura de RRHH</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={cerrar}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    )
}

function ModalCursosEspeciales({ show, onHide, cursos, fechaCurso, onChangeFechaCurso, addEx, curso, ciudades, onBlLab, errors, onBlCiu, agregarCurso, trabajador }) {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Ingreso Curso Especial</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>Curso:</Form.Label>
                    <Form.Select onChange={e => addEx(e.target.value, "nombre")}>
                        <option value="">Seleccione Curso:</option>
                        {cursos.map((c, i) => {
                            return (
                                <option key={i} value={c.nombre}>{c.nombre}</option>
                            )
                        })}
                    </Form.Select>
                    {errors.nombre && (
                        <p className="text-warning">{errors.nombre}</p>
                    )}
                </Form.Group>
                {/* <Form.Group>
                    <Form.Label>Estado</Form.Label>
                    <Form.Select value={curso.aprobado} onChange={e => addEx(e.target.value, "aprobado")}>
                        <option>Seleccione estado ...</option>
                        <option value="Por agendar">Por agendar</option>
                    </Form.Select>
                </Form.Group> */}
                <div className="mt-2"></div>
                <Button variant="primary" onClick={agregarCurso}>Agregar</Button>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onHide}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    )
}

function AddExmaenModal({ show, onHide, addEx, registrarExamen, id, date, setDate, examen, editEx, errors, checkEx, onBlLab, ciudades, onBlCiu, onChangeDate }) {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Ingreso de Examen</Modal.Title>
            </Modal.Header>
            {id.id === undefined ?
                <Modal.Body>
                    <p>Por favor Ingrese Trabajador Primero.</p>
                </Modal.Body> :
                <Modal.Body>
                    {examen.nombre != "" ? (
                        <Form.Group className="mb-3">
                            <Form.Label>Nombre:</Form.Label>
                            <p>{examen.nombre}</p>
                        </Form.Group>
                    ) : (
                        <Form.Group className="mb-3">
                            <Form.Label>Nombre:</Form.Label>
                            <Form.Check type="checkbox" value="ALTURA GEOGRÁFICA" label="ALTURA GEOGRÁFICA" onChange={(e) => checkEx(e)} />
                            <Form.Check type="checkbox" value="ALTURA FÍSICA" label="ALTURA FÍSICA" onChange={(e) => checkEx(e)} />
                            <Form.Check type="checkbox" value="ALCOHOL Y DROGAS" label="ALCOHOL Y DROGAS" onChange={(e) => checkEx(e)} />
                            <Form.Check type="checkbox" value="EXÁMEN PREOCUPACIONAL" label="EXÁMEN PREOCUPACIONAL" onChange={(e) => checkEx(e)} />
                            <Form.Check type="checkbox" value="CERO DAÑO" label="CERO DAÑO" onChange={(e) => checkEx(e)} />
                            <Form.Check type="checkbox" value="VIII PAR" label="VIII PAR" onChange={(e) => checkEx(e)} />
                            <Form.Check type="checkbox" value="MANIPULACIÓN DE ALIMENTOS" label="MANIPULACIÓN DE ALIMENTOS" onChange={(e) => checkEx(e)} />
                            <Form.Check type="checkbox" value="INDUCCION HOMBRE NUEVO" label="INDUCCION HOMBRE NUEVO" onChange={(e) => checkEx(e)} />
                            <Form.Check type="checkbox" value="ESPACIOS CONFINADOS" label="ESPACIOS CONFINADOS" onChange={(e) => checkEx(e)} />
                            {/* {id.tresd && (<Form.Check type="checkbox" value="3D" label="3D" onChange={(e) => checkEx(e)} />)} */}
                            {errors.nombre && (
                                <p className="text-warning">{errors.listacheck}</p>
                            )}
                        </Form.Group>
                    )}
                    {/* <Form.Group className="mb-3">
                        <Form.Label>Nombre:</Form.Label>
                        <Form.Control type="text" value={examen.nombre} onChange={e => addEx(e.target.value, "nombre")} />
                    </Form.Group> */}
                    <Form.Group className="mb-3">
                        <Form.Label>Hora:</Form.Label>
                        <Form.Control type="text" value={examen.hora} placeholder="HH:MM" onChange={e => addEx(e.target.value, "hora")} />
                        {errors.hora && (
                            <p className="text-warning">{errors.hora}</p>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        {/* <Form.Label>Fecha:</Form.Label> */}
                        {/* <Form.Control type="text" placeholder="DD-MM-AAAA" onChange={e => addEx(e.target.value, "fecha")} onChange={(d) => setDate(d)} /> */}
                        {/* <DatePicker className="form-control" selected={examen.fecha !== "" || examen.fecha !== null ? Date.parse(examen.fecha) : date} onChange={(d) => setDate(d)} locale="es" dateFormat="dd-MM-yyyy" /> */}
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                            <DesktopDatePicker
                                label="Fecha"
                                inputFormat="dd/MM/yyyy"
                                value={examen.fecha}
                                onChange={(e) => onChangeDate(e, "fecha")}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Laboratorio/Institución:</Form.Label>
                        <Form.Select value={examen.laboratorio} onChange={e => addEx(e.target.value, "laboratorio")} onBlur={(e) => onBlLab(e)}>
                            <option value="">Seleccione una sucursal ...</option>
                            <option value="LABORATORIO MEDINORT">LABORATORIO MEDINORT</option>
                            <option value="LABORATORIO CMT">LABORATORIO CMT</option>
                            <option value="LABORATORIO CEM">LABORATORIO CEM</option>
                            <option value="CEIM">CEIM</option>
                            <option value="MUTUAL">MUTUAL</option>
                            <option value="PREVENIR SALUD">PREVENIR SALUD</option>
                            <option value="EXPRESSA MÉDICA">EXPRESSA MÉDICA</option>
                            <option value="ACHS">ACHS</option>
                        </Form.Select>
                        {/* <Form.Control type="text" value={examen.laboratorio} placeholder="Ingresar Laboratorio" onChange={e => addEx(e.target.value, "laboratorio")} /> */}
                        {errors.laboratorio && (
                            <p className="text-warning">{errors.laboratorio}</p>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Ciudad:</Form.Label>
                        <Form.Select value={examen.ciudad} onChange={e => addEx(e.target.value, "ciudad")} onBlur={(e) => onBlCiu(e.target.value)}>
                            <option value="">Seleccione una ciudad ...</option>
                            {ciudades != null ? ciudades.map((c, i) => {
                                return (
                                    <option key={i} value={c.ciudad}>{c.ciudad}</option>
                                )
                            }) : ""}
                        </Form.Select>
                        {/* <Form.Control type="text" value={examen.ciudad} placeholder="Ingresar Ciudad" onChange={e => addEx(e.target.value, "ciudad")} /> */}
                        {errors.ciudad && (
                            <p className="text-warning">{errors.ciudad}</p>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Dirección:</Form.Label>
                        <Form.Control type="text" value={examen.direccion} placeholder="Ingresar Dirección" onChange={e => addEx(e.target.value, "direccion")} disabled />
                        {errors.direccion && (
                            <p className="text-warning">{errors.direccion}</p>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Codigo de reserva:</Form.Label>
                        <Form.Control type="text" value={examen.reserva} placeholder="Ingresar codigo de reserva" onChange={e => addEx(e.target.value, "reserva")} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Estado</Form.Label>
                        <Form.Select value={examen.aprobado} onChange={e => addEx(e.target.value, "aprobado")}>
                            <option>Seleccione estado ...</option>
                            <option value="Agendado">Agendado</option>
                            <option value="Por agendar">Por agendar</option>
                            <option value="Apto">Apto</option>
                            <option value="No Apto">No Apto</option>
                            <option value="Abandona Proceso">Abandona Proceso</option>
                            <option value="Derivado Médico">Derivado Médico</option>
                            <option value="Reagendado">Reagendado</option>
                            <option value="Desvincular">Desvincular</option>
                        </Form.Select>
                    </Form.Group>
                    <div className="mt-2"></div>
                    {examen.id === undefined ?
                        <Button variant="primary" onClick={registrarExamen}>Agregar</Button>
                        :
                        <Button variant="secondary" onClick={() => editEx(examen.id)}>Actualizar</Button>}
                </Modal.Body>}
            <Modal.Footer>
                <Button onClick={onHide}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    )
};

const initialExamen = {
    nombre: "",
    fecha: "",
    hora: "",
    reserva: "",
    aprobado: "",
    ciudad: "",
    direccion: "",
    laboratorio: "",
    trabajadorId: ""
}

const initialCurso = {
    nombre: "",
    fecha: "",
    hora: "",
    reserva: "",
    aprobado: "Por Agendar",
    ciudad: "",
    direccion: "",
    laboratorio: "",
    trabajadorId: ""
}

const initialTrabajador = {
    nombre: "",
    cargo: "",
    rut: "",
    centro_costo: "",
    fono: "",
    correo: "",
    ejecutiva: "",
    numero_ejecutiva: "",
    usuarioId: "",
    estado: "",
    tresd: false,
    respuestaws: "",
    ap_paterno: "",
    ap_materno: "",
    conduccion: false,
    trabajo_escondida: false
}

const FmIngresoTrabajadro = () => {
    const [modalShow, setModalShow] = useState(false);
    const [examenes, setExamenes] = useState([]);
    const [trabajador, setTrabajador] = useState(initialTrabajador);
    const [examen, setExamen] = useState(initialExamen);
    const [starDate, setStarDate] = useState(new Date());
    const [formerrors, setFormErrors] = useState({});
    const [formerrorsEx, setFormErrorsEx] = useState({});
    const [loading, setLoading] = useState(false);
    const [ssid, setSsid] = useState('');
    const [usuario, setUsuario] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [examenCheck, setExamenCheck] = useState([]);
    //const { getSession, logout } = useContext(AccountContext);
    const [ciudades, setCiudades] = useState([]);
    const { rut_trabajador } = useParams();
    const [cargos, setCargos] = useState([]);
    const [modalAviso, setModalAviso] = useState(false);
    const [showMCurso, setShowMCurso] = useState(false);
    const [cursos, setCursos] = useState([]);
    const [fechaCurso, setFechaCurso] = useState(new Date());
    const [curso, setCurso] = useState(initialCurso);
    const [formErrosCurso, setFormErrorsCurso] = useState({});
    const [cursosTrabajadores, setCursosTrabajadores] = useState([]);
    const [ccs, setCcs] = useState([]);
    LoadingOverlay.propTypes = undefined;
    const auth = useAuth();
    const confirm = useConfirm();

    useEffect(() => {
        /* getSession().then((session) => {
            const ssids = session.idToken.payload.sub;
            setSsid(ssids);
            retreiveUsuario(ssids);
            retreiveCargos();
            if (rut_trabajador != undefined) {
                trabajador.rut = rut_trabajador;
                onBlurTrabajador(rut_trabajador);
            }
            retreiveCursos();
            retreiveCC();

        })
            .catch(err => {
                console.error(err);
                navigate("/login");
            }); */
        //retreiveUsuario(ssids);
        setUsuario(auth.user);
        setTrabajador({ ...trabajador, ['ejecutiva']: auth.user.nombre, ['numero_ejecutiva']: auth.user.numero });
        retreiveCargos();
        if (rut_trabajador != undefined) {
            trabajador.rut = rut_trabajador;
            onBlurTrabajador(rut_trabajador);
        }
        retreiveCursos();
        retreiveCC();
    }, []);

    const onChangeDate = (newvalue) => {
        setStarDate(newvalue);
        //setExamen({...examen, })
    }

    function retreiveCC() {
        ccService.getAll()
            .then((resp) => {
                setCcs(resp.data);
            })
            .catch(err => {
                console.error(err);
            });
    }

    function retreiveCursos() {
        cursoespecialService.getAll()
            .then((res, index) => {
                setCursos(res.data);
            })
            .catch(err => {
                console.error(err);
            });
    }

    const retreiveCargos = () => {
        cargoService.getAll()
            .then((cargo) => {
                setCargos(cargo.data);
            })
            .catch(err => {
                console.error(err);
            });
    }

    const onBlurLaboratorio = (e) => {
        const nombre = e.target.value;
        var arrayc = [];
        sucursalService.getByNombre(nombre)
            .then(data => {
                var sucursales = data.data;
                for (var i = 0; i < sucursales.length; i++) {
                    arrayc.push(data.data[i]);
                }
                setCiudades(arrayc);
            })
            .catch(err => {
                console.error(err);
            });
    }

    const onBlurCiudad = (c) => {
        var city = ciudades.find(v => v.ciudad === c);
        if (city) {
            setExamen({ ...examen, ['direccion']: city.direccion });
            setCurso({ ...curso, ['direccion']: city.direccion });
        }
    }

    function retreiveUsuario(ssid) {
        usuarioService.getBySSID(ssid)
            .then(user => {
                var u = user.data[0];
                setTrabajador({ ...trabajador, ['ejecutiva']: u.nombre, ['numero_ejecutiva']: u.numero });
                setUsuario(u);
                //setTrabajador({...trabajador, ['numero_ejecutiva']: u.numero});
            })
            .catch(err => {
                console.error(err);
            });
    }

    const validarFormExamen = () => {
        let exerrors = {};

        /* if (!examen.nombre) {
            exerrors.nombre = "El Examen es requerido";
        } */

        if (!examen.hora) {
            exerrors.hora = "La hora es requerida";
        }

        if (examen.ciudad === "") {
            exerrors.ciudad = "La ciudad es requerida";
        }
        if (!examen.direccion) {
            exerrors.direccion = "La dirección es requerida";
        }
        if (examen.laboratorio === "") {
            exerrors.laboratorio = "El Laboratorio o Recinto es requerido";
        }

        if (examenCheck.length === 0) {
            exerrors.listacheck = "Debe seleccionar un exámen";
        }

        setFormErrorsEx(exerrors);
        if (Object.keys(exerrors).length === 0) {
            return true;
        } else {
            return false;
        }
    }

    const validarFormCurso = () => {
        let exerrors = {};

        /* if (!examen.nombre) {
            exerrors.nombre = "El Examen es requerido";
        } */

        if (!curso.hora) {
            exerrors.hora = "La hora es requerida";
        }

        if (curso.ciudad === "") {
            exerrors.ciudad = "La ciudad es requerida";
        }
        if (!curso.direccion) {
            exerrors.direccion = "La dirección es requerida";
        }
        if (curso.laboratorio === "") {
            exerrors.laboratorio = "El Laboratorio o Recinto es requerido";
        }

        if (curso.nombre === "") {
            exerrors.nombre = "Debe seleccionar un curso";
        }

        setFormErrorsCurso(exerrors);
        if (Object.keys(exerrors).length === 0) {
            return true;
        } else {
            return false;
        }
    }

    const validarForm = () => {
        let errors = {};
        //name field
        if (!trabajador.nombre) {
            errors.nombre = "El Nombre es Requerido";
        }
        //email field
        if (!trabajador.correo) {
            errors.correo = "El correo es requerido";
        } else if (!/\S+@\S+\.\S+/.test(trabajador.correo)) {
            errors.correo = "El Correo es invalido";
        }

        //Celular
        if (!trabajador.fono) {
            errors.fono = "El Celular es Requerido";
        } else if (!/^([+]56)?(\s?)(0?9)(\s?)[98765432]\d{7}$/.test(trabajador.fono)) {
            errors.fono = "El Numero es invalido";
        }

        //Ejecutiva
        if (!trabajador.ejecutiva) {
            errors.ejecutiva = "El nombre de la ejecutiva es obligatorio"
        }

        //Numero de ejecutiva
        if (!trabajador.numero_ejecutiva) {
            errors.numero_ejecutiva = "El número de la ejecutiva es obligatorio"
        }

        //Apellido Paterno
        if (!trabajador.ap_paterno) {
            errors.ap_paterno = "El Apellido Paterno es obligatorio"
        }

        if (!trabajador.centro_costo) {
            errors.centro_costo = "El centro de costo es obligatorio";
        }

        if (!trabajador.cargo) {
            errors.cargo = "El cargo es obligatorio";
        }

        setFormErrors(errors);
        if (Object.keys(errors).length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const add_examen = async () => {
        if (!validarFormExamen(examen)) {
            return;
        }
        /* if (examen.nombre === "") {
            toast.error("Debe seleccionar un Examen", {
                position: "top-center",
                autoClose: 3000
            });
            return;
        } */
        setLoading(true);
        for (var i = 0; i < examenCheck.length; i++) {
            examen.trabajadorId = trabajador.id;
            //examen.fecha = starDate;
            examen.nombre = examenCheck[i];
            let ex = await examenService.create(examen);
            examenes.push(ex.data);
            setExamenes(examenes);
        }
        /* examen.trabajadorId = trabajador.id;
        examen.fecha = starDate;
        //examenService.create(examen).then(e => { console.log(e); setExamen(e.data); }).catch(err => console.error(err));
        let ex = await examenService.create(examen);
        examenes.push(ex.data);
        setExamenes(examenes);
        console.log('Examenes', examenes); */
        setExamenCheck([]);
        setModalShow(false);
        setLoading(false);
    }

    const handleCheckExamen = (e) => {
        const valor = e.target.value;
        if (e.target.checked) {
            const indx = examenCheck.findIndex(v => v.id === valor);
            examenCheck.splice(indx, indx >= 0 ? 1 : 0);
            examenCheck.push(valor);
            setExamenCheck(examenCheck);
        }
        if (!e.target.checked) {
            const index = examenCheck.findIndex(v => v === valor);
            examenCheck.splice(index, 1);
            setExamenCheck(examenCheck);
        }
    }

    const handleChangeExamen = (valor, nombre) => {
        console.log("examen", valor);
        setExamen({ ...examen, [nombre]: valor });
    }

    const handleChangeCurso = (valor, nombre) => {
        setCurso({ ...curso, [nombre]: valor });
    }

    async function cambioEstado(estado, id_trabajador) {
        let t = await trabajadorService.getByID(id_trabajador);
        var exs = t.data.examenes;
        var con = 0;
        for (var i = 0; i < exs.length; i++) {
            if (exs[i].nombre === "ALTURA GEOGRÁFICA" & (exs[i].aprobado === "Apto" || exs[i].aprobado === "NA")) {
                con++;
            }
            if (exs[i].nombre === "ALTURA FÍSICA" & (exs[i].aprobado === "Apto" || exs[i].aprobado === "NA")) {
                con++;
            }
            if (exs[i].nombre === "ALCOHOL Y DROGAS" & exs[i].aprobado === "Apto") {
                con++;
            }
            if (exs[i].nombre === "EXÁMEN PREOCUPACIONAL" & (exs[i].aprobado === "Apto" || exs[i].aprobado === "NA")) {
                con++;
            }
        }
        if (con === 4) {
            //Actualizamos estado de trabajador.
            setTrabajador({ ...trabajador, ['estado']: estado });
            /* trabajadorService.updateTrabajador(id_trabajador, { estado: estado })
                    .then(tr => {
                        toast.info("Trabajador paso a estado: "+estado, {
                            position: "top-center",
                            autoClose: 5000
                        });
                    })
                    .catch(err => {
                        console.error(err);
                    }); */
        }
    }

    function updateEstadoTrabajador(id_trabajador, nombre_estado) {
        console.log("UP IDT", id_trabajador);
        trabajadorService.updateTrabajador(id_trabajador, { estado: nombre_estado })
            .then(tr => {
                toast.info("Trabajador paso a estado: "+nombre_estado, {
                    position: "top-center",
                    autoClose: 5000
                });
                /* registroEstado(nombre_estado, new Date(), trabajador.id, usuario.id);
                setLoadingPerfil(false);
                actualizaSegunPerfil(perfil); */
            })
            .catch(err => {
                console.error(err);
            });
    }

    async function cambiarEstado(perfil) {
        setLoading(true);
        let t = await trabajadorService.getByID(trabajador.id);
        console.log("TR", t.data);
        var exs = t.data.examenes;
        var docs = t.data.documentos;
        var con_examen = exs.findIndex(v => v.nombre === "EXÁMEN PREOCUPACIONAL" & (v.aprobado === "Apto" || v.aprobado === "NA"));
        var con_docs = exs.findIndex(v=> (v.nombre === "CERO DAÑO" & v.aprobado === "Apto") || (v.nombre === "INDUCCION HOMBRE NUEVO" & v.aprobado === "Apto"));
        console.log(con_examen, con_docs);
        if (con_examen >= 0 && con_docs >= 0 && trabajador.tresd === true) {
            updateEstadoTrabajador(trabajador.id, "Listo para 3D");
        } else if (con_examen >= 0 && con_docs >= 0 && trabajador.tresd === false) {
            updateEstadoTrabajador(trabajador.id, "Listo para Contratar");
        } else if (con_examen >= 0) {
            updateEstadoTrabajador(trabajador.id, "Exámenes Cargados");
        } else if (con_docs >= 0) {
            updateEstadoTrabajador(trabajador.id, "Curso Inducción Cargado")
        }
        //actualizaSegunPerfil(perfil);
        setLoading(false);
    }

    const handleChangeTrabajador = (valor, nombre) => {
        if (nombre === "tresd") {
            if (trabajador.rut === "" || trabajador.id === undefined) {
                toast.error("Debe ingresar un trabajador primero");
                return;
            }
            setLoading(true);
            trabajador.tresd = valor;
            if (valor === false) {
                //Buscamos el curso 3D
                var arr_tr = [...cursosTrabajadores];
                var curso3d = arr_tr.find(v => v.nombre === "3D");
                var curso3dIndex = arr_tr.findIndex(v => v.nombre === "3D");
                //Si existe lo eliminamos
                if (curso3d !== undefined) {
                    setLoading(true);
                    cursoService.eliminarCurso(curso3d.id)
                        .then(resp => {
                            console.log(resp);
                            arr_tr.splice(curso3dIndex, 1);
                            setCursosTrabajadores(arr_tr);
                            cambiarEstado("");
                            setLoading(false);
                        })
                        .catch(err => {
                            console.error(err);
                            setLoading(false);
                        });
                }
                //Actualizamos el estado del trabajador
                setLoading(true);
                cambioEstado("Listo para contratar", trabajador.id);
                trabajadorService.updateTrabajador(trabajador.id, { tresd: false })
                    .then(resp => {
                        console.log(resp);
                        setLoading(false);
                    })
                    .catch(err => {
                        console.error(err);
                        setLoading(false);
                    });
            }
            if (valor) {
                //Primero buscamos si ya existe un curso y lo eliminamos

                var curso3d = cursosTrabajadores.find(v => v.nombre === "3D");
                //Si existe lo eliminamos
                if (curso3d !== undefined) {
                    setLoading(true);
                    cursoService.eliminarCurso(curso3d.id)
                        .then(resp => {
                            console.log(resp);
                            //setCursosTrabajadores(resp.data);
                            cambiarEstado("");
                            setLoading(false);
                        })
                        .catch(err => {
                            console.error(err);
                            setLoading(false);
                        });
                } else {
                    //En caso que no exista lo creamos
                    var arr_tr = [...cursosTrabajadores];
                    setLoading(true);
                    cursoService.create({ nombre: "3D", aprobado: "Por agendar", fecha: "infinity", trabajadorId: trabajador.id })
                        .then(resp => {
                            console.log(resp);
                            arr_tr.push(resp.data);
                            setCursosTrabajadores(arr_tr);
                            cambiarEstado("");
                            setLoading(false);
                        })
                        .catch(err => {
                            console.error(err);
                            setLoading(false);
                        });
                }
                //Actualizamos el estado del trabajador
                setLoading(true);
                cambioEstado("Listo para 3D", trabajador.id);
                trabajadorService.updateTrabajador(trabajador.id, { tresd: true })
                    .then(resp => {
                        console.log(resp);
                        setLoading(false);
                    })
                    .catch(err => {
                        console.error(err);
                        setLoading(false);
                    });
            }
            //setLoading(false);
        }
        //Si requiere conduccion
        if (nombre === "conduccion") {
            if (trabajador.rut === "" || trabajador.id === undefined) {
                toast.error("Debe ingresar un trabajador primero");
                return;
            }
            setLoading(true);
            trabajador.conduccion = valor;
            if (valor === false) {
                //Eliminamos el examen si existe
                var psico = examenes.find(v => v.nombre === "PSICOSENSOMÉTRICO");
                var psicoIndex = examenes.findIndex(v => v.nombre === "PSICOSENSOMÉTRICO");
                //Si existe lo eliminamos
                if (psico !== undefined) {
                    examenService.eliminarExamen(psico.id)
                        .then(resp => {
                            console.log(resp);
                            examenes.splice(psicoIndex, 1);
                            setExamen(examenes);
                        })
                        .catch(err => {
                            console.error(err);
                        });
                }
                //Actualizamos el estado del trabajador
                trabajadorService.updateTrabajador(trabajador.id, { conduccion: false })
                    .then(resp => {
                        console.log(resp);
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }
            if (valor) {
                //Primero buscamos si ya existe un curso y lo eliminamos
                var psico = examenes.find(v => v.nombre === "PSICOSENSOMÉTRICO");
                //Si existe lo eliminamos
                if (psico !== undefined) {
                    examenService.eliminarExamen(psico.id)
                        .then(resp => {
                            console.log(resp);
                            examenes.splice(psicoIndex, 1);
                            setExamen(examenes);
                        })
                        .catch(err => {
                            console.error(err);
                        });
                } else {
                    //En caso que no exista lo creamos
                    examenService.create({ nombre: "PSICOSENSOMÉTRICO", trabajadorId: trabajador.id, fecha: "infinity" })
                        .then(resp => {
                            console.log(resp);
                            examenes.push(resp.data);
                            setExamen(examenes);
                        })
                        .catch(err => {
                            console.error(err);
                        });
                }
                //Actualizamos el estado del trabajador
                trabajadorService.updateTrabajador(trabajador.id, { conduccion: true })
                    .then(resp => {
                        console.log(resp);
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }
            setLoading(false);
        }
        setTrabajador({ ...trabajador, [nombre]: valor });
    }

    const crearTrabajador = () => {
        setLoading(true);
        if (!validate(trabajador.rut)) {
            alert("Debe ingresar un Rut Valido!");
            setLoading(false);
            return;
        }
        if (!validarForm(trabajador)) {
            setLoading(false);
            return;
        }
        //Creamos el trabajador.
        var nombre = trabajador.nombre;
        trabajador.nombre = nombre.toUpperCase();
        trabajador.usuarioId = usuario.id;
        trabajador.estado = "En Proceso";
        trabajadorService.create(trabajador)
            .then(t => {
                setTrabajador(t.data);
                bitacoraService.create({ fecha: new Date(), accion: "Ingreso de Trabajador " + t.data.rut, usuarioId: usuario.id })
                    .then(resp => {
                        console.log(resp);
                    })
                    .catch(err => {
                        console.error(err);
                    });
                setLoading(false);
                toast.info("Trabajador creado correctamente!", {
                    position: "top-right",
                    autoClose: 3000
                });
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
                toast.error("No se pudo crear el trabajador", {
                    position: "top-center",
                    autoClose: 3000
                });
            });
        //Si la id del trabajador es vacia o nula, se crea el trabajador
        //Si la id del trabajador no esta vacia, se actualiza el trabajador.
    }

    const onBlurTrabajador = (rut) => {
        setLoading(true);
        if (!validate(rut)) {
            alert("Ingrese un Rut Valido por favor!");
            setLoading(false);
            return;
        }
        const r = format(rut);
        trabajadorService.buscarPorRut(r)
            .then(t => {
                if (t.data[0]) {
                    if (t.data[0].disponibilidad === "Bloqueado") {
                        setModalAviso(true);
                    }
                    setTrabajador(t.data[0]);
                    setExamenes(t.data[0].examenes);
                    setCursosTrabajadores(t.data[0].cursos);
                    setLoading(false);
                } else {
                    initialTrabajador.rut = r;
                    initialTrabajador.ejecutiva = usuario.nombre;
                    initialTrabajador.numero_ejecutiva = usuario.numero;
                    setTrabajador(initialTrabajador);
                    setExamenes([]);
                    setLoading(false);
                }
            })
            .catch(err => {
                console.error(err);
                console.error(err.message);
                setLoading(false);
                toast.error("Error en la conexión", {
                    position: "top-center",
                    autoClose: 3000
                });
            });
    }

    const updateTrabajador = (id) => {
        setLoading(true);
        if (!validarForm(trabajador)) {
            setLoading(false);
            return;
        }
        var nombre = trabajador.nombre;
        trabajador.nombre = nombre.toUpperCase();
        trabajadorService.updateTrabajador(id, trabajador)
            .then(t => {
                bitacoraService.create({ fecha: new Date(), accion: "Actualiza el Trabajador " + trabajador.rut, usuarioId: usuario.id })
                    .then(resp => {
                        console.log(resp);
                    })
                    .catch(err => {
                        console.error(err);
                    });
                setLoading(false);
                toast.info("Actualizado Correctamente!!", {
                    position: "top-right",
                    autoClose: 3000
                });

            })
            .catch(err => {
                console.error(err);
                setLoading(false);
                toast.error("Error al actualizar", {
                    position: "top-center",
                    autoClose: 2000
                });
            });
    }

    const showEditExamen = (id) => {
        setLoading(true);
        //Obtenemos el examen
        examenService.get(id)
            .then(e => {
                setExamen(e.data);
                setModalShow(true);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    }

    const editExamen = (id) => {
        setLoading(true);
        //examen.fecha = starDate;
        examenService.update(id, examen)
            .then(e => {
                //setExamen(e.data);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
        var indx = examenes.findIndex(v => v.id === id);
        examenes.splice(indx, indx >= 0 ? 1 : 0);
        examenes.push(examen);
        setExamenes(examenes);
        cambioAcontratar(examenes);
        setLoading(false);
        setModalShow(false);
    }

    function cambioAcontratar(exs) {
        var con = 0;
        for (var i = 0; i < exs.length; i++) {
            if (exs[i].nombre === "ALTURA GEOGRÁFICA") {
                con++;
            }
            if (exs[i].nombre === "ALTURA FÍSICA") {
                con++;
            }
            if (exs[i].nombre === "ALCOHOL Y DROGAS") {
                con++;
            }
            if (exs[i].nombre === "ALCOHOL Y DROGAS") {
                con++;
            }
            if (exs[i].nombre === "CERO DAÑO" || exs[i].nombre === "INDUCCION HOMBRE NUEVO") {
                con++;
            }
        }
        if (con === 5) {
            //Actualizamos estado de trabajador.
        }
    }

    const envioPrimero = () => {
        var mje = "Your package has been shipped. It will be delivered in 2 business days.";
        wsService.evniar_primerto({ mensaje: mje, numero: trabajador.fono, nombre: trabajador.nombre, dias: 0 })
            .then(r => {
                console.log(r);
                setLoading(false);
            });
        setLoading(false);
    }

    const enviarWs = async () => {
        setLoading(true);
        //Preparamos el mensaje
        var domain = window.location.host; //"https://csi.digitalmetaprime.com
        var mje = "";
        var mje_examenes = "";
        var mje_cerodano = "";
        var con = 0;
        var hora = "";
        var ciudad = "";
        var direccion = "";
        var fecha = "";
        var laboratorio = "";
        var reserva = "";
        const enlace = domain + "/trabajador/" + btoa(trabajador.rut); //"https://main.dutc7orrvtax3.amplifyapp.com/trabajador/" + btoa(trabajador.rut);
        con = 0;
        var mje_propio = "";
        var arr_mjs = [];
        mje_propio = "Estimad@ " + trabajador.nombre + " " + trabajador.ap_paterno + " desde MetaPrime SpA. le indicamos que sus exámenes/cursos quedaron agendados en las siguientes fechas: \n";
        examenes.map((e, i) => {
            if (e.nombre === "ALTURA GEOGRÁFICA" || e.nombre === "ALTURA FÍSICA" || e.nombre === "ALCOHOL Y DROGAS" || e.nombre === "EXÁMEN PREOCUPACIONAL") {
                con++;
                hora = e.hora;
                ciudad = e.ciudad;
                direccion = e.direccion;
                fecha = moment(e.fecha).format("DD-MM-YYYY");
                laboratorio = e.laboratorio;
                reserva = e.reserva;
            }
            if (e.nombre === "CERO DAÑO" || e.nombre === "INDUCCION HOMBRE NUEVO") {
                mje_cerodano = mje_cerodano + " - " + e.nombre + " el día " + moment(e.fecha).format("DD-MM-YYYY") + " a las " + e.hora + ", recuerde que este curso es Online y se le hará llegar un correo con el link para el ingreso al curso. \n";
                arr_mjs.push("<strong>" + e.nombre + "</strong> el día " + moment(e.fecha).format("DD-MM-YYYY") + " a las " + e.hora + ", recuerde que este curso es Online y se le hará llegar un correo con el link para el ingreso al curso.");
            }
            if (e.nombre !== "ALTURA GEOGRÁFICA" & e.nombre !== "ALTURA FÍSICA" & e.nombre !== "ALCOHOL Y DROGAS" & e.nombre !== "EXÁMEN PREOCUPACIONAL" & e.nombre !== "CERO DAÑO" & e.nombre !== "INDUCCION HOMBRE NUEVO") { //& (e.nombre.includes("CERO DAÑO")  || e.nombre.includes("INDUCCION HOMBRE NUEVO"))
                console.log("Examen WS", e.nombre);
                mje_propio = mje_propio + " - " + e.nombre + " el día " + moment(e.fecha).format("DD-MM-YYYY") + " a las " + e.hora + " en " + e.laboratorio + " en la ciudad de " + e.ciudad + " ubicada en " + e.direccion + " \n";
                arr_mjs.push("<strong>" + e.nombre + "</strong> el día " + moment(e.fecha).format("DD-MM-YYYY") + " a las " + e.hora + " en " + e.laboratorio + " en la ciudad de " + e.ciudad + " ubicada en " + e.direccion + ".");
            }
            //mje_propio = mje_propio + " - " + e.nombre + " en el día " + moment(e.fecha).format("DD-MM-YYYY") + " a las " + e.hora + " en la ciudad de " + e.ciudad + " con la siguiente dirección " + e.direccion + " \n";
        });
        if (con > 0) {
            mje_examenes = mje_examenes + " - EXÁMENES MEDICOS el día " + fecha + " a las " + hora + " en la ciudad de " + ciudad + " en " + laboratorio + " ubicada en " + direccion + ". Su código de reserva es el " + reserva + "\n";
            arr_mjs.push("<strong>EXÁMENES MEDICOS</strong> el día " + fecha + " a las " + hora + " en la ciudad de " + ciudad + " en " + laboratorio + " ubicada en " + direccion + ". Su código de reserva es el " + reserva);
        }
        mje = mje + "Indicarle que su ejecutiv@ de atención es " + trabajador.ejecutiva + " y puede contactarla/o al número " + trabajador.numero_ejecutiva + ". \n";
        mje = mje + "Además, para continuar con el proceso debe completar el siguiente formulario a través del link; " + enlace;
        mje = mje + " en un plazo de 24 hrs por favor.";

        var number = trabajador.fono.split('+');

        //** Nuevo envio de WS */

        //Enviamos template
        /* let h24 = await revisar24hrs(trabajador.fono);
        if ((trabajador.respuestaws != "SI") || h24) {
            wsService.enviar_template({ mensaje: "template", numero: "00" + number[1] })
                .then(ws => {
                    console.log(ws);
                    toast.info("Invitación enviada", {
                        position: "top-center",
                        autoClose: 1000
                    });
                    registrarEnvio();
                    setLoading(false);
                })
                .catch(err => {
                    console.error(err);
                    setLoading(false);
                    toast.error("Ocurrio un error interno", {
                        position: "top-right",
                        autoClose: 1000
                    });
                });
        } else {
            wsService.enviarmje({ numero: "00" + number[1], mensaje: mje_propio })
                .then(async wm => {
                    toast.info("WhatSapp enviado correctamente", {
                        position: "top-right",
                        autoClose: 2000
                    });
                    registrarEnvio();
                    //Enviamos segunda parte
                    if (mje_examenes) {
                        let mexamenes = await wsService.enviarmje({ numero: "00" + number[1], mensaje: mje_examenes });
                    }
                    if (mje_cerodano) {
                        let mdcano = await wsService.enviarmje({ numero: "00" + number[1], mensaje: mje_cerodano });
                    }
                    wsService.enviarmje({ numero: "00" + number[1], mensaje: mje })
                        .then(ws2 => {
                            console.log(ws2);
                        })
                        .catch(err => {
                            console.error(err);
                        });
                    setLoading(false);
                })
                .catch(err => {
                    console.error(err);
                    toast.error("Ocurrio un error al enviar el mensaje", {
                        position: "top-right",
                        autoClose: 1000
                    });
                    setLoading(false);
                });
        }
 */
        //Enviamos correo de respaldo
        const mail = {
            nombre: trabajador.nombre,
            //from_name: "Encuesta Salud CSI Ltda.",
            mensaje: mje_propio + mje_cerodano + mje_examenes + mje,
            email: trabajador.correo
        }

        const mail2 = {
            mensaje: formatHTML(trabajador, arr_mjs, enlace),
            email: trabajador.correo
        }

        emailService.sendMail(mail2)
            .then(e => {
                console.log(e);
                toast.info("Correo enviado correctamente!", {
                    position: "top-left",
                    autoClose: 2000
                });
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                toast.error("Ocurrio un error al enviar el mail", {
                    position: "top-left",
                    autoClose: 2000
                });
                setLoading(false);
            });
        setLoading(false);
    }

    function formatHTML(trabajador, mensajes, enlace) {
        var html = `
        <p>Hola ${trabajador.nombre} ${trabajador.ap_paterno} ${trabajador.ap_materno},</p>
        <p><br />Tienes un nuevo mensaje de Reclutamiento MetaPrime SpA.:</p>
        <p><br />Estimad@ ${trabajador.nombre} ${trabajador.ap_paterno} ${trabajador.ap_materno} desde MetaPrime SpA. le indicamos que sus ex&aacute;menes/cursos quedaron agendados en las siguientes fechas:</p>
        <ul style="list-style-type: square;">`
        for (var i = 0; i < mensajes.length; i++) {
            html = html + "<li>" + mensajes[i] + "</li>";
        }
        html = html + `
        </ul>
        <p>Indicarle que su ejecutiv@ de atenci&oacute;n es ${trabajador.ejecutiva} y puede contactarl@ al n&uacute;mero ${trabajador.numero_ejecutiva}. 
        Adem&aacute;s, para continuar con el proceso debe completar el siguiente formulario con sus datos personales y adjuntar 
        la documentaci&oacute;n solicitada, a trav&eacute;s del link; <a href=${enlace}>${enlace}</a>
        en un plazo de 24 hrs por favor.</p>
        `;
        return html;
    }

    const btnAgregarExamen = () => {
        setExamenCheck([]);
        setExamen(initialExamen);
        setModalShow(true);
    }

    const EnviarFormulario = () => {
        var id_trabjador = trabajador.rut;
        var id_codificado = btoa(id_trabjador);
        navigate("/trabajador/" + id_codificado);
    }

    function registrarEnvio() {
        const data = {
            nombre_usuario: usuario.nombre,
            fono_usuario: usuario.numero,
            nombre_trabajador: trabajador.nombre,
            fono_trabajador: trabajador.fono
        }

        registroService.create(data)
            .then(r => {
                console.log(r);
            })
            .catch(err => {
                console.error(err);
            });
    }

    function revisar24hrs(numero_trabajador) {
        return registroService.getAll()
            .then(response => {
                var r = response.data;
                r.sort(compareRegistro);
                var r2 = r.find(v => v.fono_trabajador === numero_trabajador);
                if (r2) {
                    var fecha_ws = moment(r2.createdAt);
                    var fecha_hoy = moment();
                    var fecha_24h = fecha_hoy.diff(fecha_ws, 'hours');//new Date(moment(r2.createdAt).add(24, 'hours'))
                    if (fecha_24h >= 12) {
                        //Actualizamos a NO o null
                        trabajadorService.updateTrabajador(trabajador.id, { respuestaws: "NO" })
                            .then(tr => {
                                console.log(tr);
                            })
                            .catch(errt => {
                                console.error(errt);
                            });
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }

                //console.log("PRIMER DATO",[...r2][0]);
                /* r.map(re=>{
                    if(re.fono_trabajador === numero_trabajador) {
                        console.log("NUMERO ECONTRADO",re);
                    }
                }); */
            })
            .catch(err => {
                console.error(err);
            });
    }

    function compareRegistro(a, b) {
        if (a.id > b.id) {
            return -1;
        }
        if (a.id < b.id) {
            return 1;
        }
        return 0;
    }

    function RespuestaFormat() {
        if (trabajador.respuestaws != null && trabajador.respuestaws === "SI") {
            return <Emoji symbol="✔" />
        } else {
            return <Emoji symbol="❌" />
        }
    }

    const btnCerrarAviso = () => {
        window.location.reload();
    }

    const btnIngresarCurso = () => {
        setShowMCurso(true);
    }

    const onChangeFechaCurso = (newValue) => {
        setFechaCurso(newValue);
    }

    //Ingresamos el curso Especial
    const ingresarCursoEspecial = async () => {
        /* if (!validarFormCurso(curso)) {
            alert("Problemas al validar");
            return;
        } */
        if (curso.nombre === "") {
            alert("Debe ingresar un curso");
            return;
        }
        if (curso.nombre === "3D" && (trabajador.tresd === false || trabajador.tresd === null)) {
            alert("El trabajador no requiere 3D");
            return;
        }
        setLoading(true);
        curso.trabajadorId = trabajador.id;
        curso.fecha = "infinity";
        try {
            let cur = await cursoService.create(curso);
            if (cur) {
                console.log(cur);
                toast.info("Curso Ingresado Correctamente!", {
                    position: "top-right",
                    autoClose: 2000
                });
                cursosTrabajadores.push(cur.data);
                setCursosTrabajadores(cursosTrabajadores);
                setLoading(false);
                setShowMCurso(false);
            }
        } catch (error) {
            toast.error("Error al ingresar curso!!", {
                position: "top-center",
                autoClose: 2000
            });
            console.error(error);
            setLoading(false);
        }
    }

    function actualizarCursos(rut) {
        trabajadorService.buscarPorRut(rut)
            .then(resp => {
                setCursosTrabajadores(resp.data[0].cursos);
            })
            .catch(err => {
                console.error(err);
            });
    };

    const columns_examenes = useMemo(
        () => [{
            id: 'examenss',
            header: "Exámenes",
            columns: [
                {
                    header: 'Nombre',
                    accessorKey: 'nombre',
                },
                {
                    header: 'Fecha',
                    accessorKey: 'fecha',
                    Cell: ({cell}) => (
                        moment(cell.getValue()).utc().format("DD-MM-YYYY")
                    )
                },
                {
                    header: 'Hora',
                    accessorKey: 'hora',
                },
                {
                    header: 'Reserva',
                    accessorKey: 'reserva',
                },
            ],
        }
        ],
    );

    const eliminarExamen = (id_examen) => {
        confirm({title: "¿Estas Seguro?", description: "Estas seguro de eliminar el Examen?", cancellationText: "CANCELAR", confirmationText: "ACEPTAR"})
        .then(()=>{
            setLoading(true);
            examenService.eliminarExamen(id_examen)
            .then(resp=>{
                console.log(resp);
                toast.info("Eliminado Correctamente!!", {
                    position: "top-center",
                    autoClose: 2000,
                });
                setLoading(false);
                onBlurTrabajador(trabajador.rut);
            }).catch(error=>{
                console.error(error);
                setLoading(false);
            });
        }).catch(err=>{
            console.error(err);
        })
    };

    const columns_cursostrabajdores = useMemo(
        () => [{
            id: 'cursos_trabajadores',
            header: "Cursos Especiales de Trabajadores",
            columns: [
                {
                    header: 'Nombre',
                    accessorKey: 'nombre',
                },
                {
                    header: 'Estado',
                    accessorKey: 'aprobado',
                },
                {
                    header: 'Fecha',
                    accessorKey: 'fecha',
                    Cell: ({cell}) => {
                        if(cell.getValues !== "") {
                            return "";
                        } else {
                            return moment(cell.getValue()).utc().format("DD-MM-YYYY");
                        }
                    }
                },
                {
                    header: 'Hora',
                    accessorKey: 'hora',
                },
            ],
        }
        ],
    );

    return (
        <div>
            <NavBarComponent usuario={auth.user} auth={auth} />
            <LoadingOverlay active={loading} spinner={<PacmanLoader color="#8BC7FA" />} >
                <Container className='mt-3'>
                    <Row>
                        <ModalAviso
                            show={modalAviso}
                            cerrar={btnCerrarAviso}
                        />
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Rut:</Form.Label>
                                <Form.Control type="text" value={trabajador.rut} placeholder="12345678-9" onBlur={e => onBlurTrabajador(e.target.value)} onChange={(e) => handleChangeTrabajador(e.target.value, "rut")} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="validationCustom03">
                                <Form.Label>Nombre:</Form.Label>
                                <Form.Control type="text" value={trabajador.nombre} placeholder="NOMBRE1 NOMBRE2" onChange={(e) => handleChangeTrabajador(e.target.value.toUpperCase(), "nombre")} required />
                                {formerrors.nombre && (
                                    <p className="text-warning">{formerrors.nombre}</p>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Apellido Paterno:</Form.Label>
                                <Form.Control type="text" value={trabajador.ap_paterno} placeholder="APELLIDO PATERNO" onChange={(e) => handleChangeTrabajador(e.target.value.toUpperCase(), "ap_paterno")} required />
                                {formerrors.ap_paterno && (
                                    <p className="text-warning">{formerrors.ap_paterno}</p>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Apellido Materno:</Form.Label>
                                <Form.Control type="text" value={trabajador.ap_materno} placeholder="APELLIDO MATERNO" onChange={(e) => handleChangeTrabajador(e.target.value.toUpperCase(), "ap_materno")} />

                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Centro Costo:</Form.Label>
                                <Form.Select aria-label="Default select example" value={trabajador.centro_costo} onChange={(e) => handleChangeTrabajador(e.target.value, "centro_costo")}>
                                    <option value="">Seleccione Centro de Costo:</option>
                                    {ccs.map((cc, i) => {
                                        return (
                                            <option key={i} value={cc.nombre}>{cc.nombre}</option>
                                        )
                                    })}
                                </Form.Select>
                                {formerrors.centro_costo && (
                                    <p className="text-warning">{formerrors.centro_costo}</p>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Cargo:</Form.Label>
                                <Form.Select value={trabajador.cargo} onChange={(e) => handleChangeTrabajador(e.target.value, "cargo")}>
                                    <option value="">Seleccione Cargo:</option>
                                    {cargos.map((c, i) => {
                                        return (
                                            <option key={i} value={c.nombre}>{c.nombre}</option>
                                        )
                                    })}
                                </Form.Select>
                                {formerrors.cargo && (
                                    <p className="text-warning">{formerrors.cargo}</p>
                                )}
                                {/* <Form.Control type="text" value={trabajador.cargo} onChange={(e) => handleChangeTrabajador(e.target.value, "cargo")} /> */}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Celular:</Form.Label>
                                <Form.Control type="text" value={trabajador.fono ? trabajador.fono : ""} placeholder="+56988665544" onChange={(e) => handleChangeTrabajador(e.target.value, "fono")} />
                                {formerrors.fono && (
                                    <p className="text-warning">{formerrors.fono}</p>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Correo:</Form.Label>
                                <Form.Control type="mail" value={trabajador.correo ? trabajador.correo : ""} placeholder="aa@aaa.com" onChange={(e) => handleChangeTrabajador(e.target.value, "correo")} />
                                {formerrors.correo && (
                                    <p className="text-warning">{formerrors.correo}</p>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Reclutadora(o):</Form.Label>
                                <Form.Control type="text" value={trabajador.ejecutiva ? trabajador.ejecutiva : ""} onChange={(e) => handleChangeTrabajador(e.target.value, "ejecutiva")}></Form.Control>
                                {formerrors.ejecutiva && (
                                    <p className="text-warning">{formerrors.ejecutiva}</p>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Número reclutadora(o):</Form.Label>
                                <Form.Control type="text" value={trabajador.numero_ejecutiva ? trabajador.numero_ejecutiva : ""} onChange={(e) => handleChangeTrabajador(e.target.value, "numero_ejecutiva")}></Form.Control>
                                {formerrors.numero_ejecutiva && (
                                    <p className="text-warning">{formerrors.numero_ejecutiva}</p>
                                )}
                            </Form.Group>
                        </Col>
                        <Col>
                            {/* <h4 className="text-center">Ingreso Exámenes</h4>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Fecha</th>
                                        <th>Hora</th>
                                        <th>Reserva</th>
                                        <th>Editar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {examenes.map((ex, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{ex.nombre}</td>
                                                <td>{ex.fecha === null ? "" : moment(ex.fecha).format("DD-MM-YYYY")}</td>
                                                <td>{ex.hora}</td>
                                                <td>{ex.reserva}</td>
                                                <td><Button onClick={() => showEditExamen(ex.id)}><FontAwesomeIcon icon="fa-solid fa-pen-to-square" /></Button></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table> */}
                            <div className='mt-2'></div>
                            <MaterialReactTable
                                columns={columns_examenes}
                                data={examenes}
                                muiTableHeadCellProps={{
                                    sx: {
                                        backgroundColor: '#102337',
                                        color: 'white'
                                    }
                                }}
                                muiTableHeadCellColumnActionsButtonProps={{
                                    sx: {
                                        color: 'white'
                                    }
                                }}
                                muiExpandAllButtonProps={{
                                    sx: {
                                        color: 'white'
                                    }
                                }}
                                //onEditingRowSave={showEditExamen}
                                localization={MRT_Localization_ES}
                                enableRowActions
                                renderRowActionMenuItems={({ row, table, closeMenu }) => [
                                    <MenuItem
                                        key={0}
                                        onClick={() => {showEditExamen(row.original.id); closeMenu();}}
                                        sx={{ m: 0 }}
                                    >
                                        <ListItemIcon>
                                            <EditIcon />
                                        </ListItemIcon>
                                        Editar
                                    </MenuItem>,
                                    <MenuItem
                                        key={1}
                                        sx={{ m: 0 }}
                                        onClick={() => {eliminarExamen(row.original.id); closeMenu();}}
                                    >
                                        <ListItemIcon>
                                            <DeleteIcon />
                                        </ListItemIcon>
                                        Eliminar
                                    </MenuItem>,
                                ]}
                            />
                            <Button variant="primary" onClick={btnAgregarExamen}>Agendar</Button>{' '}
                            <Button variant="success" onClick={enviarWs}>Enviar Mensaje</Button>
                            <div className='mt-3'></div>
                            <Form.Group className="mb-3">
                                <Form.Check
                                    onChange={(e) => handleChangeTrabajador(e.target.checked, "tresd")}
                                    type="switch"
                                    id="s3d"
                                    label="¿Requiere 3D?"
                                    value={trabajador.tresd}
                                    checked={trabajador.tresd}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check
                                    onChange={(e) => handleChangeTrabajador(e.target.checked, "conduccion")}
                                    type="switch"
                                    id="s3d"
                                    label="Requiere conducción? / Es operador?"
                                    value={trabajador.conduccion}
                                    checked={trabajador.conduccion}
                                />
                            </Form.Group>
                            {/* <Form.Group className="mb-3">
                                <Form.Check
                                    onChange={(e) => handleChangeTrabajador(e.target.checked, "trabajo_escondida")}
                                    type="switch"
                                    id="s3d"
                                    label="Trabajo en Escondida?"
                                    value={trabajador.trabajo_escondida}
                                    checked={trabajador.trabajo_escondida}
                                />
                            </Form.Group> */}
                            <div className="mt-3"></div>
                            <AddExmaenModal
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                                addEx={handleChangeExamen}
                                registrarExamen={add_examen}
                                id={trabajador}
                                date={starDate}
                                setDate={setStarDate}
                                examen={examen}
                                editEx={editExamen}
                                errors={formerrorsEx}
                                checkEx={handleCheckExamen}
                                onBlLab={onBlurLaboratorio}
                                ciudades={ciudades}
                                onBlCiu={onBlurCiudad}
                                onChangeDate={handleChangeExamen}
                            />
                            {/* <div className="mt-3"></div>
                            <h4 className="text-center">Ingreso Cursos Especiales</h4>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Fecha</th>
                                        <th>Hora</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cursosTrabajadores.map((ex, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{ex.nombre}</td>
                                                <td>{ex.fecha === null ? "" : moment(ex.fecha).format("DD-MM-YYYY")}</td>
                                                <td>{ex.hora}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table> */}
                            <div className='mt-2'></div>
                            <MaterialReactTable
                                columns={columns_cursostrabajdores}
                                data={cursosTrabajadores}
                                muiTableHeadCellProps={{
                                    sx: {
                                        backgroundColor: '#102337',
                                        color: 'white'
                                    }
                                }}
                                muiTableHeadCellColumnActionsButtonProps={{
                                    sx: {
                                        color: 'white'
                                    }
                                }}
                                muiExpandAllButtonProps={{
                                    sx: {
                                        color: 'white'
                                    }
                                }}
                                //onEditingRowSave={showEditExamen}
                                localization={MRT_Localization_ES}
                                /* enableRowActions
                                renderRowActionMenuItems={({ row, table, closeMenu }) => [
                                    <MenuItem
                                        key={0}
                                        onClick={() => {showMCurso(row.original.id); closeMenu();}}
                                        sx={{ m: 0 }}
                                    >
                                        <ListItemIcon>
                                            <EditIcon />
                                        </ListItemIcon>
                                        Editar
                                    </MenuItem>,
                                    <MenuItem
                                        key={1}
                                        sx={{ m: 0 }}
                                        onClick={() => {eliminarExamen(row.original.id); closeMenu();}}
                                    >
                                        <ListItemIcon>
                                            <DeleteIcon />
                                        </ListItemIcon>
                                        Eliminar
                                    </MenuItem>,
                                ]} */
                            />
                            <div className='mt-2'></div>
                            <Button variant="primary" onClick={btnIngresarCurso}>Agendar Curso Especial</Button>
                            <ModalCursosEspeciales
                                show={showMCurso}
                                onHide={() => setShowMCurso(false)}
                                cursos={cursos}
                                fechaCurso={fechaCurso}
                                onChangeFechaCurso={onChangeFechaCurso}
                                curso={curso}
                                addEx={handleChangeCurso}
                                ciudades={ciudades}
                                errors={formErrosCurso}
                                onBlLab={onBlurLaboratorio}
                                onBlCiu={onBlurCiudad}
                                agregarCurso={ingresarCursoEspecial}
                                trabajador={trabajador}

                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {trabajador.id ?
                                <Button variant="secondary" onClick={() => updateTrabajador(trabajador.id)}>Actualizar Trabajador</Button>
                                :
                                <Button variant="primary" onClick={crearTrabajador}>Agregar Trabajador</Button>
                            }
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            {/* <Button variant="success" onClick={envioPrimero}>Mandar WS 1</Button>{' '} */}
                            {/* <Button variant="success" onClick={enviarWs}>Mandar WS</Button>{' '} */}
                            {/* <Button variant="success" onClick={EnviarFormulario}>Enviar Formulario</Button> */}
                        </Col>
                    </Row>
                </Container>
            </LoadingOverlay>
        </div>
    );
};

export default FmIngresoTrabajadro;