import http from "../http-common";

class TokenService {

    getToken() {
        return fetch('http://ecoraapp.com:8080/api/token/1', {
            method: 'GET',
            //mode: 'no-cors'
        })
            .then(resp => {
                if (resp.ok) {
                    //console.log(resp.json());
                    return resp.json();
                }
            });
    }

    getTokenB() {
        return http.post("/token");
    }
}

export default new TokenService();