import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useConfirm } from "material-ui-confirm";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { DotLoaderOverlay } from 'react-spinner-overlay';
import { toast } from 'react-toastify';
import { useAuth } from "../AuthProvider";
import NavBarComponent from "../navbar.component";

/** MUI MATERIAL */
import {
    ListItemIcon,
    MenuItem
} from '@mui/material';

/** MATERIAL REACT TABLE */
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';

/** MUI ICONOS */
/** MUI ICONOS */
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';


//Servicios
import cursoespecialService from "../../services/cursoespecial.service";

const CursosEspeciales = () => {
    const [cursos, setCursos] = useState([]);
    const [nombreCurso, setNombreCurso] = useState('');
    const auth = useAuth();
    const confirm = useConfirm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        cargarCursos();
    }, []);

    function cargarCursos() {
        //Obtenemos los cursos
        setLoading(true);
        cursoespecialService.getAll()
            .then(res => {
                setCursos(res.data);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    }

    const ingresarCurso = () => {
        if (nombreCurso === "") {
            alert("Debe ingresar un nombre!");
            return;
        }
        setLoading(true);
        cursoespecialService.create({ nombre: nombreCurso.toUpperCase() })
            .then(res => {
                console.log(res);
                toast.info("Curso Ingresado Correctamente!", {
                    position: "top-center",
                    autoClose: 1000
                });
                cargarCursos();
                setLoading(false);
            }).catch(err => {
                console.error(err);
                setLoading(false);
            });
    }

    function buttonFormat(cell, row) {
        return <Button variant="danger" onClick={() => eliminarCurso(row.id)}><FontAwesomeIcon icon="fa-solid fa-trash-can" /></Button>;
    }

    const eliminarCurso = (id) => {
        confirm({ title: "¿Estas Seguro?", description: "Estas seguro de eliminar el Curso?", cancellationText: "CANCELAR", confirmationText: "ACEPTA" })
            .then(() => {
                setLoading(true);
                cursoespecialService.eliminarCurso(id)
                    .then(data => {
                        toast.info("Curso Eliminado Correctamente!", {
                            position: "top-center",
                            autoClose: 1000
                        });
                        cargarCursos();
                        setLoading(false);
                    })
                    .catch(err => {
                        console.error(err);
                        setLoading(false);
                    });
            }).catch(error => {
                console.error(error);
            })
        /* if (window.confirm("Esta seguro de eliminar el Curso?")) {
            cursoespecialService.eliminarCurso(id)
                .then(data => {
                    toast.info("Curso Eliminado Correctamente!", {
                        position: "top-center",
                        autoClose: 1000
                    });
                    cargarCursos();
                })
                .catch(err => {
                    console.error(err);
                });
        } */
    }

    function onAfterSaveCell(row, cellName, cellValue) {
        if (cellName === "nombre") {
            cursoespecialService.updateCurso(row.id, { nombre: cellValue })
                .then(resp => {
                    console.log(resp);
                    toast.info("Curso editado correctamente!!", {
                        position: "top-right",
                        autoClose: 1000
                    });
                })
                .catch(err => {
                    console.error(err);
                });
        }
    }

    function onBeforeSaveCell(row, cellName, cellValue) {
        // You can do any validation on here for editing value,
        // return false for reject the editing


    }

    const cellEditProp = {
        mode: 'click',
        blurToSave: true,
        //beforeSaveCell: onBeforeSaveCell, // a hook for before saving cell
        afterSaveCell: onAfterSaveCell  // a hook for after saving cell
    };

    const columns_cursos = useMemo(
        () => [{
            id: 'cursos',
            header: "Cursos Especiales",
            columns: [
                {
                    header: 'Nombre',
                    accessorKey: 'nombre',
                },
            ],
        }
        ],
    );

    const handleSaveRow = async ({ exitEditingMode, row, values }) => {
        //Actualizamos en la BS
        setLoading(true);
        var id = row.original.id;
        cursoespecialService.updateCurso(id, values)
            .then(resp => {
                console.log(resp);
                toast.info("Actualizado correctamente!!", {
                    position: "top-center",
                    autoClose: 2000,
                })
                setLoading(false);
            }).catch(error => {
                console.error(error);
                setLoading(false);
            });
        //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here.
        cursos[row.index] = values;
        //send/receive api updates here
        setCursos([...cursos]);
        exitEditingMode(); //required to exit editing mode
    };

    return (
        <div>
            <NavBarComponent usuario={auth.user} auth={auth} />
            <div className="mt-3">
                <Container>
                    {loading ? (
                        <DotLoaderOverlay size={28} message="Cargando..." color="#4F8BFA" />
                    ) : ""}
                    <Row>
                        <Col sm={4}>
                            <h3>Ingresar Curso:</h3>
                            <Form.Group>
                                <Form.Label>Nombre Curso:</Form.Label>
                                <Form.Control type="text" onChange={(e) => setNombreCurso(e.target.value)} />
                            </Form.Group>
                            <div className="mt-3"></div>
                            <Button variant="success" onClick={ingresarCurso}>Ingresar</Button>
                        </Col>
                        <Col>
                            {/* <h3 className="text-center">Lista de Cursos</h3>
                            <BootstrapTable data={cursos} cellEdit={cellEditProp}>
                                <TableHeaderColumn dataField="id" isKey>ID</TableHeaderColumn>
                                <TableHeaderColumn dataField="nombre">Nombre</TableHeaderColumn>
                                <TableHeaderColumn dataFormat={buttonFormat} width="15%" tdStyle={{ textAlign: "center", whiteSpace: 'normal', wordWrap: 'break-word' }} editable={false}>Eliminar</TableHeaderColumn>
                            </BootstrapTable>
                            <div className='mt-3'></div> */}
                            <MaterialReactTable
                                columns={columns_cursos}
                                data={cursos}
                                muiTableHeadCellProps={{
                                    sx: {
                                        backgroundColor: '#102337',
                                        color: 'white'
                                    }
                                }}
                                muiTableHeadCellColumnActionsButtonProps={{
                                    sx: {
                                        color: 'white'
                                    }
                                }}
                                muiExpandAllButtonProps={{
                                    sx: {
                                        color: 'white'
                                    }
                                }}
                                onEditingRowSave={handleSaveRow}
                                localization={MRT_Localization_ES}
                                enableRowActions
                                renderRowActionMenuItems={({ row, table, closeMenu }) => [
                                    <MenuItem
                                        key={0}
                                        onClick={() => table.setEditingRow(row)}
                                        sx={{ m: 0 }}
                                    >
                                        <ListItemIcon>
                                            <EditIcon />
                                        </ListItemIcon>
                                        Editar
                                    </MenuItem>,
                                    <MenuItem
                                        key={1}
                                        sx={{ m: 0 }}
                                        onClick={() => eliminarCurso(row.original.id)}
                                    >
                                        <ListItemIcon>
                                            <DeleteIcon />
                                        </ListItemIcon>
                                        Eliminar
                                    </MenuItem>,
                                ]}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default CursosEspeciales;