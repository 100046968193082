import React, { useEffect, useState, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, Row, Col, Form, Stack, Tooltip, OverlayTrigger, Accordion, Card, Modal } from 'react-bootstrap';
import { ThreeDots } from 'react-loader-spinner';
import moment from 'moment';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";

/**Material UI */
import { DataGrid, esES } from '@mui/x-data-grid';

import { AccountContext } from "./Account";

/** Serivicios de API */
import usuarioService from "../services/usuario.service";
import cursoespecialService from "../services/cursoespecial.service";
import maestroExamenService from "../services/maestroexamen.service";
import trabajadorService from "../services/trabajador.service";

const initialPerfil = {
    reclutador: false,
    contratador: false,
    aprobador: false,
    acreditador: false,
    administrador: false,
    curso_especial: false
}


const TresdPage = () => {
    const { getSession, logout, status } = useContext(AccountContext);
    const [tableCursos, setTableCursos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [usuario, setUsuario] = useState(null);
    const [perfil, setPerfil] = useState(initialPerfil);
    const [cursosEspeciales, setCursosEspeciales] = useState([]);
    const [maestroExamenes, setMaestroExamenes] = useState([]);
    const [trabajador, setTrabajador] = useState('');

    //Columnas DataGrid
    const columns = [{
        field: "id", headerName: "ID"
    }, {
        field: "nombre", headerName: "Nombre", width: 360
    }, {
        field: "rut", headerName: "Rut", width: 180
    }, {
        field: "avanceDeloitte", headerName: "Avance Deloitte", width: 180
    }, {
        field: "cargo", headerName: "Cargo", width: 180
    }, {
        field: "centro_costo", headerName: "Centro Costo", width: 180
    }, {
        field: "avance", headerName: "Avance", width: 180, renderCell: (cellValues) => {
            return (
                <Progress percent={cellValues.row.avance} />
            );
        }
    }];


    useEffect(() => {
        getSession().then(async (session) => {
            const ssids = session.idToken.payload.sub;
            let u = await usuarioService.getBySSID(ssids);
            setUsuario(u.data[0]);
            retreivePerfiles(u.data[0]);
            retreiveCursosEspeciales();
            retreiveMaestroExamenes();
        });
    }, []);

    function retreivePerfiles(u) {
        var curso_especial = u.perfiles.find(v => v.nombre === "Curso_Especial");

        if (curso_especial) {
            setPerfil({ ...perfil, ['curso_especial']: true });
            initialPerfil.curso_especial = true;
            cols2();
        }
    }

    /** Funcion para los cursos especiales */
    function retreiveCursosEspeciales() {
        cursoespecialService.getAll()
            .then(resp => {
                setCursosEspeciales(resp.data);
            })
            .catch(err => {
                console.error(err);
            });
    }

    /** Funcion que cargar los maestros de examenes */
    function retreiveMaestroExamenes() {
        maestroExamenService.getAll()
            .then(resp => {
                setMaestroExamenes(resp.data);
            })
            .catch(err => {
                console.error(err);
            });
    }

    async function cols2() {
        setLoading(true);
        var contador_dias = 0;
        let t = await trabajadorService.getAll();
        /* cantidad_trabajadores.aprobador = t.data.length;
        cantidad_trabajadores.acreditador = t.data.length;
        cantidad_trabajadores.administrador = t.data.length; */
        var fecha_hoy = moment(new Date());
        const data = t.data.map(tr => ({
            rut: tr.rut,
            nombre: tr.nombre + ' ' + tr.ap_paterno,
            id: tr.id,
            documentos: tr.documentos,
            avance: (tr.documentos.length / 10) * 100,
            examenes: tr.examenes,
            estado: tr.estado,
            avanceDeloitte: avanceDeloitte(tr.documentos, tr.examenes, tr.trabajo_escondida, tr.conduccion),
            respuesta: tr.respuestaws,
            perfil: "aprobador",
            fecha_ingreso: moment(tr.createdAt).utc().format("DD/MM/YYYY"),
            cursos: tr.cursos,
            dias: fecha_hoy.diff(moment(tr.createdAt), 'days'),
            trabajo_escondida: tr.trabajo_escondida,
            conduccion: tr.conduccion,
            cargo: tr.cargo,
            centro_costo: tr.centro_costo,
            estado_3d: tr.cursos.find(v => v.nombre === "3D") ? tr.cursos.find(v => v.nombre === "3D").aprobado : ""
        }));
        //cantidad_trabajadores.ingresos_hoy_reclutador = contador_dias;
        setTableCursos(data);
        setLoading(false);
    }

    function avanceDeloitte(documentos, examenes, trabajo_escondida, conduccion) {
        var total_total = 0;
        if ((conduccion) && (trabajo_escondida)) {
            total_total = 11;
        }
        if ((conduccion === false || conduccion === null) && (trabajo_escondida)) {
            total_total = 8;
        }
        if ((conduccion === false || conduccion === null) && (trabajo_escondida === false || trabajo_escondida === null)) {
            total_total = 7;
        }
        var contador = 0;
        var contador_examenes = 0;
        documentos.map(d => {
            if (d.nombre === "carnet") {
                contador++;
            }
            /* if (d.nombre === "foto") {
                contador++;
            } */
            if (d.nombre === "antecedente") {
                contador++;
            }
            if (d.nombre === "ficha") {
                contador++;
            }
            if (d.nombre === "contrato") {
                contador++;
            }
            if (d.nombre === "transporte_mel") {
                contador++;
            }
            if (trabajo_escondida) {
                if (d.nombre === "finiquito") {//si aplica solamente
                    contador++;
                }
            }
            if (trabajador.conduccion) {
                if (d.nombre === "licencia_conducir") {
                    contador += 1;
                }
                if (d.nombre === "hoja_vida_conductor") {
                    contador += 1;
                }
            }
        });

        examenes.map(e => {
            if (e.nombre === "CERO DAÑO" && e.aprobado === "Apto") {
                contador_examenes++;
            }
            if (e.nombre === "EXÁMEN PREOCUPACIONAL" && e.aprobado === "Apto" || ((e.nombre === "ALTURA FÍSICA" && e.aprobado === "Apto") && (e.nombre === "ALTURA GEOGRÁFICA" && e.aprobado === "Apto"))) {
                contador_examenes++;
            }
            if (conduccion) {
                if (e.nombre === "PSICOSENSOMÉTRICO" && e.aprobado === "Apto") {
                    contador_examenes++;
                }
            }
        });

        var total = contador + contador_examenes;

        var total_retorno = 0;//(total / total_total) * 100;
        if (conduccion && trabajo_escondida) {
            var total_retorno = (total / 11) * 100;
            return Math.round(total_retorno);//(total / 9) * 100;
        }
        if ((conduccion === false || conduccion === null) && trabajo_escondida) {
            var total_retorno = (total / 8) * 100;
            return Math.round(total_retorno);//(total / 9) * 100;
        }
        if ((conduccion === false || conduccion === null) && (trabajo_escondida === false || trabajo_escondida === null)) {
            var total_retorno = (total / 7) * 100;
            return Math.round(total_retorno);//(total / 9) * 100;
        }

        //return Math.round(total_retorno);//(total / 9) * 100;
    }

    return (
        <Container>
            <Row>
                {loading ? (
                    <div className="spinner">
                        <ThreeDots color="#241654" height="100" width="100" ariaLabel="Loading..." />
                    </div>
                ) : (
                    <div style={{ height: 350, width: '100%' }}>
                        <DataGrid
                            rows={tableCursos}
                            columns={columns}
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        />
                    </div>
                )}
            </Row>
        </Container>
    )
}

export default TresdPage;