import React, { useEffect, useMemo, useState } from "react";
import { Accordion, Button, Col, Container, Form, Modal, OverlayTrigger, Row, Stack, Tooltip } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
//import Modal from 'react-bootstrap4-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import LoadingOverlay from 'react-loading-overlay';
import BeatLoader from 'react-spinners/BeatLoader';
import PacmanLoader from 'react-spinners/PacmanLoader';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import { toast } from 'react-toastify';
//import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";
import loadImage from 'blueimp-load-image';
import ExportJsonExcel from 'js-export-excel';
import JSPdf from 'jspdf';
import { PDFDocument } from 'pdf-lib';
import PDFMerger from 'pdf-merger-js/browser';
import S3 from 'react-aws-s3';
import { Spinner } from '../common/components/spinner';
import Emoji from "./Emoji";
//import { convertWordFiles } from 'convert-multiple-files';
//import * as path from 'path';
import { Buffer } from 'buffer';
import { useDropzone } from 'react-dropzone';
import { DotLoaderOverlay } from 'react-spinner-overlay';

//Material UI Design
import {
    Avatar,
    Box,
    Card, CardContent,
    CardHeader,
    createTheme,
    Grid,
    IconButton,
    ListItemIcon,
    Toolbar,
    Typography,
    useTheme
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import esLocale from 'date-fns/locale/es';

/** MATERIAL REACT TABLE */
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';

/** ICONOS MUI */
import EditIcon from '@mui/icons-material/Edit';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import FolderSharedIcon from '@mui/icons-material/FolderShared';

//Modals
import ModalEditarCursoLIsta from "./modals/modalEditarCursoLista";
import useModal from "./modals/useModal";

import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import bitacoraService from "../services/bitacora.service";
import ccService from "../services/cc.service";
import convertService from "../services/convertfile.service";
import cursoService from "../services/curso.service";
import cursoespecialService from "../services/cursoespecial.service";
import documentoService from "../services/documento.service";
import estadoService from "../services/estado.service";
import examenService from "../services/examen.service";
import maestroExamenService from "../services/maestroexamen.service";
import motivosDService from "../services/motivosd.service";
import rechazoexamenService from "../services/rechazoexamen.service";
import tokenService from "../services/token.service";
import trabajadorService from "../services/trabajador.service";
import usuarioService from "../services/usuario.service";
import { useAuth } from "./AuthProvider";
import NavBarComponent from "./navbar.component";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

Array.prototype.unique = function () {
    var a = this.concat();
    for (var i = 0; i < a.length; ++i) {
        for (var j = i + 1; j < a.length; ++j) {
            if (a[i] === a[j])
                a.splice(j--, 1);
        }
    }

    return a;
};

function base64toPDF(data) {
    var bufferArray = base64ToArrayBuffer(data);
    var blobStore = new Blob([bufferArray], { type: "application/pdf" });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blobStore);
        return;
    }
    var data = window.URL.createObjectURL(blobStore);
    var link = document.createElement('a');
    document.body.appendChild(link);
    link.href = data;
    link.download = "file.pdf";
    link.click();
    window.URL.revokeObjectURL(data);
    link.remove();
}

function base64ToArrayBuffer(data) {
    var bString = window.atob(data);
    var bLength = bString.length;
    var bytes = new Uint8Array(bLength);
    for (var i = 0; i < bLength; i++) {
        var ascii = bString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
};

function readFileDataAsBase64(e) {
    const file = e;

    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            resolve(event.target.result);
        };

        reader.onerror = (err) => {
            reject(err);
        };

        reader.readAsArrayBuffer(file);
    });
}

const options = {
    expandRowBgColor: 'rgb(242, 255, 163)'
};

function ProgressFormat(cell, row) {
    return <Progress percent={row.avance} />;
}

function ProgressFormatDeloitte(cell, row) {
    return <Progress percent={row.avanceDeloitte} theme={{ active: { color: 'orange' } }} />
}

function RespuestaFormat(cell, row) {
    if (row.respuesta != null && row.respuesta === "SI") {
        return <Emoji symbol="✔" />
    } else {
        return <Emoji symbol="❌" />
    }
}

function dateFormat(cell, row) {
    return row.fecha === null ? "" : moment(row.fecha).format("DD-MM-YYYY");
}

function aprobadoFormat(cell, row) {
    if (row.aprobado === "Apto") {
        return <><p style={{ color: "green", fontWeight: "bold" }}>Apto</p> {/* <FontAwesomeIcon icon="fa-solid fa-check-double" style={{ color: "green" }} /> */}</>
    }
    if (row.aprobado === "Verde") {
        return <><p style={{ color: "green", fontWeight: "bold" }}>Verde</p> {/* <FontAwesomeIcon icon="fa-solid fa-check-double" style={{ color: "green" }} /> */}</>
    }
    /* else {
        return <FontAwesomeIcon icon="fa-solid fa-square-xmark" style={{ color: "red" }} />
    } */
    /* if (row.aprobado === "NO APROBADO") {
        return <FontAwesomeIcon icon="fa-solid fa-square-xmark" style={{ color: "red" }} />
    } */
    return row.aprobado;//"Pendiente";
}

function bloqueado(row, rowIdx) {
    if (row === null) {
        return { backgroundColor: 'red' };
    }
}

function MotivoModal({ show, onHide, onChangeMotivo, onChangeBloqueo, ingresarMotivo }) {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Ingreso de Motivo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>Motivo:</Form.Label>
                    <Form.Control as="textarea" onChange={(e) => onChangeMotivo(e.target.value)} />
                </Form.Group>
                <Form.Group>
                    <Form.Check
                        label="Bloquear"
                        type="checkbox"
                        onChange={(e) => onChangeBloqueo(e.target.checked)}
                    />
                </Form.Group>
                <div className="mt-2"></div>
                <Button variant="success" onClick={ingresarMotivo}>Ingresar</Button>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onHide}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    )
}

function AddExmaenModal({ show, onHide, addEx, date, setDate, examen, editEx, upLoadF, loadB, tra, rechazo, addRechazo, perfil, onChangeDate, files, getRootProps, getInputProps }) {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Editar Examen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>Nombre:</Form.Label>
                    <Form.Control type="text" value={examen.nombre} onChange={e => addEx(e.target.value, "nombre")} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Hora:</Form.Label>
                    <Form.Control type="text" value={examen.hora} placeholder="HH:MM" onChange={e => addEx(e.target.value, "hora")} />
                </Form.Group>
                <Form.Group className="mb-3">
                    {/* <Form.Label>Fecha:</Form.Label> */}
                    {/* <Form.Control type="text" placeholder="DD-MM-AAAA" onChange={e => addEx(e.target.value, "fecha")} onChange={(d) => setDate(d)} /> */}
                    {/* <DatePicker className="form-control" selected={examen.fecha !== "" ? Date.parse(examen.fecha) : date} onChange={(d) => setDate(d)} locale="es" dateFormat="dd-MM-yyyy" /> */}
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                        <DesktopDatePicker
                            label="Fecha"
                            inputFormat="dd/MM/yyyy"
                            value={examen.fecha}
                            onChange={(e) => onChangeDate(e, "fecha")}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Ciudad:</Form.Label>
                    <Form.Control type="text" value={examen.ciudad} placeholder="Ingresar Ciudad" onChange={e => addEx(e.target.value, "ciudad")} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Dirección:</Form.Label>
                    <Form.Control type="text" value={examen.direccion} placeholder="Ingresar Dirección" onChange={e => addEx(e.target.value, "direccion")} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Laboratorio:</Form.Label>
                    <Form.Control type="text" value={examen.laboratorio} placeholder="Ingresar Laboratorio" onChange={e => addEx(e.target.value, "laboratorio")} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Codigo de reserva:</Form.Label>
                    <Form.Control type="text" value={examen.reserva} placeholder="Ingresar codigo de reserva" onChange={e => addEx(e.target.value, "reserva")} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Estado</Form.Label>
                    <Form.Select value={examen.aprobado} onChange={e => addEx(e.target.value, "aprobado")}>
                        <option value="">Seleccione estado ...</option>
                        <option value="Agendado">Agendado</option>
                        <option value="Por agendar">Por agendar</option>
                        <option value="Apto">Apto</option>
                        <option value="No Apto">No Apto</option>
                        <option value="NA">N/A</option>
                        <option value="Abandona Proceso">Abandona Proceso</option>
                        <option value="Derivado Médico">Derivado Médico</option>
                        <option value="Reagendado">Reagendado</option>
                        <option value="Desvincular">Desvincular</option>
                    </Form.Select>
                </Form.Group>
                {examen.aprobado === "No Apto" ? (
                    <Form.Group className="mb-3">
                        <Form.Label>Seleccione motivo del porque no es apto.</Form.Label>
                        <Form.Select value={rechazo.motivo} onChange={e => addRechazo(e.target.value, "motivo")}>
                            <option value="">Seleccione Motivo ...</option>
                            <option value="Glicemia">Glicemia</option>
                            <option value="IMC">IMC</option>
                            <option value="Framingham">Framingham</option>
                            <option value="otro">Otro</option>
                        </Form.Select>
                        {rechazo.motivo === "otro" ? (
                            <>
                                <Form.Label>Escriba el motivo de rechazo:</Form.Label>
                                <Form.Control as="textarea" />
                            </>
                        ) : ""}
                    </Form.Group>
                ) : ""}
                <Form.Group className="mb-3">
                    <Stack direction="horizontal">
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                            <DesktopDatePicker
                                label="Fecha Inicio"
                                inputFormat="dd/MM/yyyy"
                                value={examen.fecha_inicio ? examen.fecha_inicio : ""}
                                onChange={(e) => onChangeDate(e, "fecha_inicio")}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                            <DesktopDatePicker
                                label="Fecha Termino"
                                inputFormat="dd/MM/yyyy"
                                value={examen.fecha_termino ? examen.fecha_termino : ""}
                                onChange={(e) => onChangeDate(e, "fecha_termino")}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Stack>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Archivo</Form.Label>
                    {/* <Form.Control type="file" onChange={e => addEx(e.target.files[0], "archivo")}></Form.Control> */}
                    <section className="container">
                        <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            <p>Arrastre sus archivos aqui, o haga clic para seleccionarlos</p>
                        </div>
                        <aside>
                            <h4>Archivos a Cargar</h4>
                            <ul>{files}</ul>
                        </aside>
                    </section>
                    <Button variant="outline-success" disabled={loadB} onClick={!loadB ? () => upLoadF(tra.rut, examen.nombre, perfil) : null}>{loadB ? <BeatLoader color="green" /> : "Subir"}</Button>
                </Form.Group>
                <div className="mt-2"></div>
                <Button variant="secondary" onClick={() => editEx(examen.id, perfil)}>Actualizar</Button>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    )
};

const initialRechazo = {
    motivo: "",
    otro: "",
    rut_trabajador: ""
}

const initialExamen = {
    nombre: "",
    fecha: "",
    hora: "",
    reserva: "",
    aprobado: "",
    ciudad: "",
    direccion: "",
    laboratorio: "",
    trabajadorId: "",
    archivo: "",
    ruta: "",
    fecha_inicio: "",
    fecha_termino: ""
}

const initialCurso = {
    nombre: "",
    fecha: "",
    hora: "",
    reserva: "",
    aprobado: "",
    ciudad: "",
    direccion: "",
    laboratorio: "",
    trabajadorId: "",
    archivo: "",
    ruta: "",
    oc: ""
}

const initialPerfil = {
    reclutador: false,
    contratador: false,
    aprobador: false,
    acreditador: false,
    administrador: false,
    curso_especial: false
}

const cantidad_trabajadores = {
    reclutador: 0,
    contratador: 0,
    aprobador: 0,
    acreditador: 0,
    administrador: 0,
    ingresos_hoy_reclutador: 0
}

const modificarPerfil = {
    valor: "",
}

const initialTresd = {
    hora_taxi: "",
    desde: "",
    hasta: "",
    direccion_hotel: "",
    dia: ""

}

const estados = [];

const ListaTrabajadores = () => {
    const [trabajadores, setTrabajadores] = useState([]);
    const [table, setTable] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filtroAprobado, setFiltroAprobado] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [examen, setExamen] = useState(initialExamen);
    const [starDate, setStarDate] = useState(new Date());
    const [loadingButton, setLoadingButton] = useState(false);
    const [trabajador, setTrabajador] = useState('');
    const [ruts, setRut] = useState('');
    const navigate = useNavigate();
    const [ssid, setSsid] = useState('');
    const [usuario, setUsuario] = useState(null);
    //const { getSession, logout, status } = useContext(AccountContext);
    const [rechazo, setRechazo] = useState(initialRechazo);
    const [perfil, setPerfil] = useState(initialPerfil);
    const [tableContratador, setTableContratador] = useState([]);
    const [tableAcreditador, setTableAcreditador] = useState([]);
    const [tableAprobador, setTableAprobador] = useState([]);
    const [tableCursos, setTableCursos] = useState([]);
    const [cantidadT, setCantidadT] = useState(cantidad_trabajadores);
    const [statusp, setStatusp] = useState('');
    const [modalShowM, setModalShowM] = useState(false);
    const [bloqueo, setBloqueo] = useState(false);
    const [motivo, setMotivo] = useState('');
    const [idTrabajador, setIdTrabajador] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [fechainicio, setFechaInicio] = useState('');
    const [fechatermmino, setFechaTermino] = useState('');
    const [fechaHoy, setFechaHoy] = useState(new Date());
    const [examenFiltro, setExamaenFiltro] = useState('');
    const { isShowing, toggle } = useModal();
    const [curso, setCurso] = useState(initialCurso);
    const [fechaCurso, setFechaCurso] = useState(new Date());
    const [tresD, setTresD] = useState(initialTresd);
    const [cursosEspeciales, setCursosEspeciales] = useState([]);
    const [maestroExamenes, setMaestroExamenes] = useState([]);
    //Filtros
    const [estadoExamenFiltro, setEstadoExamenFiltro] = useState('');
    const [cursoFiltro, setCursoFiltro] = useState('');
    const [estadoCursoFiltro, setEstadoCursoFiltro] = useState('');
    const [tresdFiltro, setTresdFiltro] = useState('');
    const [examenesFiltro, setExamanesFiltro] = useState([]);
    const [token, setToken] = useState('');
    const [report, setReport] = useState('');
    const [loadingPerfil, setLoadingPerfil] = useState(false);
    const [rutBusqueda, setRutBusqueda] = useState('');
    const auth = useAuth();
    LoadingOverlay.propTypes = undefined;
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone(
        {
            accept: {
                'application/pdf': [],
            }
        }
    );
    const globalTheme = useTheme();

    if (statusp === "reclutador") {
        estados.splice(0, estados.length);
        estados.push('Abandona Proceso', 'En Proceso');
    }
    if (statusp === "acreditador") {
        estados.splice(0, estados.length);
        estados.push('Listo para acreditar', 'Carpeta enviada', 'Carpeta rechazada', 'Acreditado con credencial', 'Acreditado sin credencial', 'Acceso con credencial', 'Acceso sin credencial', 'En Faena', 'Desvincular', 'Renuncia Voluntaria', 'Trabajador con problema en PMF', 'Contratado');
    }
    if (statusp === "administrador") {
        estados.splice(0, estados.length);
        estados.push('Abandona Proceso', 'En Proceso', 'Desvincular', 'Contratado');
    }

    /* useEffect(()=> {
        retreiveTrabajadores();
    },[]); */

    useEffect(async () => {
        //localStorage.removeItem('tra');
        //retreiveTrabajadores();
        //const t = await rtrabj();

        /* getSession().then(async (session) => {
            const ssids = session.idToken.payload.sub;
            let u = await usuarioService.getBySSID(ssids);
            setUsuario(u.data[0]);
            //retreiveTrabajadores();
            retreivePerfiles(u.data[0]);

        }); */
        console.log(auth);
        setUsuario(auth.user);
        retreivePerfiles(auth.user);
        retreiveCursosEspeciales();
        retreiveMaestroExamenes();
    }, []);

    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    function getPerfilesAndTrabajadores() {
        return Promise.all([retreivePerfiles(usuario), retreiveTrabajadores()])
    }

    const retreiveTrabajadores = () => {
        setLoading(true);
        trabajadorService.getAll()
            .then(data => {
                console.log("TRABAJADORES", data.data);
                setTrabajadores(data.data);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
                toast.error("Error en la conexión", {
                    position: "top-center",
                    autoClose: 3000
                });
            });
    }

    const rtrabj = async () => {
        const data = await trabajadorService.getAll();
        return data.data;
    }

    async function getTokenB() {
        let t = await tokenService.getTokenB();
        //console.log(t.data);
        setToken(t.data.accessToken);
    }

    /** Funcion que cargar los maestros de examenes */
    function retreiveMaestroExamenes() {
        maestroExamenService.getAll()
            .then(resp => {
                setMaestroExamenes(resp.data);
            })
            .catch(err => {
                console.error(err);
            });
    }

    const handleChangeMaestroExamen = (event) => {
        const {
            target: { value },
        } = event;
        setExamanesFiltro(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    /** Funcion para crear PDF a partir de un WORD */
    const docToPdf = async (id_trabajador) => {
        const tr = await trabajadorService.getByID(id_trabajador);//trabajadores.find(v => v.id === id_trabajador);
        if (tr) {
            var documentos = tr.data.documentos;
            const doc_dir = documentos.find(v => v.id === 2);
            if (doc_dir) {
                /* const pathOut = await convertWordFiles(doc_dir.ruta, 'pdf', 'data');
                console.log(pathOut); */
                convertService.convertFile({})
                    .then(async resp => {
                        console.log(resp);
                        //Subimos el archivo PDF obtenido de Base64
                        var bufferArray = base64ToArrayBuffer(resp.data);
                        var blobStore = new Blob([bufferArray], { type: "application/pdf" });
                        var dir = tr.data.rut.replace(/\./g, '');
                        const config = {
                            bucketName: 'csibucket',
                            dirName: dir,
                            acl: 'public-read',
                            //dirName: 'photos', /* optional */
                            region: 'sa-east-1',
                            accessKeyId: 'AKIA47CRWEBTLXZ6R5JJ',
                            secretAccessKey: '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',
                        }
                        //Creamos al cliente S3
                        const ReactS3Client = new S3(config);
                        window.Buffer = window.Buffer || require("buffer").Buffer;
                        let docu = await ReactS3Client.uploadFile(blobStore, "LUIS_CONTRATO.pdf");
                        if (docu) {
                            console.log(docu);
                        }
                    })
                    .catch(err => {
                        console.error(err);
                    });

            }
        }
    }

    /** Funcion para los cursos especiales */
    function retreiveCursosEspeciales() {
        cursoespecialService.getAll()
            .then(resp => {
                setCursosEspeciales(resp.data);
            })
            .catch(err => {
                console.error(err);
            });
    }

    /** Funciones para los 3D´s */
    const handleChange3D = (valor, nombre) => {
        setTresD({ ...tresD, [nombre]: valor });
    }
    const onChangeFechaInicio = (newvalue) => {
        setFechaInicio(newvalue);
    }

    const onChangeFechaTermino = (newvalue) => {
        setFechaTermino(newvalue);
    }

    const wait = (timeout) => {
        return new Promise(resolve => setTimeout(resolve, timeout));
    }

    /* const onRefresh = useCallback(() => {
        setRefresh(true);
        wait(2000).then(() => setRefresh(false));
    }, []); */

    const onLoad = () => {
        /* getSession().then((session) => {
        })
            .catch(err => {
                console.error(err);
                navigate("/login");
            }); */
    }

    function perfilContratador(contratador, trabaj) {
        if (contratador) {
            initialPerfil.contratador = true;
            setPerfil({ ...perfil, ['contratador']: true });
            colsXContratdor(trabaj);
        }
    }

    async function retreivePerfiles(u) {
        console.log("Usuario", u);
        setLoading(true);
        var aprobador = u.perfiles.find(v => v.nombre === "Aprobador");
        var reclutador = u.perfiles.find(v => v.nombre === "Reclutador");
        var aprobacion = u.perfiles.find(v => v.nombre === "Aprobacion");
        var contratador = u.perfiles.find(v => v.nombre === "Contratador");
        var acreditador = u.perfiles.find(v => v.nombre === "Acreditador");
        var administrador = u.perfiles.find(v => v.nombre === "Administrador");
        var curso_especial = u.perfiles.find(v => v.nombre === "Curso_Especial");

        const trab = []; //await trabajadorService.getAll();
        //setTrabajadores(trab.data);
        console.log("Trab", trab);

        /* if (contratador) {
            perfilContratador(contratador, trab);
        } */

        /* if (aprobador) {
            setPerfil({ ...perfil, ['aprobador']: true });
            initialPerfil.aprobador = true;
        } */

        if (reclutador) {
            setPerfil({ ...perfil, ['reclutador']: true });
            initialPerfil.reclutador = true;
            let trab_by_user = await trabajadorService.getByUser(u.id);
            colsUser(u.id, trab_by_user);
        }

        /* if (acreditador) {
            setPerfil({ ...perfil, ['acreditador']: true });
            initialPerfil.acreditador = true;
            colsXAcreditador(trab);
        } */

        if (administrador) {
            setPerfil({ ...perfil, ['administrador']: true });
            initialPerfil.administrador = true;
        }

        /* if (curso_especial) {
            setPerfil({ ...perfil, ['curso_especial']: true });
            initialPerfil.curso_especial = true;
        } */
        if (aprobador || curso_especial || administrador) {
            cols2(trab);
        }
        setLoading(false);
    }

    function retreiveUsuario(ssid) {
        usuarioService.getBySSID(ssid)
            .then(user => {
                var u = user.data[0];
                //setUsuario(u);
                var aprobador = u.perfiles.find(v => v.nombre === "Aprobador");
                var reclutador = u.perfiles.find(v => v.nombre === "Reclutador");
                var contratador = u.perfiles.find(v => v.nombre === "Contratador");
                switch (reclutador.nombre) {
                    case "Reclutador":
                        retreiveTrabajadoresUsuario(u.id);
                        colsUser(u.id);
                        break;
                }
                switch (aprobador.nombre) {
                    case "Aprobador":
                        //retreiveTrabajadores();
                        cols2();
                        break;
                }
                switch (contratador.nombre) {
                    case "Contratador":
                        let lista_t = [];
                        trabajadorService.getAll()
                            .then(t => {
                                t.data.map(tr => {
                                    if (tr.estado === "Listo para contratar" || tr.estado === "Contratado") {
                                        lista_t.push(tr);
                                    }
                                })
                                setTrabajadores(lista_t);
                                setLoading(false);
                            })
                            .catch(err => {
                                console.error(err);
                            });
                        break;
                }
                /* if(u.perfiles.find(v=>v.nombre === "Reclutador")) {
                    retreiveTrabajadoresUsuario(u.id);
                    colsUser(u.id);
                } else {
                    retreiveTrabajadores();
                    cols2();
                } */
            })
            .catch(err => {
                console.error(err);
            });
    }

    const retreiveTrabajadoresUsuario = (id_usuario) => {
        setLoading(true);
        trabajadorService.getByUser(id_usuario)
            .then(data => {
                setTrabajadores(data.data);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
                toast.error("Error en la conexión", {
                    position: "top-center",
                    autoClose: 3000
                });
            });
    }

    const retreiveTrabajadoresEstado = (estado) => {
        setLoading(true);
        trabajadorService.getByEstado(estado)
            .then(data => {
                setTrabajadores(data.data);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
                toast.error("Error en la conexión", {
                    position: "top-center",
                    autoClose: 3000
                });
            });
    }

    const isExpandableRow = (row) => {
        if (row.id != 0) return true;
        else return false;
    }

    const updateStatusDocumento = async (id, valor) => {
        setLoading(true);
        const data = {
            aprobado: valor
        }
        if (valor !== "Seleccione Status...") {
            try {
                let docupdate = await documentoService.update(id, data);
                if (docupdate) {
                    //document.getElementById(id).value = valor;
                    cols();
                    toast.info("Actulizado Correctamente!!", {
                        position: "top-center",
                        autoClose: 5000
                    });
                } else {
                    toast.error("No se ha cargado ningún archivo aún", {
                        position: "top-center",
                        autoClose: 5000
                    });
                    setLoading(false);
                    return;
                }
            } catch (err) {
                console.error(err);
                toast.error("No se ha cargado ningún archivo aún", {
                    position: "top-center",
                    autoClose: 5000
                });
                setLoading(false);
            }
        }
        setLoading(false);
    }

    const handleChangeExamen = (valor, nombre) => {
        setExamen({ ...examen, [nombre]: valor });
    }

    const handleChangeRechazo = (valor, nombre) => {
        setRechazo({ ...rechazo, [nombre]: valor });
    }

    const editExamen = (id, perfil) => {
        console.log("Examen", examen);
        setLoading(true);
        if (examen.aprobado === "No Apto") {
            //Actualizamos el rechazo
            if (rechazo.motivo === "") {
                alert("Debe seleccionar un motivo");
                setLoading(false);
                setModalShow(false);
                return;
            } else {
                rechazo.rut_trabajador = trabajador.rut;
                rechazoexamenService.create(rechazo)
                    .then(r => {
                        console.log(r);
                        toast.info("Motivo ingresado correctamente", {
                            position: "top-left",
                            autoClose: 1000
                        });
                        setLoading(false);
                        setModalShow(false);
                    })
                    .catch(err => {
                        console.error(err);
                        setLoading(false);
                        setModalShow(false);
                    });
            }
        }

        if (examen.aprobado == "Apto") {
            if (!acceptedFiles) {
                alert("Debe subir el archivo primero!");
                setLoading(false);
                //setModalShow(false);
                return;
            }
        }
        if (examen.aprobado == "Apto") {
            if (acceptedFiles && examen.fecha_termino == "") {
                alert("Falta ingresar la Fecha de Termino");
                setLoading(false);
                setModalShow(false);
                return;
            }
            if (acceptedFiles && examen.fecha_inicio == "") {
                alert("Falta ingresar la Fecha de Inicio");
                setLoading(false);
                setModalShow(false);
                return;
            }
            if (!examen.fecha_inicio || !examen.fecha_termino) {
                alert("Debe ingresar las fechas de Termino e Inicio");
                setLoading(false);
                //setModalShow(false);
                return;
            }
        }

        //examen.fecha = starDate;
        examenService.update(id, examen)
            .then(e => {
                //setExamen(e.data);
                cambioAcontratar(perfil);
                cambiarEstado(perfil);
                actualizaSegunPerfil(perfil);//cols2();
                /* const indextr = trabajadores.findIndex(v=>v.rut === trabajador.rut);
                console.log("Trabajador", trabajadores[indextr]);
                const indexExamen = trabajadores[indextr].examenes.findIndex(v=>v.id === id);
                console.log("Examen", trabajadores[indextr].examenes[indexExamen]);
                trabajadores[indextr].examenes[indexExamen] = e.data;
                setTrabajador([...trabajadores]); */
                bitacoraService.create({ fecha: new Date(), accion: `Se actualizo el exámen ${examen.nombre} del trabajador Rut ${trabajador.rut}`, usuarioId: usuario.id })
                    .then(resp => {
                        console.log(resp);
                    })
                    .catch(err => {
                        console.error(err);
                        setLoading(false);
                    });
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
        //cambioAcontratar();
        //setLoading(false);
        setModalShow(false);
    };

    function updateEstadoTrabajador(id_trabajador, nombre_estado) {
        trabajadorService.updateTrabajador(id_trabajador, { estado: nombre_estado })
            .then(tr => {
                toast.info("Trabajador paso a estado: "+nombre_estado, {
                    position: "top-center",
                    autoClose: 5000
                });
                registroEstado(nombre_estado, new Date(), trabajador.id, usuario.id);
                setLoadingPerfil(false);
                actualizaSegunPerfil(perfil);
            })
            .catch(err => {
                console.error(err);
            });
    }

    async function cambiarEstado(perfil) {
        setLoading(true);
        let t = await trabajadorService.getByID(trabajador.id);
        var exs = t.data.examenes;
        var docs = t.data.documentos;
        var con_examen = exs.findIndex(v => v.nombre === "EXÁMEN PREOCUPACIONAL" & (v.aprobado === "Apto" || v.aprobado === "NA"));
        var con_docs = exs.findIndex(v=> (v.nombre === "CERO DAÑO" & v.aprobado === "Apto") || (v.nombre === "INDUCCION HOMBRE NUEVO" & v.aprobado === "Apto"));
        if (con_examen >= 0 && con_docs >= 0 && trabajador.tresd === true) {
            updateEstadoTrabajador(trabajador.id, "Listo para 3D");
        } else if (con_examen >= 0 && con_docs >= 0 && trabajador.tresd === false) {
            updateEstadoTrabajador(trabajador.id, "Listo para Contratar");
        } else if (con_examen >= 0) {
            updateEstadoTrabajador(trabajador.id, "Exámenes Cargados");
        } else if (con_docs >= 0) {
            updateEstadoTrabajador(trabajador.id, "Curso Inducción Cargado")
        }
        actualizaSegunPerfil(perfil);
        setLoading(false);
    }

    async function cambioAcontratar(perfil) {
        setLoadingPerfil(true);
        let t = await trabajadorService.getByID(trabajador.id);
        var exs = t.data.examenes;
        var docs = t.data.documentos;
        var con = 0;
        for (var i = 0; i < exs.length; i++) {
            /* if (exs[i].nombre === "ALTURA GEOGRÁFICA" & (exs[i].aprobado === "Apto" || exs[i].aprobado === "NA")) {
                con++;
            }
            if (exs[i].nombre === "ALTURA FÍSICA" & (exs[i].aprobado === "Apto" || exs[i].aprobado === "NA")) {
                con++;
            }
            if (exs[i].nombre === "ALCOHOL Y DROGAS" & exs[i].aprobado === "Apto") {
                con++;
            } */
            if (exs[i].nombre === "EXÁMEN PREOCUPACIONAL" & (exs[i].aprobado === "Apto" || exs[i].aprobado === "NA")) {
                con++;
            }
            if ((exs[i].nombre === "CERO DAÑO" & exs[i].aprobado === "Apto") || (exs[i].nombre === "INDUCCION HOMBRE NUEVO" & exs[i].aprobado === "Apto")) {
                con++;
            }
        }
        var doc = docs.find(v => v.nombre === "contrato");
        if (doc) {
            actualizaSegunPerfil();
            //return;
        }
        if (con >= 2) {
            //Actualizamos estado de trabajador.
            if (t.data.tresd) {
                trabajadorService.updateTrabajador(trabajador.id, { estado: "Listo para 3D" })
                    .then(tr => {
                        toast.info("Trabajador paso a estado: Listo para 3D", {
                            position: "top-center",
                            autoClose: 5000
                        });
                        registroEstado("Listo para 3D", new Date(), trabajador.id, usuario.id);
                        setLoadingPerfil(false);
                        actualizaSegunPerfil(perfil);
                    })
                    .catch(err => {
                        console.error(err);
                    });
            } else {
                trabajadorService.updateTrabajador(trabajador.id, { estado: "Listo para contratar" })
                    .then(tr => {
                        toast.info("Trabajador paso a estado: Listo para contratar", {
                            position: "top-center",
                            autoClose: 5000
                        });
                        registroEstado("Listo para contratar", new Date(), trabajador.id, usuario.id);
                        setLoadingPerfil(false);
                        actualizaSegunPerfil(perfil);
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }
        } else {
            if (examen.aprobado === "No Apto") {
                trabajadorService.updateTrabajador(trabajador.id, { estado: "No Apto" })
                    .then(tr => {
                        toast.info("Trabajador paso a estado: No Apto", {
                            position: "top-center",
                            autoClose: 5000
                        });
                        setLoadingPerfil(false);
                        actualizaSegunPerfil(perfil);
                    })
                    .catch(err => {
                        console.error(err);
                    });
            } else {
                trabajadorService.updateTrabajador(trabajador.id, { estado: "En Proceso" })
                    .then(tr => {
                        toast.info("Trabajador paso a estado: En Proceso", {
                            position: "top-center",
                            autoClose: 5000
                        });
                        setLoadingPerfil(false);
                        actualizaSegunPerfil(perfil);
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }
        }
        setLoadingPerfil(false);
        actualizaSegunPerfil(perfil);
    }

    async function actualizaSegunPerfil(perfil) {
        console.log("Usuario ID", usuario.id);
        setLoadingPerfil(true);
        const trabj = [];//await trabajadorService.getAll();
        if (initialPerfil.reclutador && perfil === "reclutador") { //initialPerfil.reclutador && perfil === "reclutador"
            let trab_by_user = await trabajadorService.getByUser(usuario.id);
            colsUser(usuario.id, trab_by_user);
        }
        if (initialPerfil.aprobador && perfil === "aprobador") {
            cols2(trabj);
        }
        if (initialPerfil.contratador && perfil === "contratador") {
            colsXContratdor(trabj);
        }
        if (initialPerfil.administrador && perfil === "administrador") {
            cols2(trabj);
        }
        if (initialPerfil.administrador && perfil === "acreditador") {
            colsXAcreditador(trabj);
        }
        if (initialPerfil.curso_especial && perfil === "curso_especial") {
            cols2(trabj);//colsXEstado("Listo para 3D");
        }
        setLoadingPerfil(false);
    }

    async function verificarEstado(id_trabajador) {
        var contrata_geo = false;
        var contrata_fis = false;
        var contrata_ex_pre = false;
        var contrata_cero = false;
        var contrata_alydro = false;
        let t = trabajadorService.getByID(id_trabajador);
        if (t) {
            var ex = t.data.examenes;
            var alt_geo = ex.find(v => v.nombre === "ALTURA GEOGRÁFICA");
            var alt_fis = ex.find(v => v.nombre === "ALTURA FÍSICA");
            var ex_pre = ex.find(v => v.nombre === "EXAMEN PREOCUPACIONAL");
            var cero = ex.find(v => v.nombre === "CERO DAÑO" || v.nombre === "INDUCCION HOMBRE NUEVO");
            var alydro = ex.find(v => v.nombre === "ALCOHOL Y DROGAS");

            if (alt_geo) {
                if (alt_geo.estado === "Apto") {
                    contrata_geo = true;
                }
            }
            if (alt_fis) {
                if (alt_fis.estado === "Apto") {
                    contrata_fis = true;
                }
            }
            if (ex_pre) {
                if (ex_pre.estado === "Apto") {
                    contrata_ex_pre = true;
                }
            }
            if (cero) {
                if (cero.estado === "Apto") {
                    contrata_cero = true;
                }
            }
            if (alydro) {
                if (alydro.estado === "Apto") {
                    contrata_alydro = true;
                }
            }
            if (contrata_geo & contrata_fis & contrata_ex_pre & contrata_cero & contrata_alydro) {
                //Cambiamos el estado del trabajador a Listo para contratar
            }

        }
    }

    const showEditExamen = (id, id_trabajador) => {
        setLoading(true);
        //Obtenemos el trabajador
        trabajadorService.getByID(id_trabajador)
            .then(t => {
                setTrabajador(t.data);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
            });
        //Obtenemos el examen
        examenService.get(id)
            .then(e => {
                setExamen(e.data);
                setModalShow(true);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    }

    function buttonFormatter(cell, row) {
        return <Button variant="secondary" onClick={() => showEditExamen(row.id, row.trabajadorId)}><FontAwesomeIcon icon="fa-solid fa-pen-to-square" /></Button>;
    }

    function onRowMouseOver(row) {
        <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip id="button-tooltip">El anticipo corresponde al 40% de su liquido pactado</Tooltip>}>
            <span>row.nombre</span>
        </OverlayTrigger>
    }

    function formatterNombre(cell, row) {
        return (
            <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="button-tooltip">{row.nombre}</Tooltip>}>
                <span>{row.nombre}</span>
            </OverlayTrigger>
        )

    }

    function formatterCargo(cell, row) {
        return (
            <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="button-tooltip">{row.cargo}</Tooltip>}>
                <span>{row.cargo}</span>
            </OverlayTrigger>
        )

    }

    function formatterCC(cell, row) {
        return (
            <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="button-tooltip">{row.centro_costo}</Tooltip>}>
                <span>{row.centro_costo}</span>
            </OverlayTrigger>
        )

    }

    function formatterEstado(cell, row) {
        return (
            <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="button-tooltip">{row.estado}</Tooltip>}>
                <span>{row.estado}</span>
            </OverlayTrigger>
        )

    }

    const optionsTableExamenes = {
        //onRowMouseOver: onRowMouseOver
        noDataText: 'Sin Datos'
    };

    const uploadFileExamen = async (rut, nombre_examen, pperfil) => {
        window.Buffer = window.Buffer || require("buffer").Buffer;
        if (files.length === 0) {
            alert("No se ha cargado ningún archivo");
            return;
        } else {
            if (acceptedFiles.length >= 1) {
                const mergedPdf = await PDFDocument.create();
                for (var i = 0; i < acceptedFiles.length; i++) {
                    console.log(acceptedFiles[i]);
                    const temp = await readFileDataAsBase64(acceptedFiles[i]);
                    console.log(temp);
                    const pdfAprueba = await PDFDocument.load(temp);
                    const copiedPagesB = await mergedPdf.copyPages(pdfAprueba, pdfAprueba.getPageIndices());
                    copiedPagesB.forEach((page) => mergedPdf.addPage(page));
                }
                const mergedPdfFile2 = await mergedPdf.save();

                const bytes = new Uint8Array(mergedPdfFile2);
                const blob2 = new Blob([bytes], { type: "application/pdf" });
                const docUrl = URL.createObjectURL(blob2);
                //window.open(docUrl, "_blank");

                if (!docUrl) {
                    alert("Problemas al crear archivo PDF");
                    return;
                }
                setLoadingButton(true);
                var trabajador = null;
                let docu = null;
                var dir = rut.replace(/\./g, '');
                const config = {
                    bucketName: 'csibucket',
                    dirName: dir,
                    acl: 'public-read',
                    //dirName: 'photos', /* optional */
                    region: 'sa-east-1',
                    accessKeyId: 'AKIA47CRWEBTLXZ6R5JJ',
                    secretAccessKey: '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',
                }
                //Creamos al cliente S3
                const ReactS3Client = new S3(config);
                //window.Buffer = window.Buffer || require("buffer").Buffer;
                setExamen({ ...examen, ['archivo']: blob2 });
                var extension = "pdf";//examen.archivo.name.split('.').pop();

                //Obtenemos al trabajador
                trabajador = await trabajadorService.buscarPorRut(rut);
                if (trabajador) {
                    //Si trabajador existe creamos el nombre del documento
                    var trab = trabajador.data[0];
                    var nombre_documento = trab.nombre + "_" + nombre_examen + "." + extension;
                    //Subimos el documento
                    docu = await ReactS3Client.uploadFile(blob2, nombre_documento);
                    if (docu) {
                        //Actualizamos la ruta
                        let e = await examenService.update(examen.id, { ruta: docu.location });
                        if (e) {
                            setExamen({ ...examen, ['ruta']: docu.location });
                            //cols2();
                            bitacoraService.create({ fecha: new Date(), accion: `Se subio el Exámen ${examen.nombre} del Trabajdor ${trab.rut}`, usuarioId: usuario.id })
                                .then(resp => {
                                    console.log(resp);
                                })
                                .catch(err => {
                                    console.error(err);
                                });
                            //actualizaSegunPerfil(pperfil);
                            toast.info("Documento subido correctamente!", {
                                position: "top-center",
                                autoClose: 2000
                            });
                        }
                    }
                }
                setLoadingButton(false);
            }
        }

    }

    const expandComponent = (row) => {
        var docs = row.documentos;
        var exas = row.examenes;
        var carnet = row.documentos.find(v => v.nombre === "carnet") ? row.documentos.find(v => v.nombre === "carnet") : "";
        var antecedente = docs.find(v => v.nombre === "antecedente") ? docs.find(v => v.nombre === "antecedente") : "";
        var contrato = docs.find(v => v.nombre === "contrato") ? docs.find(v => v.nombre === "contrato") : "";
        var afp = row.documentos.find(v => v.nombre === "afp") ? row.documentos.find(v => v.nombre === "afp") : "";
        var salud = row.documentos.find(v => v.nombre === "salud") ? row.documentos.find(v => v.nombre === "salud") : "";
        var afc = row.documentos.find(v => v.nombre === "afc") ? row.documentos.find(v => v.nombre === "afc") : "";
        var estudio = row.documentos.find(v => v.nombre === "estudio") ? row.documentos.find(v => v.nombre === "estudio") : "";
        var finiquito = row.documentos.find(v => v.nombre === "finiquito") ? row.documentos.find(v => v.nombre === "finiquito") : "";
        var cv = row.documentos.find(v => v.nombre === "cv") ? row.documentos.find(v => v.nombre === "cv") : "";
        var foto = row.documentos.find(v => v.nombre === "foto") ? row.documentos.find(v => v.nombre === "foto") : "";
        var pase = row.documentos.find(v => v.nombre === "pase") ? row.documentos.find(v => v.nombre === "pase") : "";
        var ficha = row.documentos.find(v => v.nombre === "ficha") ? row.documentos.find(v => v.nombre === "ficha") : "";
        var transporte_mel = row.documentos.find(v => v.nombre === "transporte_mel") ? row.documentos.find(v => v.nombre === "transporte_mel") : "";
        var extravio_credencial = row.documentos.find(v => v.nombre === "extravio_credencial") ? row.documentos.find(v => v.nombre === "extravio_credencial") : "";
        var licencia_conducir = row.documentos.find(v => v.nombre === "licencia_conducir") ? row.documentos.find(v => v.nombre === "licencia_conducir") : "";
        var hoja_vida_conductor = row.documentos.find(v => v.nombre === "hoja_vida_conductor") ? row.documentos.find(v => v.nombre === "hoja_vida_conductor") : "";
        var conduccion = row.conduccion;
        var trabajo_escondida = row.trabajo_escondida;
        //cargarTrabajador(rut);

        return (
            <div>
                <Row>
                    <h6><strong>Nombre: </strong>{row.nombre}</h6>
                    <h6><strong>Estado: </strong>{row.estado}</h6>
                    <h6><strong>Unidad de Negocio: </strong>{row.centro_costo}</h6>
                    <h6><strong>Cargo: </strong>{row.cargo}</h6>
                </Row>
                <Row className="mt-3">
                    <Col></Col>
                    <Col sm={8}>
                        <h4 className="text-center">Documentos Acreditación</h4>
                        <Stack direction="horizontal" gap={4} className="border border-dark p-1">
                            <Form.Label>Carnet CI Frontal:</Form.Label>
                            <div className="ms-auto">{row.documentos.find(v => v.nombre === "carnet_frontal") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                            <div className="ms-auto">{row.documentos.find(v => v.nombre === "carnet_frontal") ? <a href={docs.find(v => v.nombre === "carnet_frontal").ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                            {/* {usuario.perfiles.find(v => v.nombre === "Aprobador" || v.nombre === "Acreditador") && (<div className="m-auto">Status: <Form.Select id={carnet.id} value={docs.find(v => v.nombre === "carnet") ? docs.find(v => v.nombre === "carnet").aprobado : ""} onChange={(e) => updateStatusDocumento(carnet.id, e.target.value)}><option>Seleccione Status...</option><option value="Aprobado">Aprobado</option><option value="Reprobado">Reprobado</option></Form.Select></div>)} */}
                        </Stack>
                        <Stack direction="horizontal" gap={4} className="border border-dark p-1">
                            <Form.Label>Carnet CI Atrás:</Form.Label>
                            <div className="ms-auto">{row.documentos.find(v => v.nombre === "carnet_atras") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                            <div className="ms-auto">{row.documentos.find(v => v.nombre === "carnet_atras") ? <a href={docs.find(v => v.nombre === "carnet_atras").ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                            {/* {usuario.perfiles.find(v => v.nombre === "Aprobador" || v.nombre === "Acreditador") && (<div className="m-auto">Status: <Form.Select id={carnet.id} value={docs.find(v => v.nombre === "carnet") ? docs.find(v => v.nombre === "carnet").aprobado : ""} onChange={(e) => updateStatusDocumento(carnet.id, e.target.value)}><option>Seleccione Status...</option><option value="Aprobado">Aprobado</option><option value="Reprobado">Reprobado</option></Form.Select></div>)} */}
                        </Stack>
                        <Stack direction="horizontal" gap={4} className="border border-dark p-1">
                            <Form.Label>Antecedente:</Form.Label>
                            <div className="ms-auto">{row.documentos.find(v => v.nombre === "antecedente") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                            <div className="ms-auto">{row.documentos.find(v => v.nombre === "antecedente") ? <a href={docs.find(v => v.nombre === "antecedente").ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                            {/* {usuario.perfiles.find(v => v.nombre === "Aprobador" || v.nombre === "Acreditador") && (<div className="m-auto">Status: <Form.Select id={antecedente.id} value={docs.find(v => v.nombre === "antecedente") ? docs.find(v => v.nombre === "antecedente").aprobado : ""} onChange={(e) => updateStatusDocumento(antecedente.id, e.target.value)}><option>Seleccione Status...</option><option value="Aprobado">Aprobado</option><option value="Reprobado">Reprobado</option></Form.Select></div>)} */}
                        </Stack>
                        <Stack direction="horizontal" gap={4} className="border border-dark p-1">
                            <Form.Label>Contrato:</Form.Label>
                            <div className="ms-auto">{row.documentos.find(v => v.nombre === "contrato") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                            <div className="ms-auto">{row.documentos.find(v => v.nombre === "contrato") ? <a href={docs.find(v => v.nombre === "contrato").ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                            {/* {usuario.perfiles.find(v => v.nombre === "Aprobador" || v.nombre === "Acreditador") && (<div className="m-auto">Status: <Form.Select id={contrato.id} value={docs.find(v => v.nombre === "contrato") ? docs.find(v => v.nombre === "contrato").aprobado : ""} onChange={(e) => updateStatusDocumento(contrato.id, e.target.value)}><option>Seleccione Status...</option><option value="Aprobado">Aprobado</option><option value="Reprobado">Reprobado</option></Form.Select></div>)} */}
                        </Stack>
                        {trabajo_escondida && (
                            <Stack direction="horizontal" gap={4} className="border border-dark p-1">
                                <Form.Label>Finiquito:</Form.Label>
                                <div className="ms-auto">{finiquito !== "" ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                <div className="ms-auto">{finiquito !== "" ? <a href={finiquito.ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                {/* {usuario.perfiles.find(v => v.nombre === "Aprobador" || v.nombre === "Acreditador") && (<div className="m-auto">Status: <Form.Select id={finiquito.id} value={finiquito.aprobado} onChange={(e) => updateStatusDocumento(finiquito.id, e.target.value)}><option>Seleccione Status...</option><option value="Aprobado">Aprobado</option><option value="Reprobado">Reprobado</option></Form.Select></div>)} */}
                            </Stack>
                        )}
                        <Stack direction="horizontal" gap={4} className="border border-dark p-1">
                            <Form.Label>Cero Daño:</Form.Label>
                            <div className="ms-auto">{row.examenes.find(v => (v.nombre === "CERO DAÑO" || v.nombre === "INDUCCION HOMBRE NUEVO") && v.aprobado === "Apto") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                            <div className="ms-auto">{row.examenes.find(v => v.nombre === "CERO DAÑO" || v.nombre === "INDUCCION HOMBRE NUEVO") ? <a href={exas.find(v => v.nombre === "CERO DAÑO" || v.nombre === "INDUCCION HOMBRE NUEVO").ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                            {/* {usuario.perfiles.find(v => v.nombre === "Aprobador" || v.nombre === "Acreditador") && (<div className="m-auto">Status: <Form.Select id={antecedente.id} value={docs.find(v => v.nombre === "contrato") ? docs.find(v => v.nombre === "contrato").aprobado : ""} onChange={(e) => updateStatusDocumento(antecedente.id, e.target.value)}><option>Seleccione Status...</option><option value="Aprobado">Aprobado</option><option value="Reprobado">Reprobado</option></Form.Select></div>)} */}
                        </Stack>
                        <Stack direction="horizontal" gap={4} className="border border-dark p-1">
                            <Form.Label>Examen Preocupacional:</Form.Label>
                            <div className="ms-auto">{row.examenes.find(v => v.nombre === "EXÁMEN PREOCUPACIONAL" && v.aprobado === "Apto") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                            <div className="ms-auto">{row.examenes.find(v => v.nombre === "EXÁMEN PREOCUPACIONAL") ? <a href={exas.find(v => v.nombre === "EXÁMEN PREOCUPACIONAL").ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                            {/* {usuario.perfiles.find(v => v.nombre === "Aprobador" || v.nombre === "Acreditador") && (<div className="m-auto">Status: <Form.Select id={antecedente.id} value={docs.find(v => v.nombre === "contrato") ? docs.find(v => v.nombre === "contrato").aprobado : ""} onChange={(e) => updateStatusDocumento(antecedente.id, e.target.value)}><option>Seleccione Status...</option><option value="Aprobado">Aprobado</option><option value="Reprobado">Reprobado</option></Form.Select></div>)} */}
                        </Stack>
                        {row.examenes.find(v => v.nombre === "ALTURA FÍSICA" && v.aprobado !== "NA") ? (
                            <Stack direction="horizontal" gap={4} className="border border-dark p-1">
                                <Form.Label>Altura física:</Form.Label>
                                <div className="ms-auto">{row.examenes.find(v => v.nombre === "ALTURA FÍSICA" && v.aprobado === "Apto") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                <div className="ms-auto">{row.examenes.find(v => v.nombre === "ALTURA FÍSICA") ? <a href={exas.find(v => v.nombre === "ALTURA FÍSICA").ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                {/* {usuario.perfiles.find(v => v.nombre === "Aprobador" || v.nombre === "Acreditador") && (<div className="m-auto">Status: <Form.Select id={antecedente.id} value={docs.find(v => v.nombre === "contrato") ? docs.find(v => v.nombre === "contrato").aprobado : ""} onChange={(e) => updateStatusDocumento(antecedente.id, e.target.value)}><option>Seleccione Status...</option><option value="Aprobado">Aprobado</option><option value="Reprobado">Reprobado</option></Form.Select></div>)} */}
                            </Stack>
                        ) : ""}
                        {row.examenes.find(v => v.nombre === "ALTURA GEOGRÁFICA" && v.aprobado !== "NA") ? (
                            <Stack direction="horizontal" gap={4} className="border border-dark p-1">
                                <Form.Label>Altura Geográfica:</Form.Label>
                                <div className="ms-auto">{row.examenes.find(v => v.nombre === "ALTURA GEOGRÁFICA" && v.aprobado === "Apto") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                <div className="ms-auto">{row.examenes.find(v => v.nombre === "ALTURA GEOGRÁFICA") ? <a href={exas.find(v => v.nombre === "ALTURA GEOGRÁFICA").ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                {/* {usuario.perfiles.find(v => v.nombre === "Aprobador" || v.nombre === "Acreditador") && (<div className="m-auto">Status: <Form.Select id={antecedente.id} value={docs.find(v => v.nombre === "contrato") ? docs.find(v => v.nombre === "contrato").aprobado : ""} onChange={(e) => updateStatusDocumento(antecedente.id, e.target.value)}><option>Seleccione Status...</option><option value="Aprobado">Aprobado</option><option value="Reprobado">Reprobado</option></Form.Select></div>)} */}
                            </Stack>
                        ) : ""}
                        <Stack direction="horizontal" gap={4} className="border border-dark p-1">
                            <Form.Label>Ficha Deloitte:</Form.Label>
                            <div className="ms-auto">{ficha !== "" ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                            <div className="ms-auto">{ficha !== "" ? <a href={ficha.ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                            {/* {usuario.perfiles.find(v => v.nombre === "Aprobador" || v.nombre === "Acreditador") && (<div className="m-auto">Status: <Form.Select id={ficha.id} value={ficha.aprobado} onChange={(e) => updateStatusDocumento(ficha.id, e.target.value)}><option>Seleccione Status...</option><option value="Aprobado">Aprobado</option><option value="Reprobado">Reprobado</option></Form.Select></div>)} */}
                        </Stack>
                        <Stack direction="horizontal" gap={4} className="border border-dark p-1">
                            <Form.Label>Transporte MEL:</Form.Label>
                            <div className="ms-auto">{transporte_mel !== "" ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                            <div className="ms-auto">{transporte_mel !== "" ? <a href={transporte_mel.ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                            {/* {usuario.perfiles.find(v => v.nombre === "Aprobador" || v.nombre === "Acreditador") && (<div className="m-auto">Status: <Form.Select id={transporte_mel.id} value={transporte_mel.aprobado} onChange={(e) => updateStatusDocumento(transporte_mel.id, e.target.value)}><option>Seleccione Status...</option><option value="Aprobado">Aprobado</option><option value="Reprobado">Reprobado</option></Form.Select></div>)} */}
                        </Stack>
                        <Stack direction="horizontal" gap={4} className="border border-dark p-1">
                            <Form.Label>Extravio Credencial:</Form.Label>
                            <div className="ms-auto">{extravio_credencial !== "" ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                            <div className="ms-auto">{extravio_credencial !== "" ? <a href={extravio_credencial.ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                            {/* {usuario.perfiles.find(v => v.nombre === "Aprobador" || v.nombre === "Acreditador") && (<div className="m-auto">Status: <Form.Select id={extravio_credencial.id} value={extravio_credencial.aprobado} onChange={(e) => updateStatusDocumento(extravio_credencial.id, e.target.value)}><option>Seleccione Status...</option><option value="Aprobado">Aprobado</option><option value="Reprobado">Reprobado</option></Form.Select></div>)} */}
                        </Stack>
                        {conduccion && (
                            <div>
                                <Stack direction="horizontal" gap={4} className="border border-dark p-1">
                                    <Form.Label>Licencia Conducir:</Form.Label>
                                    <div className="ms-auto">{licencia_conducir !== "" ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                    <div className="ms-auto">{licencia_conducir !== "" ? <a href={licencia_conducir.ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                    {/* {usuario.perfiles.find(v => v.nombre === "Aprobador" || v.nombre === "Acreditador") && (<div className="m-auto">Status: <Form.Select id={licencia_conducir.id} value={licencia_conducir.aprobado} onChange={(e) => updateStatusDocumento(licencia_conducir.id, e.target.value)}><option>Seleccione Status...</option><option value="Aprobado">Aprobado</option><option value="Reprobado">Reprobado</option></Form.Select></div>)} */}
                                </Stack>
                                <Stack direction="horizontal" gap={4} className="border border-dark p-1">
                                    <Form.Label>Hoja de Vida del Conductor:</Form.Label>
                                    <div className="ms-auto">{hoja_vida_conductor !== "" ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                    <div className="ms-auto">{hoja_vida_conductor !== "" ? <a href={hoja_vida_conductor.ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                    {/* {usuario.perfiles.find(v => v.nombre === "Aprobador" || v.nombre === "Acreditador") && (<div className="m-auto">Status: <Form.Select id={hoja_vida_conductor.id} value={hoja_vida_conductor.aprobado} onChange={(e) => updateStatusDocumento(hoja_vida_conductor.id, e.target.value)}><option>Seleccione Status...</option><option value="Aprobado">Aprobado</option><option value="Reprobado">Reprobado</option></Form.Select></div>)} */}
                                </Stack>
                            </div>
                        )}
                    </Col>
                    <Col></Col>
                </Row>
                <Row className="mt-3">
                    <Col></Col>
                    <Col sm={8}>
                        {(usuario.perfiles.find(v => v.nombre === "Aprobador") || usuario.perfiles.find(v => v.nombre === "Reclutador") || usuario.perfiles.find(v => v.nombre === "Acreditador") || usuario.perfiles.find(v => v.nombre === "Administrador")) && (
                            <>
                                <h4 className="text-center">Documentación</h4>
                                <Stack direction="horizontal" gap={4} className="border border-dark p-1">
                                    <Form.Label>Carnet CI:</Form.Label>
                                    <div className="ms-auto">{row.documentos.find(v => v.nombre === "carnet") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                    <div className="ms-auto">{row.documentos.find(v => v.nombre === "carnet") ? <a href={docs.find(v => v.nombre === "carnet").ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                    {/* {usuario.perfiles.find(v => v.nombre === "Aprobador" || v.nombre === "Acreditador") && (<div className="m-auto">Status: <Form.Select id={carnet.id} value={docs.find(v => v.nombre === "carnet") ? docs.find(v => v.nombre === "carnet").aprobado : ""} onChange={(e) => updateStatusDocumento(carnet.id, e.target.value)}><option>Seleccione Status...</option><option value="Aprobado">Aprobado</option><option value="Reprobado">Reprobado</option></Form.Select></div>)} */}
                                </Stack>
                                <Stack direction="horizontal" gap={4} className="border border-dark p-1">
                                    <Form.Label>Antecedente:</Form.Label>
                                    <div className="ms-auto">{row.documentos.find(v => v.nombre === "antecedente") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                    <div className="ms-auto">{row.documentos.find(v => v.nombre === "antecedente") ? <a href={docs.find(v => v.nombre === "antecedente").ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                    {/* {usuario.perfiles.find(v => v.nombre === "Aprobador" || v.nombre === "Acreditador") && (<div className="m-auto">Status: <Form.Select id={antecedente.id} value={docs.find(v => v.nombre === "antecedente") ? docs.find(v => v.nombre === "antecedente").aprobado : ""} onChange={(e) => updateStatusDocumento(antecedente.id, e.target.value)}><option>Seleccione Status...</option><option value="Aprobado">Aprobado</option><option value="Reprobado">Reprobado</option></Form.Select></div>)} */}
                                </Stack>
                                <Stack direction="horizontal" gap={4} className="border border-dark p-1">
                                    <Form.Label>AFP:</Form.Label>
                                    <div className="ms-auto">{row.documentos.find(v => v.nombre === "afp") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                    <div className="ms-auto">{row.documentos.find(v => v.nombre === "afp") ? <a href={docs.find(v => v.nombre === "afp").ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                    {/* {usuario.perfiles.find(v => v.nombre === "Aprobador" || v.nombre === "Acreditador") && (<div className="m-auto">Status: <Form.Select id={afp.id} value={afp.aprobado} onChange={(e) => updateStatusDocumento(afp.id, e.target.value)}><option>Seleccione Status...</option><option value="Aprobado">Aprobado</option><option value="Reprobado">Reprobado</option></Form.Select></div>)} */}
                                </Stack>
                                <Stack direction="horizontal" gap={4} className="border border-dark p-1">
                                    <Form.Label>Salud:</Form.Label>
                                    <div className="ms-auto">{salud !== "" ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                    <div className="ms-auto">{salud !== "" ? <a href={salud.ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                    {/* {usuario.perfiles.find(v => v.nombre === "Aprobador" || v.nombre === "Acreditador") && (<div className="m-auto">Status: <Form.Select id={salud.id} value={salud.aprobado} onChange={(e) => updateStatusDocumento(salud.id, e.target.value)}><option>Seleccione Status...</option><option value="Aprobado">Aprobado</option><option value="Reprobado">Reprobado</option></Form.Select></div>)} */}
                                </Stack>
                                <Stack direction="horizontal" gap={4} className="border border-dark p-1">
                                    <Form.Label>AFC:</Form.Label>
                                    <div className="ms-auto">{afc !== "" ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                    <div className="ms-auto">{afc !== "" ? <a href={afc.ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                    {/* {usuario.perfiles.find(v => v.nombre === "Aprobador" || v.nombre === "Acreditador") && (<div className="m-auto">Status: <Form.Select id={afc.id} value={afc.aprobado} onChange={(e) => updateStatusDocumento(afc.id, e.target.value)}><option>Seleccione Status...</option><option value="Aprobado">Aprobado</option><option value="Reprobado">Reprobado</option></Form.Select></div>)} */}
                                </Stack>
                                <Stack direction="horizontal" gap={4} className="border border-dark p-1">
                                    <Form.Label>Estudio:</Form.Label>
                                    <div className="ms-auto">{estudio !== "" ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                    <div className="ms-auto">{estudio !== "" ? <a href={estudio.ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                    {/* {usuario.perfiles.find(v => v.nombre === "Aprobador" || v.nombre === "Acreditador") && (<div className="m-auto">Status: <Form.Select id={estudio.id} value={estudio.aprobado} onChange={(e) => updateStatusDocumento(estudio.id, e.target.value)}><option>Seleccione Status...</option><option value="Aprobado">Aprobado</option><option value="Reprobado">Reprobado</option></Form.Select></div>)} */}
                                </Stack>
                                {trabajo_escondida && (
                                    <Stack direction="horizontal" gap={4} className="border border-dark p-1">
                                        <Form.Label>Finiquito:</Form.Label>
                                        <div className="ms-auto">{finiquito !== "" ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                        <div className="ms-auto">{finiquito !== "" ? <a href={finiquito.ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                        {/* {usuario.perfiles.find(v => v.nombre === "Aprobador" || v.nombre === "Acreditador") && (<div className="m-auto">Status: <Form.Select id={finiquito.id} value={finiquito.aprobado} onChange={(e) => updateStatusDocumento(finiquito.id, e.target.value)}><option>Seleccione Status...</option><option value="Aprobado">Aprobado</option><option value="Reprobado">Reprobado</option></Form.Select></div>)} */}
                                    </Stack>
                                )}
                                <Stack direction="horizontal" gap={4} className="border border-dark p-1">
                                    <Form.Label>CV:</Form.Label>
                                    <div className="ms-auto">{cv !== "" ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                    <div className="ms-auto">{cv !== "" ? <a href={cv.ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                    {/* {usuario.perfiles.find(v => v.nombre === "Aprobador" || v.nombre === "Acreditador") && (<div className="m-auto">Status: <Form.Select id={cv.id} value={cv.aprobado} onChange={(e) => updateStatusDocumento(cv.id, e.target.value)}><option>Seleccione Status...</option><option value="Aprobado">Aprobado</option><option value="Reprobado">Reprobado</option></Form.Select></div>)} */}
                                </Stack>
                                <Stack direction="horizontal" gap={4} className="border border-dark p-1">
                                    <Form.Label>Foto Carnet:</Form.Label>
                                    <div className="ms-auto">{foto !== "" ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                    <div className="ms-auto">{foto !== "" ? <a href={foto.ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                    {/* {usuario.perfiles.find(v => v.nombre === "Aprobador" || v.nombre === "Acreditador") && (<div className="m-auto">Status: <Form.Select id={foto.id} value={foto.aprobado} onChange={(e) => updateStatusDocumento(foto.id, e.target.value)}><option>Seleccione Status...</option><option value="Aprobado">Aprobado</option><option value="Reprobado">Reprobado</option></Form.Select></div>)} */}
                                </Stack>
                                <Stack direction="horizontal" gap={4} className="border border-dark p-1">
                                    <Form.Label>Pase Movilidad:</Form.Label>
                                    <div className="ms-auto">{pase !== "" ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                    <div className="ms-auto">{pase !== "" ? <a href={pase.ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                    {/* {usuario.perfiles.find(v => v.nombre === "Aprobador" || v.nombre === "Acreditador") && (<div className="m-auto">Status: <Form.Select id={pase.id} value={pase.aprobado} onChange={(e) => updateStatusDocumento(pase.id, e.target.value)}><option>Seleccione Status...</option><option value="Aprobado">Aprobado</option><option value="Reprobado">Reprobado</option></Form.Select></div>)} */}
                                </Stack>
                                {conduccion && (
                                    <div>
                                        <Stack direction="horizontal" gap={4} className="border border-dark p-1">
                                            <Form.Label>Licencia Conducir:</Form.Label>
                                            <div className="ms-auto">{licencia_conducir !== "" ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                            <div className="ms-auto">{licencia_conducir !== "" ? <a href={licencia_conducir.ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                            {/* {usuario.perfiles.find(v => v.nombre === "Aprobador" || v.nombre === "Acreditador") && (<div className="m-auto">Status: <Form.Select id={licencia_conducir.id} value={licencia_conducir.aprobado} onChange={(e) => updateStatusDocumento(licencia_conducir.id, e.target.value)}><option>Seleccione Status...</option><option value="Aprobado">Aprobado</option><option value="Reprobado">Reprobado</option></Form.Select></div>)} */}
                                        </Stack>
                                        <Stack direction="horizontal" gap={4} className="border border-dark p-1">
                                            <Form.Label>Hoja de Vida del Conductor:</Form.Label>
                                            <div className="ms-auto">{hoja_vida_conductor !== "" ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                            <div className="ms-auto">{hoja_vida_conductor !== "" ? <a href={hoja_vida_conductor.ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                            {/* {usuario.perfiles.find(v => v.nombre === "Aprobador" || v.nombre === "Acreditador") && (<div className="m-auto">Status: <Form.Select id={hoja_vida_conductor.id} value={hoja_vida_conductor.aprobado} onChange={(e) => updateStatusDocumento(hoja_vida_conductor.id, e.target.value)}><option>Seleccione Status...</option><option value="Aprobado">Aprobado</option><option value="Reprobado">Reprobado</option></Form.Select></div>)} */}
                                        </Stack>
                                    </div>
                                )}
                                {/* {usuario.perfiles.find(v => v.nombre === "Acreditador") && (
                                <div>
                                    <Stack direction="horizontal" gap={4} className="border border-dark p-1">
                                        <Form.Label>Ficha Deloitte:</Form.Label>
                                        <div className="ms-auto">{ficha !== "" ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                        <div className="ms-auto">{ficha !== "" ? <a href={ficha.ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                        {usuario.perfiles.find(v => v.nombre === "Aprobador" || v.nombre === "Acreditador") && (<div className="m-auto">Status: <Form.Select id={ficha.id} value={ficha.aprobado} onChange={(e) => updateStatusDocumento(ficha.id, e.target.value)}><option>Seleccione Status...</option><option value="Aprobado">Aprobado</option><option value="Reprobado">Reprobado</option></Form.Select></div>)}
                                    </Stack>
                                    <Stack direction="horizontal" gap={4} className="border border-dark p-1">
                                        <Form.Label>Transporte MEL:</Form.Label>
                                        <div className="ms-auto">{transporte_mel !== "" ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                        <div className="ms-auto">{transporte_mel !== "" ? <a href={transporte_mel.ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                        {usuario.perfiles.find(v => v.nombre === "Aprobador" || v.nombre === "Acreditador") && (<div className="m-auto">Status: <Form.Select id={transporte_mel.id} value={transporte_mel.aprobado} onChange={(e) => updateStatusDocumento(transporte_mel.id, e.target.value)}><option>Seleccione Status...</option><option value="Aprobado">Aprobado</option><option value="Reprobado">Reprobado</option></Form.Select></div>)}
                                    </Stack>
                                    <Stack direction="horizontal" gap={4} className="border border-dark p-1">
                                        <Form.Label>Extravio Credencial:</Form.Label>
                                        <div className="ms-auto">{extravio_credencial !== "" ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                        <div className="ms-auto">{extravio_credencial !== "" ? <a href={extravio_credencial.ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                        {usuario.perfiles.find(v => v.nombre === "Aprobador" || v.nombre === "Acreditador") && (<div className="m-auto">Status: <Form.Select id={extravio_credencial.id} value={extravio_credencial.aprobado} onChange={(e) => updateStatusDocumento(extravio_credencial.id, e.target.value)}><option>Seleccione Status...</option><option value="Aprobado">Aprobado</option><option value="Reprobado">Reprobado</option></Form.Select></div>)}
                                    </Stack>
                                </div>
                            )} */}
                            </>
                        )}
                    </Col>
                    <Col></Col>
                </Row>
                <Row className="mt-3">
                    <h4 className="text-center">Exámenes</h4>
                    <BootstrapTable data={row.examenes} options={optionsTableExamenes} hover>
                        <TableHeaderColumn dataField="id" isKey hidden>ID</TableHeaderColumn>
                        <TableHeaderColumn dataField="trabajadorId" hidden>ID T</TableHeaderColumn>
                        {/* <TableHeaderColumn dataField="nombre">Nombre</TableHeaderColumn> */}
                        <TableHeaderColumn dataFormat={formatterNombre}>Nombre</TableHeaderColumn>
                        <TableHeaderColumn dataFormat={dateFormat}>Fecha</TableHeaderColumn>
                        <TableHeaderColumn dataFormat={aprobadoFormat}>Estado</TableHeaderColumn>
                        <TableHeaderColumn dataFormat={buttonFormatter}>Editar</TableHeaderColumn>
                        <TableHeaderColumn dataFormat={viewFileFormat}>Archivo</TableHeaderColumn>
                    </BootstrapTable>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <h4 className="text-center">Cursos Especiales</h4>
                        <BootstrapTable data={row.cursos} options={optionsTableExamenes} hover>
                            <TableHeaderColumn dataField="id" isKey hidden>ID</TableHeaderColumn>
                            <TableHeaderColumn dataField="trabajadorId" hidden>ID T</TableHeaderColumn>
                            {/* <TableHeaderColumn dataField="nombre">Nombre</TableHeaderColumn> */}
                            <TableHeaderColumn dataFormat={formatterNombre}>Nombre Curso</TableHeaderColumn>
                            <TableHeaderColumn dataFormat={dateFormat}>Fecha Agendamiento</TableHeaderColumn>
                            <TableHeaderColumn dataFormat={aprobadoFormat}>Estado</TableHeaderColumn>
                            {usuario.perfiles.find(v => v.nombre === "Curso_Especial") && (<TableHeaderColumn dataFormat={buttonFormatterCurso}>Editar</TableHeaderColumn>)}
                            <TableHeaderColumn dataFormat={viewFileFormat}>Archivo</TableHeaderColumn>
                            <TableHeaderColumn dataField="oc">OC</TableHeaderColumn>
                        </BootstrapTable>
                    </Col>
                </Row>

            </div>
        );
    }

    const expandColumnComponent = ({ isExpandableRow, isExpanded }) => {
        let content = '';

        if (isExpandableRow) {
            content = (isExpanded ? <FontAwesomeIcon icon="fa-solid fa-circle-minus" /> : <FontAwesomeIcon icon="fa-solid fa-circle-plus" />);
        } else {
            content = ' ';
        }
        return (
            <div> {content} </div>
        );
    }

    async function colsXContratdor(trabaj) {
        setLoading(true);
        var colsall = [];
        let t = await trabajadorService.getAll();//trabaj;//JSON.parse(localStorage.getItem('tra'));//trabajadores; //await trabajadorService.getAll();
        var fecha_hoy = moment(new Date());
        t.data.map(tr => { //t.data.map
            if (tr.estado === "Listo para contratar" || tr.estado === "Contratado") {
                var tra = {
                    rut: tr.rut,
                    nombre: tr.nombre + ' ' + tr.ap_paterno,
                    id: tr.id,
                    documentos: tr.documentos,
                    avance: (tr.documentos.length / 10) * 100,
                    examenes: tr.examenes,
                    estado: tr.estado,
                    avanceDeloitte: avanceDeloitte(tr.documentos, tr.examenes, tr.trabajo_escondida, tr.conduccion),
                    respuesta: tr.respuestaws,
                    perfil: "contratador",
                    cursos: tr.cursos,
                    fecha_ingreso: moment(tr.createdAt).utc().format("DD/MM/YYYY"),
                    dias: fecha_hoy.diff(moment(tr.createdAt), 'days'),
                    trabajo_escondida: tr.trabajo_escondida,
                    conduccion: tr.conduccion,
                    cargo: tr.cargo,
                    centro_costo: tr.centro_costo
                }
                colsall.push(tra);
            }

        });
        cantidad_trabajadores.contratador = colsall.length;
        cantidad_trabajadores.acreditador = colsall.length;
        setTableContratador(colsall);
        setLoading(false);
    }

    async function colsXAcreditador(trabaj) {
        setLoading(true);
        var colsall = [];
        let t = await trabajadorService.getAll();//await trabajadorService.getAcreditador();
        var fecha_hoy = moment(new Date());
        if (t) {
            t.data.map(tr => { //t.data.map
                //'Carpeta enviada', 'Carpeta rechazada', 'Acreditado con credencial', 'Acreditado sin Credencial', 'Asignado con credencial', 'Asignado sin credencial', 'En Faena'
                if (tr.estado === "Carpeta enviada" || tr.estado === "Carpeta rechazada" || tr.estado === "Acreditado con credencial"
                    || tr.estado === "Acreditado sin credencial" || tr.estado === "Acceso sin credencial" || tr.estado === "Acceso con credencial"
                    || tr.estado === "En Faena" || tr.estado === "Carpeta creada" || tr.estado === "Listo para acreditar" || tr.estado === "Contratado"
                    || tr.estado === "Desvincular" || tr.estado === "Renuncia Voluntaria" || tr.estado === "Listo para 3D" || tr.estado === "Trabajador con problema en PMF" || tr.estado === "Acreditado con credencial") {
                    var tra = {
                        rut: tr.rut,
                        nombre: tr.nombre + ' ' + tr.ap_paterno,
                        id: tr.id,
                        documentos: tr.documentos,
                        avance: (tr.documentos.length / 10) * 100,
                        examenes: tr.examenes,
                        estado: tr.estado,
                        avanceDeloitte: avanceDeloitte(tr.documentos, tr.examenes, tr.trabajo_escondida, tr.conduccion),
                        respuesta: tr.respuestaws,
                        perfil: "contratador",
                        cursos: tr.cursos,
                        fecha_ingreso: moment(tr.createdAt).utc().format("DD/MM/YYYY"),
                        dias: fecha_hoy.diff(moment(tr.createdAt), 'days'),
                        trabajo_escondida: tr.trabajo_escondida,
                        conduccion: tr.conduccion,
                        cargo: tr.cargo,
                        centro_costo: tr.centro_costo
                    }
                    colsall.push(tra);
                }

            });
            cantidad_trabajadores.acreditador = colsall.length;
            setTableAcreditador(colsall);
        }
        //setTableAcreditador(t.data);
        setLoading(false);
    }

    async function colsXEstado(estado) {
        setLoading(true);
        var colsall = [];
        let t = await trabajadorService.getAll();
        var fecha_hoy = moment(new Date());
        t.data.map(tr => {
            if (tr.estado === estado) {
                var tra = {
                    rut: tr.rut,
                    nombre: tr.nombre + ' ' + tr.ap_paterno,
                    id: tr.id,
                    documentos: tr.documentos,
                    avance: (tr.documentos.length / 10) * 100,
                    examenes: tr.examenes,
                    estado: tr.estado,
                    avanceDeloitte: avanceDeloitte(tr.documentos, tr.examenes, tr.trabajo_escondida, tr.conduccion),
                    respuesta: tr.respuestaws,
                    perfil: "contratador",
                    cursos: tr.cursos,
                    fecha_ingreso: moment(tr.createdAt).utc().format("DD/MM/YYYY"),
                    dias: fecha_hoy.diff(moment(tr.createdAt), 'days'),
                    trabajo_escondida: tr.trabajo_escondida,
                    conduccion: tr.conduccion,
                    cargo: tr.cargo,
                    centro_costo: tr.centro_costo,
                    estado_3d: tr.cursos.find(v => v.nombre === "3D") ? tr.cursos.find(v => v.nombre === "3D").aprobado : "",
                    disponibilidad: tr.disponibilidad
                }
                colsall.push(tra);
            }

        });
        cantidad_trabajadores.contratador = colsall.length;
        cantidad_trabajadores.acreditador = colsall.length;
        if (estado === "Listo para acreditar") {
            setTableAcreditador(colsall);
        }
        if (estado === "Listo para 3D") {
            setTableCursos(colsall);
        }
        //setTableContratador(colsall);
        setLoading(false);
    }

    const buscarPorFechas = () => {
        filtrarXFechas("", "", examenFiltro, estadoExamenFiltro, cursoFiltro, estadoCursoFiltro, tresdFiltro);
    }

    async function filtrarXFechas(perfil, id_usuario, examen_filtro, eef, cf, ecf, tf) {
        setLoading(true);
        let t = null;
        if (statusp === "reclutador") {
            t = await trabajadorService.getByUser(usuario.id);
        } else {
            t = await trabajadorService.getAll();
        }
        var cols = [];
        var fecha_hoy = moment(new Date());

        //Array que obtiene los examenes.
        var newArray = t.data.filter(function (tr) {
            if (examenesFiltro.length > 0 && eef !== "" && tf !== "") {
                return [].concat(tr.examenes).find(v => {
                    for (let i in examenesFiltro) {
                        if (examenesFiltro[i] === v.nombre && v.aprobado === eef) {
                            var c = [].concat(tr.cursos).find(v => v.nombre === "3D" && v.aprobado === tf);
                            if (c) {
                                return v;
                            }
                        }
                    }
                });
            } else
                if (examenesFiltro.length > 0 && eef !== "") {
                    return [].concat(tr.examenes).find(v => {
                        for (let i in examenesFiltro) {
                            if (examenesFiltro[i] === v.nombre && v.aprobado === eef) {
                                return v;
                            }
                        }
                    });
                } else if (examenesFiltro.length > 0) {
                    return [].concat(tr.examenes).find(v => {
                        for (let i in examenesFiltro) {
                            if (examenesFiltro[i] === v.nombre) {
                                return v;
                            }
                        }
                    });
                } else if (eef !== "") {
                    return [].concat(tr.examenes).find(v => v.aprobado === eef);
                }
        });

        //** Filtramos el array por los cursos */
        var arrayCursos = t.data.filter(function (tr) {
            if (cf !== "") {
                return [].concat(tr.cursos).find(v => v.nombre === cf);
            } else if (ecf !== "") {
                return [].concat(tr.cursos).find(v => v.aprobado === ecf);
            } else if (cf !== "" && ecf !== "") {
                return [].concat(tr.cursos).find(v => v.nombre === cf && v.aprobado === ecf);
            }
        });

        /** FIltramos el array por los 3D */
        var array3D = t.data.filter(function (tr) {
            if (tf !== "") {
                return [].concat(tr.cursos).find(v => v.nombre === "3D" && v.aprobado === tf);
            }
        });

        var arrayTodo = [].concat(newArray).concat(arrayCursos).concat(array3D).unique();
        if (fechatermmino !== "" && fechainicio !== "") {
            if (arrayTodo.length > 0) {
                arrayTodo.map(tr => {
                    const examenes = [].concat(tr.examenes);
                    examenes.map(ex => {
                        var fecha = new Date(ex.createdAt).setHours(0, 0, 0, 0);
                        if (fecha >= fechainicio && fecha <= fechatermmino) {
                            var tra = {
                                rut: tr.rut,
                                nombre: tr.nombre + " " + tr.ap_paterno,
                                id: tr.id,
                                documentos: tr.documentos,
                                avance: (tr.documentos.length / 10) * 100,
                                examenes: tr.examenes,
                                estado: tr.estado,
                                avanceDeloitte: avanceDeloitte(tr.documentos, tr.examenes, tr.trabajo_escondida, tr.conduccion),
                                respuesta: tr.respuestaws,
                                perfil: "aprobador",
                                cursos: tr.cursos,
                                fecha_ingreso: moment(tr.createdAt).utc().format("DD/MM/YYYY"),
                                dias: fecha_hoy.diff(moment(tr.createdAt), 'days'),
                                trabajo_escondida: tr.trabajo_escondida,
                                conduccion: tr.conduccion,
                                cargo: tr.cargo,
                                centro_costo: tr.centro_costo
                            }
                            var index = cols.findIndex(v => v.id === tr.id);
                            cols.splice(index, index >= 0 ? 1 : 0);
                            cols.push(tra);
                        }
                    });
                });
            } else {
                t.data.map(tr => {
                    var fecha = new Date(tr.createdAt).setHours(0, 0, 0, 0);
                    if (fecha >= fechainicio && fecha <= fechatermmino) {
                        var tra = {
                            rut: tr.rut,
                            nombre: tr.nombre + " " + tr.ap_paterno,
                            id: tr.id,
                            documentos: tr.documentos,
                            avance: (tr.documentos.length / 10) * 100,
                            examenes: tr.examenes,
                            estado: tr.estado,
                            avanceDeloitte: avanceDeloitte(tr.documentos, tr.examenes, tr.trabajo_escondida, tr.conduccion),
                            respuesta: tr.respuestaws,
                            perfil: "aprobador",
                            cursos: tr.cursos,
                            fecha_ingreso: moment(tr.createdAt).utc().format("DD/MM/YYYY"),
                            dias: fecha_hoy.diff(moment(tr.createdAt), 'days'),
                            trabajo_escondida: tr.trabajo_escondida,
                            conduccion: tr.conduccion,
                            cargo: tr.cargo,
                            centro_costo: tr.centro_costo
                        }
                        var index = cols.findIndex(v => v.id === tr.id);
                        cols.splice(index, index >= 0 ? 1 : 0);
                        cols.push(tra);
                    }
                });
            }
        } else {
            arrayTodo.map(async tr => {
                var tra = {
                    rut: tr.rut,
                    nombre: tr.nombre + " " + tr.ap_paterno + " " + tr.ap_materno,
                    id: tr.id,
                    documentos: tr.documentos,
                    avance: (tr.documentos.length / 10) * 100,
                    examenes: tr.examenes,
                    estado: tr.estado,
                    avanceDeloitte: avanceDeloitte(tr.documentos, tr.examenes, tr.trabajo_escondida, tr.conduccion),
                    respuesta: tr.respuestaws,
                    perfil: "aprobador",
                    cursos: tr.cursos,
                    fecha_ingreso: moment(tr.createdAt).utc().format("DD/MM/YYYY"),
                    dias: fecha_hoy.diff(moment(tr.createdAt), 'days'),
                    trabajo_escondida: tr.trabajo_escondida,
                    conduccion: tr.conduccion,
                    cargo: tr.cargo,
                    centro_costo: tr.centro_costo,
                    estado_3d: tr.cursos.find(v => v.nombre === "3D") ? tr.cursos.find(v => v.nombre === "3D").aprobado : "",
                    disponibilidad: tr.disponibilidad
                }
                var index = cols.findIndex(v => v.id === tr.id);
                cols.splice(index, index >= 0 ? 1 : 0);
                cols.push(tra);
            });
        }
        if (statusp === "administrador") {
            setTableAprobador(cols);
        } else if (statusp === "curso_especial") {
            setTableCursos(cols);
        } else {
            setTable(cols);
        }
        setLoading(false);
    }

    async function cols2(trabaj) {
        setLoading(true);
        var contador_dias = 0;
        var colsall = [];
        console.log("Trabajadores en Cols", trabajadores);
        let t = await trabajadorService.getAll();//trabaj;//await trabajadorService.getAll();//JSON.parse(localStorage.getItem('tra'));//trabajadores ? trabajadores : [];//await trabajadorService.getAll();
        if (t.data.length) {
            var l = t.data.length;
            cantidad_trabajadores.aprobador = l;//t.length;//t.data.length;
            cantidad_trabajadores.acreditador = l//t.length;//t.data.length;
            cantidad_trabajadores.administrador = l;//t.length;//t.data.length;
            var fecha_hoy = moment(new Date());
            const data = t.data.map(tr => ({ //t.data.map
                rut: tr.rut,
                nombre: tr.nombre + ' ' + tr.ap_paterno + ' ' + tr.ap_materno,
                id: tr.id,
                documentos: tr.documentos,
                avance: (tr.documentos.length / 10) * 100,
                examenes: tr.examenes,
                estado: tr.estado,
                avanceDeloitte: avanceDeloitte(tr.documentos, tr.examenes, tr.trabajo_escondida, tr.conduccion, tr.id),
                respuesta: tr.respuestaws,
                perfil: "aprobador",
                fecha_ingreso: moment(tr.createdAt).utc().format("DD/MM/YYYY"),
                cursos: tr.cursos,
                dias: fecha_hoy.diff(moment(tr.createdAt), 'days'),
                trabajo_escondida: tr.trabajo_escondida,
                conduccion: tr.conduccion,
                cargo: tr.cargo,
                centro_costo: tr.centro_costo,
                estado_3d: tr.cursos.find(v => v.nombre === "3D") ? tr.cursos.find(v => v.nombre === "3D").aprobado : "",
                disponibilidad: tr.disponibilidad
            }));
            cantidad_trabajadores.ingresos_hoy_reclutador = contador_dias;
            setTableAprobador(data);
            setTableCursos(data);
            setLoading(false);
        } else {
            console.log("Problemas con T");
            setLoading(false);
        }
        //setLoading(false);
    }

    async function colsUser(id_usuario, trabaj) {
        var contador_dias = 0;
        var contador_proceso = 0;
        setLoading(true);
        var cols = [];
        let t = trabaj;//await trabajadorService.getByUser(id_usuario);
        var fecha_hoy = moment(new Date());
        //cantidad_trabajadores.reclutador = t.data.length;
        t.data.map(tr => {
            var tra = {
                rut: tr.rut,
                nombre: tr.nombre + ' ' + tr.ap_paterno,
                id: tr.id,
                documentos: tr.documentos,
                avance: Math.round((tr.documentos.length / 11) * 100),
                examenes: tr.examenes,
                estado: tr.estado,
                avanceDeloitte: avanceDeloitte(tr.documentos, tr.examenes, tr.trabajo_escondida, tr.conduccion),
                respuesta: tr.respuestaws,
                perfil: "reclutador",
                fecha_ingreso: moment(tr.createdAt).utc().format("DD/MM/YYYY"),
                cursos: tr.cursos,
                dias: fecha_hoy.diff(moment(tr.createdAt), 'days'),
                trabajo_escondida: tr.trabajo_escondida,
                conduccion: tr.conduccion,
                cargo: tr.cargo,
                centro_costo: tr.centro_costo,
                disponibilidad: tr.disponibilidad
            }
            if (new Date(tr.createdAt).toDateString() == fechaHoy.toDateString()) {
                contador_dias += 1;
            }
            if (tr.estado === "En Proceso") {
                contador_proceso += 1;
            }
            cols.push(tra);
        });
        cantidad_trabajadores.ingresos_hoy_reclutador = contador_dias;
        cantidad_trabajadores.reclutador = contador_proceso;
        setTable(cols);
        setLoading(false);
    }

    async function toExcelxIdUsuario(id_usuario) {
        setLoadingButton(true);
        var cols = [];
        let option = {};
        let t = null;
        if (id_usuario != "") {
            t = await trabajadorService.getByUser(id_usuario);
        } else {
            t = await trabajadorService.getTodos();
        }
        //let t = await trabajadorService.getByUser(id_usuario);
        var lista_t = null;//t.data;
        if (statusp === "acreditador") {
            lista_t = t.data.filter(v => v.estado === "Contratado" || v.estado === "Carpeta enviada" || v.estado === "Carpeta rechazada" || v.estado === "Acreditado con credencial"
                || v.estado === "Acreditado sin credencial" || v.estado === "Acceso sin credencial" || v.estado === "Acceso con credencial"
                || v.estado === "En Faena" || v.estado === "Carpeta creada" || v.estado === "Listo para acreditar"
                || v.estado === "Desvincular" || v.estado === "Renuncia Voluntaria");

        } else {
            lista_t = t.data;
        }

        for (let i in lista_t) {
            let examenes = lista_t[i].examenes;
            let documentos = lista_t[i].documentos;
            let cursos = lista_t[i].cursos;
            if (examenes !== undefined) {
                let obj = {
                    fecha_ingreso: moment(lista_t[i].createdAt).format("DD-MM-YYYY"),
                    rut: lista_t[i].rut,
                    nombre: lista_t[i].nombre + " " + lista_t[i].ap_paterno + " " + lista_t[i].ap_materno,
                    unidad_negocio: lista_t[i].centro_costo,
                    telefono: lista_t[i].fono,
                    email: lista_t[i].correo,
                    cargo: lista_t[i].cargo,
                    residencia: lista_t[i].ciudad,
                    cero_dano: examenes.find(v => v.nombre === "CERO DAÑO" && v.aprobado === "Apto") ? "SI" : "NO",
                    ind_hombre_nuevo: examenes.find(v => v.nombre === "INDUCCION HOMBRE NUEVO" && v.aprobado === "Apto") ? "SI" : "NO",
                    altura_geografica: examenes.find(v => v.nombre === "ALTURA GEOGRÁFICA" && v.aprobado === "Apto") ? "SI" : "NO",
                    altura_fisica: examenes.find(v => v.nombre === "ALTURA FÍSICA" && v.aprobado === "Apto") ? "SI" : "NO",
                    ex_preocupacional: examenes.find(v => v.nombre === "EXÁMEN PREOCUPACIONAL" && v.aprobado === "Apto") ? "SI" : "NO",
                    alcoholydrogas: examenes.find(v => v.nombre === "ALCOHOL Y DROGAS" && v.aprobado === "Apto") ? "SI" : "NO",
                    //Creamos los documentos
                    carnet: documentos.find(v => v.nombre === "carnet") ? "SI" : "NO",
                    foto: documentos.find(v => v.nombre === "foto") ? "SI" : "NO",
                    afp: documentos.find(v => v.nombre === "afp") ? "SI" : "NO",
                    contrato: documentos.find(v => v.nombre === "contrato") ? "SI" : "NO",
                    antecedente: documentos.find(v => v.nombre === "antecedente") ? "SI" : "NO",
                    salud: documentos.find(v => v.nombre === "salud") ? "SI" : "NO",
                    afc: documentos.find(v => v.nombre === "afc") ? "SI" : "NO",
                    estudio: documentos.find(v => v.nombre === "estudio") ? "SI" : "NO",
                    finiquito: documentos.find(v => v.nombre === "finiquito") ? "SI" : "NO",
                    cv: documentos.find(v => v.nombre === "cv") ? "SI" : "NO",
                    pase: documentos.find(v => v.nombre === "pase") ? "SI" : "NO",
                    estado: lista_t[i].estado,
                    deloitte: avanceDeloitte(lista_t[i].documentos, lista_t[i].examenes, lista_t[i].trabajo_escondida, lista_t[i].conduccion),
                    por_ecora: Math.round((lista_t[i].documentos.length / 11) * 100),
                    estado3d: cursos.find(v => v.nombre === "3D") ? cursos.find(v => v.nombre === "3D").aprobado : "-",
                }
                cols.push(obj);
            }
        }
        option.fileName = "Reclutamientos";
        option.datas = [
            {
                sheetData: cols,
                sheetName: "Reclutamiento",
                sheetHeader: ['Fecha de Ingreso', 'Rut', 'Nombre', 'Unidad Negocio', 'Telefono', 'Email', 'Cargo', 'Residencia', 'Cero Daño', 'Inducción Hombre Nuevo', 'ALTURA GEOGRÁFICA', 'ALTURA FÍSICA', 'EXAMEN PREOCUPACIONAL', 'ALCOHOL Y DROGAS', 'Carnet', 'Foto', 'AFP', 'Contrato', 'Antecedente', 'Salud', 'AFC', 'Estudio', 'Finiquito', 'CV', 'Pase', 'Estado', '% Deloitte', '% CSI', 'Estado del 3D']
            }
        ]

        let toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
        setLoadingButton(false);
    }

    async function excelIDOK() {
        setLoadingButton(true);
        let t = await trabajadorService.getByEstado("Listo para contratar");
        let option = {};
        var cols = [];
        if (t) {
            const lista_t = t.data;

            for (let i in lista_t) {
                let examenes = lista_t[i].examenes;
                let documentos = lista_t[i].documentos;
                if (examenes !== undefined) {
                    let obj = {
                        rut: lista_t[i].rut,
                        nombre: lista_t[i].nombre,
                        ap_paterno: lista_t[i].ap_paterno,
                        ap_materno: lista_t[i].ap_materno,
                        correo: lista_t[i].correo,
                        sexo: lista_t[i].sexo,
                        fecha_nacimiento: moment(lista_t[i].fecha_nacimiento).format("DD-MM-YYYY"),
                        nacionalidad: lista_t[i].nacionalidad,
                        telefono: lista_t[i].fono,
                        estado_civil: lista_t[i].estado_civil,
                        cargo: "",
                        profesion: "",
                        fecha_ingreso: "",
                        direccion: lista_t[i].direccion,
                        comuna: lista_t[i].ciudad,
                        ciudad: "",
                        region: "",
                        direccion_laboral: "",
                        comuna_laboral: "",
                        ciudad_laboral: "",
                        region_laboral: "",
                        sueldo_base: lista_t[i].sueldo_base_mensual,
                        jornada: lista_t[i].jornada_trabajo,
                        centro_costo: lista_t[i].centro_costo === "5400" ? "UPGRADE CASINO" : lista_t[i].centro_costo,
                        area: "",
                        afp: lista_t[i].afp,
                        salud: lista_t[i].salud,
                        banco: lista_t[i].banco,
                        numero_cuenta: lista_t[i].nro_cuenta,
                        tipo_cuenta: lista_t[i].tipo_cuenta,
                        rut_empresa: "76961000-1",
                        rol: "TRABAJADOR",
                        sin_rut: "",
                        etiqueta_carga: ""
                    }
                    //'afp','salud','banco','numero_cuenta','tipo_cuenta','rut_empresa','rol','sin_rut','etiqueta_carga'
                    cols.push(obj);
                }
            }
        };

        option.fileName = "Usuario sistema idok";
        option.datas = [
            {
                sheetData: cols,
                sheetName: "Sheet1",
                sheetHeader: ['rut', 'nombres', 'apellido_paterno', 'apellido_materno', 'email', 'genero', 'fecha_nacimiento', 'nacionalidad', 'telefono', 'estado_civil', 'cargo', 'profesion', 'fecha_ingreso', 'direccion', 'comuna', 'ciudad', 'region', 'direccion_laboral', 'comuna_laboral', 'ciudad_laboral', 'region_laboral', 'sueldo_base', 'jornada', 'centro_costo', 'area', 'afp', 'salud', 'banco', 'numero_cuenta', 'tipo_cuenta', 'rut_empresa', 'rol', 'sin_rut', 'etiqueta_carga']
            }
        ]

        let toExcel = new ExportJsonExcel(option);
        toExcel.saveExcel();
        setLoadingButton(false);
    }

    const exportExcelIDOK = () => {
        excelIDOK();
    }

    const exportExcel = () => {
        if (statusp === "reclutador") {
            toExcelxIdUsuario(usuario.id);
        } else {
            toExcelxIdUsuario("");
        }
        //toExcelxIdUsuario(usuario.id);
    }

    function avanceDeloitte(documentos, examenes, trabajo_escondida, conduccion, id_trabajador) {
        var total_total = 0;
        if ((conduccion) && (trabajo_escondida)) {
            total_total = 11;
        }
        if ((conduccion === false || conduccion === null) && (trabajo_escondida)) {
            total_total = 8;
        }
        if ((conduccion === false || conduccion === null) && (trabajo_escondida === false || trabajo_escondida === null)) {
            total_total = 7;
        }
        if ((trabajo_escondida === false || trabajo_escondida === null) && (conduccion)) {
            total_total = 10;
        }

        var contador = 0;
        var contador_examenes = 0;
        documentos.map(d => {
            if (d.nombre === "carnet") {
                contador++;
            }
            /* if (d.nombre === "foto") {
                contador++;
            } */
            if (d.nombre === "antecedente") {
                contador++;
            }
            if (d.nombre === "ficha") {
                contador++;
            }
            if (d.nombre === "contrato") {
                contador++;
            }
            if (d.nombre === "transporte_mel") {
                contador++;
            }
            if (trabajo_escondida) {
                if (d.nombre === "finiquito") {//si aplica solamente
                    contador++;
                }
            }
            if (conduccion) {
                if (d.nombre === "licencia_conducir") {
                    contador++;
                }
                if (d.nombre === "hoja_vida_conductor") {
                    contador++;
                }
            }
        });

        examenes.map(e => {
            if ((e.nombre === "CERO DAÑO" || e.nombre === "INDUCCION HOMBRE NUEVO") && e.aprobado === "Apto") {
                contador_examenes++;
            }
            if (e.nombre === "EXÁMEN PREOCUPACIONAL" && e.aprobado === "Apto" || ((e.nombre === "ALTURA FÍSICA" && e.aprobado === "Apto") && (e.nombre === "ALTURA GEOGRÁFICA" && e.aprobado === "Apto"))) {
                contador_examenes++;
            }
            if (conduccion) {
                if (e.nombre === "PSICOSENSOMÉTRICO" && e.aprobado === "Apto") {
                    contador_examenes++;
                }
            }
        });

        var total = contador + contador_examenes;

        var total_retorno = 0;//(total / total_total) * 100;
        if (conduccion && trabajo_escondida) {
            var total_retorno = (total / 11) * 100;
            return Math.round(total_retorno);//(total / 9) * 100;
        }
        if ((conduccion === false || conduccion === null) && trabajo_escondida) {
            var total_retorno = (total / 8) * 100;
            return Math.round(total_retorno);//(total / 9) * 100;
        }
        if ((conduccion === false || conduccion === null) && (trabajo_escondida === false || trabajo_escondida === null)) {
            var total_retorno = (total / 7) * 100;
            return Math.round(total_retorno);//(total / 9) * 100;
        }
        if ((trabajo_escondida === false || trabajo_escondida === null) && (conduccion)) {
            var total_retorno = (total / 10) * 100;
            return Math.round(total_retorno);//(total / 9) * 100;
        }
    }

    async function colsEstado(estado) {
        setLoading(true);
        var cols = [];
        let t = await trabajadorService.getByEstado(estado);
        t.data.map(tr => {
            var tra = {
                rut: tr.rut,
                nombre: tr.nombre,
                id: tr.id,
                documentos: tr.documentos,
                avance: (tr.documentos.length / 10) * 100,
                examenes: tr.examenes,
                estado: tr.estado,
                perfil: "contratador"
            }
            cols.push(tra);
        });
        setTableContratador(cols);
        setLoading(false);
    }

    const cols = async () => {
        setLoading(true);
        var cols = [];
        let t = await trabajadorService.getAll();
        t.data.map(tr => {
            var tra = {
                rut: tr.rut,
                nombre: tr.nombre + ' ' + tr.ap_paterno,
                id: tr.id,
                documentos: tr.documentos,
                avance: (tr.documentos.length / 10) * 100,
                examenes: tr.examenes,
                estado: tr.estado,
                cursos: tr.cursos,
                disponibilidad: tr.disponibilidad
            }
            cols.push(tra);
        });
        setTable(cols);
        setLoading(false);
    }

    function buttonFormat(cell, row) {
        return <Button variant="primary" onClick={() => btnEditar(row.rut)}><FontAwesomeIcon icon="fa-solid fa-pen-to-square" /></Button>;
    }

    function buttonFormattCrearCarpeta(cell, row) {
        return <Button variant="primary" onClick={() => crear_carpeta(row.id)}>{loadingButton ? <BeatLoader color="white" /> : (<FontAwesomeIcon icon="fa-solid fa-folder-open" />)}</Button>; { loadingButton ? <BeatLoader color="white" /> : (<FontAwesomeIcon icon="fa-solid fa-folder-open" />) }
    }

    function buttonFormatTRESD(cell, row) {
        return <Button variant="primary" onClick={() => iraTresd(row.id)}><FontAwesomeIcon icon="fa-solid fa-pen-to-square" /></Button>;
    }

    const iraTresd = (id) => {
        //history.push("/tresd/"+id);
    }

    function viewFileFormat(cell, row) {
        return row.ruta != null ? <a href={row.ruta} alt="Examen" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />;
    }

    const btnEditar = (rut) => {
        var id_codificado = btoa(rut);
        var ejecutiva = JSON.stringify(usuario);
        var ejecbtoa = btoa(ejecutiva)
        let objJsonB64 = Buffer.from(ejecutiva).toString("base64");
        console.log("B64", objJsonB64);
        //navigate("/trabajador/" + id_codificado + "/" + ejecbtoa,"_blank");
        window.open("/trabajador/" + id_codificado + "/" + objJsonB64, '_blank');
    }

    const onChangeSelecFiltroAprobado = (valor) => {
        setFiltroAprobado(valor);
    }

    const btnFiltrarReprobados = async () => {
        setLoading(true);
        if (filtroAprobado === "Seleccione una opción...") {
            window.location.reload();
            setLoading(false);
            return;
        }
        var cols = [];
        let t = await trabajadorService.getAll();
        if (t) {
            t.data.map(tra => {
                var docs = tra.documentos;
                if (docs) {
                    docs.map(d => {
                        if (d.aprobado === filtroAprobado) {
                            var trab = {
                                rut: tra.rut,
                                nombre: tra.nombre,
                                id: tra.id,
                                documentos: tra.documentos,
                                avance: (tra.documentos.length / 10) * 100,
                                examenes: tra.examenes,
                                cursos: tra.cursos,
                                disponibilidad: tra.disponibilidad
                            }
                            var indx = cols.findIndex(v => v.id === tra.id);
                            cols.splice(indx, indx >= 0 ? 1 : 0);
                            cols.push(trab);
                        }
                    });
                }
            });
        }
        setTable(cols);
        setLoading(false);
    }

    const editarTrabajadorReclu = (rut) => {
        //navigate("/ingtrabajador/" + rut);
        window.open("/ingtrabajador/" + rut, "_blank")
    }

    function btnEditarTrab(cell, row) {
        return <Button variant="secondary" onClick={() => editarTrabajadorReclu(row.rut)}><FontAwesomeIcon icon="fa-solid fa-pen-to-square" /></Button>
    }

    const despuesDeIncertarEnCelda = (row, cellName, cellValue) => {
        if (row.disponibilidad === "Bloqueado") {
            alert("El trabajador se encuentra bloqueado!");
            return;
        }
        if (cellValue === "Desvincular") {
            setModalShowM(true);
            setIdTrabajador(row.id);
        } else {
            //Actualizamos el estado del trabajador
            trabajadorService.updateTrabajador(row.id, { estado: cellValue, disponibilidad: "" })
                .then(tr => {
                    if (tr) {
                        toast.info("Estado actualizado", {
                            position: "top-center",
                            autoClose: 500
                        });
                        var fecha_hoy = new Date();
                        estadoService.create({ nombre_estado: cellValue, fecha: fecha_hoy, trabajadorId: row.id, usuarioId: usuario.id })
                            .then(resp => {
                                console.log(resp);
                            }).
                            catch(err => {
                                console.error(err);
                            });
                        actualizaSegunPerfil(row.perfil);
                    }
                }).catch(err => {
                    console.error(err);
                    toast.error("Ocurrio un error al actualizar estado", {
                        position: "top-center",
                        autoClose: 500
                    });
                    actualizaSegunPerfil(row.perfil);
                })
        }
    };

    function onBeforeSaveCell(row, cellName, cellValue) {
        // You can do any validation on here for editing value,
        // return false for reject the editing
        var estado = false;
        if (row.estado === "Desvinculado") {
            if (initialPerfil.administrador) {
                estado = true;
            } else {
                estado = false;
            }
        } else {
            estado = true;
        }

        return estado;
    }

    const cellEditProp = {
        mode: 'click',
        afterSaveCell: despuesDeIncertarEnCelda,
        beforeSaveCell: onBeforeSaveCell
    };

    function CustomToggle({ children, eventKey }) {
        const decoratedOnClick = useAccordionButton(eventKey, () =>
            console.log('totally custom!'),
        );

        return (
            <button
                type="button"
                style={{ backgroundColor: 'pink' }}
                onClick={decoratedOnClick}
            >
                {children}
            </button>
        );
    }

    const onChangeAcordion = (valor) => {
        modificarPerfil.valor = valor;
    }

    const onChangeBloqueo = (valor) => {
        if (valor) {
            setBloqueo(true);
        } else {
            setBloqueo(false);
        }
    }

    const onChangeMotivo = (valor) => {
        setMotivo(valor);
    }

    const ingresarMotivo = (id_trabajador) => {
        //Ingresamos motivo
        var disponible = "";
        if (bloqueo) {
            disponible = "Bloqueado";
        }
        motivosDService.create({ motivo: motivo, trabajadorId: idTrabajador })
            .then(response => {
                if (response) {
                    console.log(response);
                    toast.info("Motivo ingresado!", {
                        position: "top-center",
                        autoClose: 500
                    });
                    //Actualizamos estado y disponibilidad
                    trabajadorService.updateTrabajador(idTrabajador, { estado: "Desvinculado", disponibilidad: disponible })
                        .then(tr => {
                            if (tr) {
                                toast.info("Trabajador actualizado!", {
                                    position: "top-right",
                                    autoClose: 500
                                });
                            }
                        }).catch(err => {
                            console.error(err);
                            toast.error("Ha ocurrido un error al actualizar", {
                                position: "top-right",
                                autoClose: 500
                            });
                        });
                }
            }).catch(err => {
                console.error(err);
                toast.error("Error al ingresar motivo", {
                    position: "top-center",
                    autoClose: 500
                });
            });
    }

    const onChangeIdTrabajador = (valor) => {
        setIdTrabajador(valor);
    }

    /* Aqui comienza sección para actualizar un curso especial, se muestran las funciones handleCurso, 
    upload File de un curso, boton editar un curso */

    const handleChangeCurso = (valor, nombre) => {
        setCurso({ ...curso, [nombre]: valor });
    }

    const showEditCurso = (id, id_trabajador) => {
        setLoading(true);
        //Obtenemos el trabajador
        trabajadorService.getByID(id_trabajador)
            .then(t => {
                setTrabajador(t.data);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
            });
        //Obtenemos el curso
        cursoService.get(id)
            .then(e => {
                setCurso(e.data);
                toggle()//setModalShow(true);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setLoading(false);
            });
    }

    function buttonFormatterCurso(cell, row) {
        return <Button variant="secondary" onClick={() => showEditCurso(row.id, row.trabajadorId)}><FontAwesomeIcon icon="fa-solid fa-pen-to-square" /></Button>;
    }

    function registroEstado(estado, fecha_hoy, id_trabajador, id_usuario) {
        estadoService.create({ nombre_estado: estado, fecha: fecha_hoy, trabajadorId: id_trabajador, usuarioId: id_usuario })
            .then(resp => {
                console.log(resp);
            }).
            catch(err => {
                console.error(err);
            });
    }

    const editCurso = (id, perfil) => {
        setLoading(true);
        //curso.fecha = ;
        cursoService.update(id, curso)
            .then(e => {
                //setExamen(e.data);
                //cambioAcontratar(perfil);
                actualizaSegunPerfil(perfil)//cols2();
                toast.info("Curso Actualizado Correctamente!!", {
                    position: "top-right",
                    autoClose: 1000
                });
                var doc = trabajador.documentos.find(v => v.nombre === "contrato");
                if (!doc) {
                    actualizaSegunPerfil();
                    //return;
                    if (curso.nombre === "3D" && (curso.aprobado === "Verde" || curso.aprobado === "Amarillo" || curso.aprobado === "Falta Informe")) {
                        trabajadorService.updateTrabajador(trabajador.id, { estado: "Listo para contratar" })
                            .then(res => {
                                console.log(res);
                                registroEstado("Listo para contratar", new Date(), trabajador.id, usuario.id);
                                actualizaSegunPerfil(perfil)
                                setLoading(false);
                            })
                            .catch(err => {
                                console.error(err);
                                setLoading(false);
                            });
                    } else {
                        if (curso.nombre === "3D") {
                            trabajadorService.updateTrabajador(trabajador.id, { estado: "Listo para 3D" })
                                .then(res => {
                                    console.log(res);
                                    registroEstado(curso.aprobado + "_3D", new Date(), trabajador.id, usuario.id);
                                    actualizaSegunPerfil(perfil)
                                    setLoading(false);
                                })
                                .catch(err => {
                                    console.error(err);
                                    setLoading(false);
                                });
                        }
                    }
                }
                //setLoading(false);
            })
            .catch(err => {
                console.error(err);
                toast.error("Error al actualizar el curso!!", {
                    position: "top-right",
                    autoClose: 1000
                });
                setLoading(false);
            });
        //cambioAcontratar();
        toggle();
        //setLoading(false);
        //setModalShow(false);
    }

    const onChangeFechaCurso = (newvalue) => {
        setCurso({ ...curso, ['fecha']: newvalue });
    }

    const uploadFileCurso = async (rut, nombre_examen, pperfil) => {
        if (!curso.archivo) {
            alert("Falta agregar archivo");
            return;
        }
        setLoadingButton(true);
        var trabajador = null;
        let docu = null;
        var dir = rut.replace(/\./g, '');
        const config = {
            bucketName: 'csibucket',
            dirName: dir,
            acl: 'public-read',
            //dirName: 'photos', /* optional */
            region: 'sa-east-1',
            accessKeyId: 'AKIA47CRWEBTLXZ6R5JJ',
            secretAccessKey: '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',
        }
        //Creamos al cliente S3
        const ReactS3Client = new S3(config);
        window.Buffer = window.Buffer || require("buffer").Buffer;
        var extension = curso.archivo.name.split('.').pop();

        //Obtenemos al trabajador
        trabajador = await trabajadorService.buscarPorRut(rut);
        if (trabajador) {
            //Si trabajador existe creamos el nombre del documento
            var trab = trabajador.data[0];
            var nombre_documento = trab.nombre + "_" + nombre_examen + "." + extension;
            //Subimos el documento
            docu = await ReactS3Client.uploadFile(curso.archivo, nombre_documento);
            if (docu) {
                //Actualizamos la ruta
                let e = await cursoService.update(curso.id, { ruta: docu.location });
                if (e) {
                    setCurso({ ...curso, ['ruta']: docu.location });
                    //cols2();
                    actualizaSegunPerfil(pperfil);
                    toast.info("Documento subido correctamente!", {
                        position: "top-center",
                        autoClose: 1000
                    });
                }
            }
        }
        setLoadingButton(false);
    }

    /* -- Fin funciones Edición de curso */

    /** Funciones para crear carpeta */
    function retornarNumeroPagina(documento) {
        var pagina = 0;
        if (documento === "antecedente") {
            pagina = 0;
        }
        if (documento === "ficha") {
            pagina = 1;
        }
        if (documento === "contrato") {
            pagina = 2;
        }
        if (documento === "carnet") {
            pagina = 3;
        }
        if (documento === "EXÁMEN PREOCUPACIONAL") {
            pagina = 4;
        }
        return pagina;
    }

    function ordenExportacionPDF(documentos, examenes) {
        var arr = [];
        for (var i in documentos) {
            if (documentos[i].nombre === "antecedente") {
                arr.push({ numero: 0, nombre: documentos[i].nombre, ruta: documentos[i].ruta });
            }
            if (documentos[i].nombre === "ficha") {
                arr.push({ numero: 1, nombre: documentos[i].nombre, ruta: documentos[i].ruta });
            }
            if (documentos[i].nombre === "contrato") {
                arr.push({ numero: 2, nombre: documentos[i].nombre, ruta: documentos[i].ruta });
            }
            if (documentos[i].nombre === "carnet") {
                arr.push({ numero: 3, nombre: documentos[i].nombre, ruta: documentos[i].ruta });
            }
            if (documentos[i].nombre === "transporte_mel") {
                arr.push({ numero: 8, nombre: documentos[i].nombre, ruta: documentos[i].ruta });
            }
            if (documentos[i].nombre === "finiquito") {
                arr.push({ numero: 9, nombre: documentos[i].nombre, ruta: documentos[i].ruta });
            }
            if (documentos[i].nombre === "extravio_credencial") {
                arr.push({ numero: 10, nombre: documentos[i].nombre, ruta: documentos[i].ruta });
            }
        }

        for (var i in examenes) {
            if (examenes[i].nombre === "EXÁMEN PREOCUPACIONAL" && examenes[i].aprobado === "Apto") {
                arr.push({ numero: 4, nombre: examenes[i].nombre, ruta: examenes[i].ruta });
            }
            if (examenes[i].nombre === "ALTURA GEOGRÁFICA" && examenes[i].aprobado === "Apto") {
                arr.push({ numero: 5, nombre: examenes[i].nombre, ruta: examenes[i].ruta });
            }
            if (examenes[i].nombre === "ALTURA FÍSICA" && examenes[i].aprobado === "Apto") {
                arr.push({ numero: 6, nombre: examenes[i].nombre, ruta: examenes[i].ruta });
            }
            if (examenes[i].nombre === "CERO DAÑO" || examenes[i].nombre === "INDUCCION HOMBRE NUEVO") {
                arr.push({ numero: 7, nombre: examenes[i].nombre, ruta: examenes[i].ruta });
            }
        }

        return arr;
    }

    function compare(a, b) {
        if (a.numero < b.numero) {
            return -1;
        }
        if (a.numero > b.numero) {
            return 1;
        }
        return 0;
    }

    const crear_carpeta = async (id_trabajador) => {
        setLoadingButton(true);
        try {
            let t = await trabajadorService.getByID(id_trabajador);
            const merger = new PDFMerger();
            const pdfDoc = await PDFDocument.create();
            if (t) {
                var documentos = t.data.documentos;
                var docs = ordenExportacionPDF(t.data.documentos, t.data.examenes).sort(compare);
                for (var i in docs) {
                    var nombre_documento = docs[i].nombre;
                    //console.log("NOMBRE DOC", nombre_documento);
                    //console.log("N PAGINA", retornarNumeroPagina(nombre_documento));
                    var ruta = docs[i].ruta;
                    var imagen = "";
                    if (ruta) {
                        var extension_array = ruta.split('.').pop();
                        if (extension_array === "pdf") {
                            const existingPdfBytes = await fetch(ruta).then(res => res.arrayBuffer());
                            const firstDonorPdfDoc = await PDFDocument.load(existingPdfBytes, { ignoreEncryption: true });
                            //if(firstDonorPdfDoc)
                            const numberDoc = firstDonorPdfDoc.getPageCount();
                            for (var k = 0; k < numberDoc; k++) {
                                const [firstDonorPage] = await pdfDoc.copyPages(firstDonorPdfDoc, [k]);
                                pdfDoc.addPage(firstDonorPage);
                            }
                        }
                        if (extension_array === "jpg" || extension_array === "png" || extension_array === "jpeg") {
                            const unit = "mm";
                            const size = "letter";
                            const orientation = "portrait";
                            var doc = new JSPdf(orientation, unit, size, true);
                            //Propiedades de la pagina
                            const pageWidth = doc.internal.pageSize.getWidth();
                            const pageHeight = doc.internal.pageSize.getHeight();
                            const pageRatio = pageWidth / pageHeight;
                            imagen = docs[i].ruta;
                            var o = await orientacion(imagen);
                            if (o !== null) {
                                if (o === 6) {
                                    doc.addImage(imagen, 'JPEG', 15, -100, 180, 160, '', 'FAST', -90);
                                } else {
                                    doc.addImage(imagen, 'JPEG', 15, 15, 180, 160, '', 'FAST');
                                }
                            } else {
                                doc.addImage(imagen, 'JPEG', 15, 15, 180, 160, '', 'FAST');
                            }
                            var blob = doc.output("blob");
                            var blob3 = doc.output("arraybuffer");
                            const docPageImg = await PDFDocument.load(blob3, { ignoreEncryption: true });
                            const [firstPageImg] = await pdfDoc.copyPages(docPageImg, [0]);
                            pdfDoc.addPage(firstPageImg);
                        }
                    }
                }
                const pdfBytes = await pdfDoc.save();
                var file = new Blob([new Uint8Array(pdfBytes)], { type: 'application/pdf' });
                //window.open(URL.createObjectURL(file));
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(file);
                var nombres = t.data.nombre.split(' ');
                link.download = `${nombres[0]}_${nombres[1]}_${t.data.ap_paterno}_${t.data.ap_materno}.pdf`;
                link.click();
                //cambioCarpetaEnviada(t.data.id);
                //Cambiamos el estado del trabajador
                if (t.data.estado === "Listo para acreditar") {
                    trabajadorService.updateTrabajador(id_trabajador, { estado: "Carpeta enviada" })
                        .then(resp => {
                            toast.info("Trabajador paso a estado Carpeta enviada", {
                                position: "top-center",
                                autoClose: 3000
                            });
                            actualizaSegunPerfil("acreditador");
                        })
                        .catch(err => {
                            toast.error("Ha ocurrido un error", {
                                position: "top-center",
                                autoClose: 2000
                            });
                        });
                }
            }
        } catch (error) {
            console.error(error);
        }
        setLoadingButton(false);
    }

    async function cambioCarpetaEnviada(id_trabajador) {
        let t = await trabajadorService.getByID(id_trabajador);
        if (t.data) {
            var docs = t.data.documentos;
            var contra = docs.find(v => v.nombre === "contrato");
            if (contra) {
                if (t.data.tresd) {
                    var cursos = t.data.cursos;
                    var td = cursos.find(v => v.nombre === "3D");
                    if (td) {
                        trabajadorService.updateTrabajador(id_trabajador, { estado: "Carpeta enviada" })
                            .then(resp => {
                                console.log(resp);
                                toast.info("Trabajado cambio a estado Carpeta enviada", {
                                    position: "top-right",
                                    autoClose: 1000
                                });
                            })
                            .catch(err => {
                                console.error(err);
                            });
                    }
                } else {
                    trabajadorService.updateTrabajador(id_trabajador, { estado: "Carpeta enviada" })
                        .then(resp => {
                            console.log(resp);
                            toast.info("Trabajado cambio a estado Carpeta enviada", {
                                position: "top-right",
                                autoClose: 1000
                            });
                        })
                        .catch(err => {
                            console.error(err);
                        });
                }
            }
        }

    }

    async function orientacion(imagen) {
        let d = await loadImage(imagen, { meta: true });
        if (d.exif !== undefined) {
            return d.exif.get('Orientation');
        } else {
            return null;
        }
    }

    //** FIN FUNCIONES CARPETA */

    /** Funcion para retornar el nombre del CC */
    async function retornarNombreCC(cc) {
        let ccdata = await ccService.getbyID(cc);
        if (ccdata) {
            return ccdata.data.nombre.toString();
        } else {
            return null;
        }
    }

    const buscarRutBuesqueda = async (perfil_) => {
        setLoadingButton(true);
        if (!rutBusqueda) {
            const perAll = await trabajadorService.getAll();
            if (perAll.data.length) {
                var l = perAll.data.length;
                var fecha_hoy = moment(new Date());
                const data = perAll.data.map(tr => ({ //t.data.map
                    rut: tr.rut,
                    nombre: tr.nombre + ' ' + tr.ap_paterno + ' ' + tr.ap_materno,
                    id: tr.id,
                    documentos: tr.documentos,
                    avance: (tr.documentos.length / 10) * 100,
                    examenes: tr.examenes,
                    estado: tr.estado,
                    avanceDeloitte: avanceDeloitte(tr.documentos, tr.examenes, tr.trabajo_escondida, tr.conduccion, tr.id),
                    respuesta: tr.respuestaws,
                    perfil: "aprobador",
                    fecha_ingreso: moment(tr.createdAt).utc().format("DD/MM/YYYY"),
                    cursos: tr.cursos,
                    dias: fecha_hoy.diff(moment(tr.createdAt), 'days'),
                    trabajo_escondida: tr.trabajo_escondida,
                    conduccion: tr.conduccion,
                    cargo: tr.cargo,
                    centro_costo: tr.centro_costo,
                    estado_3d: tr.cursos.find(v => v.nombre === "3D") ? tr.cursos.find(v => v.nombre === "3D").aprobado : "",
                    disponibilidad: tr.disponibilidad
                }));
                if (perfil_ === "acreditador") {
                    setTableAcreditador(data);
                } else if (perfil_ === "administrador") {
                    setTableAprobador(data);
                }
                //setTableAprobador(data);
                setLoadingButton(false);
            }
        }
        const per = await trabajadorService.buscarPorRut(rutBusqueda);
        if (per.data.length === 0) {
            alert("Persona no encontrada");
            setLoadingButton(false);
            return;
        }
        console.log("Busqueda por Rut", per.data);
        if (per.data.length) {
            var l = per.data.length;
            //cantidad_trabajadores.aprobador = l;//t.length;//t.data.length;
            //cantidad_trabajadores.acreditador = l//t.length;//t.data.length;
            //cantidad_trabajadores.administrador = l;//t.length;//t.data.length;
            var fecha_hoy = moment(new Date());
            const data = per.data.map(tr => ({ //t.data.map
                rut: tr.rut,
                nombre: tr.nombre + ' ' + tr.ap_paterno + ' ' + tr.ap_materno,
                id: tr.id,
                documentos: tr.documentos,
                avance: (tr.documentos.length / 10) * 100,
                examenes: tr.examenes,
                estado: tr.estado,
                avanceDeloitte: avanceDeloitte(tr.documentos, tr.examenes, tr.trabajo_escondida, tr.conduccion, tr.id),
                respuesta: tr.respuestaws,
                perfil: "aprobador",
                fecha_ingreso: moment(tr.createdAt).utc().format("DD/MM/YYYY"),
                cursos: tr.cursos,
                dias: fecha_hoy.diff(moment(tr.createdAt), 'days'),
                trabajo_escondida: tr.trabajo_escondida,
                conduccion: tr.conduccion,
                cargo: tr.cargo,
                centro_costo: tr.centro_costo,
                estado_3d: tr.cursos.find(v => v.nombre === "3D") ? tr.cursos.find(v => v.nombre === "3D").aprobado : "",
                disponibilidad: tr.disponibilidad
            }));
            if (perfil_ === "acreditador") {
                setTableAcreditador(data);
            } else if (perfil_ === "administrador") {
                setTableAprobador(data);
            }
            //setTableAprobador(data);
            setLoadingButton(false);
        }
    };

    const column_admin = [
        {
            header: 'Fecha de Ingreso',
            accessorKey: 'fecha_ingreso',
        },
        {
            header: 'Nombre',
            accessorKey: 'nombre',
        },
        {
            header: 'Rut',
            accessorKey: 'rut',
        },
        {
            header: 'Cargo',
            accessorKey: 'cargo',
        },
        {
            header: 'Centro Costo',
            accessorKey: 'centro_costo',
        },
        {
            header: 'Documentos Trabajador',
            accessorKey: 'avance',
            Cell: ({ cell }) => (
                <Progress percent={cell.getValue()} />
            )
        },
        {
            header: 'Estado',
            accessorKey: 'estado',
        },
        {
            header: 'Días',
            accessorKey: 'dias',
        },
    ];

    const column_examen = useMemo(
        () => [
            {
                id: 'examenes',
                header: 'Exámenes',
                columns: [
                    {
                        header: 'Nombre',
                        accessorKey: 'nombre',
                    },
                    {
                        header: 'Fecha',
                        accessorKey: 'fecha',
                        Cell: ({ cell }) => (
                            moment(cell.getValue()).utc().format("DD-MM-YYYY")
                        )
                    },
                    {
                        header: 'Estado',
                        accessorKey: 'aprobado',
                        Cell: ({ cell }) => {
                            if (cell.getValue() === "Apto") {
                                return <><p style={{ color: "green", fontWeight: "bold" }}>Apto</p> {/* <FontAwesomeIcon icon="fa-solid fa-check-double" style={{ color: "green" }} /> */}</>
                            }
                            else if (cell.getValue() === "Verde") {
                                return <><p style={{ color: "green", fontWeight: "bold" }}>Verde</p> {/* <FontAwesomeIcon icon="fa-solid fa-check-double" style={{ color: "green" }} /> */}</>
                            } else {
                                return cell.getValue();
                            }
                        }
                    },
                    {
                        header: 'Archivo',
                        accessorKey: 'ruta',
                        Cell: ({ cell }) => {
                            return cell.getValue() != null ? <a href={cell.getValue()} alt="Examen" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />;
                        }
                    },
                ]
            }
        ]
    )

    const column_cursose = useMemo(
        () => [
            {
                id: 'cursose',
                header: 'Cursos Especiales',
                columns: [
                    {
                        header: 'Nombre Curso',
                        accessorKey: 'nombre',
                    },
                    {
                        header: 'Fecha Agendamiento',
                        accessorKey: 'fecha',
                        Cell: ({ cell }) => {
                            if (cell.getValue() !== "") {
                                return moment(cell.getValue()).utc().format("DD-MM-YYYY");
                            } else {
                                return "";
                            }
                        }
                    },
                    {
                        header: 'Estado',
                        accessorKey: 'aprobado',
                        Cell: ({ cell }) => {
                            if (cell.getValue() === "Apto") {
                                return <><p style={{ color: "green", fontWeight: "bold" }}>Apto</p> {/* <FontAwesomeIcon icon="fa-solid fa-check-double" style={{ color: "green" }} /> */}</>
                            }
                            else if (cell.getValue() === "Verde") {
                                return <><p style={{ color: "green", fontWeight: "bold" }}>Verde</p> {/* <FontAwesomeIcon icon="fa-solid fa-check-double" style={{ color: "green" }} /> */}</>
                            } else {
                                return cell.getValue();
                            }
                        }
                    },
                    {
                        header: 'Archivo',
                        accessorKey: 'ruta',
                        Cell: ({ cell }) => {
                            return cell.getValue() != null ? <a href={cell.getValue()} alt="Examen" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />;
                        }
                    },
                ]
            }
        ]
    )

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
                    primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
                    info: {
                        main: 'rgb(255,122,0)', //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === 'light'
                                ? 'rgb(188,205,255)' //random light yellow color for the background in light mode
                                : '#000', //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: 'none', //customize typography styles for all buttons in table by default
                        fontSize: '1.2rem',
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: '1.1rem', //override to make tooltip font size larger
                            },
                        },
                    },
                    MuiSwitch: {
                        styleOverrides: {
                            thumb: {
                                color: 'pink', //change the color of the switch thumb in the columns show/hide menu to pink
                            },
                        },
                    },
                },
            }),
        [globalTheme],
    );

    return (
        <>
            <NavBarComponent usuario={auth.user} auth={auth} />
            <LoadingOverlay active={loading} spinner={<PacmanLoader color="#8BC7FA" />} >
                <Container>
                    {loadingPerfil ? (
                        <DotLoaderOverlay size={28} message="Actualizando Perfil..." color="#4F8BFA" />
                    ) : ""}
                    <div className="mt-3"></div>
                    <section class="py-8 mb-3">
                        <div class="container">
                            <div class="px-8 ps-md-12 bg-info overflow-hidden rounded">
                                <div class="row">
                                    <div class="col-12 col-md-7 py-12 mb-2 mb-md-0">
                                        <h3 class="h1 mb-2 text-white px-3">
                                            <span class="text-success">MetaPrime</span>
                                            <span className='m-2'>Demo Gratis</span>
                                        </h3>
                                        <p class="h5 text-white px-3">Información de Trabajadores</p>
                                    </div>
                                    <div class="col-12 col-md-5 py-5 h-100 position-relative">
                                        <img class="d-none d-md-block position-absolute top-50 end-0 w-100 translate-middle-y mt-14" src="https://shuffle.dev/artemis-assets/images/chart-folder.png" alt="" />
                                        <img class="img-fluid d-md-none" src="artemis-assets/images/chart-folder.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <Button onClick={onRefresh}>Refresh</Button> */}
                    {/* {usuario != null ? usuario.perfiles.find(v => v.nombre === "Aprobador") && (
                        <Row>
                            <Stack direction="horizontal" gap={3} className="border p2">
                                <Form.Label>Filtrar por Reprobados:</Form.Label>
                                <Form.Select onChange={(e) => onChangeSelecFiltroAprobado(e.target.value)}>
                                    <option value="Seleccione una opción...">Seleccione una opción...</option>
                                    <option value="Aprobado">Aprobados</option>
                                    <option value="Reprobado">Reprobados</option>
                                </Form.Select>
                                <Button variant="secondary" onClick={btnFiltrarReprobados}>Filtrar</Button>
                            </Stack>
                        </Row>
                    ) : ""}
                    {perfil.reclutador && (
                        <>
                            <Row className="mb-3">
                                <Col sm={4}>
                                    <div className="text-center">
                                        <p>En Proceso:</p>
                                        <h3><span className="circulo_blue">{cantidad_trabajadores.reclutador}</span></h3>
                                    </div>
                                </Col>
                                <Col sm={8}>
                                    <Stack direction="horizontal" gap={3} className="border border-dark p-2 justify-content-center">
                                        <div className="text-center">
                                            <h5>Ingresos del día:</h5>
                                            <p><span className="circulo_blue">{cantidad_trabajadores.ingresos_hoy_reclutador}</span></p>
                                        </div>
                                        <div>
                                            <p>/</p>
                                        </div>
                                        <div className="text-center">
                                            <h5>Meta:</h5>
                                            <p><span className="circulo_blue">13</span></p>
                                        </div>
                                    </Stack>
                                </Col>
                            </Row>
                        </>
                    )}
                    {perfil.contratador && (
                        <Row>
                            <Col>
                                <p>Cantidad de Trabajadores <FontAwesomeIcon icon="fa-solid fa-file-signature" /></p>
                            </Col>
                            <Col sm={2}>
                                <h3><span className="circulo_blue">{tableContratador.length}</span></h3>
                            </Col>
                        </Row>
                    )}
                    {perfil.aprobador && (
                        <Row>
                            <Col>
                                <p>Cantidad de Trabajadores como Aprobador <FontAwesomeIcon icon="fa-solid fa-user-check" /></p>
                            </Col>
                            <Col sm={2}>
                                <h3><span className="circulo_blue">{cantidad_trabajadores.aprobador}</span></h3>
                            </Col>
                        </Row>
                    )}
                    {perfil.acreditador && (
                        <>
                            <Row>
                                <Col>
                                    <p>Cantidad de Trabajadores como Acreditador <FontAwesomeIcon icon="fa-solid fa-user-check" /></p>
                                </Col>
                                <Col sm={2}>
                                    <h3><span className="circulo_blue">{tableAcreditador.length}</span></h3>
                                </Col>
                            </Row>
                        </>
                    )}
                    {perfil.administrador && (
                        <Row>
                            <Col>
                                <p>Cantidad de Trabajadores como Administrador <FontAwesomeIcon icon="fa-solid fa-user-check" /></p>
                            </Col>
                            <Col sm={2}>
                                <h3><span className="circulo_blue">{cantidad_trabajadores.administrador}</span></h3>
                            </Col>
                        </Row>
                    )} */}
                    <div className="mt-3"></div>
                    <Accordion>
                        {perfil.reclutador && (
                            <Accordion.Item eventKey="0" onClick={() => setStatusp("reclutador")}>
                                <Accordion.Header><p>Ver mis Trabajadores como Reclutador <FontAwesomeIcon icon="fa-solid fa-id-card" /></p></Accordion.Header>
                                <Accordion.Body>
                                    {/* <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                        <div className="bd-example">
                                            <DesktopDatePicker
                                                label="Fecha de Inicio"
                                                inputFormat="dd/MM/yyyy"
                                                value={fechainicio}
                                                onChange={onChangeFechaInicio}
                                                renderInput={(params) => <TextField {...params} />}
                                            />{' '}
                                            <DesktopDatePicker
                                                label="Fecha de Termino"
                                                inputFormat="dd/MM/yyyy"
                                                value={fechatermmino}
                                                onChange={onChangeFechaTermino}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                            <div className="mt-2"></div>
                                            <Stack gap={2} direction="horizontal">
                                            </Stack>
                                            <div className="mt-2"></div>
                                            <Stack gap={2} direction="horizontal">
                                                <FormControl sx={{ m: 1, width: 300 }}>
                                                    <InputLabel id="demo-multiple-checkbox-label">Exámenes / Curso</InputLabel>
                                                    <Select
                                                        labelId="demo-multiple-checkbox-label"
                                                        id="demo-multiple-checkbox"
                                                        multiple
                                                        value={examenesFiltro ? examenesFiltro : ""}
                                                        onChange={handleChangeMaestroExamen}
                                                        input={<OutlinedInput label="Exámenes / Curso" />}
                                                        renderValue={(selected) => selected.join(', ')}
                                                        MenuProps={MenuProps}
                                                    >
                                                        {maestroExamenes.map((name) => (
                                                            <MenuItem key={name.nombre} value={name.nombre}>
                                                                <Checkbox checked={examenesFiltro.indexOf(name.nombre) > -1} />
                                                                <ListItemText primary={name.nombre} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <FormControl sx={{ m: 1, width: 300 }}>
                                                    <InputLabel id="demo-simple-select-label">Estado Exámen</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={estadoExamenFiltro ? estadoExamenFiltro : ""}
                                                        label="Age"
                                                        onChange={(e) => setEstadoExamenFiltro(e.target.value)}
                                                    >
                                                        <MenuItem value=""></MenuItem>
                                                        <MenuItem value="Agendado">Agendado</MenuItem>
                                                        <MenuItem value="Por agendar">Por agendar</MenuItem>
                                                        <MenuItem value="Apto">Apto</MenuItem>
                                                        <MenuItem value="No Apto">No Apto</MenuItem>
                                                        <MenuItem value="Derivado Médico">Derivado Médico</MenuItem>
                                                        <MenuItem value="Reagendado">Reagendado</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Stack>

                                            <div className="mt-2"></div>
                                            <Stack gap={2} direction="horizontal">
                                                <FormControl sx={{ m: 1, width: 300 }}>
                                                    <InputLabel id="demo-simple-select-label">Nombre Curso</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={cursoFiltro}
                                                        label="Age"
                                                        onChange={(e) => setCursoFiltro(e.target.value)}
                                                    >
                                                        <MenuItem value=""></MenuItem>
                                                        {cursosEspeciales.map((ce, i) => {
                                                            return (
                                                                <MenuItem key={i} value={ce.nombre}>{ce.nombre}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                                <FormControl sx={{ m: 1, width: 300 }}>
                                                    <InputLabel id="demo-simple-select-label">Estado Curso</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={estadoCursoFiltro}
                                                        label="Age"
                                                        onChange={(e) => setEstadoCursoFiltro(e.target.value)}
                                                    >
                                                        <MenuItem value=""></MenuItem>
                                                        <MenuItem value="Agendado">Agendado</MenuItem>
                                                        <MenuItem value="Reagendado">Reagendado</MenuItem>
                                                        <MenuItem value="Inasistencia">Inasistencia</MenuItem>
                                                        <MenuItem value="Aprobado">Aprobado</MenuItem>
                                                        <MenuItem value="Reprobado">Reprobado</MenuItem>
                                                        <MenuItem value="Por agendar">Por agendar</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Stack>
                                            <div className="mt-2"></div>
                                            <FormControl sx={{ m: 1, width: 300 }}>
                                                <InputLabel id="demo-simple-select-label">Estado 3D</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={tresdFiltro}
                                                    label="Age"
                                                    onChange={(e) => setTresdFiltro(e.target.value)}
                                                >
                                                    <MenuItem value=""></MenuItem>
                                                    <MenuItem value="En Proceso">En Proceso</MenuItem>
                                                    <MenuItem value="Verde">Verde</MenuItem>
                                                    <MenuItem value="Amarillo">Amarillo</MenuItem>
                                                    <MenuItem value="Rojo-Reagendar">Rojo-Reagendar</MenuItem>
                                                    <MenuItem value="Rojo-30 Días">Rojo-30 Días</MenuItem>
                                                    <MenuItem value="Falta Informe">Falta Informe</MenuItem>
                                                    <MenuItem value="Por agendar">Por agendar</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <div className="mt-3"></div>
                                            <Button variant="primary" onClick={buscarPorFechas}>Filtrar</Button>
                                        </div>
                                    </LocalizationProvider> */}
                                    <div className="mt-2"></div>
                                    <Spinner area="tgetuser" />
                                    {/* <BootstrapTable
                                        data={table ? table : ""}
                                        expandableRow={isExpandableRow}
                                        expandComponent={expandComponent}
                                        expandColumnOptions={{ expandColumnVisible: true, expandColumnComponent: expandColumnComponent }}
                                        options={options}
                                        cellEdit={cellEditProp}
                                        search
                                        searchPlaceholder="Buscar ..."
                                        pagination
                                        version="4">
                                        <TableHeaderColumn dataField="id" isKey hidden>ID</TableHeaderColumn>
                                        <TableHeaderColumn dataField="perfil" hidden>ID</TableHeaderColumn>
                                        <TableHeaderColumn dataField="fecha_ingreso" editable={false}>Fecha Ingreso</TableHeaderColumn>
                                        <TableHeaderColumn dataFormat={formatterNombre} dataField="nombre" editable={false}>Nombre</TableHeaderColumn>
                                        <TableHeaderColumn dataField="rut" editable={false}>Rut</TableHeaderColumn>
                                        <TableHeaderColumn dataFormat={formatterCargo} dataField="cargo" editable={false}>Cargo</TableHeaderColumn>
                                        <TableHeaderColumn dataFormat={formatterCC} dataField="centro_costo" editable={false}>CC</TableHeaderColumn>
                                        <TableHeaderColumn dataFormat={ProgressFormat} editable={false}>Avance</TableHeaderColumn>
                                        <TableHeaderColumn dataFormat={ProgressFormatDeloitte} editable={false}>Avance Deloitte</TableHeaderColumn>
                                        <TableHeaderColumn dataField="estado" editable={{ type: 'select', options: { values: estados } }}>Estado</TableHeaderColumn>
                                        <TableHeaderColumn dataFormat={RespuestaFormat} editable={false} width="5%">WS?</TableHeaderColumn>
                                        <TableHeaderColumn dataField="dias" editable={false} width="5%">Días</TableHeaderColumn>
                                        {usuario != null ? usuario.perfiles.find(v => v.nombre === "Aprobador") && (<TableHeaderColumn dataFormat={buttonFormat} editable={false} width="7%">Edición</TableHeaderColumn>) : <TableHeaderColumn dataField="estado">Estado</TableHeaderColumn>}
                                        {usuario != null ? usuario.perfiles.find(v => v.nombre === "Reclutador") && (<TableHeaderColumn dataFormat={btnEditarTrab} editable={false} width="7%">Edición</TableHeaderColumn>) : <TableHeaderColumn dataField="estado">Estado</TableHeaderColumn>}
                                        {usuario != null ? usuario.perfiles.find(v => v.nombre === "Reclutador") && (<TableHeaderColumn dataFormat={buttonFormat} editable={false} width="7%">Ficha</TableHeaderColumn>) : <TableHeaderColumn dataField="estado">Estado</TableHeaderColumn>}
                                    </BootstrapTable>
                                    <div className="mt-2"></div> */}
                                    <MaterialReactTable
                                        columns={column_admin}
                                        data={table}
                                        enableRowActions
                                        localization={MRT_Localization_ES}
                                        muiTableHeadCellProps={{
                                            sx: {
                                                backgroundColor: '#102337',
                                                color: 'white'
                                            }
                                        }}
                                        muiTableHeadCellColumnActionsButtonProps={{
                                            sx: {
                                                color: 'white'
                                            }
                                        }}
                                        muiExpandAllButtonProps={{
                                            sx: {
                                                color: 'white'
                                            }
                                        }}
                                        renderRowActionMenuItems={({ row, closeMenu }) => [
                                            <MenuItem
                                                key={0}
                                                onClick={() => {
                                                    // View profile logic...
                                                    editarTrabajadorReclu(row.original.rut)
                                                }}
                                                sx={{ m: 0 }}
                                            >
                                                <ListItemIcon>
                                                    <EditLocationAltIcon />
                                                </ListItemIcon>
                                                Editar Ingreso
                                            </MenuItem>,
                                            <MenuItem
                                                key={0}
                                                onClick={() => {
                                                    // View profile logic...
                                                    btnEditar(row.original.rut)
                                                }}
                                                sx={{ m: 0 }}
                                            >
                                                <ListItemIcon>
                                                    <EditCalendarIcon />
                                                </ListItemIcon>
                                                Ver Ficha
                                            </MenuItem>,
                                            <MenuItem
                                                key={1}
                                                sx={{ m: 0 }}
                                            >
                                                <ListItemIcon>
                                                    <FolderSharedIcon />
                                                </ListItemIcon>
                                                Descargar Carpeta
                                            </MenuItem>,
                                        ]}
                                        renderDetailPanel={({ row }) => (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-around',
                                                    alignItems: 'flex-start',
                                                    flexDirection: 'column'
                                                }}
                                            >
                                                <Box sx={{
                                                    margin: 2,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                    <Card sx={{ boxShadow: 2 }}>
                                                        <CardHeader
                                                            avatar={
                                                                <Avatar sx={{ bgcolor: 'red' }} aria-label="recipe">
                                                                    R
                                                                </Avatar>
                                                            }
                                                            title={row.original.nombre}
                                                            subheader={row.original.rut}
                                                        />
                                                        <CardContent>
                                                            <table style={{ border: 0, padding: 10, borderSpacing: 3, borderCollapse: 'separate' }}>
                                                                <tr>
                                                                    <td><strong>Cargo:</strong></td>
                                                                    <td>{row.original.cargo}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Centro Costo:</strong></td>
                                                                    <td>{row.original.centro_costo}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Estado:</strong></td>
                                                                    <td>{row.original.estado}</td>
                                                                </tr>
                                                            </table>
                                                        </CardContent>
                                                    </Card>
                                                </Box>
                                                {/** TABLA EXAMENES */}
                                                <Box sx={{
                                                    margin: 3,
                                                    width: '70%',
                                                }}>
                                                    <MaterialReactTable
                                                        columns={column_examen}
                                                        data={row.original.examenes}
                                                        localization={MRT_Localization_ES}
                                                        enableRowActions
                                                        renderRowActions={({ row, table }) => (
                                                            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                                                                <IconButton
                                                                    color="primary"
                                                                    onClick={() =>
                                                                        showEditExamen(row.original.id, row.original.trabajadorId)
                                                                    }
                                                                >
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </Box>
                                                        )}
                                                    />
                                                </Box>
                                                {/** TABLA CURSOS ESPECIALES */}
                                                <Box sx={{
                                                    margin: 3,
                                                    width: '70%',
                                                }}>
                                                    <MaterialReactTable
                                                        columns={column_cursose}
                                                        data={row.original.cursos}
                                                        localization={MRT_Localization_ES}
                                                        enableRowActions
                                                        renderRowActions={({ row, table }) => (
                                                            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                                                                <IconButton
                                                                    color="primary"
                                                                    onClick={() =>
                                                                        showEditCurso(row.original.id, row.original.trabajadorId)
                                                                    }
                                                                >
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </Box>
                                                        )}
                                                    />
                                                </Box>
                                                <Box sx={{
                                                    margin: 3,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '70%'
                                                }}>
                                                    <Grid container spacing={3} sx={{ alignContent: 'center', alignItems: 'center' }}>
                                                        <Grid item xs={12} sm={4}>
                                                            <Card sx={{ boxShadow: 2 }}>
                                                                <Box sx={{ marginBottom: 0 }}>
                                                                    <Toolbar sx={{ backgroundColor: 'greenyellow' }}>
                                                                        <Typography>
                                                                            Carnet CI Frontal
                                                                        </Typography>
                                                                    </Toolbar>
                                                                </Box>
                                                                <CardContent>
                                                                    <Stack direction="vertical" style={{ alignItems: 'center' }}>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "carnet_frontal") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "carnet_frontal") ? <a href={row.original.documentos.find(v => v.nombre === "carnet_frontal").ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                                                    </Stack>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Card sx={{ boxShadow: 2 }}>
                                                                <Box sx={{ marginBottom: 0 }}>
                                                                    <Toolbar sx={{ backgroundColor: 'greenyellow' }}>
                                                                        <Typography>
                                                                            Carnet CI Atrás
                                                                        </Typography>
                                                                    </Toolbar>
                                                                </Box>
                                                                <CardContent>
                                                                    <Stack direction="vertical" style={{ alignItems: 'center' }}>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "carnet_atras") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "carnet_atras") ? <a href={row.original.documentos.find(v => v.nombre === "carnet_atras").ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                                                    </Stack>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Card sx={{ boxShadow: 2 }}>
                                                                <Box sx={{ marginBottom: 0 }}>
                                                                    <Toolbar sx={{ backgroundColor: 'greenyellow' }}>
                                                                        <Typography>
                                                                            Antecedente
                                                                        </Typography>
                                                                    </Toolbar>
                                                                </Box>
                                                                <CardContent>
                                                                    <Stack direction="vertical" style={{ alignItems: 'center' }}>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "antecedente") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "antecedente") ? <a href={row.original.documentos.find(v => v.nombre === "antecedente").ruta} alt="Antecedente" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                                                    </Stack>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Card sx={{ boxShadow: 2 }}>
                                                                <Box sx={{ marginBottom: 0 }}>
                                                                    <Toolbar sx={{ backgroundColor: 'greenyellow' }}>
                                                                        <Typography>
                                                                            Contrato
                                                                        </Typography>
                                                                    </Toolbar>
                                                                </Box>
                                                                <CardContent>
                                                                    <Stack direction="vertical" style={{ alignItems: 'center' }}>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "contrato") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "contrato") ? <a href={row.original.documentos.find(v => v.nombre === "contrato").ruta} alt="Contrato" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                                                    </Stack>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Card sx={{ boxShadow: 2 }}>
                                                                <Box sx={{ marginBottom: 0 }}>
                                                                    <Toolbar sx={{ backgroundColor: 'greenyellow' }}>
                                                                        <Typography>
                                                                            AFP
                                                                        </Typography>
                                                                    </Toolbar>
                                                                </Box>
                                                                <CardContent>
                                                                    <Stack direction="vertical" style={{ alignItems: 'center' }}>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "afp") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "afp") ? <a href={row.original.documentos.find(v => v.nombre === "afp").ruta} alt="AFP" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                                                    </Stack>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Card sx={{ boxShadow: 2 }}>
                                                                <Box sx={{ marginBottom: 0 }}>
                                                                    <Toolbar sx={{ backgroundColor: 'greenyellow' }}>
                                                                        <Typography>
                                                                            Salud
                                                                        </Typography>
                                                                    </Toolbar>
                                                                </Box>
                                                                <CardContent>
                                                                    <Stack direction="vertical" style={{ alignItems: 'center' }}>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "salud") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "salud") ? <a href={row.original.documentos.find(v => v.nombre === "salud").ruta} alt="Salud" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                                                    </Stack>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Card sx={{ boxShadow: 2 }}>
                                                                <Box sx={{ marginBottom: 0 }}>
                                                                    <Toolbar sx={{ backgroundColor: 'greenyellow' }}>
                                                                        <Typography>
                                                                            AFC
                                                                        </Typography>
                                                                    </Toolbar>
                                                                </Box>
                                                                <CardContent>
                                                                    <Stack direction="vertical" style={{ alignItems: 'center' }}>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "afc") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "afc") ? <a href={row.original.documentos.find(v => v.nombre === "afc").ruta} alt="AFC" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                                                    </Stack>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Card sx={{ boxShadow: 2 }}>
                                                                <Box sx={{ marginBottom: 0 }}>
                                                                    <Toolbar sx={{ backgroundColor: 'greenyellow' }}>
                                                                        <Typography>
                                                                            CV
                                                                        </Typography>
                                                                    </Toolbar>
                                                                </Box>
                                                                <CardContent>
                                                                    <Stack direction="vertical" style={{ alignItems: 'center' }}>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "cv") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "cv") ? <a href={row.original.documentos.find(v => v.nombre === "cv").ruta} alt="CV" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                                                    </Stack>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Card sx={{ boxShadow: 2 }}>
                                                                <Box sx={{ marginBottom: 0 }}>
                                                                    <Toolbar sx={{ backgroundColor: 'greenyellow' }}>
                                                                        <Typography>
                                                                            Certificado de Estudios
                                                                        </Typography>
                                                                    </Toolbar>
                                                                </Box>
                                                                <CardContent>
                                                                    <Stack direction="vertical" style={{ alignItems: 'center' }}>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "estudio") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "estudio") ? <a href={row.original.documentos.find(v => v.nombre === "estudio").ruta} alt="Certficado de Estudios" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                                                    </Stack>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Card sx={{ boxShadow: 2 }}>
                                                                <Box sx={{ marginBottom: 0 }}>
                                                                    <Toolbar sx={{ backgroundColor: 'greenyellow' }}>
                                                                        <Typography>
                                                                            Foto Tamaño Carnet con Fondo Blanco
                                                                        </Typography>
                                                                    </Toolbar>
                                                                </Box>
                                                                <CardContent>
                                                                    <Stack direction="vertical" style={{ alignItems: 'center' }}>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "foto") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "foto") ? <a href={row.original.documentos.find(v => v.nombre === "foto").ruta} alt="Foto Tamaño Carnet con Fondo Blanco" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                                                    </Stack>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                        {row.original.conduccion && (
                                                            <div>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Card sx={{ boxShadow: 2 }}>
                                                                        <Box sx={{ marginBottom: 0 }}>
                                                                            <Toolbar sx={{ backgroundColor: 'greenyellow' }}>
                                                                                <Typography>
                                                                                    Licencia de Conducir
                                                                                </Typography>
                                                                            </Toolbar>
                                                                        </Box>
                                                                        <CardContent>
                                                                            <Stack direction="vertical" style={{ alignItems: 'center' }}>
                                                                                <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "licencia_conducir") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                                                                <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "licencia_conducir") ? <a href={row.original.documentos.find(v => v.nombre === "licencia_conducir").ruta} alt="Licencia de Conducir" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                                                            </Stack>
                                                                        </CardContent>
                                                                    </Card>
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Card sx={{ boxShadow: 2 }}>
                                                                        <Box sx={{ marginBottom: 0 }}>
                                                                            <Toolbar sx={{ backgroundColor: 'greenyellow' }}>
                                                                                <Typography>
                                                                                    Hoja de vidal del Conductor
                                                                                </Typography>
                                                                            </Toolbar>
                                                                        </Box>
                                                                        <CardContent>
                                                                            <Stack direction="vertical" style={{ alignItems: 'center' }}>
                                                                                <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "hoja_vida_conductor") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                                                                <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "hoja_vida_conductor") ? <a href={row.original.documentos.find(v => v.nombre === "hoja_vida_conductor").ruta} alt="Hoja de vida del Conductor" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                                                            </Stack>
                                                                        </CardContent>
                                                                    </Card>
                                                                </Grid>
                                                            </div>
                                                        )}
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        )}
                                    />
                                    <div className="mt-2"></div>
                                    <Button variant="primary" onClick={exportExcel}>{loadingButton ? <BeatLoader color="white" /> : (<>Exportar a Excel <FontAwesomeIcon icon="fa-solid fa-file-excel" /> </>)}</Button>
                                </Accordion.Body>
                            </Accordion.Item>
                        )}
                        {perfil.contratador && (
                            <Accordion.Item eventKey="1" onClick={() => setStatusp("contratador")}>
                                <Accordion.Header><p>Ver mis Trabajadores como Contratador <FontAwesomeIcon icon="fa-solid fa-file-signature" /></p></Accordion.Header>
                                <Accordion.Body>
                                    <BootstrapTable
                                        data={tableContratador ? tableContratador : ""}
                                        expandableRow={isExpandableRow}
                                        expandComponent={expandComponent}
                                        expandColumnOptions={{ expandColumnVisible: true, expandColumnComponent: expandColumnComponent }}
                                        options={options}
                                        cellEdit={cellEditProp}
                                        pagination
                                        search
                                        searchPlaceholder="Buscar..."
                                        version="4">
                                        <TableHeaderColumn dataField="id" isKey hidden>ID</TableHeaderColumn>
                                        <TableHeaderColumn dataField="perfil" hidden>ID</TableHeaderColumn>
                                        <TableHeaderColumn dataField="fecha_ingreso" editable={false}>Fecha Ingreso</TableHeaderColumn>
                                        <TableHeaderColumn dataField="nombre" editable={false}>Nombre</TableHeaderColumn>
                                        <TableHeaderColumn dataField="cargo" editable={false}>Cargo</TableHeaderColumn>
                                        <TableHeaderColumn dataField="centro_costo" editable={false}>CC</TableHeaderColumn>
                                        <TableHeaderColumn dataField="rut" editable={false}>Rut</TableHeaderColumn>
                                        <TableHeaderColumn dataFormat={ProgressFormat} editable={false}>Avance</TableHeaderColumn>
                                        <TableHeaderColumn dataFormat={ProgressFormatDeloitte} editable={false}>Avance Deloitte</TableHeaderColumn>
                                        <TableHeaderColumn dataField="estado" editable={{ type: 'select', options: { values: estados } }}>Estado</TableHeaderColumn>
                                        <TableHeaderColumn dataFormat={RespuestaFormat} editable={false}>Respondio WS?</TableHeaderColumn>
                                        {usuario != null ? usuario.perfiles.find(v => v.nombre === "Contratador") && (<TableHeaderColumn dataFormat={buttonFormat} editable={false}>Edición</TableHeaderColumn>) : <TableHeaderColumn dataField="estado">Estado</TableHeaderColumn>}
                                        {/* <TableHeaderColumn dataFormat={buttonFormattCrearCarpeta} editable={false}>Carpeta</TableHeaderColumn> */}
                                    </BootstrapTable>
                                    <Button variant="primary">Exportar a Excel</Button> {' '}
                                    <Button variant="primary" onClick={exportExcelIDOK}>{loadingButton ? <BeatLoader color="white" /> : (<>Exportar a IDOK <FontAwesomeIcon icon="fa-solid fa-file-excel" /> </>)}</Button>
                                    {/* <Button variant="primary" onClick={() => docToPdf(4)}>WORD TO PDF</Button> */}
                                    {/* <Button variant="primary" onClick={crear_carpeta}>CARPETA</Button> */}
                                </Accordion.Body>
                            </Accordion.Item>
                        )}
                        {perfil.aprobador && (
                            <Accordion.Item eventKey="2" onClick={() => setStatusp("aprobador")}>
                                <Accordion.Header>Ver mis Trabajadores como Aprobador <FontAwesomeIcon icon="fa-solid fa-user-check" /></Accordion.Header>
                                <Accordion.Body>
                                    <BootstrapTable
                                        data={tableAprobador ? tableAprobador : ""}
                                        expandableRow={isExpandableRow}
                                        expandComponent={expandComponent}
                                        expandColumnOptions={{ expandColumnVisible: true, expandColumnComponent: expandColumnComponent }}
                                        options={options}
                                        cellEdit={cellEditProp}
                                        version="4">
                                        <TableHeaderColumn dataField="id" isKey hidden>ID</TableHeaderColumn>
                                        <TableHeaderColumn dataField="perfil" hidden>ID</TableHeaderColumn>
                                        <TableHeaderColumn dataField="fecha_ingreso" editable={false}>Fecha Ingreso</TableHeaderColumn>
                                        <TableHeaderColumn dataField="nombre" editable={false}>Nombre</TableHeaderColumn>
                                        <TableHeaderColumn dataField="rut" editable={false}>Rut</TableHeaderColumn>
                                        <TableHeaderColumn dataField="cargo" editable={false}>Cargo</TableHeaderColumn>
                                        <TableHeaderColumn dataField="centro_costo" editable={false}>CC</TableHeaderColumn>
                                        <TableHeaderColumn dataFormat={ProgressFormat} editable={false}>Avance</TableHeaderColumn>
                                        <TableHeaderColumn dataFormat={ProgressFormatDeloitte} editable={false}>Avance Deloitte</TableHeaderColumn>
                                        <TableHeaderColumn dataField="estado" editable={{ type: 'select', options: { values: estados } }}>Estado</TableHeaderColumn>
                                        <TableHeaderColumn dataFormat={RespuestaFormat} editable={false}>Respondio WS?</TableHeaderColumn>

                                        {usuario != null ? usuario.perfiles.find(v => v.nombre === "Aprobador") && (<TableHeaderColumn dataFormat={buttonFormat} editable={false}>Edición</TableHeaderColumn>) : <TableHeaderColumn dataField="estado">Estado</TableHeaderColumn>}
                                        <TableHeaderColumn dataFormat={buttonFormattCrearCarpeta} editable={false}>Carpeta</TableHeaderColumn>
                                    </BootstrapTable>
                                    <Button variant="primary" onClick={exportExcel}>{loadingButton ? <BeatLoader color="white" /> : (<>Exportar a Excel <FontAwesomeIcon icon="fa-solid fa-file-excel" /> </>)}</Button>
                                </Accordion.Body>
                            </Accordion.Item>
                        )}
                        {perfil.acreditador && (
                            <Accordion.Item eventKey="3" onClick={() => setStatusp("acreditador")}>
                                <Accordion.Header>Ver mis Trabajadores como Acreditador <FontAwesomeIcon icon="fa-solid fa-user-check" /></Accordion.Header>
                                <Accordion.Body>
                                    <Stack direction="horizontal" gap={2} className="mt-2">
                                        <TextField
                                            label="Rut"
                                            name="rut"
                                            id="rut"
                                            fullWidth
                                            onChange={(e) => setRutBusqueda(e.target.value)}
                                        />
                                        <Button variant="primary" onClick={() => buscarRutBuesqueda("acreditador")}>{loadingButton ? <BeatLoader color="white" /> : "BUSCAR"}</Button>
                                    </Stack>
                                    <Spinner area="tgetall" />
                                    <BootstrapTable
                                        data={tableAcreditador ? tableAcreditador : ""}
                                        expandableRow={isExpandableRow}
                                        expandComponent={expandComponent}
                                        expandColumnOptions={{ expandColumnVisible: true, expandColumnComponent: expandColumnComponent }}
                                        options={options}
                                        cellEdit={cellEditProp}
                                        pagination
                                        search
                                        version="4">
                                        <TableHeaderColumn dataField="id" isKey hidden>ID</TableHeaderColumn>
                                        <TableHeaderColumn dataField="perfil" hidden>ID</TableHeaderColumn>
                                        <TableHeaderColumn dataField="fecha_ingreso" editable={false}>Fecha Ingreso</TableHeaderColumn>
                                        <TableHeaderColumn dataFormat={formatterNombre} dataField="nombre" editable={false}>Nombre</TableHeaderColumn>
                                        <TableHeaderColumn dataField="rut" editable={false}>Rut</TableHeaderColumn>
                                        <TableHeaderColumn dataFormat={formatterCargo} dataField="cargo" editable={false}>Cargo</TableHeaderColumn>
                                        <TableHeaderColumn dataFormat={formatterCC} dataField="centro_costo" editable={false}>CC</TableHeaderColumn>
                                        <TableHeaderColumn dataFormat={ProgressFormat} editable={false}>Avance</TableHeaderColumn>
                                        <TableHeaderColumn dataFormat={ProgressFormatDeloitte} editable={false}>Avance Deloitte</TableHeaderColumn>
                                        <TableHeaderColumn dataField="estado" editable={{ type: 'select', options: { values: estados } }} width="12%">Estado</TableHeaderColumn>

                                        {usuario != null ? usuario.perfiles.find(v => v.nombre === "Acreditador") && (<TableHeaderColumn dataFormat={buttonFormat} editable={false}>Edición</TableHeaderColumn>) : <TableHeaderColumn dataField="estado">Estado</TableHeaderColumn>}
                                        <TableHeaderColumn dataFormat={buttonFormattCrearCarpeta} editable={false}>Carpeta</TableHeaderColumn>
                                    </BootstrapTable>
                                    <Button variant="primary" onClick={exportExcel}>{loadingButton ? <BeatLoader color="white" /> : (<>Exportar a Excel <FontAwesomeIcon icon="fa-solid fa-file-excel" /> </>)}</Button>
                                </Accordion.Body>
                            </Accordion.Item>
                        )}
                        {perfil.administrador && (
                            <Accordion.Item eventKey="4" onClick={() => setStatusp("administrador")}>
                                <Accordion.Header>Ver totos los Trabajadores   <FontAwesomeIcon icon="fa-solid fa-user-check" /></Accordion.Header>
                                <Accordion.Body>
                                    <Stack direction="horizontal" gap={2} className="mt-2">
                                        <TextField
                                            label="Rut"
                                            name="rut"
                                            id="rut"
                                            fullWidth
                                            onChange={(e) => setRutBusqueda(e.target.value)}
                                        />
                                        <Button variant="primary" onClick={() => buscarRutBuesqueda("administrador")}>{loadingButton ? <BeatLoader color="white" /> : "BUSCAR"}</Button>
                                    </Stack>
                                    <div className="mt-2"></div>
                                    <Spinner area="tgetall" />
                                    {/* <BootstrapTable
                                        data={tableAprobador ? tableAprobador : ""}
                                        expandableRow={isExpandableRow}
                                        expandComponent={expandComponent}
                                        expandColumnOptions={{ expandColumnVisible: true, expandColumnComponent: expandColumnComponent }}
                                        options={options}
                                        cellEdit={cellEditProp}
                                        search
                                        searchPlaceholder="Buscar..."
                                        columnFilter
                                        pagination
                                        version="4"
                                        
                                        tableContainerClass="table-responsive"
                                    >
                                        <TableHeaderColumn dataField="id" isKey hidden>ID</TableHeaderColumn>
                                        <TableHeaderColumn dataField="fecha_ingreso" editable={false} width="10%">Fecha Ingreso</TableHeaderColumn>
                                        <TableHeaderColumn dataFormat={formatterNombre} dataField="nombre" editable={false}>Nombre</TableHeaderColumn>
                                        <TableHeaderColumn dataField="rut" editable={false}>Rut</TableHeaderColumn>
                                        <TableHeaderColumn dataFormat={formatterCargo} dataField="cargo" editable={false}>Cargo</TableHeaderColumn>
                                        <TableHeaderColumn dataFormat={formatterCC} dataField="centro_costo" editable={false}>CC</TableHeaderColumn>
                                        
                                        <TableHeaderColumn dataField="estado" width="12%" editable={{ type: 'select', options: { values: estados } }} tdStyle={bloqueado}>Estado</TableHeaderColumn>
                                        <TableHeaderColumn dataFormat={RespuestaFormat} editable={false} width="5%">WS?</TableHeaderColumn>
                                        <TableHeaderColumn dataField="dias" editable={false} width="5%">Días</TableHeaderColumn>
                                        <TableHeaderColumn dataFormat={buttonFormat} editable={false} width="7%">Ficha</TableHeaderColumn>
                                        <TableHeaderColumn dataFormat={buttonFormattCrearCarpeta} editable={false}>Carpeta</TableHeaderColumn>
                                        
                                        {usuario != null ? usuario.perfiles.find(v => v.nombre === "Aprobador") && (<TableHeaderColumn dataFormat={buttonFormat} editable={false}>Edición</TableHeaderColumn>) : <TableHeaderColumn dataField="estado">Estado</TableHeaderColumn>}
                                    </BootstrapTable>
                                    <div className="mt-3"></div> */}
                                    {/* <ThemeProvider theme={tableTheme}> */}
                                    <MaterialReactTable
                                        columns={column_admin}
                                        data={tableAprobador}
                                        enableRowActions
                                        localization={MRT_Localization_ES}
                                        muiTableHeadCellProps={{
                                            sx: {
                                                backgroundColor: '#102337',
                                                color: 'white'
                                            }
                                        }}
                                        muiTableHeadCellColumnActionsButtonProps={{
                                            sx: {
                                                color: 'white'
                                            }
                                        }}
                                        muiExpandAllButtonProps={{
                                            sx: {
                                                color: 'white'
                                            }
                                        }}
                                        renderRowActionMenuItems={({ row, closeMenu }) => [
                                            <MenuItem
                                                key={0}
                                                onClick={() => {
                                                    // View profile logic...
                                                    btnEditar(row.original.rut)
                                                }}
                                                sx={{ m: 0 }}
                                            >
                                                <ListItemIcon>
                                                    <EditCalendarIcon />
                                                </ListItemIcon>
                                                Ver Ficha
                                            </MenuItem>,
                                            <MenuItem
                                                key={1}
                                                sx={{ m: 0 }}
                                            >
                                                <ListItemIcon>
                                                    <FolderSharedIcon />
                                                </ListItemIcon>
                                                Descargar Carpeta
                                            </MenuItem>,
                                        ]}
                                        renderDetailPanel={({ row }) => (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-around',
                                                    alignItems: 'flex-start',
                                                    flexDirection: 'column'
                                                }}
                                            >
                                                <Box sx={{
                                                    margin: 2,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                    <Card sx={{ boxShadow: 2 }}>
                                                        <CardHeader
                                                            avatar={
                                                                <Avatar sx={{ bgcolor: 'red' }} aria-label="recipe">
                                                                    R
                                                                </Avatar>
                                                            }
                                                            title={row.original.nombre}
                                                            subheader={row.original.rut}
                                                        />
                                                        <CardContent>
                                                            <table style={{ border: 0, padding: 10, borderSpacing: 3, borderCollapse: 'separate' }}>
                                                                <tr>
                                                                    <td><strong>Cargo:</strong></td>
                                                                    <td>{row.original.cargo}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Centro Costo:</strong></td>
                                                                    <td>{row.original.centro_costo}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><strong>Estado:</strong></td>
                                                                    <td>{row.original.estado}</td>
                                                                </tr>
                                                            </table>
                                                        </CardContent>
                                                    </Card>
                                                </Box>
                                                {/** TABLA EXAMENES */}
                                                <Box sx={{
                                                    margin: 3,
                                                    width: '70%',
                                                }}>
                                                    <MaterialReactTable
                                                        columns={column_examen}
                                                        data={row.original.examenes}
                                                        localization={MRT_Localization_ES}
                                                        enableRowActions
                                                        renderRowActions={({ row, table }) => (
                                                            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                                                                <IconButton
                                                                    color="primary"
                                                                    onClick={() =>
                                                                        showEditExamen(row.original.id, row.original.trabajadorId)
                                                                    }
                                                                >
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </Box>
                                                        )}
                                                    />
                                                </Box>
                                                {/** TABLA CURSOS ESPECIALES */}
                                                <Box sx={{
                                                    margin: 3,
                                                    width: '70%',
                                                }}>
                                                    <MaterialReactTable
                                                        columns={column_cursose}
                                                        data={row.original.cursos}
                                                        localization={MRT_Localization_ES}
                                                        enableRowActions
                                                        renderRowActions={({ row, table }) => (
                                                            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                                                                <IconButton
                                                                    color="primary"
                                                                    onClick={() =>
                                                                        showEditCurso(row.original.id, row.original.trabajadorId)
                                                                    }
                                                                >
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </Box>
                                                        )}
                                                    />
                                                </Box>
                                                <Box sx={{
                                                    margin: 3,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '70%'
                                                }}>
                                                    <Grid container spacing={3} sx={{ alignContent: 'center', alignItems: 'center' }}>
                                                        <Grid item xs={12} sm={4}>
                                                            <Card sx={{ boxShadow: 2 }}>
                                                                <Box sx={{ marginBottom: 0 }}>
                                                                    <Toolbar sx={{ backgroundColor: 'greenyellow' }}>
                                                                        <Typography>
                                                                            Carnet CI Frontal
                                                                        </Typography>
                                                                    </Toolbar>
                                                                </Box>
                                                                <CardContent>
                                                                    <Stack direction="vertical" style={{ alignItems: 'center' }}>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "carnet_frontal") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "carnet_frontal") ? <a href={row.original.documentos.find(v => v.nombre === "carnet_frontal").ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                                                    </Stack>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Card sx={{ boxShadow: 2 }}>
                                                                <Box sx={{ marginBottom: 0 }}>
                                                                    <Toolbar sx={{ backgroundColor: 'greenyellow' }}>
                                                                        <Typography>
                                                                            Carnet CI Atrás
                                                                        </Typography>
                                                                    </Toolbar>
                                                                </Box>
                                                                <CardContent>
                                                                    <Stack direction="vertical" style={{ alignItems: 'center' }}>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "carnet_atras") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "carnet_atras") ? <a href={row.original.documentos.find(v => v.nombre === "carnet_atras").ruta} alt="CI" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                                                    </Stack>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Card sx={{ boxShadow: 2 }}>
                                                                <Box sx={{ marginBottom: 0 }}>
                                                                    <Toolbar sx={{ backgroundColor: 'greenyellow' }}>
                                                                        <Typography>
                                                                            Antecedente
                                                                        </Typography>
                                                                    </Toolbar>
                                                                </Box>
                                                                <CardContent>
                                                                    <Stack direction="vertical" style={{ alignItems: 'center' }}>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "antecedente") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "antecedente") ? <a href={row.original.documentos.find(v => v.nombre === "antecedente").ruta} alt="Antecedente" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                                                    </Stack>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Card sx={{ boxShadow: 2 }}>
                                                                <Box sx={{ marginBottom: 0 }}>
                                                                    <Toolbar sx={{ backgroundColor: 'greenyellow' }}>
                                                                        <Typography>
                                                                            Contrato
                                                                        </Typography>
                                                                    </Toolbar>
                                                                </Box>
                                                                <CardContent>
                                                                    <Stack direction="vertical" style={{ alignItems: 'center' }}>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "contrato") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "contrato") ? <a href={row.original.documentos.find(v => v.nombre === "contrato").ruta} alt="Contrato" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                                                    </Stack>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Card sx={{ boxShadow: 2 }}>
                                                                <Box sx={{ marginBottom: 0 }}>
                                                                    <Toolbar sx={{ backgroundColor: 'greenyellow' }}>
                                                                        <Typography>
                                                                            AFP
                                                                        </Typography>
                                                                    </Toolbar>
                                                                </Box>
                                                                <CardContent>
                                                                    <Stack direction="vertical" style={{ alignItems: 'center' }}>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "afp") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "afp") ? <a href={row.original.documentos.find(v => v.nombre === "afp").ruta} alt="AFP" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                                                    </Stack>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Card sx={{ boxShadow: 2 }}>
                                                                <Box sx={{ marginBottom: 0 }}>
                                                                    <Toolbar sx={{ backgroundColor: 'greenyellow' }}>
                                                                        <Typography>
                                                                            Salud
                                                                        </Typography>
                                                                    </Toolbar>
                                                                </Box>
                                                                <CardContent>
                                                                    <Stack direction="vertical" style={{ alignItems: 'center' }}>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "salud") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "salud") ? <a href={row.original.documentos.find(v => v.nombre === "salud").ruta} alt="Salud" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                                                    </Stack>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Card sx={{ boxShadow: 2 }}>
                                                                <Box sx={{ marginBottom: 0 }}>
                                                                    <Toolbar sx={{ backgroundColor: 'greenyellow' }}>
                                                                        <Typography>
                                                                            AFC
                                                                        </Typography>
                                                                    </Toolbar>
                                                                </Box>
                                                                <CardContent>
                                                                    <Stack direction="vertical" style={{ alignItems: 'center' }}>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "afc") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "afc") ? <a href={row.original.documentos.find(v => v.nombre === "afc").ruta} alt="AFC" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                                                    </Stack>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Card sx={{ boxShadow: 2 }}>
                                                                <Box sx={{ marginBottom: 0 }}>
                                                                    <Toolbar sx={{ backgroundColor: 'greenyellow' }}>
                                                                        <Typography>
                                                                            CV
                                                                        </Typography>
                                                                    </Toolbar>
                                                                </Box>
                                                                <CardContent>
                                                                    <Stack direction="vertical" style={{ alignItems: 'center' }}>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "cv") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "cv") ? <a href={row.original.documentos.find(v => v.nombre === "cv").ruta} alt="CV" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                                                    </Stack>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Card sx={{ boxShadow: 2 }}>
                                                                <Box sx={{ marginBottom: 0 }}>
                                                                    <Toolbar sx={{ backgroundColor: 'greenyellow' }}>
                                                                        <Typography>
                                                                            Certificado de Estudios
                                                                        </Typography>
                                                                    </Toolbar>
                                                                </Box>
                                                                <CardContent>
                                                                    <Stack direction="vertical" style={{ alignItems: 'center' }}>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "estudio") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "estudio") ? <a href={row.original.documentos.find(v => v.nombre === "estudio").ruta} alt="Certficado de Estudios" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                                                    </Stack>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Card sx={{ boxShadow: 2 }}>
                                                                <Box sx={{ marginBottom: 0 }}>
                                                                    <Toolbar sx={{ backgroundColor: 'greenyellow' }}>
                                                                        <Typography>
                                                                            Foto Tamaño Carnet con Fondo Blanco
                                                                        </Typography>
                                                                    </Toolbar>
                                                                </Box>
                                                                <CardContent>
                                                                    <Stack direction="vertical" style={{ alignItems: 'center' }}>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "foto") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                                                        <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "foto") ? <a href={row.original.documentos.find(v => v.nombre === "foto").ruta} alt="Foto Tamaño Carnet con Fondo Blanco" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                                                    </Stack>
                                                                </CardContent>
                                                            </Card>
                                                        </Grid>
                                                        {row.original.conduccion && (
                                                            <div>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Card sx={{ boxShadow: 2 }}>
                                                                        <Box sx={{ marginBottom: 0 }}>
                                                                            <Toolbar sx={{ backgroundColor: 'greenyellow' }}>
                                                                                <Typography>
                                                                                    Licencia de Conducir
                                                                                </Typography>
                                                                            </Toolbar>
                                                                        </Box>
                                                                        <CardContent>
                                                                            <Stack direction="vertical" style={{ alignItems: 'center' }}>
                                                                                <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "licencia_conducir") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                                                                <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "licencia_conducir") ? <a href={row.original.documentos.find(v => v.nombre === "licencia_conducir").ruta} alt="Licencia de Conducir" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                                                            </Stack>
                                                                        </CardContent>
                                                                    </Card>
                                                                </Grid>
                                                                <Grid item xs={12} sm={4}>
                                                                    <Card sx={{ boxShadow: 2 }}>
                                                                        <Box sx={{ marginBottom: 0 }}>
                                                                            <Toolbar sx={{ backgroundColor: 'greenyellow' }}>
                                                                                <Typography>
                                                                                    Hoja de vidal del Conductor
                                                                                </Typography>
                                                                            </Toolbar>
                                                                        </Box>
                                                                        <CardContent>
                                                                            <Stack direction="vertical" style={{ alignItems: 'center' }}>
                                                                                <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "hoja_vida_conductor") ? <FontAwesomeIcon icon="a-solid fa-check" style={{ color: "green" }} /> : <FontAwesomeIcon icon="fa-solid fa-xmark" style={{ color: "red" }} />}</div>
                                                                                <div className="ms-auto">{row.original.documentos.find(v => v.nombre === "hoja_vida_conductor") ? <a href={row.original.documentos.find(v => v.nombre === "hoja_vida_conductor").ruta} alt="Hoja de vida del Conductor" target="_blank"><FontAwesomeIcon icon="fa-solid fa-file-pdf" /></a> : <FontAwesomeIcon icon="fa-solid fa-xmark" />}</div>
                                                                            </Stack>
                                                                        </CardContent>
                                                                    </Card>
                                                                </Grid>
                                                            </div>
                                                        )}
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        )}
                                    />
                                    {/* </ThemeProvider> */}
                                    <Button variant="primary" onClick={exportExcel}>{loadingButton ? <BeatLoader color="white" /> : (<>Exportar a Excel <FontAwesomeIcon icon="fa-solid fa-file-excel" /> </>)}</Button>
                                </Accordion.Body>
                            </Accordion.Item>
                        )}
                    </Accordion>
                    <MotivoModal
                        show={modalShowM}
                        onHide={() => setModalShowM(false)}
                        onChangeMotivo={onChangeMotivo}
                        onChangeBloqueo={onChangeBloqueo}
                        ingresarMotivo={ingresarMotivo}
                    />
                    <AddExmaenModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        addEx={handleChangeExamen}
                        date={starDate}
                        setDate={setStarDate}
                        examen={examen}
                        editEx={editExamen}
                        upLoadF={uploadFileExamen}
                        loadB={loadingButton}
                        tra={trabajador}
                        rechazo={rechazo}
                        addRechazo={handleChangeRechazo}
                        perfil={statusp}
                        onChangeDate={handleChangeExamen}
                        files={files}
                        getInputProps={getInputProps}
                        getRootProps={getRootProps}
                    />
                    <ModalEditarCursoLIsta
                        isShowing={isShowing}
                        hide={toggle}
                        curso={curso}
                        loadB={loadingButton}
                        tra={trabajador}
                        perfil={statusp}
                        addEx={handleChangeCurso}
                        editCu={editCurso}
                        onChangeFechaCurso={onChangeFechaCurso}
                        fechaCurso={fechaCurso}
                        upLoadF={uploadFileCurso}
                        onChangeTresD={handleChange3D}
                    />
                </Container>
            </LoadingOverlay>
        </>
    )

}

export default ListaTrabajadores;