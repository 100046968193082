import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { useAuth } from "./AuthProvider";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const auth = useAuth();

    //const {getSession, authenticate } = useContext(AccountContext);

    useEffect(() => {
        /* getSession().then(session=>{
            if(session) {
                navigate("/inicio");
            }
        }); */
    }, []);

    const ingresar = () => {
        setLoading(true);
        auth.loginAction(email, password)
            .then(resp=>{
                console.log(resp);
                setLoading(false);
            })
            .catch(err=>{
                console.log("Error", err);
                toast.error("Error al autenticar", {autoClose: 3000});
                setLoading(false);
            });
        /* if (email !== "" && password !== "") {
            const data = { email: email, password: password };
            auth.loginAction(data);
            return;
        }
        alert("Ingresar Datos"); */

        /* setLoading(true);
        authenticate(email, password)
            .then((data) => {
                console.log("Logged in!", data);
                setLoading(false);
            })
            .catch((err) => {
                console.error("Failed to login", err);
                toast.error(err.message, {
                    position: "top-center",
                    autoClose: 3000
                });
                setLoading(false);
            }); */
    }


    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Correo:</Form.Label>
                            <Form.Control type="text" onChange={(e) => setEmail(e.target.value)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Password:</Form.Label>
                            <Form.Control type="password" onChange={(e) => setPassword(e.target.value)} />
                        </Form.Group>
                        <div className="mt-3"></div>
                        <Button variant="success" onClick={ingresar}>{loading && (<span className="spinner-border spinner-border-sm"></span>)}<span>Ingresar</span></Button>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Login;