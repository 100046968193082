
class ExternoService {
    
    getCiudades() {
        return fetch("https://api.allorigins.win/raw?url=https://apis.digital.gob.cl/dpa/comunas")
        .then((res)=>res.json())
        .then((json)=>{
            return json
        });
    }

    getPaises() {
        return fetch("https://restcountries.com/v3.1/region/ame") //https://paises-america.gqrlab.com/public/api/v1/paises
        .then((res)=>res.json())
        .then((json)=>{
            return json
        });
    }
}

export default new ExternoService();