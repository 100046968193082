import React, { useEffect } from "react";
import { Container, Image } from 'react-bootstrap';
import { Route, Routes } from "react-router-dom";
//import './App.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ConfirmProvider } from "material-ui-confirm";
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './estilos.css';
//const wbm = require('wbm');
import Login from "./components/Login";
import Estadistica from "./components/estadistica.component";
import FmIngresroTrabajador from "./components/fmIngresoTrabajador.component";
import FmTrabajador from "./components/fmTrabajador.component";
import Documentos from "./components/fmdocumentos.component";
import Graficos from "./components/graficos.component";
import Inicio from "./components/inicio.component";
import ListaTrabajadores from "./components/list-trabajador.component";
import Cargo from "./components/maestros/cargo.component";
import CC from "./components/maestros/centrocosto.component";
import CursosEspeciales from "./components/maestros/cursosespeciales.component";
import DNC from "./components/maestros/dnc.component";
import MaestroExamen from "./components/maestros/maestroexamen.component";
import Sucursal from "./components/maestros/sucursal.component";
import TraspasoReclutador from "./components/maestros/traspasoreclutador.component";
import Powerbi from "./components/powerbi.component";
import PrivateRoute from "./components/private.component";
import Registro from "./components/registro.component";
import TresdENPROBI from "./components/reportes/3denpro.component";
import TresdSINBI from "./components/reportes/3dsini.component";
import TresdTotal from "./components/reportes/3dtotal.component";
import TresdVABI from "./components/reportes/3dva.component";
import AcreditadorPBI from "./components/reportes/acreditados.component";
import ContratosNoFaenaBI from "./components/reportes/contratosnofaena.component";
import EspecialidadBI from "./components/reportes/especialidad.component";
import GestionAcrePBI from "./components/reportes/gestionacre.component";
import ReclutadorBI from "./components/reportes/reclutador.component";
import ResumenGnalBI from "./components/reportes/resumengnal.component";
import ResumentotalBI from "./components/reportes/resumentotal.component";
import TiemposPBI from "./components/reportes/tiempos.component";
import Tresd from "./components/tresd.component";
import TresdPage from "./components/tresdpage.component";
import usuarioService from "./services/usuario.service";

library.add(fas);
var QRCode = require('qrcode.react');

function App() {
  //const [status, setStatus] = useState(false);
  //const [usuario, setUsuario] = useState('');

  //const { getSession, logout, status, usuario } = useContext(AccountContext);

  useEffect(() => {
    /* getSession().then((session) => {
      const ssids = session.idToken.payload.sub;
    })
      .catch(err => {
        console.error(err);
      }); */
  }, []);

  function retreiveUsuario(ssid) {
    usuarioService.getBySSID(ssid)
      .then(user => {
        var u = user.data[0];
        //setUsuario(u);
      })
      .catch(err => {
        console.error(err);
      });
  }

  /* function ConNavbar() {
    const [status, setStatus] = useState(false);

    const { getSession, logout } = useContext(AccountContext);

    useEffect(() => {
      getSession().then((session) => {
        console.log("Session: ", session);
        setStatus(true);
      });
    }, []);

    return (
      <header className="p-3 bg-dark text-white">
        <Nav>
          <Nav.Item>
            <Nav.Link href={status ? "/inicio" : "/"}><Image src="/img/logo_header.png" width="55" height="55" /></Nav.Link>
            {status && (
              <Button variant="primary" onClick={logout}>Cerrar</Button>
            )}
          </Nav.Item>
        </Nav>
      </header>
    )
  } */

  return (
    <ConfirmProvider>
    <div>
      {/* <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="#">
            <img src="/img/logo_header.png" alt="Ecora" width="55" height="55" className="d-inline-block align-top" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
            <Nav className="me-auto">
              <Nav.Link href={status ? "/inicio" : "/"} className="nav-link">Inicio</Nav.Link>
              <Nav.Link href="/trabajadores" className="nav-link">Trabajadores</Nav.Link>
              {usuario != null ? usuario.perfiles.find(v => v.nombre === "Reclutador") && (<Nav.Link href="/ingtrabajador" className="nav-link">Ingresar Trabajador</Nav.Link>) : ""}
              {usuario != null ? usuario.perfiles.find(v => v.nombre === "Administrador") && (
                <>
                  <NavDropdown title="Bases" id="collasible-nav-dropdown">
                    {usuario != null ? usuario.perfiles.find(v => v.nombre === "Administrador") && (<NavDropdown.Item href="/sucursal">Sucursal</NavDropdown.Item>) : ""}
                    {usuario != null ? usuario.perfiles.find(v => v.nombre === "Administrador") && (<NavDropdown.Item href="/cc">Unidadades de Negocio</NavDropdown.Item>) : ""}
                    {usuario != null ? usuario.perfiles.find(v => v.nombre === "Administrador") && (<NavDropdown.Item href="/cargos">Cargos</NavDropdown.Item>) : ""}
                    {usuario != null ? usuario.perfiles.find(v => v.nombre === "Administrador") && (<NavDropdown.Item href="/cursosespeciales">Cursos Especiales</NavDropdown.Item>) : ""}
                    {usuario != null ? usuario.perfiles.find(v => v.nombre === "Administrador") && (<NavDropdown.Item href="/maestroexamen">Exámenes</NavDropdown.Item>) : ""}
                  </NavDropdown>
                  <NavDropdown title="Estadísticas" id="collasible-nav-dropdown">
                    {usuario != null ? usuario.perfiles.find(v => v.nombre === "Administrador") && (<NavDropdown.Item href="/graficos">Graficos</NavDropdown.Item>) : ""}
                    {usuario != null ? usuario.perfiles.find(v => v.nombre === "Administrador") && (<NavDropdown.Item href="/reclutadorbi">Reclutadores</NavDropdown.Item>) : ""}
                    <DropdownSubmenu title="3D">
                      <NavDropdown.Item href="/tresdtotal">3D General</NavDropdown.Item>
                    </DropdownSubmenu>
                    <NavDropdown.Item href="/resumentotal">Resumen Estados</NavDropdown.Item>
                    <NavDropdown.Item href="/especialidad">Resumen Especialidad</NavDropdown.Item>
                    <NavDropdown.Item href="/resumengnal">Resumen</NavDropdown.Item>
                    <DropdownSubmenu title="Acreditadores">
                      <NavDropdown.Item href="/acreditados">Estado Acreditadores</NavDropdown.Item>
                      <NavDropdown.Item href="/contratonofaena">Contratos que no estan en faena</NavDropdown.Item>
                      <NavDropdown.Item href="/reportgestionacre">Gestión por Acreditador</NavDropdown.Item>
                    </DropdownSubmenu>
                    <NavDropdown.Item href="/reporttiempo">Tiempo entre Estados</NavDropdown.Item>
                  </NavDropdown>
                </>
              ) : ""}

              {usuario != null ? usuario.perfiles.find(v => v.nombre === "Administrador") && (<Nav.Link href="/traspaso" className="nav-link">Traspasar un Reclutador</Nav.Link>) : ""}
              {usuario != null ? usuario.perfiles.find(v => v.nombre === "Administrador") && (<Nav.Link href="/dnc" className="nav-link">DNC</Nav.Link>) : ""}
            </Nav>
            {status && (
              <Nav className="navbar-nav ml-auto">
                <Nav.Link href={"#"} className="nav-item">{usuario != null ? usuario.nombre : "Error de conexión"}</Nav.Link>
                <Nav.Link href="/" className="nav-link" onClick={logout}>Salir</Nav.Link>
              </Nav>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar> */}
      {/* <ConNavbar /> */}
      <ToastContainer />
      <div className="mt-3"></div>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/trabajador/:id_trabajador" element={<FmTrabajador />} />
            <Route path="/" element={<PrivateRoute />}>
              <Route path="/trabajador/:id_trabajador/:ejecutiva" element={<FmTrabajador />} />
              <Route path="/inicio" element={<Inicio />} />
              <Route path="/documentos" element={<Documentos />} />
              <Route path="/trabajadores" element={<ListaTrabajadores />} />
              <Route path="/ingtrabajador" element={<FmIngresroTrabajador />} />
              <Route path="/registro" element={<Registro />} />
              <Route path="/sucursal" element={<Sucursal />} />
              <Route path="/cc" element={<CC />} />
              <Route path="/cargos" element={<Cargo />} />
              <Route path="/traspaso" element={<TraspasoReclutador />} />
              <Route path="/ingtrabajador/:rut_trabajador" element={<FmIngresroTrabajador />} />
              <Route path="/graficos" element={<Graficos />} />
              <Route path="/cursosespeciales" element={<CursosEspeciales />} />
              <Route path="/tresd/:id_trabajador" element={<Tresd />} />
              <Route path="/maestroexamen" element={<MaestroExamen />} />
              <Route path="/dnc" element={<DNC />} />
              <Route path="/estadistica" element={<Estadistica />} />
              <Route path="/powerbi" element={<Powerbi />} />
              <Route path="/reclutadorbi" element={<ReclutadorBI />} />
              <Route path="/tresdva" element={<TresdVABI />} />
              <Route path="/tresdenpro" element={<TresdENPROBI />} />
              <Route path="/tresdsini" element={<TresdSINBI />} />
              <Route path="/especialidad" element={<EspecialidadBI />} />
              <Route path="/resumentotal" element={<ResumentotalBI />} />
              <Route path="/resumengnal" element={<ResumenGnalBI />} />
              <Route path="/tresdtotal" element={<TresdTotal />} />
              <Route path="/acreditados" element={<AcreditadorPBI />} />
              <Route path="/contratonofaena" element={<ContratosNoFaenaBI />} />
              <Route path="/pagetresd" element={<TresdPage />} />
              <Route path="/reportgestionacre" element={<GestionAcrePBI />} />
              <Route path="/reporttiempo" element={<TiemposPBI />} />
              {/* <Route path="/login" element={<Login />} /> */}
            </Route>
          </Routes>
      <div className="b-example-divider"></div>
      <Container>
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
          <div className="col-md-4 d-flex align-items-center">
            <a href="#" className="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
              <Image src="/img/icon512.png" width="33" heigth="33" />
            </a>
            <span className="text-muted">© 2024 Metaprime Ltda.</span>
          </div>
          <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
            <li className="ms-3"></li>
          </ul>
        </footer>
      </Container>
    </div>
    </ConfirmProvider>
  );
}

export default App;
