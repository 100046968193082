import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import BeatLoader from 'react-spinners/BeatLoader';

//Material UI Design
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import esLocale from 'date-fns/locale/es';

const ModalEditarCursoLIsta = ({ isShowing, hide, curso, addEx, tra, upLoadF, loadB, onChangeFechaCurso, perfil, editCu, fechaCurso, onChangeTresD }) => isShowing ? ReactDOM.createPortal(
    <React.Fragment>
        <Modal show={isShowing} onHide={hide}>
            <Modal.Header closeButton>
                <Modal.Title>Editar Curso Especial</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>Nombre:</Form.Label>
                    <Form.Control type="text" value={curso.nombre} onChange={e => addEx(e.target.value, "nombre")} disabled />
                </Form.Group>
                {curso.nombre !== "3D" ? (
                    <>
                        <Form.Group className="mb-3">
                            <Form.Label>Hora:</Form.Label>
                            <Form.Control type="text" value={curso.hora} placeholder="HH:MM" onChange={e => addEx(e.target.value, "hora")} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                <DesktopDatePicker
                                    label="Fecha:"
                                    inputFormat="dd/MM/yyyy"
                                    value={curso.fecha}
                                    onChange={onChangeFechaCurso}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Form.Group>


                        <Form.Group className="mb-3">
                            <Form.Label>Ciudad:</Form.Label>
                            <Form.Control type="text" value={curso.ciudad} placeholder="Ingresar Ciudad" onChange={e => addEx(e.target.value, "ciudad")} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Dirección:</Form.Label>
                            <Form.Control type="text" value={curso.direccion} placeholder="Ingresar Dirección" onChange={e => addEx(e.target.value, "direccion")} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Laboratorio:</Form.Label>
                            <Form.Control type="text" value={curso.laboratorio} placeholder="Ingresar Laboratorio" onChange={e => addEx(e.target.value, "laboratorio")} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Codigo de reserva:</Form.Label>
                            <Form.Control type="text" value={curso.reserva} placeholder="Ingresar codigo de reserva" onChange={e => addEx(e.target.value, "reserva")} />
                        </Form.Group>
                    </>
                ) : ""}
                {curso.nombre === "3D" ? (
                    <>
                        <Form.Group className="mb-3">
                            <Form.Label>Observaciones:</Form.Label>
                            <Form.Control type="textarea" value={curso.oc} placeholder="Ingresar codigo de reserva" onChange={e => addEx(e.target.value, "oc")} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                <DesktopDatePicker
                                    label="Fecha Inicio"
                                    inputFormat="dd/MM/yyyy"
                                    value={curso.fecha_inicio_3d}
                                    onChange={(e) => addEx(e, "fecha_inicio_3d")}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                <DesktopDatePicker
                                    label="Fecha Termino"
                                    inputFormat="dd/MM/yyyy"
                                    value={curso.fecha_termino_3d}
                                    onChange={(e) => addEx(e, "fecha_termino_3d")}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Resultado Final</Form.Label>
                            <Form.Select value={curso.aprobado} onChange={e => addEx(e.target.value, "aprobado")}>
                                <option>Seleccione estado ...</option>
                                <option value="VERDE">VERDE</option>
                                <option value="AMARILLO">AMARILLO</option>
                                <option value="ROJO">ROJO</option>
                                {/* <option>Seleccione estado ...</option>
                            <option value="En Proceso">En Proceso</option>
                            <option value="Verde">Verde</option>
                            <option value="Amarillo">Amarillo</option>
                            <option value="Rojo-Reagendar">Rojo-Reagendar</option>
                            <option value="Rojo-30 Días">Rojo-30 Días</option>
                            <option value="Rojo-Rojo-no competente">Rojo-no competente</option>
                            <option value="Falta Informe">Falta Informe</option>
                            <option value="Abandona Proceso">Abandona Proceso</option>
                            <option value="Certificado Rechazado">Certificado Rechazado</option>
                            <option value="Falta evaluar dimensión">Falta evaluar dimensión</option>
                            <option value="Inasistencia">Inasistencia</option> */}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Perfil</Form.Label>
                            <Form.Select value={curso.perfil} onChange={e => addEx(e.target.value, "perfil")}>
                                <option>Seleccione estado ...</option>
                                <option value="TECNICO">TECNICO</option>
                                <option value="SUPERVISOR">SUPERVISOR</option>
                                <option value="STAFF">STAFF</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Resultado Psicolaboral</Form.Label>
                            <Form.Select value={curso.psico} onChange={e => addEx(e.target.value, "psico")}>
                                <option>Seleccione estado ...</option>
                                <option value="VERDE">VERDE</option>
                                <option value="AMARILLO">AMARILLO</option>
                                <option value="ROJO">ROJO</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Resultado Conductual</Form.Label>
                            <Form.Select value={curso.conductual} onChange={e => addEx(e.target.value, "conductual")}>
                                <option>Seleccione estado ...</option>
                                <option value="VERDE">VERDE</option>
                                <option value="AMARILLO">AMARILLO</option>
                                <option value="ROJO">ROJO</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Resultado Identicicación de Riesgos</Form.Label>
                            <Form.Select value={curso.riesgos} onChange={e => addEx(e.target.value, "riesgos")}>
                                <option>Seleccione estado ...</option>
                                <option value="VERDE">VERDE</option>
                                <option value="AMARILLO">AMARILLO</option>
                                <option value="ROJO">ROJO</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Resultado Conocimiento en Seguridad</Form.Label>
                            <Form.Select value={curso.seguridad} onChange={e => addEx(e.target.value, "seguridad")}>
                                <option>Seleccione estado ...</option>
                                <option value="VERDE">VERDE</option>
                                <option value="AMARILLO">AMARILLO</option>
                                <option value="ROJO">ROJO</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Resultado Tecnico Teórica</Form.Label>
                            <Form.Select value={curso.teorica} onChange={e => addEx(e.target.value, "teorica")}>
                                <option>Seleccione estado ...</option>
                                <option value="VERDE">VERDE</option>
                                <option value="AMARILLO">AMARILLO</option>
                                <option value="ROJO">ROJO</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Resultado Tecnico Práctica</Form.Label>
                            <Form.Select value={curso.practica} onChange={e => addEx(e.target.value, "practica")}>
                                <option>Seleccione estado ...</option>
                                <option value="VERDE">VERDE</option>
                                <option value="AMARILLO">AMARILLO</option>
                                <option value="ROJO">ROJO</option>
                            </Form.Select>
                        </Form.Group>
                    </>
                ) : (
                    <Form.Group className='mb-3'>
                        <Form.Label>Estado</Form.Label>
                        <Form.Select value={curso.aprobado} onChange={e => addEx(e.target.value, "aprobado")}>
                            <option>Seleccione estado ...</option>
                            <option value="Agendado">Agendado</option>
                            <option value="Reagendado">Reagendado</option>
                            <option value="Inasistencia">Inasistencia</option>
                            <option value="Aprobado">Aprobado</option>
                            <option value="Reprobado">Reprobado</option>
                            <option value="Abandona Proceso">Abandona Proceso</option>
                        </Form.Select>
                    </Form.Group>
                )}
                {curso.aprobado && curso.aprobado === "En Proceso" && curso.nombre === "3D" ? (
                    <>
                        <Form.Group className="mb-3">
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                                <DesktopDatePicker
                                    label="Fecha:"
                                    inputFormat="dd/MM/yyyy"
                                    value={curso.fecha}
                                    onChange={onChangeFechaCurso}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Hora:</Form.Label>
                            <Form.Control type="text" value={curso.hora} placeholder="HH:MM" onChange={e => addEx(e.target.value, "hora")} />
                        </Form.Group>
                    </>
                ) : ""}
                {curso.aprobado && curso.aprobado === "Agendar Coaching OnLine" ? (
                    <Form.Group className='mb-3'>
                        <Form.Label>Link</Form.Label>
                        <Form.Control type="text" />
                        <div className='mt-3'></div>
                        <Button variant='success'>Mandar WS</Button>
                    </Form.Group>
                ) : ""}
                {curso.aprobado && curso.aprobado === "Agendar Presencial" ? (
                    <Form.Group className='mb-3'>
                        <Form.Label>Día</Form.Label>
                        <Form.Control type="text" placeholder='día-mes-año' onChange={e => onChangeTresD(e.target.value, "dia")} />
                        <Form.Label>Desde</Form.Label>
                        <Form.Control type="text" placeholder='ciudad de origen' onChange={e => onChangeTresD(e.target.value, "desde")} />
                        <Form.Label>Hasta</Form.Label>
                        <Form.Control type="text" placeholder='ciudad destino' onChange={e => onChangeTresD(e.target.value, "hasta")} />
                        <Form.Label>Hora Recogida Taxi</Form.Label>
                        <Form.Control type="text" placeholder='HH:MM' onChange={e => onChangeTresD(e.target.value, "hora_taxi")} />
                        <Form.Label>Dirección Hotel</Form.Label>
                        <Form.Control type="text" onChange={e => onChangeTresD(e.target.value, "direccion_hotel")} />
                        <div className='mt-3'></div>
                        <Button variant='success'>Mandar WS</Button>
                    </Form.Group>
                ) : ""}
                <Form.Group className='mb-3'>
                    <Form.Label>Archivo</Form.Label>
                    <Form.Control type="file" onChange={e => addEx(e.target.files[0], "archivo")}></Form.Control>
                    <Button variant="outline-success" disabled={loadB} onClick={!loadB ? () => upLoadF(tra.rut, curso.nombre, perfil) : null}>{loadB ? <BeatLoader color="green" /> : "Subir"}</Button>
                </Form.Group>
                <Button variant="secondary" onClick={() => editCu(curso.id, perfil)}>Actualizar</Button>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='primary' onClick={hide}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    </React.Fragment >, document.body
) : null

export default ModalEditarCursoLIsta;