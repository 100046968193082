import http from "../http-common";

class UsuarioService {

    create(data) {
        return http.post("/usuario",data);
    }

    getByID(id) {
        return http.get(`/usuario/${id}`);
    }

    addPerfil(data) {
        return http.post("/usuario/addperfil", data);
    }

    getBySSID(ssid) {
        return http.get(`/usuario/ssid/${ssid}`);
    }

    signin(data) {
        return http.post('/usuario/signin', data);
    }
}

export default new UsuarioService();