import React, { useEffect, useState, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, Row, Col, Form, Modal, Stack, OverlayTrigger, Accordion, Card } from 'react-bootstrap';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ComposedChart } from 'recharts';

//Servicios
import trabajadoService from "../services/trabajador.service";
import centroCostoService from "../services/cc.service";

const data = [
    {
        name: 'Page A',
        uv: 4000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: 'Page B',
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: 'Page C',
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        name: 'Page E',
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
];

const Graficos = () => {
    const [trabajadores, setTrabajadores] = useState([]);
    const [datos, setDatos] = useState([]);
    const [datos_contratos, setDatosContratos] = useState([]);

    useEffect(() => {
        trabajadoService.getAll()
            .then(response => {
                console.log("TRABAJADORES", response.data);
                setTrabajadores(response.data);
                crearData(response.data);
                centroCostoService.getAll()
                    .then(cc => {
                        graficoPorContrato(response.data, cc.data);
                    })
                    .catch(errcc => {
                        console.error(errcc);
                    });
            })
            .catch(err => {
                console.error(err);
            });
    }, []);

    function crearData(lista) {
        var cant_proceso = 0;
        var cant_desvinculado = 0;
        var listo_contratar = 0;
        var listo_acreditar = 0;
        var abandona_prceso = 0;
        var enproceso = lista.filter(v => v.estado === "En Proceso");
        var arrlistocontrata = lista.filter(v => v.estado === "Listo para contratar");
        var arrlistoacredita = lista.filter(v => v.estado === "Listo para acreditar");
        var arrdesvinculado = lista.filter(v => v.estado === "Desvinculado");
        var arrabandonaproceso = lista.filter(v => v.estado === "Abandona proceso");

        if (enproceso) {
            cant_proceso = enproceso.length;
        }
        if (arrlistocontrata) {
            listo_contratar = arrlistocontrata.length;
        }
        if (arrlistoacredita) {
            listo_acreditar = arrlistoacredita.length;
        }
        if (arrdesvinculado) {
            cant_desvinculado = arrdesvinculado.length;
        }
        if (arrabandonaproceso) {
            abandona_prceso = arrabandonaproceso.length;
        }

        const data = [
            {
                nombre: "En Proceso",
                valor: cant_proceso
            },
            {
                nombre: "Listo para contratar",
                valor: listo_contratar
            },
            {
                nombre: "Listo para acreditar",
                valor: listo_acreditar
            },
            {
                nombre: "Desvinculados",
                valor: cant_desvinculado
            },
            {
                nombre: "Abandona proceso",
                valor: abandona_prceso
            }
        ]

        setDatos(data);
    }

    function graficoPorContrato(lista_trabajadores, lista_contratos) {
        var data = [];
        lista_contratos.map(con => {
            console.log("CON ID", con.id);
            console.log("FILTER TRAB", lista_trabajadores.filter(v=>v.centro_costo === con.id.toString()));
            var graf = {
                nombre: con.nombre,
                valor: lista_trabajadores.filter(v => v.centro_costo === con.id.toString()).length
            }
            data.push(graf);
        });
        console.log("DATA CC", data);
        setDatosContratos(data);
    }

    return (
        <Container>
            <h5 className="text-center">Estados</h5>
            {/* <ResponsiveContainer width="100%" height="100%">
                <BarChart width={150} height={40} data={data}>
                    <Bar dataKey="uv" fill="#8884d8" />
                </BarChart>
            </ResponsiveContainer> */}
            <div style={{ width: '100%', height: 300 }}>
                <ResponsiveContainer>
                    <BarChart
                        /* width={1000}
                        height={300} */
                        data={datos}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="nombre" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="valor" fill="#8884d8" />
                        {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
                    </BarChart>
                </ResponsiveContainer>
            </div>
            <div className="mt-3"></div>
            <h5 className="text-center">Contratos</h5>
            <div style={{ width: '100%', height: 800 }}>
                <ResponsiveContainer>
                    <BarChart
                        layout="vertical"
                        /* width={500}
                        height={400} */
                        data={datos_contratos}
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 20,
                            left: 20,
                        }}
                    >
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis type="number"/>
                        <YAxis dataKey="nombre" type="category" width={250} />
                        <Tooltip />
                        <Legend />
                        {/* <Area dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
                        <Bar dataKey="valor" fill="#413ea0" />
                        {/* <Line dataKey="uv" stroke="#ff7300" /> */}
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </Container>
    )
}

export default Graficos;