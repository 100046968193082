import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

/** Servicios */
import usuarioService from "../services/usuario.service";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("site"))?.user || null);
    const [token, setToken] = useState(JSON.parse(localStorage.getItem("site"))?.token || "");
    const navigate = useNavigate();

    const loginAction = async (username, password) => {
        return await new Promise(async (resolve, reject) => {
            try {
                console.log("User Name", username);
                const response = await usuarioService.signin({mail: username, password: password});
                if (response.data) {
                    setUser(response.data.user);
                    setToken(response.data.accessToken);
                    var data = { user: response.data.user, token: response.data.accessToken }
                    localStorage.setItem("site", JSON.stringify(data));
                    resolve(data);
                    navigate("/inicio");
                    return;
                }
            } catch (error) {
                reject(error);
            }
        });
    };
   /*  const loginAction = async (data) => {
        try {
            if(data.email == "jleiva@metaprime.cl" && data.password == "12345") {
                setUser({id: 1, nombre: "Jocelyn", direction: "Avenida", perfiles: [{nombre: "Administrador"}, {nombre: "Reclutador"}]});
                setToken("12345678");
                var data = {user: {id: 1, nombre: "Jocelyn", direction: "Avenida", perfiles: [{nombre: "Administrador"}, {nombre: "Reclutador"}]}, token: "12345678"}
                localStorage.setItem("site", JSON.stringify(data));
                navigate("/inicio");
                return;
            } */
            /* const response = await fetch("your-api-endpoint/auth/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });
            const res = await response.json();
            if (res.data) {
                setUser(res.data.user);
                setToken(res.token);
                localStorage.setItem("site", res.token);
                navigate("/dashboard");
                return;
            } */
            //throw new Error(res.message);
            /* throw new Error("Mensaje de Error");
        } catch (err) {
            console.error(err);
        }
    }; */

    const logOut = () => {
        setUser(null);
        setToken("");
        localStorage.removeItem("site");
        navigate("/");
    };

    return (
        <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};