import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import { Box, TextField, Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Switch, FormGroup, Checkbox, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

//Servicios
import trabajadorService from "../services/trabajador.service";


const initialCeim = {
    fecha: "",
    perfil: "",
    cc: "",
    oc: "",
    observacion: "",
}

const initialCoaching = {
    fecha: "",
    horario: "",
    dimensiones: []
}

const initialPasajes = {
    dia: "",
    hora: "",
    origen: "",
    destino: "",
    taxi: "",
    hotel: "",
    direccion_hotel: ""
}

const Tresd = () => {
    const { id_trabajador } = useParams();
    const [ceim, setCeim] = useState(initialCeim);
    const [requerido, setRequerido] = useState(false);
    const [coaching, setCoachong] = useState(initialCoaching);
    const [pasaje, setPasaje] = useState(initialPasajes);
    const [ceims, setCeims] = useState([]);
    const [trabajador, setTrabajador] = useState('');

    useEffect(()=>{
        retreiveTrabajador(id_trabajador);
    },[]);

    function retreiveTrabajador(id) {
        trabajadorService.getByID(id)
        .then(res=>{
            setTrabajador(res.data);
        })
        .catch(err=>{
            console.error(err);
        });
    }

    const handleChangeCeim = (valor, nombre) => {
        setCeim({ ...ceim, [nombre]: valor });
    }

    const handleChangeRequerido = (e) => {
        setRequerido(e);
    }

    const handleChangeCoaching = (valor, nombre) => {
        setCoachong({ ...coaching, [nombre]: valor });
    }

    const handleChangePasaje = (valor, nombre) => {
        setPasaje({ ...pasaje, [nombre]: valor });
    }

    const ingresarCeim = () => {
        var array = [...ceims];
        array.push(ceim);
        console.log("ARRAY CEIM", array);
        setCeims(array);
    }

    const ingresarCoaching = () => {
        console.log("COACHING", coaching);
    }

    const ingresarPasaje = () => {
        console.log("PASAJE", pasaje);
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Typography variant="h4">{trabajador.nombre+" "+trabajador.ap_paterno}</Typography>
                    <Typography variant="text">{trabajador.rut}</Typography>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h4 className="text-center">CEIM</h4>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 350 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell component="th">Fecha</TableCell>
                                    <TableCell component="th" align="right">Perfil</TableCell>
                                    <TableCell component="th" align="right">Orden de Compra</TableCell>
                                    <TableCell component="th" align="right">Centro Costo</TableCell>
                                    <TableCell component="th" align="right">Observación</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {ceims.map((row) => {
                                    return (
                                        <TableRow
                                            key={row.perfil}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="right">{row.fecha}</TableCell>
                                            <TableCell align="right">{row.perfil}</TableCell>
                                            <TableCell align="right">{row.oc}</TableCell>
                                            <TableCell align="right">{row.cc}</TableCell>
                                            <TableCell align="right">{row.observacion}</TableCell>
                                        </TableRow>
                                    )
                                })}
                                {/* {rows.map((row) => (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right">{row.calories}</TableCell>
                                        <TableCell align="right">{row.fat}</TableCell>
                                        <TableCell align="right">{row.carbs}</TableCell>
                                        <TableCell align="right">{row.protein}</TableCell>
                                    </TableRow>
                                ))} */}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                label="Fecha"
                                onChange={(e) => handleChangeCeim(e.target.value, "fecha")}
                            />
                            <TextField
                                label="Perfil"
                                onChange={(e) => handleChangeCeim(e.target.value, "perfil")}
                            />
                            <TextField
                                label="Gerencia"
                                onChange={(e) => handleChangeCeim(e.target.value, "cc")}
                            />
                            <TextField
                                label="Orden de Compra"
                                onChange={(e) => handleChangeCeim(e.target.value, "oc")}
                            />
                            <TextField
                                label="Observación"
                                onChange={(e) => handleChangeCeim(e.target.value, "observacion")}
                            />
                        </div>
                    </Box>
                    <Button variant="contained" endIcon={<SendIcon />} onClick={ingresarCeim}>Ingresar CEIM</Button>{' '}
                    <Button variant="contained" color="success">Generar Excel</Button>
                </Col>
                <Col>
                    <h4 className="text-center">Coaching</h4>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                label="Fecha Coaching"
                                onChange={(e) => handleChangeCoaching(e.target.value, "fecha")}
                            />
                            <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">Horario</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="radio-buttons-group"
                                >
                                    <FormControlLabel value="10:00 HRS AM" control={<Radio onChange={(e) => handleChangeCoaching(e.target.value, "horario")} />} label="10:00 HRS AM" />
                                    <FormControlLabel value="14:00 HRS" control={<Radio onChange={(e) => handleChangeCoaching(e.target.value, "horario")} />} label="14:00 HRS" />
                                    <FormControlLabel value="17:00 HRS" control={<Radio onChange={(e) => handleChangeCoaching(e.target.value, "horario")} />} label="17:00 HRS" />
                                </RadioGroup>
                            </FormControl>
                            {/* <TextField
                                label="Dimesión"
                            /> */}
                            <FormGroup>
                                <FormControlLabel control={<Checkbox />} label="Dimensión 1" />
                                <FormControlLabel control={<Checkbox />} label="Dimensión 2" />
                            </FormGroup>
                        </div>
                    </Box>
                    <Button variant="contained" endIcon={<SendIcon />} onClick={ingresarCoaching}>Ingresar Coaching</Button>{' '}
                    <Button variant="contained" color="success">Generar Excel</Button>
                </Col>
                <Col>
                    <h4 className="text-center">Pasajes</h4>
                    <FormGroup>
                        <FormControlLabel control={<Switch onChange={(e) => handleChangeRequerido(e.target.checked)} />} label="Requerido" />
                    </FormGroup>
                    {requerido ? (
                        <div>
                            <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <TextField
                                    label="Día"
                                    onChange={(e) => handleChangePasaje(e.target.value, "dia")}
                                />
                                <TextField
                                    label="Hora"
                                    onChange={(e) => handleChangePasaje(e.target.value, "hora")}
                                />
                                <TextField
                                    label="Origen"
                                    onChange={(e) => handleChangePasaje(e.target.value, "origen")}
                                />
                                <TextField
                                    label="Destino"
                                    onChange={(e) => handleChangePasaje(e.target.value, "destino")}
                                />
                                <TextField
                                    label="Taxi"
                                    onChange={(e) => handleChangePasaje(e.target.value, "taxi")}
                                />
                                <TextField
                                    label="Hotel"
                                    onChange={(e) => handleChangePasaje(e.target.value, "hotel")}
                                />
                                <TextField
                                    label="Dirección Hotel"
                                    onChange={(e) => handleChangePasaje(e.target.value, "direccion_hotel")}
                                />
                            </Box>
                            <Button variant="contained" endIcon={<SendIcon />} onClick={ingresarPasaje}>Ingresar Pasajes</Button>
                        </div>
                    ) : ""}
                </Col>
            </Row>
        </Container>
    )
}

export default Tresd;