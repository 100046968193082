import http from "../http-common";

class PerfilService {

    create(data) {
        return http.post("/perfil",data);
    }

    getAll() {
        return http.get("/perfil");
    }

    getByID(id) {
        return http.get(`/perfil/${id}`);
    }
}

export default new PerfilService();