import React, { useEffect, useState, useRef } from "react";
import { Button, Container, Row, Col, Form, Modal, Table, Stack, TabPane, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {toast} from 'react-toastify';
import Userpool from "../Userpool";
import usuarioService from "../services/usuario.service";
import perfilService from "../services/perfil.service";


const Registro = () => {
    const [mail, setMail] = useState('');
    const [password, setPassword] = useState('');
    const [perfiles, setPerfiles] = useState([]);
    const [perfilCheck, setPerfilCheck] = useState([]);
    const [nombre, setNombre] = useState('');
    const [celular, setCelular] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        retreivePerfiles();
    }, []);

    const registrar = async () => {
        //const newUser = await Auth.signUp({username: mail, password: password});
        setLoading(true);
        console.log("Perfiles", perfilCheck);
        Userpool.signUp(mail, password, [], null, (err, data) => {
            if (err) {
                console.error(err);
                toast.error(err.message, {
                    position: "top-center",
                    autoClose: 3000
                });
                return;
            }
            console.log(data);
            const data_user = {
                ssid: data.userSub,
                nombre: nombre,
                numero: celular
            }
            usuarioService.create(data_user)
                .then(user => {
                    console.log(user);
                    for (var p in perfilCheck) {
                        console.log("Perfil ID", perfilCheck[p]);
                        const data_add = {
                            id_usuario: user.data.id,
                            id_perfil: perfilCheck[p]
                        }
                        usuarioService.addPerfil(data_add)
                            .then(uadd => {
                                console.log(uadd);
                            });
                    }
                });
        });
        setLoading(false);
        toast.info("Usuario agregado correctamente!", {
            position: "top-center",
            autoClose: 3000
        });
    }

    const retreivePerfiles = () => {
        perfilService.getAll()
            .then(perfil => {
                setPerfiles(perfil.data);
            })
            .catch(err => {
                console.error(err);
            });
    }

    const perfilChange = (e) => {
        const valor = e.target.value;
        if (e.target.checked) {
            const indx = perfilCheck.findIndex(v => v.id === valor);
            perfilCheck.splice(indx, indx >= 0 ? 1 : 0);
            perfilCheck.push(valor);
            setPerfilCheck(perfilCheck);
        }
        if (!e.target.checked) {
            const index = perfilCheck.findIndex(v => v === valor);
            perfilCheck.splice(index, 1);
            setPerfilCheck(perfilCheck);
        }
    }

    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Correo:</Form.Label>
                            <Form.Control type="text" onChange={(e) => setMail(e.target.value)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Password:</Form.Label>
                            <Form.Control type="text" onChange={(e) => setPassword(e.target.value)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Nombre:</Form.Label>
                            <Form.Control type="text" placeholder="Ej. Daniel Gonzalez" onChange={(e)=>setNombre(e.target.value)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Numero Celular:</Form.Label>
                            <Form.Control type="text" placeholder="+56911223344" onChange={(e)=>setCelular(e.target.value)} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Perfiles:</Form.Label>
                            {perfiles.map(p => {
                                return (
                                    <Form.Check type="checkbox" id={p.id} label={p.nombre} value={p.id} onChange={(e) => perfilChange(e)} />
                                )
                            })}
                        </Form.Group>
                        <div className="mt-3"></div>

                        <div className="mt-3"></div>
                        <Button variant="success" onClick={registrar}>{loading && (<span className="spinner-border spinner-border-sm"></span>)}<span>Registrar</span></Button>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Registro;