import http from "../http-common";

class CCService {

    create(data) {
        return http.post("/centrocosto",data);
    }

    getAll() {
        return http.get("/centrocosto");
    }

    getbyID(id) {
        return http.get(`/centrocosto/${id}`);
    }

    updateCC(id, data) {
        return http.put(`/centrocosto/${id}`,data);
    }

    eliminarCC(id) {
        return http.delete(`/centrocosto/${id}`);
    }
}

export default new CCService();