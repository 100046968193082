import http from "../http-common";

class MotivosdService {

    create(data) {
        return http.post("/motivosd",data);
    }

    getAll() {
        return http.get("/motivosd");
    }

    updateMotivo(id, data) {
        return http.put(`/motivosd/${id}`,data);
    }

    eliminarMotivo(id) {
        return http.delete(`/motivosd/${id}`);
    }
}

export default new MotivosdService();